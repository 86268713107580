/**
 * @name flowClipboardCopy
 * @module flowingly.directives
 * @description Displays a copy icon and copies the target value to the clipboard when clicked.
 *
 * @usage
 * ```
 *   <div flow-clipboard-copy="$ctrl.property">
 *     The icon will appear here:
 *   </div>
 * ```
 */
import angular from 'angular';

angular
  .module('flowingly.directives')
  .directive('flowClipboardCopy', function () {
    return {
      link: link,
      restrict: 'A'
    };

    function link(
      scope: angular.IScope,
      element: JQuery,
      attrs: angular.IAttributes
    ) {
      let targetValue = '';
      scope.$watch(attrs.flowClipboardCopy, (newValue: string) => {
        targetValue = newValue;
      });

      const container = document.createElement('div');
      container.classList.add('flow-clipboard-copy');

      const confirmation = document.createElement('div');
      confirmation.textContent = 'Copied!';
      confirmation.style.display = 'none';

      const icon = document.createElement('i');
      icon.classList.add('fa-light', 'fa-copy', 'fa-lg');
      icon.title = 'Copy to clipboard';
      icon.addEventListener('click', () =>
        copyToClipboard(targetValue).then(() => showConfirmation(confirmation))
      );

      container.append(icon, confirmation);
      element.append(container);
    }

    function copyToClipboard(text: string) {
      return navigator.clipboard.writeText(text);
    }

    function showConfirmation(confirmation: HTMLDivElement) {
      confirmation.style.display = 'block';
      setTimeout(() => {
        confirmation.style.display = 'none';
      }, 1000);
    }
  });
