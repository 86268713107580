/**
 * @ngdoc directive
 * @name flowFileInput
 * @module flowingly.directives.files
 * @description A directive for responding to file change on a input type file
 * ### Notes
 * See Also: http://odetocode.com/blogs/scott/archive/2013/07/05/a-file-input-directive-for-angularjs.aspx
 * 
   ####Attributes
   * item : The item to render
 * 
 * @usage
 * ```
       <div file-input="file" on-change="readFile()"></div>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/files/flowFileInput.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.directives.files').directive('flowFileInput', [
  '$parse',
  function ($parse: angular.IParseService) {
    return {
      restrict: 'EA',
      link: function (
        scope: angular.IScope,
        element: JQuery<HTMLInputElement>,
        attrs: angular.IAttributes
      ) {
        const modelGet = $parse(attrs.flowFileInput);
        const modelSet = modelGet.assign;
        const onChange = $parse(attrs.onChange);

        const updateModel = function () {
          modelSet(scope, element[0].files[0]);
          onChange(scope);
        };
        element.on('change', updateModel);
      }
    };
  }
]);
