/**
 * @ngdoc service
 * @name zxcvbnAdapter
 * @module flowingly.adapters
 *
 * @description Wraps the global zxcvbn Object.
 *
 * ## Notes
 * zxcvbn is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global zxcvbn object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes zxcvbn has already been loaded on the page.
 */

'use strict';
import angular, { IWindowService } from 'angular';
angular.module('flowingly.adapters').factory('zxcvbnAdapter', [
  '$window',
  function ($window: IWindowService) {
    if ($window.zxcvbn == undefined) {
      console.error('zxcvbn is not loaded');
    }
    return $window.zxcvbn;
  }
]);
