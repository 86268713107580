/**
 * @ngdoc module
 * @name flowingly.bpmn.modeler
 * @module flowingly.bpmn.modeler
 * @requires flowingly.adapters
 * @description Contains services and directives for wrapping GoJS and implementing the GoJS BPMN editor found here
 *              http://gojs.net/latest/extensions/BPMN.html
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.module.js?at=master
 */

'use strict';
import angular from 'angular';
angular.module('flowingly.bpmn.modeler', ['flowingly.adapters']);
