/*
 * Converted to ts on 15/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/dfdceaa510b13a54e60a5db2256bda446bf4dec4/src/Flowingly.Shared.Angular/flowingly.directives/xss.validation.directive.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular, { IAttributes, INgModelController, IScope } from 'angular';

angular.module('flowingly.directives').directive('flowinglyXssValidation', [
  'validationService',
  function (validationService: Services.ValidationService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (
        scope: IScope,
        elem: JQuery<HTMLElement>,
        attr: IAttributes,
        ctrl: INgModelController
      ) {
        // add a parser that will process each time the value is
        // parsed into the model when the user updates it.
        ctrl.$parsers.unshift(function (value) {
          // test and set the validity after update.
          const valid = !validationService.isXssVulnerableString(value);
          ctrl.$setValidity('xssValidate', valid);

          return value;
        });

        // add a formatter that will process each time the value
        // is updated on the DOM element.
        ctrl.$formatters.unshift(function (value) {
          // validate.
          ctrl.$setValidity(
            'xssValidate',
            !validationService.isXssVulnerableString(value)
          );
          // return the value or nothing will be written to the DOM.
          return value;
        });
      }
    };
  }
]);
