import './flowingly.adapters/flowingly.adapters.module';
import './flowingly.bpmn.modeler/flowingly.bpmn.module';

import './flowingly.components/components.module';

import './flowingly.directives/directives.module';
import './flowingly.directives/delegation-banner/delegation-banner.module';
import './flowingly.filters/filters.module';
import './flowingly.directives/files/files.module';
import './flowingly.directives/user-notifications/user.notifications.module';
import './flowingly.directives/user-profile/user.profile.module';

import './flowingly.modeler.validation/validation.module';

import './flowingly.services/flowingly.services.module';

/**
 * This module act like a main module to create all modules for angular.shared app
 * This module also define APP_CONFIG the way the runner does. APP_CONFIG will have values populated via .env files at build time
 * I notice that templates-directives & templates-components are all emptied modules but referenced by other modules
 * I create them here to avoid errorsbut will remove all references to them later
 */

('use strict');

angular.module('templates-directives', []);
angular.module('templates-components', []);
