/**
 * @name flowinglyScroll
 * @module flowingly.directives
 * @description Add ability to scroll to current element
 * @param id - element id (essential for this directive to functional)
 * @param needScroll - set to true to enable scrolling
 * @param expand - if no needScroll attribute defined will use expand to control enable scrolling
 * @param scrollOffset - default scroll by 50 extra pixels
 * ### Notes
 * @usage
 * ```
    <flow id="{{ 'flow_' + flow.id }}" flowingly-scroll flow="flow" need-scroll="true" scroll-offset="100" on-click-method="$ctrl.toggleItem(id)" expand="flow.expanded" is-mobile="$ctrl.isMobile" user-id="$ctrl.userId"></flow>
 * ```
 *
 * Converted to ts on 15/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/scroll.directive.js?at=master
 */

'use strict';
import angular, {
  IAnchorScrollService,
  IAttributes,
  ILocationService,
  IScope,
  ITimeoutService
} from 'angular';

angular.module('flowingly.directives').directive('flowinglyScroll', [
  '$timeout',
  '$location',
  '$anchorScroll',
  function (
    $timeout: ITimeoutService,
    $location: ILocationService,
    $anchorScroll: IAnchorScrollService
  ) {
    return {
      restrict: 'A',
      link: function (
        scope: IScope,
        element: JQuery<HTMLElement>,
        attrs: IAttributes
      ) {
        const scrollFlag =
          attrs.needScroll !== undefined ? attrs.needScroll : attrs.expand;
        let scrollWatch;
        $anchorScroll.yOffset =
          attrs.scrollOffset !== undefined ? attrs.scrollOffset : 90; // default scroll by 70 extra pixels

        if (scrollFlag !== undefined) {
          scrollWatch = scope.$watch(scrollFlag, function (newVal) {
            if (newVal && attrs.id) {
              $timeout(function () {
                if ($location.hash() !== attrs.id) {
                  $location.hash(attrs.id);
                } else {
                  $anchorScroll();
                }
              }, 200);
            }
          });
        }

        scope.$on('$destroy', function () {
          if (scrollWatch) {
            scrollWatch();
          }
        });
      }
    };
  }
]);
