import angular from 'angular';
import { CURRENCIES } from './data/currencies_24_10_2019';
import { ICurrencyRecord } from '../interfaces/ICurrencyRecord';

export class CurrencyService {
  private DEFAULT_CURRENCY: ICurrencyRecord = {
    symbol: '$',
    code: '',
    name: ''
  };

  public getCurrencies(): ICurrencyRecord[] {
    /* taken from currencies_24_10_2019.json */
    return CURRENCIES;
  }

  /**
   * Given a code USD/NZD/PHP return the currency details.
   * Note: not all currencies have an equivalent symbol. In these cases
   * they will be left blank.
   *
   * @param code
   */
  public getCurrencyByCode(code: string): ICurrencyRecord {
    // our current ts setup in shared does not support
    // es6 higher order operations on arrays yet
    return (CURRENCIES as any).find((c: ICurrencyRecord) => c.code == code);
  }

  /**
   * Given a string, parse it as a float number
   * This works for the following string inputs.
   *      ["1000", "1,234,567.25", "$100.23", "KR100.23 KRN", "$1,234 NZD", "500.13 NZD","-32.22 EUR","-kr3,3003.00 NOK"]
   *
   * @param str
   */
  public parseNumber(item: number | string) {
    switch (typeof item) {
      case 'number':
        return item;
      case 'string': {
        const digits = (item as string).match(/[-0-9.]/g);
        if (digits && digits.length) {
          return +digits.join('');
        } else {
          return NaN;
        }
      }
      default:
        return NaN;
    }
  }

  public toCurrency(item: any, currencyCode: string): string {
    switch (typeof item) {
      case 'number':
        return this.numberToCurrency(item, currencyCode);
    }
  }

  private numberToCurrency(num: number, currencyCode: string): string {
    const currency: ICurrencyRecord =
      this.getCurrencyByCode(currencyCode) || this.DEFAULT_CURRENCY;
    const numberAsText = new Intl.NumberFormat('en-us', {
      maximumFractionDigits: 2
    }).format(Math.abs(num));
    const sign = num < 0 ? '-' : '';

    return `${sign}${currency.symbol}${numberAsText} ${currency.code}`;
  }
}

angular
  .module('flowingly.services')
  .service('currencyService', CurrencyService);

export type CurrencyServiceType = InstanceType<typeof CurrencyService>;
