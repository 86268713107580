/**
 * @name intercomKnowledgebaseLink
 * @module flowingly.runner.directives
 * @description display knowledgebase link and capture events for intercom
 * ### Notes
 * 
 * @usage
 * ```
    <intercom-knowledgebase-link></intercom-knowledgebase-link>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/4a74b41352c8d2a4d731ee35ac40bfd3e7c08f1f/src/Flowingly.Shared.Angular/flowingly.directives/intercom.knowledgebase.link.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';
angular.module('flowingly.directives').component('intercomKnowledgebaseLink', {
  bindings: {
    link: '<',
    title: '<'
  },
  controller: [
    '$location',
    'intercomService',
    function (
      $location: angular.ILocationService,
      intercomService: Services.IntercomService
    ) {
      const $ctrl = this;

      $ctrl.triggerIntercomEvent = triggerIntercomEvent;

      function triggerIntercomEvent() {
        intercomService.trackEvent('Clicked Knowledge Base Link', {
          Page: $location.path()
        });
      }
    }
  ],
  template: `
                <a href="{{$ctrl.link}}" title="{{$ctrl.title}}" ng-click="$ctrl.triggerIntercomEvent()" class="KnowledgebaseLink" target="_">
                    <i class="fa-light fa-question-circle" aria-hidden="true"></i>
                </a>
            `
});
