import angular from 'angular';
import { Services } from '@Shared.Angular/@types/services';
angular
  .module('flowingly.services')
  .factory('appInsightsHelper', appInsightsHelper);

appInsightsHelper.$inject = ['sessionService'];

function appInsightsHelper(sessionService: Services.SessionService) {
  const getCustomProps = () => {
    const userId = sessionService.getUser()?.id;
    const businessName = sessionService.getBusinessName();

    return {
      userId,
      businessName
    };
  };

  return { getCustomProps };
}

export type AppInsightsHelperType = ReturnType<typeof appInsightsHelper>;
