/**
 * @name flowIfPermissions
 * @module flowingly.directives
 * @description Custom ng-if implementation that shows the elmenent only if the user has permissions.
 * ### Notes
 * see: based on official ng-if directive
 *       https://github.com/angular/angular.js/blob/master/src/ng/directive/ngIf.js
 *
 *
 * NOTE: TODO: current implementation requires that sessionService is injected.
 *       TODO: this service is defined in the runner, not this shared area
 *       TODO: however will be mobing to one codebase soon, so no biggie
 *       Alternate solution is to pass user permissions in as array
 *
 * @usage
 * ```
 *   //show div if the user has permission1
 *   <div if-permitted="['permission1']" ></div>
 *
 *   //show div if the user has permission1 AND permission2
 *   <div if-permitted="['permission1', 'permission2']" ></div>*
 *
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/permissions/flow.if.permissions.directive.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.directives').directive('ifPermitted', [
  '$parse',
  'permissionsService',
  function ifPermitted($parse, permissionsService) {
    return {
      multiElement: true,
      transclude: true,
      priority: 400,
      terminal: false,
      restrict: 'A',
      link: function (
        $scope: angular.IScope,
        $element: JQuery<Element>,
        $attr: angular.IAttributes,
        ctrl: angular.IController,
        $transclude: angular.ITranscludeFunction
      ) {
        const requiredPermissions = $parse($attr.ifPermitted)($scope);

        //note this code runs once, on instantiation, it will not respond to changes in permissions
        //original version used a watch to respond to changes (see link above), but this causes other issuesm since
        //we dont need to respond to changes right now, simplify.
        doPermissionCheck();

        function doPermissionCheck() {
          let meetsPermissions = false;

          //if there are no permissions defined; then we dont need to check
          const skipCheck =
            requiredPermissions === undefined ||
            requiredPermissions.length === 0;

          if (!skipCheck) {
            meetsPermissions =
              permissionsService.currentUserHasPermissions(requiredPermissions);
          }

          if (skipCheck || meetsPermissions) {
            $transclude(function (clone) {
              $element.append(clone);
            });
          }
        }
      }
    };
  }
]);
