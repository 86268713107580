/**
 * @ngdoc module
 * @name flowingly.directives
 * @module flowingly.adapters
 * @requires templates-directives
 * @description Directives shared across our websites
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/directives.module.js?at=master
 */

'use strict';
import angular from 'angular';

const directiveModule = angular.module('flowingly.directives', [
  'templates-directives'
]);

directiveModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'intercom.message.launcher.tmpl.html',
      require('./intercom.message.launcher/intercom.message.launcher.tmpl.html')
        .default
    );
  }
]);
