/**
 * @ngdoc service
 * @name pdfService
 * @module flowingly.Shared.Angular
 *
 * @description A service for creating pdf print out of the page
 *
 * COnverted to ts on 14/01/2020
 * SEe https://bitbucket.org/flowingly-team/flowingly-source-code/src/a8200d348d2dee955dac9d2abe50ca9470a92923/src/Flowingly.Shared.Angular/flowingly.services/pdf.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { Services } from '@Shared.Angular/@types/services';
const TEXT_NODE = 3;
const EL_NODE = 1;

angular.module('flowingly.services').service('pdfService', pdfService);

pdfService.$inject = ['kendoService', '$q', '$timeout', 'jQuery', 'svgService'];
function pdfService(
  kendo: Kendo,
  $q: angular.IQService,
  $timeout: angular.ITimeoutService,
  $: JQueryStatic,
  svgService: Services.SvgService
) {
  const CONTAINER_STYLES = [
    'padding',
    'margin',
    'position',
    'top',
    'left',
    'right',
    'bottom'
  ];
  const TEXT_STYLES = ['font-size', 'line-height']; // text style that must use the style attribute
  const SVG_TEXT_STYLES = ['font-weight', 'font-family']; // text styles that can be directly applied to svg as an attr

  const pdfService = {
    utility: {
      domToImage: domToImage,
      stringToImage: stringToImage
    },
    from: {
      dom: fromDom
    },
    size: {
      // all sizes here are in inches
      a4: [6.2, 8.7],
      a4_landscape: [8.7, 6.2],
      letter: [6.3, 8.2],
      letter_landscape: [8.2, 6.3]
    }
  };

  return pdfService;

  function fromDom(selector, fileName, config: any = {}) {
    let { size } = config;
    if (!size) size = pdfService.size.a4_landscape;

    const $dom = $(selector).clone();
    $dom
      .addClass('pdf-export-container')
      .addClass('pdf-export')
      .width(`${size[0]}in`)
      .find('.pdf-exclude')
      .remove();
    if (config.pdfStyleClass) {
      $dom.addClass(config.pdfStyleClass);
    }

    $('body').append($dom);

    return $q
      .resolve()
      .then(() => config.onPreDraw && config.onPreDraw({ element: $dom }))
      .then(() => kendo.drawing.drawDOM($dom))
      .then((contents) => kendo.drawing.pdf.saveAs(contents, `${fileName}.pdf`))
      .catch(console.error)
      .then(() => !(window as any)._flowinglyPdfExportKeepDom && $dom.remove());
  }

  /**
   *
   * @param {any} dom
   * @returns {Promise}
   */
  function domToImage($dom: any) {
    if (!($dom instanceof $)) {
      throw new Error('pdfService.domToImage requires a jquery object');
    }
    let imgPr;
    switch ($dom[0].nodeType) {
      case TEXT_NODE:
        imgPr = textNodeToImage($dom);
        break;
      case EL_NODE:
        imgPr = elementNodeToImage($dom);
        break;
      default:
        imgPr = $q.resolve();
    }
    return imgPr;
  }

  function elementNodeToImage($dom) {
    const $svg = htmlToSvg($dom.outerHTML());

    const height = parseInt($dom.outerHeight(true)) + 15 + 'px';

    $svg.attr('width', $dom.outerWidth(true));
    $svg.attr('height', height);

    $svg.find('.svg-payload').css($dom.css(TEXT_STYLES)); // copy text styles`
    $svg.find('.svg-payload').css($dom.css(CONTAINER_STYLES)); // copy text styles`
    $svg.find('.svg-payload').attr(getSVGStyles($dom)); // apply styles as svg attr
    return svgToImage($svg);
  }

  /**
   *
   * @param {any} dom
   * @returns {Promise}
   */
  function textNodeToImage($dom) {
    const text = $dom[0].textContent;
    const $svg = htmlToSvg(text);
    const width = parseInt($dom.textWidth()) + 10 + 'px'; //makeup for the font differences. this number is arbitrary
    const height = parseInt($dom.textHeight()) + 15 + 'px';

    $svg.attr('width', width);
    $svg.attr('height', height);

    $svg.find('.svg-payload').css($dom.parent().css(TEXT_STYLES)); // copy text styles`
    $svg.find('.svg-payload').attr(getSVGStyles($dom.parent())); // copy text styles`

    return svgToImage($svg);
  }

  function getSVGStyles($dom) {
    const styles = $dom.css(SVG_TEXT_STYLES);
    styles['font-family'] = styles['font-family'].replace(/"/g, "'"); // replace all double quote because we sometimes get fony-family: "Open Sans",Arial and it breaks the svg
    return styles;
  }

  function htmlToSvg(htmlContent) {
    const payload = svgService.getEmbeddableHtml(htmlContent);
    return $(`
                <svg xmlns = "http://www.w3.org/2000/svg">
                    <foreignObject width="100%" height="100%">
                        <text class="svg-payload" xmlns="http://www.w3.org/1999/xhtml"> ${payload}</text>
                    </foreignObject>
                </svg >
            `);
  }

  /**
   *
   * @param {any} $svg
   * @returns {Promise}
   */
  function svgToImage($svg) {
    if (parseInt($svg.attr('width')) == 0) {
      throw new Error('Expected a non zero width . Got zero.');
    }

    if (parseInt($svg.attr('height')) == 0) {
      throw new Error('Expected a non zero height . Got zero.');
    }

    const svgAsString = $svg.outerHTML();
    const src = 'data:image/svg+xml,' + encodeURIComponent(svgAsString);
    return stringToImage(src)
      .then(($img) => {
        const canvas = document.createElement('canvas');
        const $canvas = $(canvas);
        const ctx = canvas.getContext('2d');

        canvas.width = $img.width();
        canvas.height = $img.height();

        $canvas.appendTo(document.body); // need to append to get the correct width and height
        return $timeout(1000).then(() => {
          ctx.drawImage($img[0], 0, 0); // draw twice to make it bolder
          ctx.drawImage($img[0], 0, 0); // // draw twice to make it bolder

          const dataUrl = canvas.toDataURL();
          if (dataUrl == 'data:,') {
            throw new Error('Could not draw svg into canvas ' + src);
          }

          return stringToImage(dataUrl, true).then(($canvasImg) => {
            $canvasImg.addClass('from-html-svg');

            // clean up to remove old svg
            $img.remove(); // destroy temporary image
            $canvas.remove(); // destroy temporary canvas
            return $canvasImg;
          });
        });
      })
      .catch(() => {
        console.error(
          'There was an error in conveting the payload to xml ',
          svgAsString
        );
      });
  }

  /**
   * Creates an image dom with the _src_ as the contents
   * of the image.
   *
   * @param {string} _src_
   * @returns {Promise}
   */
  function stringToImage(_src_, useRaw = false) {
    let hasLoaded = false;

    const src = useRaw ? _src_ : _src_.replace(/\s+/g, ' ').trim();
    const $img = $(document.createElement('img'));

    $(document.body).append($img); // need to append to get the correct width and height
    const $deferred = $q.defer<JQuery<HTMLImageElement>>();

    const onError = (err) => {
      $deferred.reject(new Error(err));
    };

    const onResolve = () => {
      hasLoaded = true;
      $deferred.resolve($img);
    };

    $img.on('error', onError);
    $img.on('load', onResolve);
    $timeout(() => {
      if (hasLoaded == false)
        $img.prop('complete')
          ? onResolve()
          : onError('Setting src timeout' + src);
    }, 5000);

    $img.attr('src', src);
    return $deferred.promise;
  }
}

export type PDFServiceType = ReturnType<typeof pdfService>;
