/**
 * @ngdoc service
 * @name papaParseService
 * @module flowingly.adapters
 *
 * @description Wraps the global Papa Object.
 *
 * ## Notes
 * Papa is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global Papa object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes Papa has already been loaded on the page. Papa Parse is a library to parse csv to json and more, refer http://papaparse.com/docs for more information.
 */
(function () {
  'use strict';

  angular.module('flowingly.adapters').factory('papaParseService', [
    '$window',
    function ($window) {
      if ($window.Papa == undefined) {
        console.error('Papa Parse is not loaded');
      }
      return $window.Papa;
    }
  ]);
})();
