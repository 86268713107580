/**
 * @ngdoc service
 * @name brandingService
 * @module flowingly.services
 *
 * @description A service for changing branding
 *
 */

import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('brandingService', brandingService);

brandingService.$inject = ['APP_CONFIG'];

function brandingService(APP_CONFIG: Services.APP_CONFIG) {
  const service = {
    setPageTitle: setPageTitle,
    setFavIcon: setFavIcon,
    usingDefaultBrandName: usingDefaultBrandName,
    getBrandedHelpUri: getBrandedHelpUri
  };

  return service;

  //////////// Public API Methods

  function setPageTitle(newTitle: string) {
    document.title = newTitle;
  }

  function setFavIcon(newFavIcon: string) {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = newFavIcon;
  }

  function usingDefaultBrandName() {
    return APP_CONFIG.brandingName === 'Flowingly';
  }

  function getBrandedHelpUri(uri: string) {
    return usingDefaultBrandName() ? uri : APP_CONFIG.brandingHelpUri;
  }
}

export type BrandingServiceType = ReturnType<typeof brandingService>;
