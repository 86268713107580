/**
 * @ngdoc directive
 * @name flowTableBuilderCellEditor
 * @module flowingly.components
 * @description This comppnent displays an editor used to configure the cells of a table.
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Dynamic+Table+Builder
 * ### Notes
 * ### Properties
 * * cell : the cell to be edited
 * * cells : a list of all cells
 * * onCellChanged : method to call each time the cell format is changed
 * * onOverlayClicked : method to call each time the overlay is clicked
 * @usage
 * ```
  <flow-table-builder-cell-editor ng-show="$ctrl.activeCell" cell="$ctrl.activeCell" cells="$ctrl.cells" on-cell-changed="$ctrl.updateTableSchema(cell)"></flow-table-builder-cell-editor>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/825435bf2da8dee2e2049804b1c5a431dfc7b33c/src/Flowingly.Shared.Angular/flowingly.components/tablebuilder/table.builder.cell.editor.component.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.components')
    .component('flowTableBuilderCellEditor', {
      bindings: {
        cell: '<',
        cells: '<', //list of all cells
        allFields: '<',
        fieldName: '<',
        onCellChanged: '&',
        onOverlayClicked: '&'
      },
      require: {
        parent: '^flowTableBuilder'
      },
      controller: [
        '$element',
        'APP_CONFIG',
        function ($element, APP_CONFIG) {
          var _lastHeight = 0; //height of the container, the last time a cell was edited
          var $ctrl = this;

          this.$onInit = function () {
            $ctrl.types = $ctrl.parent.types;
            $ctrl.options = [
              { value: $ctrl.types.text, text: 'Text' },
              { value: $ctrl.types.currency, text: 'Currency' },
              { value: $ctrl.types.number, text: 'Number' },
              { value: $ctrl.types.file, text: 'File Upload' },
              { value: $ctrl.types.date, text: 'Date' },
              { value: $ctrl.types.dropdown, text: 'Dropdown List' },
              { value: $ctrl.types.lookup, text: 'Lookup' }
            ];
            if (APP_CONFIG.enableTableFormula) {
              $ctrl.options.push({
                value: $ctrl.types.formula,
                text: 'Formula'
              });
            }

            if (
              $ctrl.cell.type === $ctrl.types.dropdown &&
              $ctrl.cell.dbDataSource === undefined
            ) {
              $ctrl.cell.dbDataSource = {
                dbName: '',
                displayValue: '',
                filters: [
                  {
                    column: '',
                    operation: '',
                    value: ''
                  }
                ]
              };
            } else if (
              $ctrl.cell.type === $ctrl.types.lookup &&
              $ctrl.cell.lookupConfig === undefined
            ) {
              $ctrl.cell.lookupConfig = {
                dbName: '',
                displayValue: '',
                queryValue: '',
                value: ''
              };
            }
          };

          this.$onChanges = function (changes) {
            if (changes.cell) {
              //make a copy of the data - this breaks two way binding.
              // [ Data passed down through one-way bindings are not $watch-ed by Angular,
              // however they are passed by reference. This means any changes we make to Objects
              // (specifically, primitives are not bound by reference) actually affect the parent ]
              this.cell = angular.copy(this.cell);

              formatEditor($ctrl.cell);
            }
            if (changes.cells) {
              //open editor at this index
              var cell = $ctrl.cells[$ctrl.cells.length - 1];
              formatEditor(cell);
              $ctrl.cell = cell;
            }
          };

          function formatEditor(cell) {
            //this method moves the editor window so that it is in line with the cell it is editing.
            if (cell != undefined) {
              const rawElement = document.getElementById(
                `${$ctrl.fieldName}_cell_${cell.id}`
              );
              if (rawElement !== null) {
                const cellElement = angular.element(rawElement)[0];
                const builderRow = cellElement.offsetParent;
                if (builderRow === null) {
                  return;
                  // Repositioning the table field on the form triggers this function
                  // but during the move there's no offsetParent
                }
                cell.x = cellElement.offsetLeft;
                cell.y = cellElement.offsetTop;
                cell.w = builderRow.offsetWidth;

                const cellWidth = 575;
                const cellHeight = 100;
                const editor = angular.element(
                  $element[0].getElementsByClassName(
                    'flow-table-builder-cell-editor'
                  )
                );
                //cell.w is the width of the containing row (flow-table-builder-row).
                //take into account space available to the right
                let xPos = cell.x - 20;
                if (xPos + cellWidth > cell.w) {
                  xPos = cell.w - cellWidth;
                }

                editor.css('left', xPos + 'px');
                editor.css('top', cell.y + cellHeight + 'px');
              }

              //finally update the height of the container to accomodate the editor
              updateContainerHeight(cell);
            } else {
              //reset the container height
              updateContainerHeight();
            }
          }

          function updateContainerHeight(cell?) {
            //if there is a cell, expand container to allow editor to edit it
            //else shrink conainer
            var containerPaddingWhenEditorOpen = 400;

            if (cell) {
              var container = angular.element(
                document.querySelector('#tableBuilder')
              );
              _lastHeight = containerPaddingWhenEditorOpen + cell.y;
              container.css('min-height', _lastHeight + 'px');
            } else {
              var container = angular.element(
                document.querySelector('#tableBuilder')
              );
              container.css(
                'min-height',
                _lastHeight - containerPaddingWhenEditorOpen + 'px'
              );
            }
          }
        }
      ],
      templateUrl: 'table.builder.cell.editor.component.tmpl.html'
    });
})();
