/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.filters/utcToLocal.filter.js?at=master
 */

'use strict';
import angular, { IFilterService } from 'angular';

angular.module('flowingly.filters').filter('utcToLocal', [
  '$filter',
  function utcToLocal($filter: IFilterService) {
    return function (utcDateString: string, format: string) {
      if (!utcDateString) {
        return;
      }
      // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
      if (
        utcDateString.indexOf('Z') === -1 &&
        utcDateString.indexOf('+') === -1
      ) {
        utcDateString += 'Z';
      }

      return $filter('date')(utcDateString, format);
    };
  }
]);
