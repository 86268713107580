/**
 * @ngdoc directive
 * @name dialogWarningBox
 * @module flowingly.components
 * @description A component for warning box
 * @usage
 * ```
 *    <dialog-warning-box>
      </dialog-warning-box>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/67bd6e8112f8f40ecb80b6e12e9fe8e6aba1a94f/src/Flowingly.Shared.Angular/flowingly.components/dialogwarningbox/dialog.warning.box.component.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('dialogWarningBox', {
    bindings: {
      paragraphs: '<'
    },

    templateUrl: 'dialog.warning.box.component.tmpl.html'
  });
})();
