/**
 * @ngdoc directive
 * @name flowFileInput
 * @module flowingly.directives.files
 * @description A directive for responding to file change on a input type file
 * ### Notes
 * It is not possible to bind to input File type in angular so this is not possible: onchange="onFileChange(this)
 * See Also: https://github.com/angular/angular.js/issues/1375.
 * 
    This directive resolve that.
    This directive was developed to be added to a form using the angular-form-gen
    Unfortunately due to the multiple nested isolated scopes it is not possible to bind
    the change event to a handler method on the controller.
    Therefore to get around this, we publish an event.

    We need to update ng-model as the selected file needs to be
    associated to a form field in order to be saved on the API
    FlowModel is the model for the fileuplod input that comes from angular-form-gen.
    We cannot set the model here as it will cause a model change update and fire of another file upload!
    scope.flowModel[ngModelCtrl.$name] = file.name;

   ####Attributes
   * flowModel : The flowModel associated with the input
 * 
 * @usage
 * ```
       <div file-input="file" flow-publish-file-change"></div>
 * ```
 *
 * Converted to ts o 16/01/2020
 * see https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/files/flowPublishFileChange.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.directives.files')
  .directive('flowPublishFileChange', ['pubsubService', flowPublishFileChange]);

function flowPublishFileChange(pubsubService: Services.PubSubService) {
  const directive = {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      flowModel: '='
    },
    link: function (
      scope: angular.IScope,
      element: JQuery<HTMLInputElement>,
      attrs: angular.IAttributes,
      ngModelCtrl: angular.IController
    ) {
      function onChangeHandler(event) {
        event.stopPropagation();
        if (element[0] && element[0].files && element[0].files.length > 0) {
          const files = element[0].files;

          //we need to update ng-model as the selected file needs to be
          //associated to a form field in order to be saved on the API
          //FlowModel is the model for the fileuplod input that comes from angular-form-gen
          //cannot set the model here as it will cause a model change update and fire of another file upload!
          //scope.flowModel[ngModelCtrl.$name] = file.name;

          //so therefore pass the data in the event
          const filesToUpload = { files: files, key: ngModelCtrl.$name };
          pubsubService.publish('FILE_SELECTED', filesToUpload);
        }
      }

      element.bind('change', onChangeHandler);

      scope.$on('$destroy', function () {
        element.unbind('change', onChangeHandler);
      });
    }
  };

  return directive;
}
