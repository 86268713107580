/**
 * @ngdoc directive
 * @name onFileChange
 * @module flowingly.components
 * @description It is not possible to bind to input File type in angular. So this is not possible: onchange="onFileChange(this)
 * https://github.com/angular/angular.js/issues/1375.
 * ### Notes
 * The use case here is slightly different so we cannot resuse the shared version of this directive.
 * @usage
 * ```
        <input class="not-clickable"
               id="{{$ctrl.cell.row }}{{ $ctrl.cell.id }}"
               type="file"
               name="fileUpload"
               ng-model="$ctrl.filepath"
               on-file-change="$ctrl.onFileChange"
               tabindex="{{ tabIndex }}"/>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.components/tablerunner/table.runner.fileChange.js?at=master
 */
angular
  .module('flowingly.components')
  .directive('onFileChange', [onFileChange]);

function onFileChange() {
  var directive = {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var onChangeHandler = scope.$eval(attrs.onFileChange);

      element.bind('change', onChangeHandler);

      element.bind('click', function () {
        //clear the value so that we can select a file with the same name
        element[0].value = '';
      });

      scope.$on('$destroy', function () {
        element.unbind('change', onChangeHandler);
      });
    }
  };

  return directive;
}
