/**
 * @ngdoc module
 * @name flowingly.directives
 * @module flowingly.adapters
 * @requires templates-services
 * @description Services shared across our websites
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.services/flowingly.services.module.js?at=master
 */

'use strict';
import angular from 'angular';

const m = angular.module('flowingly.services', []);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'confirmDialog.tmpl.html',
      require('./confirmDialog.tmpl.html').default
    );

    $templateCache.put(
      'services.message.dialog.tmpl.html',
      require('./services.message.dialog.tmpl.html').default
    );

    $templateCache.put(
      'unPublishFlowDialog.tmpl.html',
      require('./unPublishFlowDialog.tmpl.html').default
    );
  }
]);
