'use strict';
import angular from 'angular';

angular
  .module('flowingly.directives')
  .directive('illegalCharacterStrip', function () {
    function link(scope, elem, attrs, ngModel) {
      ngModel.$parsers.push(function (value) {
        const illegalCharacters = new RegExp(
          `[${scope.illegalCharacters}]+`,
          'g'
        );
        if (!illegalCharacters.test(value)) {
          return value;
        }
        const legalValue = value.replace(illegalCharacters, '');
        ngModel.$setViewValue(legalValue);
        ngModel.$render();
        return legalValue;
      });
    }

    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        illegalCharacters: '@'
      },
      link: link
    };
  });
