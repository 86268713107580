/**
 * @ngdoc service
 * @module flowingly.services
 * @name workflowModelFactory
 *
 * @description A factory for creating Workflow models
 */

import { IQService } from 'angular';
import { ActorTypes, NodeCategory } from './flowingly.constants';
import { IFlowModel, IWorkflow } from '@Shared.Angular/@types/workflow';
import { Services } from '@Shared.Angular/@types/services';
import IModelNode from '@Shared.Angular/@types/modelNode';

angular
  .module('flowingly.services')
  .factory('workflowModelFactory', workflowModelFactory);

workflowModelFactory.$inject = [
  '$q',
  'goService',
  'avatarService',
  'sessionService',
  'guidService',
  'flowinglyModelUtilityService',
  'flowinglyConstants',
  'userApiService'
];

function workflowModelFactory(
  $q: IQService,
  goService,
  avatarService,
  sessionService: Services.SessionService,
  guidService,
  flowinglyModelUtilityService,
  flowinglyConstants,
  userApiService
) {
  //API
  const service = {
    createFlowModel: createFlowModel,
    loadFlowModel: loadFlowModel,
    createGoJsModel: createGoJsModel,
    createNodes: createNodes
  };

  return service;

  /// PUBLIC API METHODS /////////////////////////////////////////

  function createFlowModel(name = '', description = '') {
    ///
    /// Creates an empty Flow Model object
    /// Initalises to default state (e.g. UpdatedDate is set to now)
    ///
    return $q((resolve) => {
      if (sessionService.inTenantBusiness()) {
        userApiService.getUsersWithOptions({ pageSize: 1 }).then((result) => {
          resolve(result.users[0]);
        });
      } else {
        resolve(null);
      }
    }).then((tenantUser) => {
      const user = sessionService.getUser();

      const workflow: IWorkflow = {
        errors: [],
        Id: guidService.empty(),
        Name: name || '',
        Description: description || '',
        WorkflowStatusId: 1,
        Category: 'Workflow',
        CreatedById: user.id,
        BusinessId: tenantUser ? tenantUser.businessId : user.businessId,
        BusinessName: '',
        CreatedDate: new Date().toISOString(),
        UpdatedDate: new Date().toISOString(),
        EditableByCurrentUser: true,
        HasUnpublishedChanges: false,
        LastModifiedById: user.id,
        Nodes: [],
        FlowModelVersions: undefined,
        currentFlowModelVersion: undefined,
        hash: '',
        model: undefined,
        Active: false,
        FlowSchema: '',
        HasErrors: false,
        SelectedFieldForSubject: '',
        BackgroundColour: null,
        NodesAreEditable: false,
        DisableContactSupport: false,
        FeedbackCount: 0,
        CustomPlaceholder: '',
        ProcessDetail: undefined,
        IsConfidential: false
      };

      return create(workflow);
    });
  }

  function loadFlowModel(workflow: IWorkflow) {
    ///
    /// Creates a new workflow object from the passed in data
    ///
    // workflow MAY include a schema, but will not include a (go) model
    workflow.BusinessName =
      workflow.BusinessName && workflow.BusinessName !== ''
        ? workflow.BusinessName
        : '';

    return createGoJsModel(workflow.FlowSchema).then((model) => {
      workflow.model = model;
      flowinglyModelUtilityService.addFieldsToNodes(
        workflow.model.nodeDataArray,
        workflow.model.linkDataArray
      );
      workflow.FlowSchema = workflow.model.toJson(); // Now update the flowSchema
      return setModelFromSchema(workflow);
    });
  }

  function createGoJsModel(flowSchema?: string) {
    ///
    /// Creates a goModel object from an existing schema,
    /// or if none provided, a default empty one
    ///
    if (flowSchema != undefined) {
      if (flowSchema.charAt(0) === '"') {
        flowSchema = flowSchema.substr(1);
      }
      if (flowSchema.charAt(flowSchema.length - 1) === '"') {
        flowSchema = flowSchema.substr(0, flowSchema.length - 1);
      }
      const model = goService.Model.fromJson(flowSchema) as IFlowModel;
      return $q.resolve(model);
    } else {
      return createEmptySchema().then((schema) => {
        return goService.Model.fromJson(schema) as IFlowModel;
      });
    }
  }

  // These nodes underpin the goJS Model for us. In the database they allow Flows and Steps to reference
  // and work with nodes in the goJS model
  function createNodes(workflow) {
    return $q((resolve) => {
      if (sessionService.inTenantBusiness()) {
        userApiService.getUsersWithOptions({ pageSize: 1 }).then((result) => {
          resolve(result.users[0].id);
        });
      } else {
        resolve(sessionService.getUser().id);
      }
    }).then((userId) => {
      return [
        {
          Id: workflow.model.nodeDataArray[2].id,
          AssignedUserId: userId,
          AssignedGroupId: null,
          AssignedInitiator: false,
          AssignedInitiatorMananger: false,
          ModelerNodeId: workflow.model.nodeDataArray[2].id,
          NodeDataArrayIndex: 2,
          IsPublicForm: false,
          IsFirstNode: true,
          IsLastNode: false,
          PublicFormId: undefined,
          NumberOfApproversRequired: 0,
          MaxNumberOfApproversRequired: 0,
          NumberOfApproversRequiredType:
            flowinglyConstants.numberOfApproversRequiredType.ALL,
          WhenApproversSelected:
            flowinglyConstants.whenApproversSelected.RUN_TIME,
          StepName: workflow.model.nodeDataArray[2].text,
          Card: {
            dateLimit: '2019-01-01T00:00:00',
            description: '',
            formElements: []
          },
          rules: [
            {
              id: null,
              ruleName: '',
              nodeId: null,
              triggerFieldId: '',
              condition: 0,
              actions: [
                {
                  id: null,
                  action: 0,
                  applyToNodeId: null
                }
              ]
            }
          ]
        },
        {
          Id: workflow.model.nodeDataArray[3].id,
          AssignedUserId: userId,
          AssignedGroupId: null,
          AssignedInitiator: false,
          AssignedInitiatorMananger: false,
          ModelerNodeId: workflow.model.nodeDataArray[3].id,
          NodeDataArrayIndex: 3,
          IsPublicForm: false,
          IsFirstNode: false,
          IsLastNode: false,
          PublicFormId: undefined,
          NumberOfApproversRequired: 0,
          MaxNumberOfApproversRequired: 0,
          NumberOfApproversRequiredType:
            flowinglyConstants.numberOfApproversRequiredType.ALL,
          WhenApproversSelected:
            flowinglyConstants.whenApproversSelected.RUN_TIME,
          StepName: workflow.model.nodeDataArray[3].text,
          Card: {
            dateLimit: '2019-01-01T00:00:00',
            description: '',
            formElements: []
          },
          rules: [
            {
              id: null,
              ruleName: '',
              nodeId: null,
              triggerFieldId: '',
              condition: 0,
              actions: [
                {
                  id: null,
                  action: 0,
                  applyToNodeId: null
                }
              ]
            }
          ]
        },
        {
          Id: workflow.model.nodeDataArray[4].id,
          AssignedUserId: userId,
          AssignedGroupId: null,
          AssignedInitiator: false,
          AssignedInitiatorMananger: false,
          ModelerNodeId: workflow.model.nodeDataArray[4].id,
          NodeDataArrayIndex: 4,
          IsPublicForm: false,
          IsFirstNode: false,
          IsLastNode: true,
          PublicFormId: undefined,
          NumberOfApproversRequired: 0,
          MaxNumberOfApproversRequired: 0,
          NumberOfApproversRequiredType:
            flowinglyConstants.numberOfApproversRequiredType.ALL,
          WhenApproversSelected:
            flowinglyConstants.whenApproversSelected.RUN_TIME,
          StepName: workflow.model.nodeDataArray[4].text,
          Card: {
            dateLimit: '2019-01-01T00:00:00',
            description: '',
            formElements: []
          },
          rules: [
            {
              id: null,
              ruleName: '',
              nodeId: null,
              triggerFieldId: '',
              condition: 0,
              actions: [
                {
                  id: null,
                  action: 0,
                  applyToNodeId: null
                }
              ]
            }
          ]
        }
      ];
    });
  }

  /// PRIVATE METHODS //////////////////////////////////

  function create(workflow: IWorkflow) {
    return createGoJsModel()
      .then((model) => {
        workflow.model = model;
        workflow.FlowSchema = workflow.model.toJson();
        return createNodes(workflow);
      })
      .then((nodes) => {
        workflow.Nodes = nodes;
        return workflow;
      });
  }

  function setModelFromSchema(workflow: IWorkflow) {
    return createGoJsModel(workflow.FlowSchema).then((model) => {
      workflow.model = model;
      return workflow;
    });
  }

  function createEmptySchema() {
    return $q((resolve) => {
      if (sessionService.inTenantBusiness()) {
        userApiService.getUsersWithOptions({ pageSize: 1 }).then((result) => {
          resolve(result.users[0]);
        });
      } else {
        resolve(sessionService.getUser());
      }
    }).then((user) => {
      const userId = user.id;
      const fullName = user.fullName;
      const avatarUrl = avatarService.getModelerNodeAvatarUrl(userId, fullName);

      // Be very careful with the data you add in here for the initial flow model. There was a "stepType" field sitting in the Task 2 node and that
      // broke the node. I have not tried to work out why it broke it - but it did. I do wonder if it is something to do
      // with the updateStepType() method (in bpmn-diagram-directive.js) but cant fathom how it could be.
      return {
        class: go.GraphLinksModel,
        modelData: {
          position: '-550 -200' //todo calcuate cetner of screen
        },
        nodeDataArray: [
          {
            id: guidService.new(),
            key: 101,
            category: NodeCategory.EVENT,
            text: 'Start',
            eventType: 1,
            eventDimension: 1,
            item: 'start',
            IsInitial: 'true',
            loc: '79.5 268.75'
          },
          {
            id: guidService.new(),
            key: 104,
            category: NodeCategory.EVENT,
            text: 'End',
            eventType: 1,
            eventDimension: 8,
            item: 'End',
            IsFinal: 'true',
            loc: '867.00 268.75'
          },
          {
            key: 131,
            category: NodeCategory.ACTIVITY,
            text: 'Step 1',
            item: 'generic task',
            avatarUrl: avatarUrl,
            actorName: fullName,
            taskType: 0,
            loc: '251.75781250000006 268.75',
            id: guidService.new(),
            actorType: ActorTypes.USER,
            displayNotificationIcon: 'false',
            actor: userId,
            displayStepTaskIcon: false,
            displayStepIntegrationIcon: false,
            displayPublicFormIcon: false,
            displayStepRuleIcon: false,
            NotifyOnStepCreated: true,
            approvalRuleFieldName: '',
            rules: [
              {
                id: null,
                ruleName: '',
                nodeId: null,
                triggerFieldId: '',
                condition: 0,
                actions: [{ id: null, action: 0, applyToNodeId: null }]
              }
            ]
          },
          {
            key: -4,
            category: NodeCategory.ACTIVITY,
            text: 'Step 2',
            item: 'generic task',
            avatarUrl: avatarUrl,
            actorName: fullName,
            taskType: 0,
            loc: '473.00781249999994 268.75',
            id: guidService.new(),
            actorType: ActorTypes.USER,
            displayNotificationIcon: 'false',
            actor: userId,
            displayStepTaskIcon: false,
            displayStepIntegrationIcon: false,
            displayPublicFormIcon: false,
            displayStepRuleIcon: false,
            NotifyOnStepCreated: true,
            approvalRuleFieldName: '',
            rules: [
              {
                id: null,
                ruleName: '',
                nodeId: null,
                triggerFieldId: '',
                condition: 0,
                actions: [{ id: null, action: 0, applyToNodeId: null }]
              }
            ]
          },
          {
            key: -5,
            category: NodeCategory.ACTIVITY,
            text: 'Step 3',
            item: 'generic task',
            avatarUrl: avatarUrl,
            actorName: fullName,
            taskType: 0,
            loc: '696.7578125000005 268.75',
            id: guidService.new(),
            actorType: ActorTypes.USER,
            displayNotificationIcon: 'false',
            actor: userId,
            displayStepTaskIcon: false,
            displayStepIntegrationIcon: false,
            displayPublicFormIcon: false,
            displayStepRuleIcon: false,
            NotifyOnStepCreated: true,
            approvalRuleFieldName: '',
            rules: [
              {
                id: null,
                ruleName: '',
                nodeId: null,
                triggerFieldId: '',
                condition: 0,
                actions: [{ id: null, action: 0, applyToNodeId: null }]
              }
            ]
          }
        ] as IModelNode[],
        linkDataArray: [
          {
            Trigger: { Type: 'Auto', NameRef: 'ExecuteActivityCommand' },
            from: 101,
            to: 131,
            Restrictions: [{ NameRef: 'User', Type: 'Allow' }],
            points: [
              102.99999999999996, 268.75, 112.99999999999996, 268.75,
              132.25390625000003, 268.75, 132.25390625000003, 268.75,
              151.5078125000001, 268.75, 171.5078125000001, 268.75
            ]
          },
          {
            Trigger: { Type: 'Command', NameRef: 'ExecuteActivityCommand' },
            from: 131,
            to: -4,
            Restrictions: [{ NameRef: 'User', Type: 'Allow' }],
            points: [
              322.0078125000001, 268.75, 332.0078125000001, 268.75, 354.8828125,
              268.75, 354.8828125, 268.75, 377.75781249999994, 268.75,
              397.75781249999994, 268.75
            ]
          },
          {
            Trigger: { Type: 'Command', NameRef: 'ExecuteActivityCommand' },
            from: -4,
            to: -5,
            Restrictions: [{ NameRef: 'User', Type: 'Allow' }],
            points: [
              548.2578125, 268.75, 558.2578125, 268.75, 579.8828125000002,
              268.75, 579.8828125000002, 268.75, 601.5078125000005, 268.75,
              621.5078125000005, 268.75
            ]
          },
          {
            Trigger: { Type: 'Command', NameRef: 'ExecuteActivityCommand' },
            from: -5,
            to: 104,
            Restrictions: [{ NameRef: 'User', Type: 'Allow' }],
            points: [
              772.0078125000005, 268.75, 782.0078125000005, 268.75,
              802.7539062500005, 268.75, 802.7539062500005, 268.75,
              823.5000000000005, 268.75, 843.5000000000005, 268.75
            ]
          }
        ]
      };
    });
  }
}

export type WorkflowModelFactoryType = ReturnType<typeof workflowModelFactory>;
