/**
 * @name flowOptional
 * @module flowingly.directives
 * @description Add optional html to optional fields.
 * ### Notes
 * 
 * @usage
 * ```
    <label class="not-clickable" flow-optional="{{field.schema.validation.required!=true}}">{{field.schema.displayName}}</label>
 * ```
 *
 * Converted to ts on 15/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/optional.directive.js?at=master
 */

'use strict';
import angular, { IScope, IAttributes } from 'angular';

angular.module('flowingly.directives').directive('flowOptional', function () {
  return {
    restrict: 'A',
    link: function (scope: IScope, element: JQuery, attrs: IAttributes) {
      if (attrs.flowOptional === 'true' || attrs.flowOptional === '') {
        element.append("<span class='optional'></span>");
      }
    }
  };
});
