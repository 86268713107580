'use strict';
import angular from 'angular';

/**
 * How to use:
 *      # add errors via .addError(key, err)
 *      # in the node service (activity, component, gateway etc), when drawing the border check
 *          if this particular node's key has an error associated with it.
 *      # if it has an error draw it red
 */
export default class ModelerValidationErrorsService {
  static $inject: string[] = [];

  private errorMap: object;

  constructor() {
    (window as any).modelerValidationErrorsService = this;
    this.resetAllErrors();
  }

  resetAllErrors() {
    this.errorMap = {};
  }

  emptyErrors(key: string | number) {
    if (this.errorMap[key]) {
      this.errorMap[key] = [];
      return true;
    } else {
      return false;
    }
  }

  hasErrors(key: string | number) {
    return this.errorMap[key] && this.errorMap[key].length > 0;
  }

  addError(key: string | number, error) {
    const errors = this.errorMap[key] || [];
    this.errorMap[key] = errors.concat(error);
  }
}

angular
  .module('flowingly.modeler.validation')
  .service('modelerValidationErrorsService', ModelerValidationErrorsService);

export type ModelerValidationErrorsServiceType = InstanceType<
  typeof ModelerValidationErrorsService
>;
