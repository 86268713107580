import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('flowinglyGatewayService', flowinglyGatewayService);

flowinglyGatewayService.$inject = ['BPMN_CONSTANTS', 'flowinglyConstants'];

function flowinglyGatewayService(
  BPMN_CONSTANTS,
  flowinglyConstants: Services.FlowinglyConstants
) {
  //API
  const service = {
    isGateway: isGateway,
    isExclusiveGateway: isExclusiveGateway,
    isDivergeGateway: isDivergeGateway,
    isConvergeGateway: isConvergeGateway
  };

  return service;

  //////////// Public API Methods
  function isGateway(modelNode) {
    return (
      modelNode &&
      modelNode.category.toLowerCase().indexOf(BPMN_CONSTANTS.GATEWAY) >= 0
    );
  }

  function isExclusiveGateway(modelNode) {
    return (
      modelNode &&
      modelNode.category === flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY
    );
  }

  function isDivergeGateway(modelNode) {
    return (
      modelNode &&
      modelNode.category === flowinglyConstants.nodeCategory.DIVERGE_GATEWAY
    );
  }

  function isConvergeGateway(modelNode) {
    return (
      modelNode &&
      modelNode.category === flowinglyConstants.nodeCategory.CONVERGE_GATEWAY
    );
  }
}

export type FlowinglyGatewayServiceType = ReturnType<
  typeof flowinglyGatewayService
>;
