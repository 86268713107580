/**
 * Converted to ts on 17/01/2020
 * SEe https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.components/devtools/dev-logger.service.js?at=master
 */

'use strict';

export function devLoggingService(level = null) {
  // Available log levels
  // A message will be logged if it's level is the same or less than current level
  const levels = {
    OFF: 1,
    ERRORS: 2,
    INFO: 3,
    DEBUG: 4
  };

  //API
  const service = {
    error: error,
    info: info,
    levels: levels,
    log: log,
    setLoggingLevel: setLoggingLevel,
    currentLevel: level
  };

  return service;

  function error(message) {
    if (service.currentLevel >= levels.ERRORS) {
      console.error(message);
    }
  }

  function info(message) {
    if (service.currentLevel >= levels.INFO) {
      console.info(message);
    }
  }

  function log(message) {
    if (service.currentLevel >= levels.DEBUG) {
      console.log(message);
    }
  }

  function setLoggingLevel(level) {
    service.currentLevel = level;
  }
}

export type DevLoggingServiceType = ReturnType<typeof devLoggingService>;
