/**
 * @ngdoc directive
 * @name flowDownloadLink
 * @module flowingly.directives
 * @description  A directive that requests file data from the server, attaching appropriate auth header(s) and token and assigns return data to
 *               a tag href atttribute and initiates download to browser (or opens file)
 * @usage
 * ```
 *  <a flow-download-link="{{::file.downloadLink}}" target="_blank"> {{::file.filename}}</a>
 * ```
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import { IAttributes, IScope } from 'angular';

angular.module('flowingly.directives.files').directive('flowDownloadLink', [
  'fileService',
  function (fileService: Services.FileService) {
    return {
      restrict: 'A',
      link: function (
        scope: IScope,
        element: JQuery<HTMLElement>,
        attrs: IAttributes
      ) {
        attrs.$set('title', 'download');
        attrs.$set('style', 'cursor:pointer');

        // handle the user clicking the element
        element.bind('click', function (e) {
          fileService.downloadByUrl(attrs.flowDownloadLink);
        });
      }
    };
  }
]);
