/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/83cc170e12a35da4c6f4928f79530954089a126f/src/Flowingly.Shared.Angular/flowingly.directives/comment.file.onchange.directive.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.directives').directive('commentFileOnchange', [
  function () {
    const directive = {
      restrict: 'A',
      require: 'commentFileUpload',
      link: function (
        scope: angular.IScope,
        element: JQuery<HTMLInputElement>,
        attrs,
        commentFileUpload
      ) {
        const onChangeHandler = commentFileUpload.onFileChange;
        element.on('change', onChangeHandler);

        element.on('click', function () {
          //clear the value so that we can select a file with the same name
          element[0].value = '';
        });

        scope.$on('$destroy', function () {
          element.off('change', onChangeHandler);
        });
      }
    };

    return directive;
  }
]);
