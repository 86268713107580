import angular from 'angular';
import { IRuleset } from './ruleset.interface';
import { ModelerValidation } from './@types/services';

export class RulesetService {
  DIVERGE_RULESET: IRuleset[];
  CONVERGE_RULESET: IRuleset[];

  constructor(private ruleService: ModelerValidation.RuleService) {
    this.DIVERGE_RULESET = [
      {
        rule: this.ruleService.DYNAMICACTOR_DYNAMICACTOR,
        allowed: true
      },
      {
        rule: this.ruleService.DYNAMICACTORAR_DYNAMICACTORAR,
        allowed: true
      },
      {
        rule: this.ruleService.DYNAMICACTOR_PARALLELAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.DYNAMICACTORAR_PARALLELAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.DYNAMICACTOR_SEQUENTIALAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.DYNAMICACTORAR_SEQUENTIALAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.SEQUENTIALAPPROVAL_SEQUENTIALAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.PARALLELAPPROVAL_PARALLELAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.PARALLELAPPROVAL_SEQUENTIALAPPROVAL,
        allowed: false
      },
      {
        rule: this.ruleService.DEFAULT,
        allowed: true
      }
    ];

    this.CONVERGE_RULESET = [
      {
        rule: this.ruleService.EXCLUSIVEGATEWAY_CONVERGEGATEWAY,
        allowed: false
      },
      {
        rule: this.ruleService.DEFAULT,
        allowed: true
      }
    ];
  }
}

angular
  .module('flowingly.modeler.validation')
  .factory('rulesetService', [
    'ruleService',
    (ruleService) => new RulesetService(ruleService)
  ]);

export type RuleSetServiceType = InstanceType<typeof RulesetService>;
