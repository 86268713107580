/*
 * Convered to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/user-profile/user.profile.module.js?at=master
 */

'use strict';
import angular from 'angular';

const userProfileModule = angular.module('flowingly.user.profile', [
  'templates-directives'
]);

userProfileModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'user.profile.change.avatar.tmpl.html',
      require('./user.profile.change.avatar.tmpl.html').default
    );
  }
]);
