/**
 * Html Entity (he) module https://github.com/mathiasbynens/he
 *
 * @license MIT
 */
import * as angular from 'angular';
declare var window: any;

export interface IHeAdapter {
  version: string;
  encode: (string, IHeAdapterEncodeOptions?) => string;
  decode: (string, IHeAdapterDecodeOptions?) => string;
  unescape: (string, IHeAdapterDecodeOptions?) => string;
  escape: (string) => string;
}

export interface HeAdapter extends IHeAdapter {
  ID: string;
}

export interface IHeAdapterEncodeOptions {
  useNamedReferences: boolean;
  decimal: boolean;
  strict: boolean;
  encodeEverything: boolean;
  allowUnsafeSymbols: boolean;
}

export interface IHeAdapterDecodeOptions {
  isAttributeValue: boolean;
  strict: boolean;
}

export var he: HeAdapter = window.he;
he.ID = 'he';

angular.module('flowingly.adapters').factory(he.ID, [() => he]);
