/**
 * @name iconPicker
 * @module flowingly.components
 * @description Displays an interface for choosing a font awesome icon
 * 
 * @usage
 * ```
    <icon-picker icon="icon.property.to.change"></icon-picker>
 * ```
 */
'use strict';

import angular from 'angular';

export default class IconPickerComponentController {
  // Bindings
  private icon: string;

  private icons = [];

  constructor() {}

  $onInit() {
    Array.from(document.styleSheets).forEach((css) => {
      try {
        const firstRule =
          css.cssRules && css.cssRules.length > 0 && css.cssRules[0];
        if (
          !firstRule ||
          typeof firstRule.cssText !== 'string' ||
          firstRule.cssText.indexOf('Font Awesome 6 Pro') === -1
        ) {
          return;
        }
      } catch (e) {
        // Remote stylesheets will not let us read their rules
        return;
      }

      let skipping = false;

      Array.from(css.cssRules).forEach((rule: CSSStyleRule) => {
        if (
          skipping ||
          typeof rule.selectorText !== 'string' ||
          rule.selectorText.indexOf('::before') === -1
        ) {
          return;
        }

        const name = rule.selectorText.split(':')[0].replace('.', '');
        if (name === 'fa-monero') {
          // Currently this is the first of the Brand icons
          // We don't support these so skip the rest
          skipping = true;
          return;
        }
        // shorter names are helper classes e.g. fa-xl
        if (name.length > 5) {
          this.icons.push({
            name: name,
            visible: true
          });
        }
      });
    });
  }

  setIcon(name) {
    this.icon = name;
  }

  search(term) {
    if (term === '') {
      this.icons.forEach((icon) => {
        icon.visible = true;
      });
      return;
    }
    term = term.toLowerCase();
    this.icons.forEach((icon) => {
      icon.visible = icon.name.indexOf(term) > -1;
    });
  }
}

export class IconPickerComponent implements angular.IComponentOptions {
  public templateUrl = 'icon.picker.component.tmpl.html';
  public controllerAs = 'ctrl';
  public bindings = {
    icon: '='
  };

  controller = IconPickerComponentController;
}

angular
  .module('flowingly.components')
  .component('iconPicker', new IconPickerComponent());
