/**
 * @name flowinglyRandomSrc
 * @module flowingly.filters
 * @description Append random query string to a given path
 * @usage
 * ```
    <img ng-if="$ctrl.showImage" ng-src="{{#ctrl.filename | flowinglyRandomSrc}}" title="{{$ctrl.name}}">
 * ```
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.filters/randomsrc.filter.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular.module('flowingly.filters').filter('flowinglyRandomSrc', [
  'fileService',
  function (fileService: Services.FileService) {
    return function (filename: string) {
      return fileService.appendUniqueSuffix(filename);
    };
  }
]);
