/**
 * @ngdoc service
 * @module flowingly.modeler.services
 * @name componentApiService
 *
 * @description A service for fetching component information from the API
 */
import angular from 'angular';
import { Services } from '../@types/services';

angular
  .module('flowingly.services')
  .factory('componentApiService', componentApiService);

componentApiService.$inject = [
  '$q',
  '$http',
  'APP_CONFIG',
  'flowinglyConstants'
];

function componentApiService(
  $q: angular.IQService,
  $http: angular.IHttpService,
  APP_CONFIG: Services.APP_CONFIG,
  flowinglyConstants: Services.FlowinglyConstants
) {
  const service = {
    getProcessMapComponents: getProcessMapComponents,
    getProcessMaps: getProcessMaps,
    getWorkflowComponents: getWorkflowComponents
  };

  return service;

  function getProcessMapComponents() {
    return $http
      .get<IResponseDataPascalCase>(
        APP_CONFIG.apiBaseUrl +
          'modeler/getComponents/' +
          flowinglyConstants.flowModelPublishType.PROCESS_MAP_COMPONENT
      )
      .then(function (response) {
        return response.data.DataModel;
      });
  }

  function getProcessMaps() {
    return $http
      .get<IResponseDataPascalCase>(
        APP_CONFIG.apiBaseUrl +
          'modeler/getComponents/' +
          flowinglyConstants.flowModelPublishType.PROCESS_MAP
      )
      .then(function (response) {
        return response.data.DataModel;
      });
  }

  function getWorkflowComponents() {
    return $http
      .get<IResponseDataPascalCase>(
        APP_CONFIG.apiBaseUrl +
          'modeler/getComponents/' +
          flowinglyConstants.flowModelPublishType.WORKFLOW_COMPONENT
      )
      .then(function (response) {
        return response.data.DataModel;
      });
  }
}

export type ComponentApiServiceType = ReturnType<typeof componentApiService>;
