/**
 * @ngdoc directive
 * @name flowTableRunner
 * @module flowingly.components
 * @description This comppnent displays a table to a user in the Runner (as part of a form for a step).
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Dynamic+Table+Builder
 * ### Notes
 * Has a dependency on the flow-elastic directive which is used to have the textarea gorw/shrink with content.
 * ### Properties
 * #### Inputs
 * * field : remove?
 * * form : the form to which this field belongs. We use this to determine if form submitted.
 * * isRequired : is the table a required field on the form
 * * table-title: title for the table
 * * tableSchema: JSON schema of how to render the table
 * #### Outputs
 * * formData : the formdata to save for this step. I.e. the users inputs
 * #### Events
 * * onTableUpdated : Method to call each time the user completes a cell in the table / adds a row
 * * onFormInvalid : method to call when the form becomes invalid
 * * onFormValid : method to call when the form becomes valid
 * @usage
 * ```
      <flow-table-runner 
        form="form"flow-table-runner
        table-title="field.schema.displayName"
        table-schema="field.schema.tableSchema"
        form-data="form.data[field.schema.name]"
        is-required="field.schema.validation.required"
        on-form-invalid="ctrl.onFormInvalid()"
        on-form-valid="ctrl.onFormValid()"
    ></flow-table-runner>
 * ```
 */

import { IFieldOptionQuery } from '@Shared.Angular/@types/fieldOptions';
import { PubSubService } from '@Shared.Angular/@types/pubSub';
import { Services } from '@Shared.Angular/@types/services';
import { FieldServiceType } from '@Shared.Angular/flowingly.services/field.service';
import { FlowinglyConstantsType } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { FlowinglyFormulaServiceType } from '@Shared.Angular/flowingly.services/flowingly.formula.service';
import { IScope, ITimeoutService } from 'angular';

///
/// This component is the root table runner component. It encapsulates all other components.
///
///   <flow-table-runner></flow-table-runner>

angular.module('flowingly.components').component('flowTableRunner', {
  bindings: {
    //INPUTS
    field: '<',
    form: '<',
    isRequired: '<',
    tableTitle: '<',
    tableSchema: '<',
    //OUTPUTS
    formData: '=',
    //EVENTS
    onTableUpdated: '&',
    onFormInvalid: '&',
    onFormValid: '&'
  },
  require: {
    formCtrl: '?^fgForm'
  },
  templateUrl: 'table.runner.component.tmpl.html',
  controller: [
    '$scope',
    'lodashService',
    'pubsubService',
    'flowinglyFormulaService',
    'flowinglyConstants',
    '$timeout',
    'fieldService',
    'browserUtilsService',
    'busyService',
    function (
      $scope: IScope,
      lodashService: Lodash,
      pubsubService: PubSubService,
      flowinglyFormulaService: FlowinglyFormulaServiceType,
      flowinglyConstants: FlowinglyConstantsType,
      $timeout: ITimeoutService,
      fieldService: FieldServiceType,
      browserUtilsService: Services.BrowserUtilsService,
      busyService: Services.BusyService
    ) {
      const SUBSCRIBER_NAME = 'flowTableRunner';

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const $ctrl = this;
      //the collection of cells to add to each row, as defined by the designed schema
      $ctrl.cells = [];
      //collection of rows added to the table AND the values assigned to each cell
      $ctrl.rows = [];

      $ctrl.addRow = addRow;
      $ctrl.removeRow = removeRow;
      $ctrl.updateForm = updateForm;
      $ctrl.populateLookupFields = populateLookupFields;
      $ctrl.isMobileApp = browserUtilsService.isCordovaApp();

      this.$onInit = function () {
        $ctrl.dateSelectorOptions = {
          start: 'year',
          depth: 'day'
        };

        initializeTableFromFormData();

        $ctrl.stepId = $ctrl.formCtrl != null ? $ctrl.formCtrl.stepId : '';

        $scope.$watch(function () {
          if (typeof $ctrl.field.schema.name !== 'undefined')
            return $ctrl.form.data[$ctrl.field.schema.name];
        }, initializeTableFromFormData);

        pubsubService.subscribe(
          'FILEUPLOAD_FILE_ERROR',
          fileUploadError,
          SUBSCRIBER_NAME
        );
      };

      this.$onDestroy = function () {
        pubsubService.unsubscribeAll(SUBSCRIBER_NAME);
      };

      this.$onChanges = function (changes) {
        //this component is used to display the table the user edits in the Runner, but also is also used
        //to show a preview of the table in the Modeler
        if (changes.isRequired && !changes.isRequired.isFirstChange()) {
          //we need to watch the validation required flag, in order to change the default row status
          //when the user edits required states in the builder (modeler)
          $ctrl.isRequired
            ? $ctrl.rows.push(generateRow(0))
            : ($ctrl.rows.length = 0);
        }

        if (changes.tableSchema && !changes.tableSchema.isFirstChange()) {
          //we need to watch the schema, since the user might be editing it in the builder (modeler)
          //update existing rows cells definition
          $ctrl.rows.forEach((r) => {
            updateCellsFromSchema($ctrl.tableSchema, r.cells);
          });
        }
      };

      $ctrl.formatFormula = (cell) => {
        return flowinglyFormulaService.formatCell(
          $ctrl.form.schema,
          $ctrl.cells,
          cell
        );
      };

      $ctrl.isCurrencyCell = (cell) => {
        return flowinglyFormulaService.isCurrencyCell(
          $ctrl.form.schema,
          $ctrl.cells,
          cell
        );
      };

      $ctrl.isNumberCell = (cell) => {
        return flowinglyFormulaService.isNumberCell(
          $ctrl.form.schema,
          $ctrl.cells,
          cell
        );
      };
      $ctrl.isTableHasFileUpload = (tableSchema) => {
        if (tableSchema.indexOf('"type":4') > -1) return true;
      };

      // PUBLIC METHODS ///////////////////////////////////////////////////////////

      function fileUploadError(event, data) {
        if (
          $ctrl.field == null ||
          $ctrl.field.name == null ||
          data.fileControlId == null
        ) {
          return;
        }

        // Make sure the file upload error is for this table.
        if ($ctrl.field.name === data.fileControlId) {
          validateForm();
        }
      }

      function addRow() {
        // adds a new row to the table, with each cell configured as per schema and
        // defaulting to empty values
        $ctrl.rows.push(generateRow($ctrl.rows.length));
        $ctrl.form.state.$submitted = false; // As a new row has been added, ensure that the state is set back to unsubmitted
        $ctrl.updateForm();
      }

      function removeRow(rowId: number) {
        if ($ctrl.rows.length === 1 && $ctrl.isRequired) {
          return;
        }
        const rowIndex = $ctrl.rows.findIndex((row) => row.id === rowId);
        $ctrl.rows.splice(rowIndex, 1);

        if ($ctrl.rows.length === 0) {
          $ctrl.formData = undefined;
        } else {
          $ctrl.formData = generateFormData();
        }
        validateForm();
        sumRows();
      }

      function populateLookupFields(cell, row) {
        if (!cell.value) {
          return;
        }

        if (
          cell.type === flowinglyConstants.tableCellType.NUMBER ||
          cell.type === flowinglyConstants.tableCellType.CURRENCY
        ) {
          updateFormula(row);
        }

        if (
          cell.type !== flowinglyConstants.tableCellType.TEXT &&
          cell.type !== flowinglyConstants.tableCellType.DROPDOWN &&
          cell.type !== flowinglyConstants.tableCellType.NUMBER &&
          cell.type !== flowinglyConstants.tableCellType.CURRENCY
        ) {
          return;
        }
        // check if any other columns lookup this column
        const lookupFields = $ctrl.cells.filter((col) => {
          return col.lookupConfig && col.lookupConfig.value == cell.id;
        });

        if (lookupFields.length === 0) {
          return;
        }

        const requestPayload = lookupFields.map(
          (field): IFieldOptionQuery => ({
            fieldName: $ctrl.stepId,
            tableColumnId: field.id,
            flowModelId: $ctrl.field.schema.publicForm,
            dbDataSource: {
              dbName: field.lookupConfig.dbName,
              displayValue: field.lookupConfig.displayValue,
              displayValueOptions: [],
              filters: [
                {
                  column: field.lookupConfig.queryValue,
                  operation: '=',
                  value: cell.value
                }
              ]
            }
          })
        );

        const optionsPromise = fieldService
          .getFieldOptions($ctrl.field.schema.publicForm, requestPayload)
          .then((results) => {
            for (const result of results) {
              const matchingCell = row.cells.find((cell) => {
                return cell.id == result.tableColumnId;
              });

              if (!matchingCell) {
                return;
              }

              matchingCell.value = result.options
                .map((option) => option.text)
                .join(', ');
            }
            $ctrl.updateForm();
            updateFormula(row);
          });
        busyService.addPromise(optionsPromise);
      }

      function updateFormula(row) {
        if (row.cells.find((cell) => cell.value !== undefined)) {
          const tableSchema = JSON.parse($ctrl.tableSchema);
          for (const cellSchema of tableSchema.filter(
            (s) => s.type === flowinglyConstants.tableCellType.FORMULA
          )) {
            const formulaCell = row.cells.find(
              (cell) => cell.id === cellSchema.id
            );
            if (formulaCell) {
              const result = flowinglyFormulaService.evaluate(
                cellSchema.formulaConfig,
                $ctrl.form.schema,
                $ctrl.form.data,
                tableSchema,
                row
              );
              if (result !== formulaCell.value) {
                formulaCell.value = result;
                $ctrl.updateForm();
              }
            }
          }
        }
      }

      function updateForm(cell, row) {
        $ctrl.tableEdited = true;
        //called in response to the user editing a cell.
        if (cell) {
          cell.touched = true;
        }

        validateForm();
        sumRows();

        if (
          cell &&
          (cell.type === flowinglyConstants.tableCellType.TEXT ||
            cell.type === flowinglyConstants.tableCellType.DROPDOWN ||
            cell.type === flowinglyConstants.tableCellType.NUMBER ||
            cell.type === flowinglyConstants.tableCellType.CURRENCY)
        ) {
          populateLookupFields(cell, row);
        }

        //add / update this cells data and save to the form
        $ctrl.formData = generateFormData();
      }

      /// PRIVATE METHODS //////////////////////////////////////////////////////////

      function initializeTableFromFormData() {
        if ($ctrl.tableEdited) {
          const schema = JSON.parse($ctrl.tableSchema);
          if (
            schema.find(
              (cell) => cell.type === flowinglyConstants.tableCellType.FORMULA
            )
          ) {
            for (const row of $ctrl.rows) {
              updateFormula(row);
            }
          }
          return;
        }
        $ctrl.cells = [];
        $ctrl.rows = [];
        //if there is form data saved, then restore it
        if (
          $ctrl.formData &&
          $ctrl.formData !== flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW
        ) {
          const parsed = JSON.parse($ctrl.formData);

          //set up default cells (we need to do this so that Add New Row works)
          //take them from the first row (all rows are the same)
          const cells = [];
          if (
            typeof parsed['rows'] !== 'undefined' &&
            parsed['rows'].length > 0
          ) {
            angular.copy(parsed['rows'][0].cells, cells);
          }

          for (let c = 0; c < cells.length; c++) {
            const cell = cells[c];
            cell.value = undefined;
            if (hasSum(cell)) {
              $ctrl.showSumRow = true;
            }
            $ctrl.cells.push(cell);
          }

          //set up row data (this will also set up the correct schema)
          for (const rowKey in parsed) {
            const rows = parsed[rowKey];
            for (let r = 0; r < rows.length; r++) {
              const row = rows[r];
              lodashService.forEach(row.cells, function (c) {
                c.row = r;
              });

              if (typeof $ctrl.tableSchema !== 'undefined') {
                $ctrl.rows.push({
                  id: row.id,
                  cells: lodashService.merge(
                    row.cells,
                    JSON.parse($ctrl.tableSchema)
                  )
                });
              }
            }
          }
          getCellsFromSchema($ctrl.tableSchema);
          sumRows();
        } else {
          //create a table, as designed by flow author and populate with one row.
          getCellsFromSchema($ctrl.tableSchema);

          //we always add a row (previously we only did this for required tables)
          $ctrl.rows.push(generateRow(0));
        }

        if ($ctrl.tableSchema && typeof $ctrl.tableSchema !== 'undefined') {
          const objTableSchema = JSON.parse($ctrl.tableSchema);
          lodashService.forEach(objTableSchema, function (fts) {
            if (fts.type != flowinglyConstants.tableCellType.DROPDOWN) {
              delete fts.dbDataSource;
            }

            if (fts.type != flowinglyConstants.tableCellType.LOOKUP) {
              delete fts.lookupConfig;
            }

            if (fts.type != flowinglyConstants.tableCellType.FORMULA) {
              delete fts.formulaConfig;
            }
          });
          $ctrl.tableSchema = JSON.stringify(objTableSchema);
          $ctrl.field.schema.tableSchema = $ctrl.tableSchema;
        }

        // update existing rows cells definition
        $ctrl.rows.forEach((row, rowIndex) => {
          for (const [cellIndex, cell] of row.cells.entries()) {
            const matchCell = $ctrl.cells.find((c) => cell.id === c.id);

            if (
              matchCell &&
              cell.type === flowinglyConstants.tableCellType.DROPDOWN
            ) {
              cell.filteredOptions = angular.copy(matchCell.options);

              if (!matchCell.searchable && matchCell.options) {
                cell.filteredOptions.optionLabel = 'Please Choose...';
                cell.options = cell.filteredOptions;
              }

              if (matchCell.searchable && matchCell.options) {
                $timeout(function () {
                  if (
                    cell.filteredOptions &&
                    cell.filteredOptions.length >
                      flowinglyConstants.searchableComboPageSize
                  ) {
                    cell.filteredOptions = cell.filteredOptions.slice(
                      0,
                      flowinglyConstants.searchableComboPageSize
                    );

                    // If the selected value is not present in the initial dropdown list, appends the text and value from fields object.
                    if (
                      cell.value &&
                      cell.value.trim().length > 0 &&
                      !cell.filteredOptions.find(
                        (option) => option.value === cell.value
                      )
                    ) {
                      // To get the Text attribute
                      const text = JSON.parse(
                        $ctrl?.formCtrl?.flowFields?.find(
                          (field) => field.Name === $ctrl?.field?.schema?.name
                        )?.Text
                      )?.rows[rowIndex]?.cells[cellIndex]?.value;

                      const data = {
                        value: cell.value,
                        checked: false,
                        text: text
                      };
                      cell.filteredOptions = [...cell.filteredOptions, data];
                    }
                  }

                  cell.options = {
                    optionLabel: 'Please Choose...',
                    filter: 'contains'
                  };

                  cell.options.filtering = function (e) {
                    const filter = e.filter;
                    e.preventDefault();

                    filterAndSearchAPICall(
                      $ctrl.field.name,
                      cell,
                      filter.value
                    );
                  };
                });
              }

              fillUserLookupValue(cell);
            }
          }
        });

        $ctrl.formData = generateFormData();

        //Validate the form's table whether it is required or optional, to prevent form submission with invalid rows
        validateForm();
      }

      function generateFormData() {
        //for each row the user has added, create an object for each cell
        //we save the id and value; and to simplify generating the table in the history, the header value and cell type
        const formData = { rows: [] };
        let currentRow = undefined;
        lodashService.forEach($ctrl.rows, function (row) {
          currentRow = { id: row.id, cells: [] };
          lodashService.forEach(row.cells, function (cell) {
            currentRow.cells.push({
              id: cell.id,
              value: cell.value,
              header: cell.header,
              type: cell.type
            });
          });
          formData.rows.push(currentRow);
        });

        return JSON.stringify(formData);
      }

      function generateRow(id) {
        //create a new table row and initialise with default cell configurations.
        const row = { id: id, cells: [] };
        angular.copy($ctrl.cells, row.cells);
        lodashService.forEach(row.cells, function (cell) {
          cell.row = id;
          fillUserLookupValue(cell);
          populateDropdownOnAddRow(cell);
        });
        return row;
      }

      function updateCellsFromSchema(schema, rowcells) {
        if (schema == undefined) {
          return;
        }

        schema = JSON.parse(schema);
        lodashService.forEach(schema, function (cell) {
          lodashService.forEach(rowcells, function (rcell) {
            if (cell.id === rcell.id) {
              if (hasSum(cell)) {
                $ctrl.showSumRow = true;
                rcell.sum = 0;
              } else if (
                cell.type === flowinglyConstants.tableCellType.DROPDOWN
              ) {
                //if filter present
                if (
                  cell.dbDataSource.filters &&
                  cell.dbDataSource.filters[0].column
                ) {
                  populateDropdownCellOptions(cell);
                  rcell.value = cell.value;
                  rcell.options = cell.options;
                  rcell.filteredOptions = cell.filteredOptions;
                }
              }
            }
          });
        });
      }

      function getCellsFromSchema(schema) {
        //use the passed in schema (as designed by flow auther) to generate the default cells
        //i.e. cell type, header value etc. Value will default to undefined
        if (schema == undefined) {
          return;
        }
        $ctrl.cells.length = 0;
        schema = JSON.parse(schema);

        lodashService.forEach(schema, function (cell) {
          cell.value = undefined;
          cell.row = 0;
          //if this is a currency/number/formula type, show sum row and initialise sum for this column to zero.
          if (hasSum(cell)) {
            $ctrl.showSumRow = true;
            cell.sum = 0;
          }
          if (cell.type === flowinglyConstants.tableCellType.DROPDOWN) {
            populateDropdownCellOptions(cell);
          }

          $ctrl.cells.push(cell);
        });
      }

      function fillUserLookupValue(cell) {
        if (
          cell.type !== flowinglyConstants.tableCellType.LOOKUP ||
          !angular.isArray($ctrl.field.schema.lookupValues)
        ) {
          return;
        }

        const matchField = $ctrl.field.schema.lookupValues.find(
          (f) => f.ColumnId === cell.id
        );
        if (matchField) {
          cell.value = matchField.Value;
        }
      }

      function populateDropdownCellOptions(cell) {
        if (
          $ctrl.field.schema.options &&
          $ctrl.field.schema.options.length > 0
        ) {
          if (cell.id != null) {
            const matchCol = $ctrl.field.schema.options.find(
              (o) => o.tableColumnId === cell.id
            );

            if (matchCol) {
              if (cell.searchable) {
                cell.value = ''; //FLOW-5426: Fix for the search keyword automatically being removed on the very first search
              }

              cell.options = matchCol.options;
              if (
                cell.dbDataSource.filters &&
                !cell.dbDataSource.filters[0].column
              ) {
                cell.filteredOptions = matchCol.options;
              }
            }
          }
        }
      }

      function populateDropdownOnAddRow(cell) {
        if (
          !cell ||
          cell.type !== flowinglyConstants.tableCellType.DROPDOWN ||
          !$ctrl.field.schema.options ||
          $ctrl.field.schema.options.length <= 0
        ) {
          return;
        }

        if (cell.id != null) {
          const matchCol = $ctrl.field.schema.options.find(
            (o) => o.tableColumnId === cell.id
          );

          if (matchCol) {
            cell.options = cell.options || matchCol.options;
            cell.options.optionLabel = 'Please Choose...';
            cell.filteredOptions = matchCol.options;

            if (cell.searchable) {
              cell.options.filter = 'contains';

              if (
                cell.filteredOptions &&
                cell.filteredOptions.length >
                  flowinglyConstants.searchableComboPageSize
              ) {
                cell.filteredOptions = cell.filteredOptions.slice(
                  0,
                  flowinglyConstants.searchableComboPageSize
                );
              }

              cell.options.filtering = function (e) {
                const filter = e.filter;
                e.preventDefault();

                filterAndSearchAPICall($ctrl.field.name, cell, filter.value);
              };
            }
          }
        }
      }

      function sumRows() {
        //$ctrl.cells contains the default schema cells for each row.
        //We use this now to also store sum of any number / currency columns.
        //The sum will default to zero (set in getCellsFromSchema) and we update it each time
        //this method is called, in response to updateForm(cell) being called.
        if ($ctrl.showSumRow) {
          lodashService.forEach($ctrl.cells, function (sumCell) {
            sumCell.sum = 0;
            //find valu of all rows with this cell id
            lodashService.forEach($ctrl.rows, function (row) {
              lodashService.forEach(row.cells, function (cell) {
                if (cell.id === sumCell.id && cell.value !== undefined) {
                  if (flowinglyFormulaService.hasLookupSumCell(cell)) {
                    if (cell.value.indexOf(',') >= 0) {
                      const valueArray = cell.value.split(',');

                      for (const v of valueArray) {
                        if (!isNaN(parseFloat(v))) {
                          sumCell.sum += parseFloat(v);
                        }
                      }
                    } else {
                      if (!isNaN(parseFloat(cell.value))) {
                        sumCell.sum += parseFloat(cell.value);
                      }
                    }
                  } else {
                    sumCell.sum += Number(cell.value);
                  }
                }
                if (sumCell.sum === +sumCell.sum) {
                  const tableName = $ctrl.field.name;
                  const sumFieldName = tableName + '__' + sumCell.id + '_sum';
                  $ctrl.form.data[sumFieldName] = sumCell.sum;
                }
              });
            });
          });
        }
      }

      function filterAndSearchAPICall(fieldCtrl, cellData, filterValue) {
        const requestPayload: IFieldOptionQuery = {
          fieldName: fieldCtrl,
          dbDataSource: angular.copy(cellData.dbDataSource),
          searchable: true,
          searchablePageSize: flowinglyConstants.searchableComboPageSize,
          searchTerm: filterValue,
          flowModelId: $ctrl.field.schema.publicForm
        };

        if (
          requestPayload.dbDataSource.filters &&
          requestPayload.dbDataSource.filters.length > 0
        ) {
          requestPayload.dbDataSource.filters[0].value = filterValue;
        }

        fieldService
          .getFieldOptions($ctrl.field.schema.publicForm, [requestPayload])
          .then((results) => {
            if (results && results.length > 0) {
              cellData.options = results[0].options;
              cellData.filteredOptions = angular.copy(cellData.options);

              const matchCell = $ctrl.cells.find((c) => c.id === cellData.id);
              if (matchCell && cellData.row === 0) {
                matchCell.options = cellData.options;
                matchCell.filteredOptions = cellData.filteredOptions;
              }
            }
          });
      }

      function hasValuesInTableRow(row) {
        if (row == null || row.cells == null) {
          // Nothing to check.
          return false;
        }

        let hasValue = false;
        lodashService.forEach(row.cells, (cell) => {
          if (
            cell.value != null &&
            cell.value != undefined &&
            cell.value !== ''
          ) {
            // The row contains a value.
            hasValue = true;
          }
        });

        return hasValue;
      }

      function validateForm() {
        //this method validates the table each time the user changes a cell value
        //fields are marked invalid if they are required, but have no value set.
        //an error is only displayed per COLUMN however. i.e. if 5 cells in a column are in error,
        //only one message gets added to the list.
        $ctrl.formErrors = [];
        $ctrl.formHasErrors = false;

        const isTableRequired = $ctrl.isRequired;

        //check that all required fields have values, if not set error status
        lodashService.forEach($ctrl.rows, function (row) {
          let hasValueInRow = false;
          if (isTableRequired === false) {
            // Optional table. Check for any data in the row.
            hasValueInRow = hasValuesInTableRow(row);
          }

          const optionalTableWithoutRowData =
            isTableRequired === false && hasValueInRow === false;

          lodashService.forEach(row.cells, function (cell) {
            cell.error = undefined;

            // When the table is optional and no data has been entered then nothing is required.
            if (optionalTableWithoutRowData === false) {
              cell.error = getCellError(cell);
            }

            if (cell.error !== undefined) {
              $ctrl.formHasErrors = true;
              //error messages are added to list only if there is not already one for the same type.
              //they will only be shown in the view if the cell touched, for form submitted.
              if (
                !lodashService.some($ctrl.formErrors, function (e) {
                  return e.header === cell.header;
                })
              ) {
                $ctrl.formErrors.push(cell.error);
              }
            }
          });
        });

        //raise event - this will be handled by the flow form component to enable/prevent submit
        $ctrl.formHasErrors ? $ctrl.onFormInvalid() : $ctrl.onFormValid();
        return $ctrl.formHasErrors;
      }

      function getCellError(cell) {
        if (
          cell.type === flowinglyConstants.tableCellType.TEXT &&
          $ctrl.form.state &&
          $ctrl.form.state.$error &&
          $ctrl.form.state.$error.xssValidate &&
          $ctrl.form.state.$error.xssValidate.find((f) => f.$name === '')
        ) {
          return {
            touched: cell.touched,
            header: cell.header,
            msg: 'This input does not support HTML'
          };
        }

        if (cell.isRequired && (cell.value == undefined || cell.value === '')) {
          let validationMsg = '';

          switch (cell.type) {
            case 1:
              validationMsg = 'n entry is required for';
              break;
            case 2:
              validationMsg = 'n amount	is required for';
              break;
            case 3:
              validationMsg = ' number is required for';
              break;
            case 4:
              validationMsg = ' file must be uploaded for';
              break;
            case 5:
              validationMsg = ' date is required for';
              break;
            case 6:
              validationMsg = 'n option must be selected from';
              break;
            default:
              validationMsg = ' value is required for';
              break;
          }

          return {
            touched: cell.touched,
            header: cell.header,
            msg: 'A' + validationMsg + ' the ' + cell.header + ' column.'
          };
        }

        //currency - we use ng-currency which will automatically convert input to a valid currency value (on blur)
        if (
          cell.isRequired &&
          cell.type === flowinglyConstants.tableCellType.NUMBER &&
          !/^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/.test(cell.value)
        ) {
          return {
            touched: cell.touched,
            header: cell.header,
            msg: 'Value must be a number for the ' + cell.header + ' column'
          };
        }

        return undefined;
      }

      function hasSum(cell) {
        return flowinglyFormulaService.hasSumCell(
          $ctrl.form.schema,
          $ctrl.cells,
          cell
        );
      }
    }
  ]
});
