/**
 * @ngdoc service
 * @name categoryApiService
 * @module flowingly.services
 *
 * @description A service responsible solely for fetching categories data
 */

import Guid from '@Shared.Angular/@types/guid';
import { Services } from '@Shared.Angular/@types/services';
import angular, { IHttpService } from 'angular';
import { CategoryDeleteWarningStatus } from './flowingly.constants';
import ICategoryDetail from '@Shared.Angular/@types/core/contracts/queryModel/category/categoryDetail';

export interface ISubCategoryFlowModel {
  subCategoryName: string;
  flowModelCount: number;
}

export interface ICategoryDeleteWarning {
  subCategoryFlowModels: ISubCategoryFlowModel[];
  flowModelCount: number;
  status: CategoryDeleteWarningStatus;
}

export default class CategoryApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: Services.APP_CONFIG
  ) {}

  public cachedCategories: ICategoryDetail[];

  addCategory(category: ICategoryDetail) {
    return this.$http.post<IResponseData<Guid>>(
      this.APP_CONFIG.apiBaseUrl + `categories/`,
      category
    );
  }

  editCategory(category: ICategoryDetail) {
    return this.$http.put<IResponseData<Guid>>(
      this.APP_CONFIG.apiBaseUrl + `categories/`,
      category
    );
  }

  deleteCategory(categoryId, replaceCategoryId) {
    return this.$http({
      url: this.APP_CONFIG.apiBaseUrl + 'categories/',
      method: 'DELETE',
      data: {
        categoryId: categoryId,
        replaceCategoryId: replaceCategoryId
      },
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  getCategories(noSpinner = false) {
    return this.$http
      .get<IResponseData<ICategoryDetail[]>>(
        this.APP_CONFIG.apiBaseUrl + 'categories',
        {
          noSpinner: noSpinner || false
        }
      )
      .then((response) => {
        this.cachedCategories = angular.copy(response.data.dataModel);
        return response.data.dataModel;
      });
  }

  getSubCategoryWarning(itemId) {
    return this.$http
      .get<ICategoryDeleteWarning>(
        this.APP_CONFIG.apiBaseUrl +
          'categories/deletewarnings?categoryId=' +
          itemId
      )
      .then((res) => {
        return res.data;
      });
  }
}

angular
  .module('flowingly.services')
  .factory('categoryApiService', [
    '$http',
    'APP_CONFIG',
    ($http: IHttpService, APP_CONFIG: Services.APP_CONFIG) =>
      new CategoryApiService($http, APP_CONFIG)
  ]);

export type CategoryApiServiceType = InstanceType<typeof CategoryApiService>;
