/**
 * @ngdoc directive
 * @name goBpmnPalette
 * @module flowingly.modeler.directives
 * @description A directive that represents a GoJS BMPN palette.
 * @usage
 * ```
       <go-bpmn-Palette id="mainPalette" go-model="ctrl.paletteModel.goModel" style="" class="bpmn-palette"></go-bpmn-Palette>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/4ab0b7597264c5c63831f8c92c0455197ccf689a/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.palette.directive.js?at=master
 */

'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';

angular.module('flowingly.bpmn.modeler').directive('goBpmnPalette', [
  'goService',
  'BpmnService',
  function (goService: GoJS, BpmnService: BpmnModeler.BpmnService) {
    return {
      restrict: 'E',
      template: '<div></div>',
      replace: true,
      scope: { model: '=goModel' },
      link: function (scope, element, attrs) {
        const $GO = goService.GraphObject.make;
        const container = angular.element(element[0]);

        //------------------------------------------Helpers ----------------------------------------

        /// Orders palette items by their key
        function keyCompare(a, b) {
          const at = a.data.key;
          const bt = b.data.key;
          if (at < bt) return -1;
          if (at > bt) return 1;
          return 0;
        }

        const PALETTE_MAX_HEIGHT = 865; // grow up to this height
        const PALETTE_MIN_HEIGHT = 665; // shrink up to this height
        const PALETTE_SPACING_MAX = 30;
        const PALETTE_SPACING_MIN = 10;
        /**
         * Returns the spacing between icons which is between 10 and 30. The size is proportional to the
         * height of the window, but it is calculated to be between the PALETTE_MIN_HEIGHT and PALETTE_MAX_HEIGHT meaning
         * if the height is on or below 665 it is calculated as 0% and if the height is on or above 865 it is
         * calculated is 100%
         *
         * @returns {number} a ratioed number between 10 and 30
         */
        function getPaletteSpacing(diamgramHeight) {
          const ratio =
            (diamgramHeight - PALETTE_MIN_HEIGHT) /
            (PALETTE_MAX_HEIGHT - PALETTE_MIN_HEIGHT);
          const spacing =
            ratio * (PALETTE_SPACING_MAX - PALETTE_SPACING_MIN) +
            PALETTE_SPACING_MIN;
          if (PALETTE_SPACING_MIN >= spacing) {
            return new goService.Size(0, PALETTE_SPACING_MIN);
          } else if (PALETTE_SPACING_MAX <= spacing) {
            return new goService.Size(0, PALETTE_SPACING_MAX);
          } else {
            return new goService.Size(0, spacing);
          }
        }

        //------------------------------------------the main Diagram----------------------------------------------
        const diagram =
          // create a Diagram for the given HTML DIV element (we are using a direct reference)
          $GO(goService.Palette, element[0], {
            //maybe we should pass this into directive?
            nodeTemplateMap: BpmnService.getPaletteNodeTemplateMap(),
            groupTemplateMap: BpmnService.getPaletteGroupTemplateMap(),
            layout: $GO(goService.GridLayout, {
              //spacing: getPaletteSpacing(),
              wrappingColumn: 1, //this forces the palettes to form a single column
              comparer: keyCompare
            })
          });

        diagram.addDiagramListener('ViewportBoundsChanged', function (e) {
          e.diagram.layout.spacing = getPaletteSpacing(container.height());
        });

        if (/Edge/.test(navigator.userAgent)) {
          diagram.animationManager.isEnabled = false;
        }
        //------------------------------------------end Diagram-------------------------------------------------------

        // notice when the value of "model" changes: update the Diagram.model
        scope.$watch('model', function (newmodel) {
          const oldmodel = diagram.model;
          if (oldmodel !== newmodel) {
            diagram.model = newmodel;
          }
        });
      }
    };
  }
]);
