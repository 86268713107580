/**
 * @ngdoc directive
 * @name flowinglyComments
 * @module flowingly.components
 * @description This is a component for displaying the comments / feedback history and allow add new comment / feedback
 *
 * Input attributes
 * commentTargetId - the id for the comment target (e.g. flowId)
 * commentTargetType - the type of the comment target (e.g. flow, flowModel)
 * commentList - the comments object
 * isMobile - is that mobile view?
 *
 * @usage
 * ```
 * <flowingly-comments comment-target-type="1" comment-target-id="$ctrl.flow.flowId" comment-list="$ctrl.commentList" is-mobile="false" ></flow-comments>
 * ```
 */

import angular from 'angular';
import { IComment } from '../../interfaces/comment.interface';

import { Services } from '@Shared.Angular/@types/services';

class CommentsComponentController implements angular.IController {
  public static $inject = [
    'commentApiService',
    'pubsubService',
    'avatarService'
  ];

  public commentTargetId: string;
  public commentTargetType: string;
  public flowOwnerOnly: boolean;
  public scrollToCommentBottom: boolean;
  private commentList: IComment[];
  public onCommentAdded: (data) => void;
  public commentTargetTypeFromReact: string;

  public constructor(
    private commentApiService: Services.CommentApiService,
    private pubsubService: Services.PubSubService,
    private avatarService: Services.AvatarService
  ) {}

  $onInit() {
    this.pubsubService.subscribe(
      'CLIENT_USER_PROFILE_UPDATED',
      () => {
        if (this.commentList && this.commentList.length > 0) {
          for (let c of this.commentList) {
            c.avatarUrl = this.avatarService.getAvatarUrl(c.userId);
          }
        }
      },
      'comments.component'
    );

    this.pubsubService.subscribe(
      'SIGNALR_RUNNER_NEW_FLOW_COMMENT',
      (event, data) => {
        data = JSON.parse(data);

        if (this.commentTargetId === data.commentTargetId) {
          this.loadComments();
        }
      },
      'comments.component'
    );

    this.pubsubService.subscribe(
      'SIGNALR_RUNNER_NEW_STEP_TASK_COMMENT',
      (event, data) => {
        data = JSON.parse(data);

        if (this.commentTargetId === data.stepTaskId) {
          this.loadComments();
        }
      },
      'comments.component'
    );
  }

  $onDestroy() {
    this.pubsubService.unsubscribeAll('comments.component');
  }
  public loadComments(): void {
    this.commentApiService
      .getFlowComments(this.commentTargetType, this.commentTargetId)
      .then((data: IComment[]) => {
        this.commentList = data;
      });
  }

  onAddComment(comment: IComment): void {
    this.commentList = this.commentList || [];
    this.commentList.push(comment);
    this.scrollToCommentBottom = true;
    // update the comment count
    this.onCommentAdded({
      commentTargetId: this.commentTargetId,
      count: this.commentList.length
    });
  }
}

export class CommentsComponent implements ng.IComponentOptions {
  public bindings: any;
  public templateUrl: string;
  /* TODO: 2 way binding of commentTargetType isn't working when in react compoenent. 
      Adding a workaround until we re-write this in react or figure out why this commentTargetType bidning needs to be a 2 way binding
      it doesnt look like it needs to be but it breaks the component. Leaving this re-visit later */

  constructor() {
    this.bindings = {
      commentTargetId: '<',
      commentTargetType: '@',
      commentList: '<',
      isMobile: '<',
      flowOwnerOnly: '<',
      onCommentAdded: '&',
      commentTargetTypeFromReact: '<'
    };

    this.templateUrl = 'comments.tmpl.html';
  }

  controller = CommentsComponentController;
}

angular
  .module('flowingly.components')
  .component('flowinglyComments', new CommentsComponent());
