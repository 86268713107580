/**
 * @ngdoc directive
 * @name flowOptionalText
 * @module flowingly.components
 * @description This component displays optional text is the filed is NOT required
 * ### Properties
 * #### Inputs
 * * isRequired : is the field required on the form

 * @usage
 * ```
    <flow-optional-text is-required="false"></flow-optional-text>
 * ```
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.components/optional.text.component.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.components').component('flowOptionalText', {
  bindings: {
    isRequired: '<'
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  controller: function () {},
  template: '<span class="optional" ng-hide="$ctrl.isRequired"></span>'
});
