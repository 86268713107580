'use strict';
import angular, { IQService } from 'angular';

/**
 *  Because kendo datasource is so confusing and does not natively support
 *  our use case of fetching data as you type unless you use the autocomplete
 *  module. However the autocomplete module does not have the same features
 *  as the combobox (which we want).
 *
 *  So we use a facade to simplify the implementation details.
 */
angular
  .module('flowingly.components')
  .factory('kendoDataSourceFacade', [
    '$q',
    'lodashService',
    kendoDataSourceFacade
  ]);

export type Data = any[] | ((...params: any[]) => any); // either an array or a function
function kendoDataSourceFacade($q: IQService, lodashService: Lodash) {
  const { isArray, isFunction } = lodashService;

  class DataSourceFacade {
    kendoDsConfig: kendo.data.DataSourceOptions;
    data: Data;
    kendoDS: kendo.data.DataSource;

    constructor(data: Data) {
      this.data = data;
      this.kendoDsConfig = {
        serverFiltering: isFunction(data),
        transport: {
          read: this.dataBridge()
        }
      };
      this.kendoDS = new kendo.data.DataSource(this.kendoDsConfig);
    }

    /**
     * Reruns the underlying kendo data retrieval.
     * Kendo hands off the data to the kendo input.
     */
    update() {
      return this.kendoDS.read();
    }

    /**
     * Returns a function that can be used as a databridge
     * between Kendo and the flowingly app.
     *
     * Typically this would be the read value you supply
     * to a new Kendo Datasource.
     *
     * @return {function}
     */
    dataBridge() {
      return (options: kendo.data.DataSourceTransportOptions) => {
        return this.fetchData(this.data).then((fetchedResults) => {
          options.success(fetchedResults);
        });
      };
    }

    getCurrentList() {
      return this.kendoDS._data;
    }

    /**
     * Given data, return it as a promise.
     * If given an array, resolve to the array.
     * If given a function, resolve to the return value.
     *
     * @param {array|function} data
     * @param {string} term
     * @return {promise}
     */
    fetchData(data: Data, term?) {
      if (isArray(data)) {
        return $q.resolve(data);
      } else if (isFunction(data)) {
        return $q.resolve(data(term));
      }
    }

    /**
     * Returns the underlying Kendo datasource.
     *
     * @returns {kendo.data.Datasource}
     */
    getAsKendoBridge() {
      return this.kendoDS;
    }
  }

  return DataSourceFacade;
}

export type DataSourceFacadeInstanceType = InstanceType<
  ReturnType<typeof kendoDataSourceFacade>
>;

export type DataSourceFacadeType = ReturnType<typeof kendoDataSourceFacade>;
