import angular, { IPromise, IQService } from 'angular';

/**
 * BusyLight is a prototype of a helper class I designed that returns isBusy for as long as
 * a promise operation is currently being executed.
 *
 * Example:
 *      var busyLight = new BusyLight
 *      busyLight.addPromise(someHttpRequest)
 *      busyLight.addPromise(someTimeoutRequest)
 *      busyLight.isBusy    // returns true until both promises resolve
 */

function busyLightFactory($q: IQService) {
  class BusyLight {
    private addedPromiseCount = 0;
    private resolvedPromiseCount = 0;

    get isBusy() {
      return this.addedPromiseCount != this.resolvedPromiseCount;
    }

    public addPromise(args: IPromise<unknown> | IPromise<unknown>[]) {
      if (Array.isArray(args)) {
        const nextPromises: IPromise<unknown>[] = args;
        this.addedPromiseCount += nextPromises.length;
        $q.all(nextPromises).finally(
          () => (this.resolvedPromiseCount += nextPromises.length)
        );
      } else {
        const nextPromise: IPromise<unknown> = args;
        this.addedPromiseCount++;
        nextPromise.finally(() => {
          this.resolvedPromiseCount++;
        });
      }
    }
  }

  return BusyLight;
}

angular
  .module('flowingly.services')
  .factory('BusyLight', ['$q', busyLightFactory]);

export type BusyLightFactoryType = ReturnType<typeof busyLightFactory>;
export type BusyLightType = InstanceType<BusyLightFactoryType>;
