/**
 * @ngdoc service
 * @name jQuery
 * @module flowingly.adapters
 *
 * @description Wraps the global jQuery Object.
 *
 * ## Notes
 * $ is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global $ object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes jquery has already been loaded on the page.
 */
import angular from 'angular';

angular.module('flowingly.adapters').factory('jQuery', [
  '$window',
  'lodashService',
  function ($window, _) {
    if ($window.$ == undefined) {
      console.error('jQuery is not loaded');
    }

    $.fn.extend({
      /**
       *  Returns the `.html()` of this command but includes the current element.
       */
      outerHTML() {
        return this[0].outerHTML || this.clone().wrap('<div/>').parent().html();
      },

      /**
       * Like `.width()` but returns only fthe width of its text box contents
       */
      textWidth() {
        const $e = $(document.createElement('span'));
        $e.html(this.clone()).appendTo('body');
        const n = $e.width();
        $e.remove();
        return n;
      },
      /**
       * Like `.height()` but returns only fthe height of its text box contents
       */
      textHeight() {
        const $e = $(document.createElement('span'));
        $e.html(this.clone()).appendTo('body');
        const n = $e.height();
        $e.remove();
        return n;
      },

      /**
       * https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
       * Returns true if nodeType is either Element or Text
       */
      isWorkableNode() {
        return this[0].nodeType == 3 || this[0].nodeType == 1;
      },

      /**
       *  .is(:visible) does not work with pure text nodes
       */
      isVisible() {
        const isTextNode = this[0].nodeType == 3;
        return isTextNode ? this.parent().is(':visible') : this.is(':visible');
      }
    });

    return $window.$;
  }
]);
