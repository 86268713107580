/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/user-notifications/user.notifications.module.js?at=master
 */

'use strict';
import angular from 'angular';

const userNotificationsModule = angular.module('flowingly.user.notifications', [
  'ngAnimate'
]);

userNotificationsModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'user.notifications.tmpl.html',
      require('./user.notifications.tmpl.html').default
    );

    $templateCache.put(
      'user.notification-view.tmpl.html',
      require('./user.notification-view.tmpl.html').default
    );
  }
]);
