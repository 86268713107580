import IFlowModelForUser from '@Shared.Angular/@types/core/contracts/queryModel/flows/flowModelForUser';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('flowinglyApiService', flowinglyApiService);

flowinglyApiService.$inject = ['$http', 'APP_CONFIG'];

function flowinglyApiService(
  $http: angular.IHttpService,
  APP_CONFIG: Services.APP_CONFIG
) {
  //API
  const service = {
    getFlowModelsReferencingComponent: getFlowModelsReferencingComponent,
    updateFlowModelsReferencingComponent: updateFlowModelsReferencingComponent
  };

  return service;

  function getFlowModelsReferencingComponent(flowModelId) {
    return $http
      .get<IResponseData<IFlowModelForUser[]>>(
        APP_CONFIG.apiBaseUrl +
          `workflow/getFlowModelsReferencingComponent/${flowModelId}`,
        { noSpinner: true }
      )
      .then((response) => {
        return response.data.dataModel;
      });
  }

  function updateFlowModelsReferencingComponent(flowModelId) {
    return $http
      .post<IResponseData>(
        APP_CONFIG.apiBaseUrl +
          `workflow/updateFlowModelsReferencingComponent/${flowModelId}`,
        { noSpinner: true }
      )
      .then(function (response) {
        return response.data.dataModel;
      });
  }
}

export type FlowinglyApiServiceType = ReturnType<typeof flowinglyApiService>;
