/*
 * @ngdoc service
 * @name intercomService
 * @module flowingly.services
 *
 * @description A service for using Intercom.
 *
 * ## Notes
 * Bootstraps the Intercom service and sends events to the Intercom endpoint.
 * See: https://bizflo.atlassian.net/wiki/display/TECH/Intercom
 *      https://docs.intercom.com/configure-intercom-for-your-product-or-site/customize-the-intercom-messenger/the-intercom-javascript-api
 *
 * ###API
 * * boot - creates a new session for this user in Intercom
 * * shutdown - ends this users session in Intercom
 * * trackEvent - associates an event with the logged in user
 *
 * ###Usage
 *
 * intercomService.boot({
 *       app_id: $window.intercomId,
 *       email: user.email,
 *       name: user.fullName,
 *       user_id: user.id,
 *       widget: {
 *           activator: '#IntercomDefaultWidget'
 *       }
 *   });
 *
 *
 *   //clear this intercom session when the modeler is closed
 *   $window.onbeforeunload = () => {
 *       intercomService.shutdown();
 *   }
 *
 *   NOTE: FLOW-4280 introduces an intercom hack which should be fixed by FLOW-4292
 *   The intercom service currently is stubbed out, and it's original functionality
 *   will be returned only if the user has access to intercom
 *
 *   It is important to know that the service is already used way before the script
 *   is loaded. So we fix this by pushing all the calls into a deferred execution stack
 *   Once the script has loaded, we execute all the calls in the deferred stack
 */
import angular from 'angular';

export default class IntercomService {
  static $inject = [
    '$window',
    'pubsubService',
    'sessionService',
    'flowsUtilityService',
    'APP_CONFIG',
    '$timeout',
    'flowinglyConstants'
  ];

  private hasAccess = false;
  private deferExecStack = []; // why? because the service is already used before it's ready!

  constructor(
    private $window,
    private pubsubService,
    private sessionService,
    private flowsUtilityService,
    private APP_CONFIG,
    private $timeout: angular.ITimeoutService,
    private flowinglyConstants
  ) {
    // @TODO FLOW-4292 fix this intercom hack
    const execOrDeferFactory = (fnToCall): any => {
      return (...args) => {
        if (this.hasAccess) {
          // if the user has access to intercom (See FLOW-4292)
          // then we execute the fn
          fnToCall.call(this, ...args);
        } else {
          // else, we push it into a deferred stack then execute it later, see
          // the explanation above as to why we do this
          this.deferExecStack.push(() => {
            console.log('calling', fnToCall.toString());
            fnToCall.call(this, ...args);
          });
        }
      };
    };

    this.boot = execOrDeferFactory(this.boot);
    this.shutdown = execOrDeferFactory(this.shutdown);
    this.trackEvent = execOrDeferFactory(this.trackEvent);
    this.turnOffLauncher = execOrDeferFactory(this.turnOffLauncher);
  }

  //////////// Public API Methods

  // @@TODO FLOW-4292 fix this intercom hack
  initializeIntercom(
    intercomServiceOptions,
    user = this.sessionService.getUser()
  ) {
    // @TODO if possible dont make intercom dependent on the session
    if (!user) {
      // during login authentication this is empty
      return;
    }

    const correctRole = user.roles.some(
      (role) =>
        (role.name ==
          this.flowinglyConstants.flowinglyRoles.BUSINESS_ADMINISTRATOR &&
          role.isDefault) ||
        (role.name ==
          this.flowinglyConstants.flowinglyRoles.FLOWINGLY_ADMINISTRATOR &&
          role.isDefault)
    );
    const isPMI = user.businessId == '9ae52a39-4457-4e82-9275-2cacc8e8809e'; // this logic only for PMI apparently

    if ((isPMI && correctRole) || !isPMI) {
      (window as any)
        ._flowinglyLoadIntercComForUser(this.APP_CONFIG.intercomUrl)
        .then(() => {
          this.startIntercom(intercomServiceOptions, user);

          this.hasAccess = true;
        })
        .then(() => {
          // if there were any calls delayed by this hack
          // we are gonna execute it now
          let exectuable;
          while ((exectuable = this.deferExecStack.pop())) {
            exectuable();
          }
        });
    }
  }

  startIntercom(intercomServiceOptions, user) {
    //clear this intercom session when the modeler is closed
    this.$window.onbeforeunload = () => {
      this.shutdown();
    };

    if (this.flowsUtilityService.isInSupportHours()) {
      intercomServiceOptions.custom_launcher_selector = '.intercom-launcher';
    }

    if (user) {
      this.boot(intercomServiceOptions);
    }
  }

  boot(userSettings) {
    //creates a new session for this user in Intercom
    this.$window.Intercom('boot', userSettings);

    //publish event to track unread messages
    this.$window.Intercom('onUnreadCountChange', (unreadCount) => {
      this.pubsubService.publish(
        'INTERCOM_UNREAD_MESSAGE_COUNT_CHANGED',
        unreadCount
      );
    });
  }

  shutdown() {
    //ends this users session in Intercom and removes their conversation history
    this.$window.Intercom('shutdown');
  }

  trackEvent(eventName, metaData): angular.IPromise<void> {
    //associates an event with the logged in user
    if (metaData) {
      this.$window.Intercom(
        'trackEvent',
        this.formatEvent(eventName),
        metaData
      );
    } else {
      this.$window.Intercom('trackEvent', this.formatEvent(eventName));
    }

    return this.$timeout(() => {
      this.$window.Intercom('update', {
        last_request_at: Math.floor(new Date().getTime() / 1000)
      });
    }, this.APP_CONFIG.intercomConfig.pollDelay);
  }

  turnOffLauncher() {
    this.$window.Intercom('update', {
      custom_launcher_selector: '_00000000-0000-0000-0000-000000000000'
    });
  }

  /// PRIVATE

  formatEvent(eventName) {
    switch (eventName) {
      case 'app.runner.flowsactive':
        return 'viewed runner start flows';

      case 'app.runner.flowsin':
        return 'viewed runner flows im in';

      case 'app.runner.flow':
        return 'viewed runner flow';

      case 'app.runner.flowstodo':
        return 'viewed runner to do';

      case 'app.runner.learnmorelink':
        return 'Clicked In-App Learn More Link';

      case 'app.runner.clicked_view_flow_model':
        return 'Clicked View Flow Model';

      case 'app.runner.clicked_view_card':
        return 'Clicked View Card';

      case 'app.runner.notifications':
        return 'viewed notifications';

      default:
        return eventName
          .replace('app', 'viewed')
          .replace(new RegExp('\\.', 'g'), ' ');
    }
  }
}

angular
  .module('flowingly.services')
  .service('intercomService', IntercomService);

export type IntercomServiceType = InstanceType<typeof IntercomService>;
