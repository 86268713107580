/**
 * @name scrollToCommentBottom
 * @module flowingly.directives
 * @description scroll to bottom when adding comment
 * ### Notes
 * 
 * @usage
 * ```
    <div ng-repeat="comment in $ctrl.commentList" scroll-to-comment-bottom>
 * ```
 *
 * Converted to ts on 15/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/83cc170e12a35da4c6f4928f79530954089a126f/src/Flowingly.Shared.Angular/flowingly.directives/scrollToCommentBottom.directive.js?at=master
 */

'use strict';
import angular, { IAttributes, IScope, ITimeoutService } from 'angular';

angular.module('flowingly.directives').directive('scrollToCommentBottom', [
  '$timeout',
  function ($timeout: ITimeoutService) {
    return {
      restrict: 'A',
      link: function (
        scope: IScope,
        element: JQuery<HTMLElement>,
        attr: IAttributes
      ) {
        if (attr.scrollToCommentBottom === 'true') {
          $timeout(function () {
            const commentList = document.querySelector('.flow-comments-list');
            commentList.scrollTop = commentList.scrollHeight;
          });
        }
      }
    };
  }
]);
