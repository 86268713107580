import { SharedAngular } from '@Angular.Runner/@types/sharedAngular';
import { LookupSpecialFields } from '@Shared.Angular/@types/core/contracts/queryModel/workflow/lookupSpecialFields';

angular.module('flowingly.components').component('lookupConfig', {
  bindings: {
    field: '=',
    allFields: '<',
    cells: '<',
    onFieldChange: '&'
  },
  templateUrl: 'lookup.config.component.tmpl.html',
  controller: [
    '$http',
    '$timeout',
    'APP_CONFIG',
    'customdbService',
    'guidService',
    'kendoHelper',
    '$q',
    function (
      $http,
      $timeout,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      customdbService: SharedAngular.CustomdbService,
      guidService,
      kendoHelper,
      $q
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const $ctrl = this;
      $ctrl.allDbs = [];
      $ctrl.displayValueOptions = [];
      $ctrl.valueOptions = [];
      $ctrl.randomId = guidService.new();

      $ctrl.instance = {};

      const databasesApiBaseUrl = APP_CONFIG.apiBaseUrl + 'customdatabase';

      $ctrl.$onInit = () => {
        $ctrl.isReady = false;
        if ($ctrl.field.lookupConfig === undefined) {
          $ctrl.field.lookupConfig = {
            dbName: '',
            displayValue: '',
            queryValue: '',
            value: undefined
          };
        }

        // FLOW-6927 If EnableLookupFieldCurrentActorOptions is toggeld off then we need to unset the previously set
        // lookup fields that had Current Actor options. By this way at the time of publish validation will trigger and
        // forces the Flow Model Admin to change the value or turn the business setting back on again, which means
        // toggle on and off works as they are intended.
        if (
          !APP_CONFIG.enableLookupFieldCurrentActorOptions &&
          ($ctrl.field.lookupConfig.value ===
            LookupSpecialFields.CurrentActorFullName ||
            $ctrl.field.lookupConfig.value ===
              LookupSpecialFields.CurrentActorEmail)
        ) {
          $ctrl.field.lookupConfig.value = undefined;
        }

        $timeout(() => {
          $ctrl.instance.kendoQuery = (
            angular.element('#lookupQueryValue_' + $ctrl.randomId) as any
          )
            .kendoDropDownList({
              dataSource: $ctrl.displayValueOptions,
              dataTextField: 'name',
              dataValueField: 'name',
              clearButton: false
            })
            .data('kendoDropDownList');

          $ctrl.instance.kendoDisplay = (
            angular.element('#lookupDisplayValue_' + $ctrl.randomId) as any
          )
            .kendoDropDownList({
              dataSource: $ctrl.displayValueOptions,
              dataTextField: 'name',
              dataValueField: 'name',
              clearButton: false
            })
            .data('kendoDropDownList');

          $ctrl.instance.kendoDb = (
            angular.element('#lookupDBValue_' + $ctrl.randomId) as any
          )
            .kendoDropDownList({
              dataSource: $ctrl.allDbs,
              dataTextField: 'name',
              dataValueField: 'name',
              clearButton: false
            })
            .data('kendoDropDownList');
        }).then(() => {
          const lookupConfig = $ctrl.field.lookupConfig;
          return $q
            .all([
              customdbService.getAllDatabases(),
              lookupConfig.dbName &&
                customdbService.getColumns(lookupConfig.dbName)
            ])
            .then(([allDbs, currColumns]) => {
              // work out the valueOptions for filters which are
              // short text|number|dropdown list|option list|currency|email
              // fields before current field
              $ctrl.valueOptions = customdbService.populateValueOptions(
                { allFields: $ctrl.allFields, cells: $ctrl.cells },
                $ctrl.field,
                undefined,
                shouldAddCurrentActorOptions()
              );
              $ctrl.allDbs = allDbs;

              $ctrl.instance.kendoDb.setDataSource($ctrl.allDbs);
              if (lookupConfig.dbName) {
                $ctrl.instance.kendoQuery.setDataSource(currColumns);
                $ctrl.instance.kendoDisplay.setDataSource(currColumns);

                kendoHelper.selectByCriteria($ctrl.instance.kendoDb, {
                  name: lookupConfig.dbName
                });

                if (lookupConfig.queryValue) {
                  kendoHelper.selectByCriteria($ctrl.instance.kendoQuery, {
                    name: lookupConfig.queryValue
                  });
                }
                if (lookupConfig.displayValue) {
                  kendoHelper.selectByCriteria($ctrl.instance.kendoDisplay, {
                    name: lookupConfig.displayValue
                  });
                }
              }
              return $ctrl.populateQueryAndDisplayColumns(); // populate the query/and dispaly columns
            })
            .then(() => {
              $ctrl.isReady = true;
            });
        });
      };

      $ctrl.$onDestroy = function () {
        $ctrl.allDbs = [];
        $ctrl.displayValueOptions = [];
        $ctrl.valueOptions = [];
      };

      $ctrl.onDbNameChange = () => $ctrl.populateQueryAndDisplayColumns();

      $ctrl.populateQueryAndDisplayColumns = (
        suppressSubmitFieldChangeNotification: boolean
      ) => {
        console.log($ctrl.field.displayName + ': enter onDbNameChange');

        // reset values
        $ctrl.displayValueOptions = [];

        if (
          $ctrl.field.lookupConfig.dbName &&
          $ctrl.field.lookupConfig.dbName !== ''
        ) {
          console.log(
            $ctrl.field.displayName +
              `: API getting all columns ${$ctrl.field.lookupConfig.dbName}`
          );
          return $http
            .get(
              `${databasesApiBaseUrl}/columns/${$ctrl.field.lookupConfig.dbName}`,
              { noSpinner: true }
            )
            .then(function (response) {
              const idColumnIndex = response.data.findIndex(
                (d) => d.name === 'Id'
              );
              const customIdColumnIndex = response.data.findIndex(
                (d) => d.name === 'customdatabaseid'
              );

              if (idColumnIndex >= 0 && customIdColumnIndex < 0) {
                response.data.splice(idColumnIndex, 1);
              } else if (customIdColumnIndex >= 0) {
                response.data.splice(customIdColumnIndex, 1);
              }

              $ctrl.displayValueOptions = response.data;

              setQueryValueOptionAndEnablement({
                options: $ctrl.displayValueOptions,
                enable: true
              });

              $timeout(() => {
                angular
                  .element('#lookupQueryValue_' + $ctrl.randomId)
                  .data('kendoDropDownList')
                  .value($ctrl.field.lookupConfig.queryValue);
                angular
                  .element('#lookupQueryValue_' + $ctrl.randomId)
                  .data('kendoDropDownList')
                  .text($ctrl.field.lookupConfig.queryValue || '');

                angular
                  .element('#lookupDisplayValue_' + $ctrl.randomId)
                  .data('kendoDropDownList')
                  .value($ctrl.field.lookupConfig.displayValue);
                angular
                  .element('#lookupDisplayValue_' + $ctrl.randomId)
                  .data('kendoDropDownList')
                  .text($ctrl.field.lookupConfig.displayValue || '');
              }, 200);

              // work out the valueOptions for filters which are short text/number/dropdown list/option list/currency/email fields before current field
              $ctrl.valueOptions = customdbService.populateValueOptions(
                { allFields: $ctrl.allFields, cells: $ctrl.cells },
                $ctrl.field,
                undefined,
                shouldAddCurrentActorOptions()
              );
              if (
                suppressSubmitFieldChangeNotification &&
                suppressSubmitFieldChangeNotification == true
              ) {
                return;
              }
              // submit changes
              $ctrl.submitFieldChange({ cell: $ctrl.field });
            });
        } else {
          return $q.resolve();
        }
      };

      $ctrl.onDisplayColumnChange = () => {
        const optionName = $ctrl.field.lookupConfig.displayValue;
        $ctrl.field.lookupConfig.displayValueType = _.chain(
          $ctrl.displayValueOptions
        )
          .find({ name: optionName })
          .get('dataType')
          .value();
        $ctrl.submitFieldChange();
      };

      $ctrl.onQueryColumnChange = () => {
        $ctrl.submitFieldChange();
      };

      $ctrl.submitFieldChange = (isQueryColumn, isDisplayColumn) => {
        if (
          $ctrl.field.lookupConfig.value ===
            LookupSpecialFields.CurrentActorFullName ||
          $ctrl.field.lookupConfig.value ===
            LookupSpecialFields.CurrentActorEmail
        ) {
          // reset values
          $ctrl.displayValueOptions = [];

          setQueryValueOptionAndEnablement({
            options: $ctrl.displayValueOptions,
            enable: false
          });

          $timeout(() => {
            angular
              .element('#lookupQueryValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .value($ctrl.field.lookupConfig.queryValue);
            angular
              .element('#lookupQueryValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .text($ctrl.field.lookupConfig.queryValue || '');

            angular
              .element('#lookupDisplayValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .value($ctrl.field.lookupConfig.displayValue);
            angular
              .element('#lookupDisplayValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .text($ctrl.field.lookupConfig.displayValue || '');
          }, 200);
        } else {
          $ctrl.populateQueryAndDisplayColumns(true);
        }
        if ($ctrl.field) {
          $ctrl.onFieldChange({ cell: $ctrl.field });
        }
      };

      function setQueryValueOptionAndEnablement(config) {
        const queryValueDropdown = angular
          .element('#lookupQueryValue_' + $ctrl.randomId)
          .data('kendoDropDownList');
        if (queryValueDropdown) {
          queryValueDropdown.setDataSource(config.options);
          queryValueDropdown.enable(config.enable);
        }

        const displayValueDropdown = angular
          .element('#lookupDisplayValue_' + $ctrl.randomId)
          .data('kendoDropDownList');
        if (displayValueDropdown) {
          displayValueDropdown.setDataSource(config.options);
          displayValueDropdown.enable(config.enable);
        }

        $timeout(() => {
          angular
            .element('#lookupQueryValue_' + $ctrl.randomId)
            .data('kendoDropDownList')
            .value($ctrl.field.lookupConfig.queryValue);
          angular
            .element('#lookupQueryValue_' + $ctrl.randomId)
            .data('kendoDropDownList')
            .text($ctrl.field.lookupConfig.queryValue || '');

          angular
            .element('#lookupDisplayValue_' + $ctrl.randomId)
            .data('kendoDropDownList')
            .value($ctrl.field.lookupConfig.displayValue);
          angular
            .element('#lookupDisplayValue_' + $ctrl.randomId)
            .data('kendoDropDownList')
            .text($ctrl.field.lookupConfig.displayValue || '');
        }, 200);
      }

      function shouldAddCurrentActorOptions() {
        return APP_CONFIG.enableLookupFieldCurrentActorOptions;
      }
    }
  ]
});
