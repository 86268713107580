'use strict';
import angular from 'angular';

angular.module('flowingly.directives').directive('duedatePastValidation', [
  'momentService',
  function (moment: Moment) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl: angular.IController) {
        ctrl.controlDateTimeFormat = 'DD/MM/YYYY hh:mm A';

        // add a parser that will process each time the value is
        // parsed into the model when the user updates it.
        ctrl.$parsers.unshift(function (value) {
          if (value) {
            value = moment(value, ctrl.controlDateTimeFormat);

            // test and set the validity after update.
            const valid = moment(value).isAfter(moment());
            ctrl.$setValidity('pattern', valid);

            return value;
          } else {
            ctrl.$setValidity('required', false);
          }
        });

        // add a formatter that will process each time the value
        // is updated on the DOM element.
        ctrl.$formatters.unshift(function (value) {
          if (value) {
            value = moment(value, ctrl.controlDateTimeFormat);
            // validate.
            ctrl.$setValidity('pattern', moment(value).isAfter(moment()));
            // return the value or nothing will be written to the DOM.

            return value;
          } else {
            ctrl.$setValidity('required', false);
          }
        });
      }
    };
  }
]);
