/**
 * @ngdoc service
 * @name appInsightsService
 * @module flowingly.services
 *
 * @description A service responsible for app insights
 *
 * ###API
 * * startAppInsights - Initialise app insights so logging can start
 * * trackPageView - Log a page view event
 * * trackMetric - Log a metric
 * * startEventTimer - Start a timer for the named event
 * * getAndClearElapsedEventSeconds - Returns the seconds elapsed since the named event started, and clears the timer
 */
'use strict';
import angular, { IPromise } from 'angular';
import {
  ApplicationInsights,
  ICustomProperties,
  IPageViewTelemetry
} from '@microsoft/applicationinsights-web';
import { Services } from '@Shared.Angular/@types/services';

export type AppInsightsConfig = {
  instrumentationKey: string;
  [key: string]: string | number | boolean | string[] | null | undefined;
};

type AppInsights = InstanceType<typeof ApplicationInsights>;
type Properties = IPageViewTelemetry['properties'];
type CustomProperties = {
  businessName: string;
  flowIdentifier?: string; // eg FLOW-11289
  userId: string;
  flowId?: string;
  flowIds?: string;
  stepName?: string;
  stepTypeName?: string;
  bulkActorsCount?: number;
  taskName?: string;
  publicFormId?: string;
  publicFormBusinessId?: string;
  publicFormUserId?: string;
  percentageComplete?: number;
  isLastStep?: boolean;
  transitionToStep?: string;
  finalisedReason?: string;
};

// map event name to metric name
const eventToMetricMap = {
  flowStarted: 'flow-started',
  flowStartedForBulkActors: 'flow-started', // flowStarted and flowStartedForBulkActors under same metric flow-started
  flowSelectedFromList: 'flow-loaded',
  flowLoadFromExternalLink: 'flow-loaded-by-link',
  flowsActiveEntered: 'flows-active-loaded',
  flowsTodoEntered: 'flows-todo-loaded',
  flowsInEntered: 'flows-in-loaded',
  flowLoadProcessMapList: 'processmap-list-loaded',
  flowRenderProcessMap: 'processmap-loaded',
  stepFormSubmitted: 'flow-step-form-completed', // click submit on a step form, and see the toast Step completed
  stepFormTransitionStarted: 'flow-next-step-transitioned', // user click Submit a step form, and transitioned to next step form
  stepFormSaved: 'flow-step-form-saved', // user click Save button on a step form, and see the toast Changes successfully saved
  publicFormSubmitted: 'flow-public-form-completed',
  stepTaskDoneSubmitted: 'flow-step-task-done',
  stepTaskApprovalSubmitted: 'flow-step-task-approval-done',
  stepReassigned: 'flow-step-reassigned',
  stepTaskReassigned: 'flow-step-task-reassigned',
  flowCanceled: 'flow-cancelled',
  stepTaskCanceled: 'flow-step-task-cancelled',
  stepUserNudged: 'flow-step-user-nudged',
  subcategoriesFetched: 'categories-with-subcategories-fetched',
  subcategoryDeleteWarning: 'delete-warnings-for-subcategories-fetched',
  subcategoriesdeleted: 'subcategory-deleted',
  categoryEdited: 'category-edited',
  subcategoryAdded: 'subcategory-added',
  userInviteLoaded: 'user-invite-loaded',
  userInviteBusinessSettingsLoaded: 'user-invite-business-settings-loaded',
  userInviteProfileCompleted: 'user-invite-profile-completed'
} as const;
// app insights name of event
export type EventName = keyof typeof eventToMetricMap;
// app insights name of metrics
export type MetricName = (typeof eventToMetricMap)[EventName];

type EventTimers = Record<EventName, number> | Record<string, never>;
type UserActivityTimed = Record<EventName, number> | Record<string, never>;
angular
  .module('flowingly.services')
  .factory('appInsightsService', appInsightsService);

appInsightsService.$inject = ['appInsightsHelper'];
function appInsightsService(appInsightsHelper: Services.AppInsightsHelper) {
  const eventTimers: EventTimers = {};
  const userActivityTimed: UserActivityTimed = {};
  let appInsights: AppInsights;

  const service = {
    startAppInsights,
    trackPageView,
    startEventTimer,
    trackMetricIfTimerExist,
    timeUserActivityForEvent
  };

  return service;

  //////////// Public API Methods
  function startAppInsights(cfg: AppInsightsConfig) {
    /**
     * instrumentationKey field always exist
     * but rest of fields are dynamic depending on the setup of AppInsights in Azure
     */
    const { instrumentationKey, ...restOfConfig } = cfg;

    const config = {
      connectionString: 'InstrumentationKey=' + instrumentationKey,
      ...restOfConfig
    };

    appInsights = new ApplicationInsights({
      config
    });

    appInsights.loadAppInsights();
    // Manually call trackPageView to establish the current user/session/pageview
    appInsights.trackPageView();
  }

  function trackPageView(name: string, properties: Properties) {
    if (!appInsights) {
      return;
    }
    try {
      appInsights.trackPageView({
        name,
        properties
      });
    } catch {
      /* empty */
    }
  }

  function trackMetric(
    name: MetricName,
    average: number,
    properties: CustomProperties
  ) {
    if (!appInsights) {
      return;
    }
    try {
      appInsights.trackMetric({
        name,
        average,
        properties
      });
    } catch {
      /* empty */
    }
  }

  function startEventTimer(eventName: EventName) {
    resetAllTimer(eventName);
    eventTimers[eventName] = Date.now();
  }

  function resetAllTimer(eventName: EventName) {
    eventTimers[eventName] = undefined;
    userActivityTimed[eventName] = undefined;
  }

  function getElapsedTimeInSeconds(eventName: EventName) {
    const startTime = eventTimers[eventName];
    const userActivitiyTimeTaken = userActivityTimed[eventName] ?? 0;

    if (startTime) {
      return (Date.now() - startTime - userActivitiyTimeTaken) / 1000;
    }
  }

  function trackMetricIfTimerExist(
    eventName: EventName,
    otherProps?: ICustomProperties
  ) {
    const metricName = eventToMetricMap[eventName];
    const elapsedInSeconds = getElapsedTimeInSeconds(eventName);

    if (elapsedInSeconds && metricName) {
      resetAllTimer(eventName);

      const properties = {
        ...appInsightsHelper.getCustomProps(),
        ...otherProps
      };
      trackMetric(metricName, elapsedInSeconds, properties);
    }
  }

  // user activity time taken while an event is going on
  function addUserActivityToEvent(eventName: EventName, timeMs: number) {
    const currentTimeMs = userActivityTimed[eventName] ?? 0;
    userActivityTimed[eventName] = timeMs + currentTimeMs;
  }

  /**
   * Measure time taken when user perform an activity like entering data, select data in a dialog box
   * Time in the app insights will exclude any user activity time
   */
  function timeUserActivityForEvent<T = unknown>(
    fn: (...params: unknown[]) => IPromise<T>,
    names: EventName | EventName[]
  ) {
    return (...rest: unknown[]) => {
      const startTime = Date.now();
      return fn(...rest).then((result) => {
        const elapsedTime = Date.now() - startTime;
        const eventNames = Array.isArray(names) ? names : [names];

        eventNames.forEach((eventName) => {
          addUserActivityToEvent(eventName, elapsedTime);
        });

        return result;
      });
    };
  }
}

export type AppInsightsServiceType = ReturnType<typeof appInsightsService>;
