import angular from 'angular';
angular.module('flowingly.adapters').factory('kendoService', [
  '$window',
  function ($window: angular.IWindowService) {
    if ($window.kendo === undefined) {
      console.error('kendo is not loaded');
    }

    return $window.kendo;
  }
]);
