/**
 * @ngdoc service
 * @name FlowinglyMentionService
 * @module flowingly.services
 *
 * @description A helper service for mention
 *
 * ## Notes
 *
 * ###API
 * * transformMentionForDisplay - helper function to transform mention text to special html tag for display
 * * transformMentionDisplayToStore - helper function to transform mention display raw text to clean text ready for persist to db
 * * transformRawComments - helper function to transform raw comment to ready for UI object, eg. moment datetime
 * * extractMentionsFromCommentText - helper function to extract out a unique mention object list from raw comment content
 * * trimSpaces - helper function to trim off all spaces and &nbsp;
 *
 */

import angular from 'angular';
import { IComment } from '../interfaces/comment.interface';
import { ICommentMention } from '../interfaces/comment-mention.interface';

export class FlowinglyMentionService {
  constructor(private flowinglyMomentService) {}

  private displayRegex =
    /(<a class="mention-name" (?:(?:actor-id=".*?" actor-type-id=".*?")|(?:actor-type-id=".*?" actor-id=".*?"))>)(.*?)(<\/a>)/gm;
  // TODO actor-id=".*?" actor-type-id=".*?" or actor-type-id=".*?" actor-id=".*?"

  public transformMentionForDisplay(mention: ICommentMention): string {
    return `<a class="mention-name" actor-id="${mention.actorId}" actor-type-id="${mention.actorTypeId}">${mention.actorName}</a>`;
  }

  public transformMentionTag(contents: string): string {
    const regex = /(\[~((?!\[~).)+\])/gm;
    const afterTransformName = contents.replace(
      regex,
      (match, openTag, name) => {
        return `<a class="mention-name">${match.substring(
          2,
          match.length - 1
        )}</a>`;
      }
    );
    return afterTransformName;
  }

  public transformMentionDisplayToStore(mentionDisplay: string): string {
    const afterTransformName = mentionDisplay.replace(
      this.displayRegex,
      (match, openTag, name) => {
        return `[~${name}]`;
      }
    );
    const lineBreakRegex = /(<div>)/gm;
    const afterStripHtmlTag = afterTransformName
      .replace(/(<font color="#039be5">)/gm, '')
      .replace(/(<\/font>)/gm, '')
      .replace(lineBreakRegex, '<br>')
      .replace(/(<\/div>)/gm, '')
      .replace(/(&nbsp;)/gm, ' ');

    return afterStripHtmlTag;
  }

  public transformRawComments(rowComments: IComment[]): IComment[] {
    for (const comment of rowComments) {
      comment.momentCommented =
        this.flowinglyMomentService.calculateRelativeTimeOrFullDate(
          comment.createdDate
        );
    }

    return rowComments;
  }

  public extractMentionsFromCommentText(
    commentText: string
  ): ICommentMention[] {
    const mentions: ICommentMention[] = [];
    const actorIdRegex = /(actor-id=".*?")/gm;
    const actorTypeIdRegex = /(actor-type-id=".*?")/gm;

    let tempArray: string[];
    while ((tempArray = this.displayRegex.exec(commentText)) !== null) {
      if (tempArray && tempArray.length > 0) {
        const actorIdRaw = tempArray[0].match(actorIdRegex);
        const actorTypeIdRaw = tempArray[0].match(actorTypeIdRegex);

        if (
          actorIdRaw &&
          actorIdRaw.length > 0 &&
          actorTypeIdRaw &&
          actorTypeIdRaw.length > 0
        ) {
          const actorId: string = this.extractId(actorIdRaw[0], 'actor-id');
          const actorTypeId: number = parseInt(
            this.extractId(actorTypeIdRaw[0], 'actor-type-id')
          );

          if (
            mentions.findIndex((m) => {
              return m.actorId === actorId;
            }) < 0
          ) {
            mentions.push({
              actorId: actorId,
              actorTypeId: actorTypeId
            });
          }
        }
      }
    }

    return mentions;
  }

  public trimSpaces(contents: string): string {
    const regex = /(&nbsp;)|(\s)|(<br>)/gm;
    return contents.replace(regex, '');
  }

  private extractId(str: string, key: string): string {
    const temp = str.replace(`${key}="`, '');
    return temp.slice(0, temp.length - 1);
  }
}

angular
  .module('flowingly.services')
  .factory('flowinglyMentionService', [
    'flowinglyMomentService',
    (flowinglyMomentService) =>
      new FlowinglyMentionService(flowinglyMomentService)
  ]);

export type FlowinglyMentionServiceType = InstanceType<
  typeof FlowinglyMentionService
>;
