(function () {
  'use strict';

  angular.module('flowingly.adapters').factory('html2canvasService', [
    '$window',
    function ($window) {
      if ($window.html2canvas === undefined) {
        console.error('html2canvas is not loaded');
      }
      return $window.html2canvas;
    }
  ]);
})();
