/**
 * @ngdoc service
 * @name templateService
 * @module flowingly.services
 *
 * @description A service for Flow Model Templates
 */
'use strict';
import { Services } from '@Shared.Angular/@types/services';
import {
  ITemplatePack,
  ITemplateTenant
} from '@Shared.Angular/@types/templates';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('templateService', templateService);

templateService.$inject = ['APP_CONFIG', '$http'];

function templateService(
  APP_CONFIG: Services.APP_CONFIG,
  $http: angular.IHttpService
) {
  const service = {
    getTemplates: getTemplates,
    getTemplate: getTemplate,
    saveTemplate: saveTemplate,
    getPacks: getPacks,
    savePack: savePack,
    getTenants: getTenants,
    saveTenant: saveTenant,
    getCategories: getCategories,
    getTemplateIdForKey: getTemplateIdForKey,
    sendAccessRequest: sendAccessRequest,
    getExportTemplates: getExportTemplates,
    importTemplates: importTemplates
  };

  return service;

  function getTemplates(forManagement: boolean) {
    return $http
      .get(`${APP_CONFIG.apiBaseUrl}templates?forManagement=${forManagement}`)
      .then((response) => response.data);
  }

  function getTemplate(key: string) {
    return $http
      .get(`${APP_CONFIG.apiBaseUrl}templates/${key}`)
      .then((response) => response.data);
  }

  function saveTemplate(template) {
    return $http
      .put(`${APP_CONFIG.apiBaseUrl}templates/`, template)
      .then((response) => response.data);
  }

  function getCategories() {
    return $http
      .get(`${APP_CONFIG.apiBaseUrl}templates/categories`)
      .then((response) => response.data);
  }

  function getPacks() {
    return $http
      .get<ITemplatePack[]>(`${APP_CONFIG.apiBaseUrl}templates/packs`)
      .then((response) => response.data);
  }

  function savePack(pack) {
    return $http
      .put<string>(`${APP_CONFIG.apiBaseUrl}templates/packs`, pack)
      .then((response) => response.data);
  }

  function getTenants() {
    return $http
      .get<ITemplateTenant[]>(`${APP_CONFIG.apiBaseUrl}templates/tenants`)
      .then((response) => response.data);
  }

  function saveTenant(tenant) {
    return $http
      .put<string>(`${APP_CONFIG.apiBaseUrl}templates/tenants/`, tenant)
      .then((response) => response.data);
  }

  function sendAccessRequest(params) {
    return $http
      .post(`${APP_CONFIG.apiBaseUrl}templates/access`, params)
      .then((response) => response.data);
  }

  function getTemplateIdForKey(key: string) {
    const uri = `${APP_CONFIG.apiBaseUrl}templates/key/${encodeURIComponent(
      key
    )}`;
    return $http.get(uri).then((response) => response.data);
  }

  function getExportTemplates() {
    return $http
      .get(`${APP_CONFIG.apiBaseUrl}templates/export`)
      .then((response) => response.data);
  }

  function importTemplates(templates) {
    return $http
      .put(`${APP_CONFIG.apiBaseUrl}templates/export`, templates)
      .then((response) => response.data);
  }
}

/**
 * type of templateService
 */
export type TemplateServiceType = ReturnType<typeof templateService>;
