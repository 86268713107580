/**
 * @ngdoc directive
 * @name mention
 * @module flowingly.components
 * @description This is a component for editing text contents with ability to search mentions
 *
 * Input attributes
 * contents: the text contents reference, we need to use reference here,
 *  then the place that consumes this component can get the latest text contents easily
 * debounceInterval: the interval in milliseconds for model debounce option,
 *  eg. how long to wait between model changes and searching API for match mentions
 *
 * @usage
 * ```
 * <flowingly-mention contents="$ctrl.comment" debounce-interval="$ctrl.debounceInterval"></flowingly-mention>
 * ```
 */

import * as angular from 'angular';
import { ICommentMention } from '../../interfaces/comment-mention.interface';
import { ActorType } from '../../interfaces/actor-type.enum';
import { Services } from '@Shared.Angular/@types/services';
import { AddCommentComponentController } from '../comments/add-comment.component';

class MentionComponentController {
  static $inject = [
    '$q',
    '$scope',
    'commentApiService',
    'flowinglyMentionService',
    'avatarService',
    'validationService'
  ];
  public contents: string;
  public commentTargetId: string;
  public flowOwnerOnly: string;
  public debounceInterval: number;
  public hasXssVulnerableString = false;
  public resultStack: ICommentMention[][] = [];
  public everyoneMentioned = false;
  private everyoneActorId: string;

  constructor(
    private $q: ng.IQService,
    private $scope: angular.IScope,
    private commentApiService: Services.CommentApiService,
    private flowinglyMentionService: Services.FlowinglyMentionService,
    private avatarService: Services.AvatarService,
    private validationService: Services.ValidationService
  ) {}

  $onInit() {
    this.debounceInterval = this.debounceInterval || 500;
    this.$scope.$on(AddCommentComponentController.COMMENT_RESET_EVENT, () => {
      this.contents = '';
      this.onContentsChange();
    });

    // Add an event listener for the Enter key
    this.addEnterKeyListener();
  }

  /**
   * Add an event listener to handle the Enter key in the contenteditable element.
   */
  addEnterKeyListener() {
    const contentEditableElement = document.querySelector('.mention-contents');
    if (contentEditableElement) {
      contentEditableElement.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          document.execCommand('insertHTML', false, '<br><br>');

          // Scroll to the bottom of the contentEditable element
          contentEditableElement.scrollTop =
            contentEditableElement.scrollHeight;
        }
      });
    }
  }

  /**
   * Find the first finished result in the execution stack.
   * The topmost can be null (because the request have not come back yet)
   */
  get searchResult() {
    for (let i = this.resultStack.length - 1; i >= 0; i--) {
      const result = this.resultStack[i];
      if (result) return result;
    }
  }

  searchAvailableMentions(term: string) {
    if (term.length > 0) {
      const index = this.resultStack.push(null) - 1; // placeholder

      return this.commentApiService
        .searchMentions(
          term,
          this.flowOwnerOnly === 'true',
          this.commentTargetId,
          false
        )
        .then((data: ICommentMention[]) => {
          data.forEach((mention) => {
            mention.avatarUrl = this.avatarService.getAvatarUrl(
              mention.actorId
            );
          });

          this.resultStack[index] = data;
          return this.$q.when(data);
        });
    }
  }

  onMentionSelect(item: ICommentMention): string {
    if (item.actorName == 'Everyone' && item.actorTypeId === ActorType.Team) {
      this.everyoneActorId = item.actorId;
      this.everyoneMentioned = true;
    }

    return this.flowinglyMentionService.transformMentionForDisplay(item);
  }

  onContentsChange() {
    this.everyoneMentioned =
      this.everyoneActorId &&
      this.contents.indexOf(`actor-id="${this.everyoneActorId}"`) > -1;
    this.hasXssVulnerableString = false;
    const commentContents: string =
      this.flowinglyMentionService.transformMentionDisplayToStore(
        this.contents || ''
      );

    if (this.validationService.isXssVulnerableString(commentContents)) {
      this.hasXssVulnerableString = true;
    }
  }

  handlePaste(e): void {
    e.preventDefault();
    const copyData = e.originalEvent.clipboardData.getData('text/plain');

    if (this.flowinglyMentionService.trimSpaces(copyData) !== '') {
      this.contents += copyData;
    }
  }
}

export class MentionComponent implements angular.IComponentOptions {
  public bindings: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      contents: '=',
      debounceInterval: '<?',
      flowOwnerOnly: '<',
      commentTargetId: '<'
    };

    this.templateUrl = 'flowingly.mention.tmpl.html';
  }

  controller = MentionComponentController;
}

angular
  .module('flowingly.components')
  .component('flowinglyMention', new MentionComponent());
