/*
 * COnverted to ts on 14/01/2020
 * SEe https://bitbucket.org/flowingly-team/flowingly-source-code/src/b5788369b5193fa36b3a87d2ff6b9d99e5193c48/src/Flowingly.Shared.Angular/flowingly.services/flowingly.step.service.js?at=master
 */

'use strict';
import angular from 'angular';
angular
  .module('flowingly.services')
  .factory('flowinglyStepService', flowinglyStepService);

function flowinglyStepService() {
  //API
  const service = {
    stepRefSequenceComparer: stepRefSequenceComparer
  };

  return service;

  //////////// Public API Methods
  function stepRefSequenceComparer(
    refSequence1,
    refSequence2,
    componentSequence1,
    componentSequence2
  ) {
    if (!refSequence1) return 1;
    if (!refSequence2) return -1;
    const parts1 = refSequence1.split('.');
    const parts2 = refSequence2.split('.');
    const parts3 = componentSequence1 && componentSequence1.split('.');
    const parts4 = componentSequence2 && componentSequence2.split('.');

    if (parts1.length !== 2 || parts2.length !== 2) return 0;

    if (
      parts1[0].length < parts2[0].length ||
      (parts1[0].length === parts2[0].length && parts1[0] < parts2[0])
    )
      return -1;

    if (
      parts1[0] === parts2[0] &&
      (parts1[1].length < parts2[1].length ||
        (parts1[1].length === parts2[1].length && parts1[1] < parts2[1]))
    )
      return -1;

    if (parts1[0] === parts2[0] && parts1[1] === parts2[1]) {
      if (!componentSequence1) return 1;
      if (!componentSequence2) return -1;

      if (parts3.length !== 2 || parts4.length !== 2) return 0;

      if (
        parts3[0].length < parts4[0].length ||
        (parts3[0].length === parts4[0].length && parts3[0] < parts4[0])
      )
        return -1;

      if (
        parts3[0] === parts4[0] &&
        (parts3[1].length < parts4[1].length ||
          (parts3[1].length === parts4[1].length && parts3[1] < parts4[1]))
      )
        return -1;
    }

    if (
      parts3 &&
      parts4 &&
      parts3[0] === parts4[0] &&
      parts3[1] === parts4[1]
    ) {
      return 0;
    }

    return 1;
  }
}

export type FlowinglyStepServiceType = ReturnType<typeof flowinglyStepService>;
