/*
 *
 * This directive will check back to some function to see if a confirmation click is required
 * ie Do you "REALLY" want to do this?
 * If a confirmarion click is require then it will change the display name of the button and await 5 seconds
 * for that confirmation click by the user.
 * It also shakes the window a bit to grab the attention of the user
 *
 * Usage: <button really-click="dlgCtrl.cancel()" confirm-needed="dlgCtrl.changed()" confirm-message="Are you sure? Click to Cancel.">Cancel</button>
 *
 * Attributes:
 * confirm-needed: pass in the function that will return true (confirmation is needed) or false (confirmation is not needed)
 * confirm-message: the text of the confirmation message - dont make it too long
 * really-click: the directive name and the function to call when the user confirms the action
 *
 * Converted to ts on 15/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/really.directive.js?at=master
 */
import angular, { IAttributes, IDocumentService, IScope } from 'angular';
angular.module('flowingly.directives').directive('reallyClick', [
  '$document',
  function ($document: IDocumentService) {
    return {
      scope: {},
      restrict: 'A',
      link: function (
        scope: IScope,
        element: JQuery<HTMLElement>,
        attrs: IAttributes
      ) {
        const actionText = element.text();
        let textWidth = null;
        let hasConfirmed = false;
        scope.confirmingAction = false;
        element.css({ transition: 'max-width 1s' });
        scope.$watch('confirmingAction', function (newVal, oldVal) {
          let body: HTMLElement, clone: JQuery<HTMLElement>;
          if (newVal === oldVal && oldVal === false) {
            clone = element.clone();
            clone.css({
              left: '-9999px',
              position: 'absolute'
            });
            body = $document[0].body;
            body.appendChild(clone[0]);
            textWidth = clone[0].offsetWidth + 100;
            textWidth = textWidth + 'px';
            body.removeChild(clone[0]);
          }
          if (scope.confirmingAction) {
            element.text('');
            scope.IsVisible = true;
            element.css({ maxWidth: '400px' });
            return element.addClass('confirming');
          } else {
            element.text(actionText);
            scope.IsVisible = false;
            element.css({ maxWidth: textWidth });
            return element.removeClass('confirming');
          }
        });
        return element.on('click', function () {
          const res = scope.$parent.$apply(attrs.confirmNeeded);
          if (!scope.confirmingAction && res) {
            scope.$apply(function () {
              scope.IsVisible = true;
              return (scope.confirmingAction = true);
            });
          } else {
            if (hasConfirmed) {
              return;
            }
            hasConfirmed = true;
            element.css({ opacity: '0.5' });
            element.removeClass('confirming');
            scope.IsVisible = false;
            return scope.$parent.$apply(attrs.reallyClick);
          }
        });
      }
    };
  }
]);
