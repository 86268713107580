/**
 * @ngdoc module
 * @name flowingly.directives.files
 * @module flowingly.directives.files
 * @requires templates-directives
 * @description File utilties
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/files/files.module.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.directives.files', ['templates-directives']);
