/**
 * @ngdoc service
 * @name BpmnLaneNodeService
 * @module flowingly.bpmn.modeler
 *
 * @description  A service for creating BPMN Lane nodes.
 *
 * ## Notes
 *  There are two types of nodes (node/palette node). The first is what gets drawn on the canvas;
 *  the second what gets drawni the palette and dragged on to the canvas.
 *
 * ### Model Usage
 * { key: "Lane5", "text": "Lane 1", "isGroup": "true", "group": "501","color": "lightyellow", "category": "Lane" },
 *
 * ###API
 * * getNode - Get a swim lane defined by the (optional) options
 * * getPaletteNode - Get a swim lane palette node defined by the (optional) options
 *
 * Converted to ts on 17/01/020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/895ebfda5208378cbdb14687b51a1dd6f0d0d4e4/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.lane.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';
angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnLaneNodeService', BpmnLaneNodeService);

BpmnLaneNodeService.$inject = ['goService', '$window', 'BpmnCommonService'];

// ReSharper disable once InconsistentNaming
function BpmnLaneNodeService(
  goService: GoJS,
  $window: angular.IWindowService,
  BpmnCommonService: BpmnModeler.BpmnCommonService
) {
  const service = {
    getNode: getNode,
    getPaletteNode: getPaletteNode
  };
  return service;

  /// PUBLIC ///////////////////////////////////////////////////////////////////////

  function getNode(options) {
    //angular.extend(defaults, options); //override defaults, if options supplied

    return getLaneNodeTemplate();
  }

  ///
  /// Get a Pool node defined by the (optional) options
  /// If options is not supplied, default values will be used
  ///
  function getPaletteNode(options) {
    //angular.extend(defaults, options); //override defaults, if options supplied

    return getLanePaletteNode();
  }

  //private functions

  function groupStyle() {
    // common settings for both Lane and Pool Groups
    return [
      {
        cursor:'move',
        layerName: 'Background', // all pools and lanes are always behind all nodes and links
        background: 'transparent', // can grab anywhere in bounds
        movable: true, // allows users to re-order by dragging
        copyable: false, // can't copy lanes or pools
        avoidable: false // don't impede AvoidsNodes routed Links
      },
      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify)
    ];
  }

  // hide links between lanes when either lane is collapsed
  function updateCrossLaneLinks(group) {
    group.findExternalLinksConnected().each(function (l) {
      l.visible = l.fromNode.isVisible() && l.toNode.isVisible();
    });
  }

  function relayoutDiagram(dia) {
    const myDiagram =
      typeof $window.mainDiagram === 'undefined' ? dia : $window.mainDiagram;
    myDiagram.layout.invalidateLayout();
    myDiagram.findTopLevelGroups().each(function (g) {
      if (g.category === 'Pool') g.layout.invalidateLayout();
    });
    myDiagram.layoutDiagram();
  }

  function getLaneNodeTemplate() {
    const $GO = goService.GraphObject.make;

    const laneNodeTemplate = $GO(
      goService.Group,
      'Spot',
      groupStyle(),
      {
        name: 'Lane',
        contextMenu: null,
        minLocation: new goService.Point(NaN, -Infinity), // only allow vertical movement
        maxLocation: new goService.Point(NaN, Infinity),
        selectionObjectName: 'SHAPE', // selecting a lane causes the body of the lane to be highlit, not the label
        resizable: true,
        resizeObjectName: 'SHAPE', // the custom resizeAdornmentTemplate only permits two kinds of resizing

        mouseEnter: function (e, obj) {
          obj.part.movable = false;
        },
        mouseLeave: function (e, obj) {
          obj.part.movable = true;
        },

        layout: $GO(
          goService.LayeredDigraphLayout, // automatically lay out the lane's subgraph
          {
            isInitial: false, // don't even do initial layout
            isOngoing: false, // don't invalidate layout when nodes or links are added or removed
            direction: 0,
            columnSpacing: 10,
            layeringOption:
              goService.LayeredDigraphLayout.LayerLongestPathSource
          }
        ),
        computesBoundsAfterDrag: true, // needed to prevent recomputing Group.placeholder bounds too soon
        computesBoundsIncludingLinks: false, // to reduce occurrences of links going briefly outside the lane
        computesBoundsIncludingLocation: true, // to support empty space at top-left corner of lane
        handlesDragDropForMembers: true, // don't need to define handlers on member Nodes and Links
        mouseDrop: function (e, grp) {
          // dropping a copy of some Nodes and Links onto this Group adds them to this Group
          // don't allow drag-and-dropping a mix of regular Nodes and Groups
          //todo: not sure if we need this right now
          if (
            !e.diagram.selection.any(function (n) {
              return (
                (n instanceof goService.Group && n.category !== 'subprocess') ||
                n.category === 'privateProcess'
              );
            })
          ) {
            const ok = grp.addMembers(grp.diagram.selection, true);
            if (ok) {
              updateCrossLaneLinks(grp);
              relayoutDiagram(grp.diagram);
            } else {
              grp.diagram.currentTool.doCancel();
            }
          }
        },
        subGraphExpandedChanged: function (grp) {
          const shp = grp.resizeObject;
          if (grp.diagram.undoManager.isUndoingRedoing) return;
          if (grp.isSubGraphExpanded) {
            shp.height = grp._savedBreadth;
          } else {
            grp._savedBreadth = shp.height;
            shp.height = NaN;
          }
          updateCrossLaneLinks(grp);
        }
      },
      //new goService.Binding("isSubGraphExpanded", "expanded").makeTwoWay(),
      $GO(
        goService.Shape,
        'Rectangle', // this is the resized object
        { name: 'SHAPE', fill: 'white', stroke: null }, // need stroke null here or you gray out some of pool border.
        new goService.Binding('fill', 'color'),
        new goService.Binding(
          'desiredSize',
          'size',
          goService.Size.parse
        ).makeTwoWay(goService.Size.stringify)
      ),

      $GO(
        goService.Panel,
        'Horizontal',
        {
          name: 'HEADER',
          alignment: goService.Spot.TopLeft,
          alignmentFocus: goService.Spot.TopLeft
        },
        $GO('SubGraphExpanderButton', {
          margin: 4,
          width: 20,
          height: 20
        }) // but this remains always visible!
      ),
      // the lane header
      $GO(
        goService.Panel,
        'Horizontal',
        {
          name: 'HEADER',
          alignment: goService.Spot.LeftCenter,
          alignmentFocus: goService.Spot.LeftCenter
        },
        $GO(
          goService.TextBlock, // the lane label
          {
            editable: true,
            textEditor: BpmnCommonService.getTextEditingTool(),
            margin: new goService.Margin(2, 0, 0, 8),
            width: 500
          },
          new goService.Binding('visible', 'isSubGraphExpanded').ofObject(),
          new goService.Binding('text', 'text').makeTwoWay(),
          new goService.Binding('font', 'fontSize', function (s) {
            return 'normal ' + s + 'px sans-serif';
          })
        )
      ), // end Horizontal Panel
      $GO(goService.Placeholder, {
        padding: 12,
        alignment: goService.Spot.TopLeft,
        alignmentFocus: goService.Spot.TopLeft
      }),
      $GO(
        goService.Panel,
        'Horizontal',
        {
          alignment: goService.Spot.TopLeft,
          alignmentFocus: goService.Spot.TopLeft
        },
        $GO(
          goService.TextBlock, // this TextBlock is only seen when the swimlane is collapsed
          {
            name: 'LABEL',
            editable: false,
            visible: false,
            margin: new goService.Margin(6, 0, 0, 30)
          },
          new goService.Binding('visible', 'isSubGraphExpanded', function (e) {
            return !e;
          }).ofObject(),
          new goService.Binding('text', 'text').makeTwoWay()
        )
      ),
      { click: BpmnCommonService.nodeClickHandler }
    );

    laneNodeTemplate.resizeAdornmentTemplate = $GO(
      goService.Adornment,
      'Spot',
      $GO(goService.Placeholder),
      $GO(
        goService.Shape, // for changing the length of a lane
        {
          alignment: goService.Spot.Right,
          desiredSize: new goService.Size(7, 50),
          fill: 'lightblue',
          stroke: 'dodgerblue',
          cursor: 'col-resize'
        },
        new goService.Binding('visible', '', function (ad) {
          return ad.adornedPart.isSubGraphExpanded;
        }).ofObject()
      ),
      $GO(
        goService.Shape, // for changing the breadth of a lane
        {
          alignment: goService.Spot.Bottom,
          desiredSize: new goService.Size(50, 7),
          fill: 'lightblue',
          stroke: 'dodgerblue',
          cursor: 'row-resize'
        },
        new goService.Binding('visible', '', function (ad) {
          return ad.adornedPart.isSubGraphExpanded;
        }).ofObject()
      )
    );

    return laneNodeTemplate;
  }

  function getLanePaletteNode() {
    const $GO = goService.GraphObject.make;

    const poolNodePaletteTemplate = $GO(goService.Group, 'Vertical');

    return poolNodePaletteTemplate;
  }
}

export type BpmnLaneNodeServiceType = ReturnType<typeof BpmnLaneNodeService>;
