/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/74bdce56b87ea9da733103efa0422090ca820ac3/src/Flowingly.Shared.Angular/flowingly.directives/user-notifications/user.notification-view.component.js?at=master
 */
(function () {
  'use strict';

  ///
  /// This component is used to display the read or unread notification to user

  angular
    .module('flowingly.user.notifications')
    .component('userNotificationView', {
      templateUrl: 'user.notification-view.tmpl.html',
      bindings: {
        notification: '<',
        read: '<',
        isMobile: '<',
        dismissNotification: '&',
        readNotification: '&'
      },
      controller: [
        '$rootScope',
        function ($rootScope) {
          var $ctrl = this;

          $ctrl.onDismissNotification = () => {
            $ctrl.dismissNotification({ notification: $ctrl.notification });
          };

          $ctrl.onReadNotification = () => {
            $ctrl.readNotification({
              flowId: $ctrl.notification.flowId,
              notificationType: $ctrl.notification.notificationType,
              stepTask: $ctrl.notification.stepTaskId
            });

            $rootScope.highlightState = 'app.runner.flowsin';
          };
        }
      ]
    });
})();
