/*
 * @ngdoc service
 * @name watchService
 * @module flowingly.services
 *
 * @description A service for removing for hiding some $watch boilerplate.
 *
 * ## Notes
 * Provides a consistent API and will allow for further checks, clean up.
 * * The event exists in the eventNames array
 * * There is at least one subscriber to that event
 * 
 * ###API
 * * addWatch - register a watch
 * * addCollectionWatch - register a watch for collection
 * * removeWatch - remove a watch
 * 
 * ###Usage
 *    
   Method:
   function addWatch(scope, name, watchFunction, invokeFunction, deep) 
   function addCollectionWatch(scope, name, watchFunction, invokeFunction) 

   Where:
   watchFunction = the expression to watch; should be in the following form
   invokeFunction = the function to invoke if the value has changed; should be in the following form


   It can then be called like this
   watchService.addWatch(
        $scope,
        'collapseContainer.openPanel',
           //watchFunction
           function() {
              return workflowService.selectedNode;
           },
           //invokeFunction
           function() {
             ctrl.Card = workflowService.selectedNode.Card;
           }, 
           true //true for deep watch
    );
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/54444afedbff05ba3348eba812574c11be6f87df/src/Flowingly.Shared.Angular/flowingly.services/watch.service.js?at=master
*/

'use strict';
import angular from 'angular';

angular.module('flowingly.services').factory('watchService', watchService);

watchService.$inject = [];

// @TODO remove all traces of this service
function watchService() {
  const watches = {};

  //API
  const service = {
    addWatch: addWatch, // please PLEASE do not use this service. It promotes BAD and unreadble design patterns
    addCollectionWatch: addCollectionWatch, // plus you shouldn't even be using $watch in the first place! Try rearranaging your
    removeWatch: removeWatch // code instead.                                                - Cassey
  };

  return service;

  //////////// Public API Methods

  function addWatch(scope, name, watchFunction, invokeFunction, deep) {
    const watch = scope.$watch(
      watchFunction,
      function watchCallback(newValue, oldValue) {
        if (newValue !== oldValue) {
          invokeFunction();
        }
      },
      deep
    );

    watches[name] = watch;
  }

  function addCollectionWatch(scope, name, watchFunction, invokeFunction) {
    const watch = scope.$watchCollection(
      watchFunction,
      function watchCallback(newValue, oldValue) {
        if (!angular.equals(newValue, oldValue)) {
          invokeFunction();
        }
      }
    );

    watches[name] = watch;
  }

  function removeWatch(name) {
    if (watches[name]) {
      watches[name]();
    }
  }
}
