/**
 * @ngdoc module
 * @name flowingly.components
 * @module flowingly.components
 * @requires templates-components
 * @description Shared Components
 * 
 * ###Notes
 * This module could be split into sub modules as it grows
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.components/components.module.js?at=master
 *
 
 */

'use strict';
import angular from 'angular';

const componentsModule = angular.module('flowingly.components', [
  'kendo.directives',
  'templates-components'
]);

componentsModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    // comments
    $templateCache.put(
      'add-comment.tmpl.html',
      require('./comments/add-comment.tmpl.html').default
    );

    $templateCache.put(
      'comment-history.tmpl.html',
      require('./comments/comment-history.tmpl.html').default
    );

    $templateCache.put(
      'comments.tmpl.html',
      require('./comments/comments.tmpl.html').default
    );

    // mention
    $templateCache.put(
      'flowingly.mention-search-list.tmpl.html',
      require('./mention/flowingly.mention-search-list.tmpl.html').default
    );
    $templateCache.put(
      'flowingly.mention.tmpl.html',
      require('./mention/flowingly.mention.tmpl.html').default
    );

    $templateCache.put(
      'table.runner.component.tmpl.html',
      require('./tablerunner/table.runner.component.tmpl.html').default
    );

    $templateCache.put(
      'table.viewer.component.tmpl.html',
      require('./tableviewer/table.viewer.component.tmpl.html').default
    );

    $templateCache.put(
      'table.builder.component.tmpl.html',
      require('./tablebuilder/table.builder.component.tmpl.html').default
    );

    $templateCache.put(
      'table.builder.cell.component.tmpl.html',
      require('./tablebuilder/table.builder.cell.component.tmpl.html').default
    );
    $templateCache.put(
      'table.builder.cell.editor.component.tmpl.html',
      require('./tablebuilder/table.builder.cell.editor.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'customdatabase.config.component.tmpl.html',
      require('./customdatabase/customdatabase.config.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'formula.config.component.tmpl.html',
      require('./formula/formula.config.component.tmpl.html').default
    );

    $templateCache.put(
      'lookup.config.component.tmpl.html',
      require('./lookup/lookup.config.component.tmpl.html').default
    );

    $templateCache.put(
      'table.runner.date.tmpl.html',
      require('./tablerunner/table.runner.date.tmpl.html').default
    );

    $templateCache.put(
      'table.runner.fileUpload.tmpl.html',
      require('./tablerunner/table.runner.fileUpload.tmpl.html').default
    );

    $templateCache.put(
      'dialog.warning.box.component.tmpl.html',
      require('./dialogwarningbox/dialog.warning.box.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'icon.picker.component.tmpl.html',
      require('./icon.picker.component.tmpl.html').default
    );

    $templateCache.put(
      'editor.youtube.dialog.component.tmpl.html',
      require('./editor.youtube.dialog.component.tmpl.html').default
    );
  }
]);
