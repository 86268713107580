/**
 * @ngdoc component
 * @name flowSignalrClient
 * @module flowingly.services
 * @description  A component for subscribing to (and publishing) signalR events.
 * ### Notes
 * This component shows the data entered by the user for the table, when they completed the step.
 * ### Properties
 * #### Bindings
 * * signalrServerUrl: url to signalR end point (communicationsApi)
 * 
 * @usage
 * ```
     <flow-signalr-client signalr-server-url="https://communications.flowingly.net"></flow-signalr-client>
 * ```
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/ed3755154181f70764d13e46532576aa138c2e2a/src/Flowingly.Shared.Angular/flowingly.services/signalr.client.directive.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular, { ITimeoutService } from 'angular';

angular.module('flowingly.services').component('flowSignalrClient', {
  bindings: {
    signalrServerUrl: '<'
  },
  controller: [
    'pubsubService',
    '$timeout',
    'sessionService',
    'auditService',
    'APP_CONFIG',
    'tokenService',
    function (
      pubsubService: Services.PubSubService,
      $timeout: ITimeoutService,
      sessionService: Services.SessionService,
      auditService: Services.AuditService,
      APP_CONFIG: Services.APP_CONFIG,
      tokenService: Services.TokenService
    ) {
      const $ctrl = this;

      let connection: SignalR.Hub.Connection = undefined;
      let hub: SignalR.Hub.Proxy;
      const serverUrl = $ctrl.signalrServerUrl || APP_CONFIG.signalrServerUrl;
      const subscriberId = 'runner.signalR.client';

      function startSignalr() {
        if (sessionService.getSignalR() === false) return;
        // connection already establised or in initialization phase
        if (connection) {
          return;
        }

        //start the signal r hub
        connection = $.hubConnection(serverUrl);
        connection.logging = false;
        // the name of the hub should match the HubName defined in
        // Flowingly.Core.Communications
        hub = connection.createHubProxy('NotificationsHub');

        //Define the SignalR communications before connecting, otherwise
        //"No hubs have been subscribed to.The client will not receive data from hubs"
        //error is thrown

        //each event to be handled, needs to be subscribed to here
        hub.on('actordeleted', (message) => {
          log('actorDeleted');
          auditNotification(message); // I decided to add these here as I wanted to be able to pick and choose which event to add it to
          pubsubService.publish('SIGNALR_ACTOR_DELETED', message);
        });

        hub.on('workflowpublished', (message) => {
          log('workflowpublished', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_WORKFLOW_PUBLISHED', message);
        });

        hub.on('workflowunpublished', (message) => {
          log('workflowunpublished', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_WORKFLOW_UNPUBLISHED', message);
        });

        hub.on('flowmodelstatuschanged', (message) => {
          log('flowmodelstatuschanged', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_FLOW_MODEL_STATUS_CHANGED', message);
        });

        hub.on('newflowmodelsaved', (message) => {
          log('newflowmodelsaved', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_NEW_FLOW_MODEL_SAVED', message);
        });

        hub.on('flowModelNameChanged', (message) => {
          log('flowModelNameChanged', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_WORKFLOW_NAME_CHANGED', message);
        });

        hub.on('workflowHasUnPublishedChages', (message) => {
          log('workflowHasUnPublishedChages', message);
          auditNotification(message);
          pubsubService.publish(
            'SIGNALR_WORKFLOW_UNPUBLISHED_CHANGES',
            message
          );
        });

        // SignalR Events specific to the runner
        hub.on('runnercompletestep', (message) => {
          log('runnercompletestep', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_COMPLETE_STEP', message);
        });
        hub.on('runnerreassignstep', (message) => {
          log('runnerreassignstep', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_REASSIGN_STEP', message);
        });
        hub.on('runnerstartflow', (message) => {
          log('runnerstartflow', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_START_FLOW', message);
        });

        hub.on('runnerwithdrawflow', (message) => {
          log('runnerwithdrawflow', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_WITHDRAW_FLOW', message);
        });
        hub.on('userprofileupdated', (message) => {
          log('userprofileupdated', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_USER_PROFILE_UPDATED', message);
        });
        hub.on('categoryDeleted', (message) => {
          log('categoryDeleted', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_SETUP_CATEGORY_DELETED', message);
        });

        hub.on('flowModelDeleted', (message) => {
          log('flowModelDeleted', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_SETUP_FLOW_MODEL_DELETED', message);
        });

        hub.on('runnerUserNotificationCountChanged', (message) => {
          log('runnerUserNotificationCountChanged', message);
          auditNotification(message);
          pubsubService.publish(
            'SIGNALR_USER_NOTIFICATION_COUNT_CHANGED',
            message
          );
        });
        hub.on('runnerNewFlowCommentCount', (message) => {
          log('runnerNewFlowCommentCount', message);
          auditNotification(message);
          pubsubService.publish(
            'SIGNALR_RUNNER_NEW_FLOW_COMMENT_COUNT',
            message
          );
        });
        hub.on('runnerNewFlowComment', (message) => {
          log('runnerNewFlowComment', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_NEW_FLOW_COMMENT', message);
        });
        hub.on('runnerUserTeamUpdated', (message) => {
          log('runnerUserTeamUpdated', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_USER_TEAM_UPDATED', message);
        });
        hub.on('runnerStepWebhookProcessed', (message) => {
          log('runnerStepWebhookProcessed', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_STEP_WEBHOOK_UPDATED', message);
        });

        hub.on('runnerNewStepTaskComment', (message) => {
          log('runnerNewStepTaskComment', message);
          auditNotification(message);
          pubsubService.publish(
            'SIGNALR_RUNNER_NEW_STEP_TASK_COMMENT',
            message
          );
        });

        hub.on('runnerStepTaskCreated', (message) => {
          log('runnerStepTaskCreated', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_STEP_TASK_CREATED', message);
        });

        hub.on('runnerStepTaskUpdated', (message) => {
          log('runnerStepTaskUpdated', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_STEP_TASK_UPDATED', message);
        });

        hub.on('runnerStepTaskCancelled', (message) => {
          log('runnerStepTaskCancelled', message);
          auditNotification(message);
          pubsubService.publish('SIGNALR_RUNNER_STEP_TASK_CANCELLED', message);
        });

        hub.on('runnerStepIntegrationStateChanged', (message) => {
          log('runnerStepIntegrationStateChanged', message);
          auditNotification(message);
          pubsubService.publish(
            'SIGNALR_RUNNER_STEP_INTEGRATION_PROCESSING_UPDATED',
            message
          );
        });

        connection.connectionSlow(() => {
          log('SignalR connection is slow. Connection Id=' + connection.id);
        });

        //handle the errors thrown from hub
        connection.error((error) => {
          log('SignalR error: ' + error);
        });

        // log that we are reconnecting
        connection.reconnecting(() => {
          log(
            'SignalR connection is reconnecting. connection Id=' + connection.id
          );
        });

        // handle disconnection: retry in 5 seconds.
        connection.disconnected(() => {
          if (connection.lastError) {
            log(
              'SignalR connection disconnected. Reason: ' +
                connection.lastError.message
            );
          } else {
            log(
              'SignalR hub connection disconnected. Connection Id=' +
                connection.id
            );
          }
          $timeout(() => {
            startConnection(true);
          }, 5000);
        });

        //initiate connection
        log('Initiating SignalR connection, url = ' + serverUrl);
        startConnection();
      }

      function auditNotification(message) {
        const data = JSON.parse(message);
        auditService.receivedNotification(data);
      }

      function isSessionUserExist() {
        const user = sessionService.getUser();
        return user !== null && user !== undefined;
      }

      function startConnection(isReconnect?) {
        const reestablishStr = isReconnect ? 're-' : '';

        connection
          .start({ waitForPageLoad: false })
          .done(() => {
            log(
              'SignalR connection ' +
                reestablishStr +
                'established! connection Id=' +
                connection.id
            );

            // Before publising READY_FOR_SIGNALR you MUST ensure the user is stored in the session.
            if (!isSessionUserExist()) {
              log(
                'ERROR: ',
                'Could not map SignalR connection to User because User was not in the SessionService.'
              );
            } else {
              const userId = sessionService.getUser().id;
              const businessId = tokenService.getTenant().id;

              //register this client connection with the logged in user
              //so that it gets mapped on the hub on the server side.
              hub
                .invoke('mapUserConnection', { userId, businessId })
                .done(() => {
                  log('Mapping this connection to user Id= ' + userId);
                  pubsubService.publish('SIGNALR_CONNECTED');
                })
                .fail((error) => {
                  log('SignalR registration failed. Error:  ' + error);
                  pubsubService.publish('SIGNALR_CONNECT_FAILED');
                });
            }
          })
          .fail((e) => {
            log(
              'SignalR connection could not be ' +
                reestablishStr +
                'established.',
              e
            );
            pubsubService.publish('SIGNALR_CONNECT_FAILED');
          });
      }

      function log(event, message?) {
        if (APP_CONFIG.enableConsoleLogging) {
          console.log(event, message || '');
        }
      }

      // start signalR on when READY_FOR_SIGNALR published
      $ctrl.$onInit = () => {
        pubsubService.subscribe(
          'READY_FOR_SIGNALR',
          startSignalr,
          subscriberId
        );
      };

      //Destroy
      $ctrl.$onDestroy = () => {
        log('SignalR client closing...');
        connection.stop();
        pubsubService.unsubscribeAll(subscriberId);
      };
    }
  ]
});
