/**
 * @ngdoc service
 * @name userNotificationsApiService
 * @module flowingly.user.notifications
 *
 * @description A service that maintains a list of user notification items, for consumption by a component that displays them
 *
 * ## Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/UserNotifications+Component
 *
 * ###API
 * * notifications - list of notification items to display
 * * readNotification - mark a notificaition as read and redirect to that flow comments section
 * * discardNotificaiton - discard Notification
 * * notificationCount - count of all notifications
 *
 * ###Usage
 *     Consumed by userNotificaitons component.
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f460f2f16cf3d6f22680828e178182d507cef9f1/src/Flowingly.Shared.Angular/flowingly.directives/user-notifications/user.notifications.api.service.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.user.notifications')
  .factory('userNotificationsApiService', userNotificationsApiService);

userNotificationsApiService.$inject = ['$http', 'APP_CONFIG', 'sessionService'];

function userNotificationsApiService(
  $http: angular.IHttpService,
  APP_CONFIG: Services.APP_CONFIG,
  sessionService: Services.SessionService
) {
  //API
  const service = {
    notificationCount: 0,
    dismissNotification: dismissNotification,
    getNotifications: getNotifications,
    getNotificationCount: getNotificationCount,
    readNotification: readNotification
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////

  function dismissNotification(notificationId, noSpinner) {
    return $http.delete(
      APP_CONFIG.apiBaseUrl +
        'usernotifications/' +
        sessionService.getUser().id +
        '/notifications/' +
        notificationId,
      { noSpinner: noSpinner }
    );
  }

  function getNotificationCount(noSpinner = false) {
    return $http
      .get(
        APP_CONFIG.apiBaseUrl +
          'usernotifications/' +
          sessionService.getUser().id +
          '/notificationCount',
        { noSpinner: noSpinner }
      )
      .then(function (response) {
        service.notificationCount = response.data as number;
      });
  }

  function getNotifications(pageNumber, noSpinner) {
    return $http
      .get(
        APP_CONFIG.apiBaseUrl +
          'usernotifications/' +
          sessionService.getUser().id +
          '/notifications?pageNumber=' +
          pageNumber,
        { noSpinner: noSpinner }
      )
      .then(function (response) {
        return response.data;
      });
  }

  function readNotification(flowId, noSpinner) {
    return $http.put(
      APP_CONFIG.apiBaseUrl +
        'usernotifications/' +
        sessionService.getUser().id +
        '/notifications/' +
        flowId,
      { noSpinner: noSpinner }
    );
  }
}

export type UserNotificationsApiServiceType = ReturnType<
  typeof userNotificationsApiService
>;
