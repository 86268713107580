import angular from 'angular';
import { Services } from '../@types/services';
angular.module('flowingly.services').factory('gtmService', gtmService);

gtmService.$inject = ['$window', 'scriptService'];
function gtmService(
  $window: angular.IWindowService,
  scriptService: Services.ScriptService
) {
  /**
   * Google Tag Manager: Flowingly Production
   * See: https://bizflo.atlassian.net/wiki/display/TECH/Google+Tag+Manager
   * When browser disable javascript, i will not be able to inject noscript tag to body with tagId so will not able to track who disable JS
   * The asp.net can handle this because the index.html got injected with noscript & tagId on the server
   * static runner cannot do this unless tagId is known at build time
   * static runner will not track users who disable JS for now. Can re-visit this later if needed
   */
  function loadGTM(tagId: string, l = 'dataLayer') {
    $window[l] = $window[l] || [];
    $window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const dl = l != 'dataLayer' ? '&l=' + l : '';
    scriptService.loadScript(
      'https://www.googletagmanager.com/gtm.js?id=' + tagId + dl,
      'text/javascript',
      'utf-8'
    );
  }

  return {
    loadGTM
  };
}

export type GTMServiceType = ReturnType<typeof gtmService>;
