/**
 * @ngdoc directive
 * @name flowOptionalComment
 * @module flowingly.directives
 * @description  This directive allows us to toggle dynamically the (optional) text for the approval rule comment.
 * ### Notes
 * 
 * @usage
 * ```
       <label class="select-label" flow-optional-comment="field.schema.validation.required">
             Comment
       </label>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/bfe1723665de38ba7241ee87acb4791d84478661/src/Flowingly.Shared.Angular/flowingly.directives/optional.comment.directive.js?at=master
 */

'use strict';
import angular, { IScope } from 'angular';
angular
  .module('flowingly.directives')
  .directive('flowOptionalComment', function () {
    return {
      restrict: 'A',
      scope: { flowOptionalComment: '=' },
      link: function (scope: IScope, element: JQuery<HTMLElement>) {
        scope.$watch('flowOptionalComment', function (newvalue) {
          if (newvalue == true) {
            element.html('Comment');
          } else {
            element.append("<span class='optional'></span>");
          }
        });
      }
    };
  });
