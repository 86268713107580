/**
 * @ngdoc service
 * @name momentService
 * @module flowingly.services
 *
 * @description A helper service for moment related functionality
 *
 * ## Notes
 *
 * ###API
 * * calculateRelativeTime - return moment relative time
 * * capitalizeEachWord - capitalize each word in a given string
 *
 */

import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

export class FlowinglyMomentService {
  constructor(
    private momentService: Moment,
    private appConfig: Services.APP_CONFIG
  ) {}

  calculateRelativeTime(dateTime: string): string {
    if (dateTime == undefined) {
      return undefined;
    }

    //configure moment to use 24 hours, not 22 when determining if a flow is today or tmrw
    this.momentService.relativeTimeThreshold('h', 24);
    //convert the utc time from the server to local
    //the utc time arrivs here with an offset - use the format to remove it
    const local = this.momentService.utc(dateTime, 'YYYY-MM-DD HH:mm:ss');
    //convert to relative time
    const relative = this.momentService(local).fromNow();
    return this.capitalizeEachWord(relative);
  }

  calculateRelativeTimeFromLocalDateTime(dateTime: string): string {
    if (dateTime == undefined) {
      return undefined;
    }

    //configure moment to use 24 hours, not 22 when determining if a flow is today or tmrw
    this.momentService.relativeTimeThreshold('h', 24);

    const local = this.momentService(dateTime, 'YYYY-MM-DD HH:mm:ss');
    //convert to relative time
    const relative = this.momentService(local).fromNow();
    return this.capitalizeEachWord(relative);
  }

  calculateRelativeTimeWithNoPrefix(dateTime) {
    if (dateTime == undefined) {
      return undefined;
    }
    //configure moment to use 24 hours, not 22 when determining if a flow is today or tmrw
    this.momentService.relativeTimeThreshold('h', 24);
    const local = this.momentService.utc(dateTime, 'YYYY-MM-DD HH:mm:ss');
    return this.capitalizeEachWord(this.momentService(local).fromNow(true)); //true removes the prefixes (ago etc)
  }

  calculateRelativeTimeFromLocalDateTimeWithNoPrefix(dateTime) {
    if (dateTime == undefined) {
      return undefined;
    }
    //configure moment to use 24 hours, not 22 when determining if a flow is today or tmrw
    this.momentService.relativeTimeThreshold('h', 24);
    const local = this.momentService(dateTime, 'YYYY-MM-DD HH:mm:ss');
    return this.capitalizeEachWord(this.momentService(local).fromNow(true)); //true removes the prefixes (ago etc)
  }

  calculateRelativeTimeOrFullDate(date) {
    return this.isOverOneDayAgo(date)
      ? 'on ' + this.formatFullDate(date, 'LLLL')
      : this.calculateRelativeTime(date);
  }

  capitalizeEachWord(str: string): string {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  isOverOneDayAgo(date) {
    if (date) {
      const daysDiff = this.appConfig.defaultDays;
      const now = this.momentService();
      const momentDate = this.momentService.utc(date, 'YYYY-MM-DD HH:mm:ss');
      if (!momentDate.isValid()) return false;
      const diffrence = now.diff(momentDate.format('YYYY-MM-DD'), 'days');
      return daysDiff - 1 < diffrence;
    }
    return false;
  }

  formatUTCToLocal(utcDate) {
    return this.momentService
      .utc(utcDate, 'YYYY-MM-DD HH:mm:ss')
      .local()
      .format('DD MMM YYYY');
  }

  formatFullDate(date, format) {
    if (date && format)
      return this.momentService
        .utc(date, 'YYYY-MM-DD HH:mm:ss')
        .local()
        .format(format);
    else return '';
  }

  formatFullDateFromLocalDateTime(date, format) {
    if (date && format)
      return this.momentService(date, 'YYYY-MM-DD HH:mm:ss')
        .local()
        .format(format);
    else return '';
  }

  calculateDeadlineTime(dateTime) {
    if (dateTime == undefined) {
      return 'No Due Date';
    }
    if (this.isOverdue(dateTime)) {
      return (
        'Due ' + this.capitalizeEachWord(this.calculateRelativeTime(dateTime))
      );
    } else {
      return (
        'Due In ' +
        this.capitalizeEachWord(
          this.calculateRelativeTimeWithNoPrefix(dateTime)
        )
      );
    }
  }

  calculateStepTaskCompletedTime(dateTime) {
    if (dateTime == undefined) {
      return 'No Completed Date';
    }

    if (this.isOverdue(dateTime)) {
      return (
        'Completed ' +
        this.capitalizeEachWord(this.calculateRelativeTime(dateTime))
      );
    } else {
      return (
        'Completed ' +
        this.capitalizeEachWord(
          this.calculateRelativeTimeWithNoPrefix(dateTime)
        )
      );
    }
  }

  calculateDeadlineTimeFromLocalDateTime(dateTime) {
    if (dateTime == undefined) {
      return 'No Due Date';
    }
    if (this.isOverdue(dateTime)) {
      return (
        'Due ' +
        this.capitalizeEachWord(
          this.calculateRelativeTimeFromLocalDateTime(dateTime)
        )
      );
    } else {
      return (
        'Due In ' +
        this.capitalizeEachWord(
          this.calculateRelativeTimeFromLocalDateTimeWithNoPrefix(dateTime)
        )
      );
    }
  }

  isOverdue(dateTime) {
    if (dateTime == undefined) {
      return false;
    }
    const local = this.momentService.utc(dateTime, 'YYYY-MM-DD HH:mm:ss');
    return this.momentService().isAfter(local);
  }
}

angular
  .module('flowingly.services')
  .factory('flowinglyMomentService', [
    'momentService',
    'APP_CONFIG',
    (momentService: Moment, appConfig: Services.APP_CONFIG) =>
      new FlowinglyMomentService(momentService, appConfig)
  ]);

export type FlowinglyMomentServiceType = InstanceType<
  typeof FlowinglyMomentService
>;
