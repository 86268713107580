/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/d305b40cfe0f2c5528f168ef03cd2f47fa0237e3/src/Flowingly.Shared.Angular/flowingly.services/flowingly.kb.service.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.services')
    .factory('flowinglyKBService', flowinglyKBService);

  flowinglyKBService.$inject = ['$http', 'APP_CONFIG', '$q'];

  function flowinglyKBService($http, APP_CONFIG, $q) {
    //API
    var service = {
      kbLinks: [],
      getKbLink: getKbLink
    };

    return service;

    // Private Methods
    function getKbLink(kbKey) {
      if (service.kbLinks[kbKey]) return $q.when(service.kbLinks[kbKey]);

      // call api to get the kb link
      return $http
        .get(APP_CONFIG.apiBaseUrl + 'workflow/getKbLink/?linkKey=' + kbKey)
        .then(function (response) {
          service.kbLinks[kbKey] = response.data;
          return response.data;
        });

      //service.kbLinks[kbKey] = "http://help.flowingly.net/design-and-build-your-business-processes/adding-form-fields-to-your-process";
      //return service.kbLinks[kbKey];
    }
  }
})();
