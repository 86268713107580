/**
 * @ngdoc service
 * @name lodashService
 * @module flowingly.adapters
 *
 * @description Wraps the global Lodash (_) Object.
 *
 * ## Notes
 * _ is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global _ object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes lodash has already been loaded on the page.
 */
(function () {
  'use strict';

  angular.module('flowingly.adapters').factory('lodashService', [
    '$window',
    function ($window) {
      if ($window._ == undefined) {
        console.error('Lodash is not loaded');
      }

      return $window._;
    }
  ]);
})();
