/**
 * @name editorYoutubeDialog
 * @module flowingly.components
 * @description Displays dialog for adding a YouTube video to a kendo editor
 * 
 * @usage
 * ```
    <editor-youtube-dialog></editor-youtube-dialog>
 * ```
 */
'use strict';

import angular from 'angular';

export default class EditorYoutubeDialogComponentController {
  static $inject = ['$scope'];

  constructor(private $scope) {
    $scope.$on('EditorYoutubeClicked', (event, params) => this.insertVideo(params.event, params.selector))
  }

  insertVideo(e, selector) {
    const editor = angular.element(document).find(selector).data('kendoEditor');
    const testUrlForMedia = (pastedData) => {
      if (pastedData.match('https://(www.)?youtube|youtu.be')) {
        const youtube_id = pastedData.match('embed')
          ? pastedData.split(/embed\//)[1].split('"')[0]
          : pastedData.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
        return {
          type: 'youtube',
          id: youtube_id
        };
      }
      alert(
        'No valid media id detected.\nBe sure to use the "Share" url, located in the menu under the video on the youtube page.'
      );
      return false;
    };

    // 1. save the selected range
    const range = editor.getRange();
    const dialog = angular
      .element(angular.element('#insertVideo-template').html())
      .find('.insertVideo-insert')
      .click(() => {
        const media = testUrlForMedia(dialog.element.find('input').val());
        if (media) {
          const template = kendo.template($('#youTube-template').html());
          // 2. restore the selected range
          editor.selectRange(range);
          editor.exec('insertHtml', {
            value: template({
              source: media.id
            })
          });
        }

        dialog.close();
      })
      .end()
      .find('.insertVideo-cancel')
      .click(function () {
        dialog.close();
      })
      .end()
      .kendoWindow({
        modal: true,
        title: 'Insert Video',
        animation: false,
        deactivate: () => {
          dialog.destroy();
        },
        activate: () => {
          setTimeout(() => {
            $('#videoUrl').focus();
          }, 100);
        }
      })
      .data('kendoWindow');

    dialog.center().open();
  }
}

export class EditorYoutubeDialogComponent implements angular.IComponentOptions {
  public templateUrl = 'editor.youtube.dialog.component.tmpl.html';
  public controllerAs = 'ctrl';

  controller = EditorYoutubeDialogComponentController;
}

angular
  .module('flowingly.components')
  .component('editorYoutubeDialog', new EditorYoutubeDialogComponent());
