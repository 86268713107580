/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/0259ccb38b1aed72cf2327fdafae455ab3ae5029/src/Flowingly.Shared.Angular/flowingly.components/smartselect/smart.select.list.component.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.components')
    ///
    /// A component for enabling a user to easily select/unselect entities to publish a flow to.
    ///
    /// See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Smart+Select+Component
    ///
    .component('smartSelectList', {
      bindings: {
        searchList: '<',
        searchGroup: '<',
        selectedList: '=',
        filterBy: '<?',
        readOnly: '<',
        headerText: '@',
        infoText: '@',
        onSelectedListUpdated: '&',
        expandGroupMembersFilter: '&'
      },
      controller: [
        'lodashService',
        'flowinglyConstants',
        'smartSelectService',
        function (lodashService, flowinglyConstants, smartSelectService) {
          var $ctrl = this;

          $ctrl.addAll = addAll;
          $ctrl.deselect = deselect;
          $ctrl.filter = filter;
          $ctrl.removeAll = removeAll;
          $ctrl.select = select;
          $ctrl.hasMoreResults = () => {
            return (
              smartSelectService.getHasMoreResults() &&
              ($ctrl.searchGroup === flowinglyConstants.searchEntityType.USER ||
                $ctrl.searchGroup ===
                  flowinglyConstants.searchEntityType.APPROVER)
            );
          };

          $ctrl.searchTermChanged = () => {
            if (!$ctrl.hasMoreResults()) {
              return;
            }

            if (
              $ctrl.searchGroup === flowinglyConstants.searchEntityType.USER
            ) {
              smartSelectService
                .getUsers($ctrl.searchTerm)
                .then(function (response) {
                  $ctrl.onSelectedListUpdated({
                    results: response.data.userDetailForModals,
                    searchGroup: $ctrl.searchGroup
                  });
                });
            } else {
              smartSelectService
                .getAvailableApprovers($ctrl.filterBy, $ctrl.searchTerm)
                .then(function (response) {
                  $ctrl.onSelectedListUpdated({
                    results: response.data.dataModel.availableStepApprovers,
                    searchGroup: $ctrl.searchGroup
                  });
                });
            }
          };

          this.$onInit = function () {
            //IE11 polyfill
            if (!String.prototype.startsWith) {
              String.prototype.startsWith = function (searchString, position) {
                position = position || 0;
                return (
                  this.substr(position, searchString.length) === searchString
                );
              };
            }

            lodashService.forEach($ctrl.selectedList, function (item) {
              filterGroupMembers(item);
            });
          };

          //// PUBLIC METHODS /////////////////////////////////////////

          function addAll() {
            lodashService.forEach($ctrl.searchList, function (item) {
              $ctrl.selectedList.push(item);
              filterGroupMembers(item);
            });
            $ctrl.searchList.length = 0;
          }

          function deselect(item) {
            if ($ctrl.readOnly) {
              return;
            }

            // Remove the item from the selected list
            var index = lodashService.findIndex(
              $ctrl.selectedList,
              function (i) {
                return i.id === item.id;
              }
            );
            $ctrl.selectedList.splice(index, 1);

            // add it to the input list, if it meets the current search criteria used to filter the list
            // the list itself will be for a particular user type and the list will then be filtered by the search box
            var searchGroup = $ctrl.searchGroup;
            if (searchGroup === flowinglyConstants.searchEntityType.APPROVER) {
              searchGroup = flowinglyConstants.searchEntityType.USER;
            }

            if (
              $ctrl.searchGroup === undefined ||
              searchGroup === item.searchEntityType
            ) {
              $ctrl.searchList.push(item);
            }
          }

          function filter(item) {
            if ($ctrl.searchTerm == undefined) {
              return true;
            }
            //we search on the display name, split it into two parts
            var split = item.displayName.split(' ');
            var firstname = split[0];
            //Groups only have a firstname
            var lastname = '';
            if (split.length > 1) {
              lastname = split[1];
            }
            //get the search terms, if the user has entered 2 (or more) grab the first two for more precise searchin
            //first term will search firstname, second term will search lastname
            var terms = $ctrl.searchTerm.split(' ');
            if (terms.length === 1) {
              return (
                firstname
                  .toLowerCase()
                  .startsWith($ctrl.searchTerm.toLowerCase()) ||
                lastname
                  .toLowerCase()
                  .startsWith($ctrl.searchTerm.toLowerCase())
              );
            } else {
              return (
                firstname.toLowerCase().startsWith(terms[0].toLowerCase()) &&
                lastname.toLowerCase().startsWith(terms[1].toLowerCase())
              );
            }
          }

          function removeAll() {
            var searchGroup = $ctrl.searchGroup;
            if (searchGroup === flowinglyConstants.searchEntityType.APPROVER) {
              searchGroup = flowinglyConstants.searchEntityType.USER;
            }

            if ($ctrl.searchGroup === undefined) {
              //copy all selected items back to search list
              $ctrl.searchList = $ctrl.searchList.concat($ctrl.selectedList);
            } else {
              //find all items that match current group
              var filtered = lodashService.filter(
                $ctrl.selectedList,
                function (item) {
                  return (
                    item.searchEntityType === searchGroup ||
                    item.itemGroup === searchGroup
                  );
                }
              );
              $ctrl.searchList = $ctrl.searchList.concat(filtered);
            }

            $ctrl.selectedList.length = 0;
          }

          function select(item) {
            if ($ctrl.readOnly) {
              return;
            }

            filterGroupMembers(item);

            //when an item is selected, copy it to the RHS and remove from the LHS
            var index = lodashService.findIndex($ctrl.searchList, function (i) {
              return i.id === item.id;
            });
            $ctrl.searchList.splice(index, 1);
            $ctrl.selectedList.push(item);
          }

          function filterGroupMembers(item) {
            if (
              item.searchEntityType ===
              flowinglyConstants.searchEntityType.GROUP
            ) {
              item.filteredGroupMembers =
                item.users &&
                item.users.filter((user) => {
                  return $ctrl.expandGroupMembersFilter({ user: user });
                });
            }
          }
        }
      ],
      template: `
                <div class ="smart-select-list" ng-class ="{'smart-select-list--read-only': $ctrl.readOnly}">
                    <div>
                        <i ng-if="!$ctrl.readOnly" class="fa-light fa-magnifying-glass"></i>
                        <input ng-if="!$ctrl.readOnly" type="text" ng-model="$ctrl.searchTerm" ng-change="$ctrl.searchTermChanged()" ng-model-options="{ debounce: 300 }" class="text left-align mb-18" placeholder="search" flowingly-xss-validation  />
                        <div class ="search-list">
                            <ul>
                                <li ng-if="$ctrl.hasMoreResults()" ng-repeat="item in $ctrl.searchList" ng-click="$ctrl.select(item)"><i class="fa-light fa-circle-plus fa-lg"/>{{item.displayName}}</li>
                                <li ng-if="!$ctrl.hasMoreResults()" ng-repeat="item in $ctrl.searchList | filter: $ctrl.filter" ng-click="$ctrl.select(item)"><i class="fa-light fa-circle-plus fa-lg"/>{{item.displayName}}</li>
                            </ul>
                        </div>
                        <div ng-if="$ctrl.hasMoreResults()" class="ml-5 mb-10 smart-select-list__more-result-text">Please search to see additional users</div>
                        <a ng-click="$ctrl.addAll()" ng-show="$ctrl.searchList.length > 0 && !$ctrl.readOnly && !$ctrl.hasMoreResults()" class ="pl-5">Add All</a>
                    </div>
                    <div>
                    <div class="text-block">
                        <h5>{{$ctrl.headerText || '&nbsp;'}}</h5>
                        <span class="small-text">{{$ctrl.infoText || '&nbsp;'}}</span>
                    </div>
                        <div class="selected-list">
                            <ul>
                                <li ng-repeat="item in $ctrl.selectedList" ng-click="$ctrl.deselect(item)">
                                    <i class="fa-light fa-circle-minus fa-lg" />{{item.displayName}}
                                    <ul ng-if="item.filteredGroupMembers && item.filteredGroupMembers.length > 0" class="filtered-group-members">
                                        <li ng-repeat="member in item.filteredGroupMembers">{{member.fullName}}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <a ng-click="$ctrl.removeAll()" ng-show="$ctrl.selectedList.length > 0 && !$ctrl.readOnly" class ="pl-5">Remove All</a>
                    </div>
                </div>
            `
    });
})();
