/**
 * @ngdoc directive
 * @name flowConvertToNumber
 * @module flowingly.modeler.directives
 * @description  A directive for converting a string to a number.
 * @usage
 * ```
    <select class="browser-default" ng-model="ctrl.model.nodeData.field" material-select watch flow-convert-to-number>
       <option value="{{field.fieldId}}" ng-repeat="field in ctrl.model.fields">{{field.nodeName}} - {{field.name}}</option>
    </select>
 * ```
 */
angular
  .module('flowingly.directives')
  .directive('flowConvertToNumber', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function (val) {
          return parseInt(val, 10);
        });
        ngModel.$formatters.push(function (val) {
          return '' + val;
        });
      }
    };
  });
