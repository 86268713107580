/**
 * @ngdoc service
 * @name flowinglyDiagramService
 * @module flowingly.flowingly.services
 *
 * @description A service for drawing GO.js flow diagram
 *
 * ## Notes
 *
 * ###API
 * * generateProcessModel - generate flow model diagram
 *
 */

import angular from 'angular';
import { Services } from '../@types/services';

import { BpmnModeler } from '@Shared.Angular/flowingly.bpmn.modeler/@types/services';

export interface GenerateProcessModelArguments {
  flow?: any;
  focusId?: any;
  applyAvatar?: any;
  userID?: any;
  fullName?: any;
  avatarUrl?: any;
  modelCustomArgs?: any;
  allowSelect?: boolean;
  dynamicInitialHeight?: any;
  allowMove?: boolean;
}

export class FlowinglyDiagramService {
  constructor(
    private lodashService: Lodash,
    private goService: GoJS,
    private BpmnDiagramService: BpmnModeler.BpmnDiagramService,
    private avatarService: Services.AvatarService,
    private flowinglyConstants: Services.FlowinglyConstants,
    private APP_CONFIG: Services.APP_CONFIG,
    private BPMN_CONSTANTS: BpmnModeler.BpmnConstants
  ) {}

  //flow: any, applyAvatar, userID, fullName, avatarUrl): void {
  public generateProcessModel(
    options: GenerateProcessModelArguments,
    onDoKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  ): void {
    const $ = this.goService.GraphObject.make;

    const defaultArgs = this.BpmnDiagramService.getDiagramModel();
    const modelArgs = options.modelCustomArgs || {};
    const diagramModel = {
      ...defaultArgs,
      ...modelArgs
    };
    const DEFAULT_SCALE_FOR_WORKFLOWS = 0.75;
    const DEFAULT_SCALE_FOR_PROCESSMAPS = 0.55; /* default meaning a process map of 7 normal nodes, 1 diverge, 1 merge, 1 end, 1 start node HORIZONTALLY,
                                                       and 4 other nodes VERTICALLY aligned on one of those normal node (think of it as 7x5).
                                                       This is how they envisioned the size of normal Process Maps are */
    const THRESHOLD_HEIGHT_FOR_PROCESSMAPS = 200;

    const flow = options.flow;
    let flowId = flow.Id || flow.id;
    const isProcessMap = flow.isProcessMap,
      isPrintMapRequest = flow.isPrintMapRequest;

    if (flow.FlowId !== undefined) flowId = flow.FlowId;

    diagramModel.initialContentAlignment = this.goService.Spot.Center; // center the content
    diagramModel.scale = isProcessMap
      ? DEFAULT_SCALE_FOR_PROCESSMAPS
      : DEFAULT_SCALE_FOR_WORKFLOWS;

    //Diagram.Uniform = setup for Zoom to Fit if not Process Map
    if (diagramModel.initialAutoScale === undefined) {
      if (isProcessMap) {
        diagramModel.initialAutoScale = this.goService.Diagram.None;
      } else if (!isPrintMapRequest) {
        diagramModel.initialAutoScale = this.goService.Diagram.Uniform;
      }
    }

    diagramModel.InitialLayoutCompleted = (e) => {
      const diagram = e.diagram;
      const _this = this;

      diagram.padding = 35;
      diagram.isEnabled = true;
      diagram.isReadOnly = true;
      diagram.allowLink = false;
      diagram.allowSelect = options.allowSelect || false;
      diagram.hasHorizontalScrollbar = false;
      diagram.hasVerticalScrollbar = false;

      // FLOW-4947 - enable highlighting of current step
      const currentStepKey = this.getCurrentStepKey(options.flow);
      if (currentStepKey) {
        const keyStr = currentStepKey.toString();
        const currStep = diagram.findNodeForKey(keyStr);
      }

      if (!isProcessMap)
        diagram.div.style.height =
          diagram.documentBounds.height * DEFAULT_SCALE_FOR_WORKFLOWS +
          49 +
          'px';
      else {
        /*sources:
                    https://gojs.net/latest/intro/commands.html
                    https://stackoverflow.com/questions/32520179/gojs-how-is-it-possible-to-retrieve-the-array-of-the-selected-nodes
                */

        diagram.commandHandler.doKeyDown = function () {
          const e = diagram.lastInput,
            cmd = diagram.commandHandler;
          let sel = '';

          diagram.selection.each(function (n) {
            if (n instanceof _this.goService.Node) {
              if (sel.length > 0) sel += ', ';
              sel += n.data.key;
            } else return;
          });

          if (sel.length > 0) {
            //we disable keyboard directional keys if at least 1 node is selected

            switch (
              e.key // could also check for e.control or e.shift
            ) {
              case 'Up':
              case 'Down':
              case 'Left':
              case 'Right':
                break;
              default: // call base method with no arguments
                _this.goService.CommandHandler.prototype.doKeyDown.call(cmd);
                break;
            }
          } else _this.goService.CommandHandler.prototype.doKeyDown.call(cmd);

          if (typeof onDoKeyDown === 'function') {
            onDoKeyDown(e);
          }
        };

        diagram.div.style.height = options.dynamicInitialHeight + 'px';

        if (diagram.documentBounds.height <= THRESHOLD_HEIGHT_FOR_PROCESSMAPS) {
          //to make the nodes of smaller process maps a little bigger than the "default 7x5"
          diagram.scale = DEFAULT_SCALE_FOR_WORKFLOWS;
        }

        //Probably it takes more time to load because we want the nodes to be selectable if Process Map
        diagram.addDiagramListener('AnimationStarting', () => {
          someGoJSCalls(options, diagram, _this);
        });
      }
    };

    //Below lines of code finds diagram bound to div
    //to redraw diagram, we need to set div to null first.
    const modelDiv = this.goService.Diagram.fromDiv(flowId);
    if (modelDiv) {
      modelDiv.div = null;
    }

    const flowSchema = flow.flowSchema || flow.FlowSchema;
    if (
      !isProcessMap &&
      (!flowSchema || flowSchema.length > this.APP_CONFIG.flowModelSizeLimit)
    ) {
      return;
    }
    // We will show it no matter how big if it is a process map

    let model = this.goService.Model.fromJson(flowSchema);

    // Poke fields and data into the model
    model = this.updateModelWithFieldsAndData(
      model,
      options.applyAvatar,
      options.userID,
      options.fullName,
      options.avatarUrl,
      options.flow
    );

    //here new diagram gets created and bound to div either first time or due to reload.
    const divRef = window.document.getElementById(flowId);
    if (divRef !== null) {
      const diagram = $(this.goService.Diagram, flowId, diagramModel);

      diagram.model = model;
      return diagram;
    }

    function someGoJSCalls(options, diagram, _this) {
      diagram.contentAlignment = _this.goService.Spot.Center;
      diagram.animationManager.stopAnimation();
      diagram.zoomToFit();

      if (options.allowMove === true) {
        diagram.scrollMode = _this.goService.Diagram.InfiniteScroll;
      }

      //the diagram steals focus at this point, so restore focus if required
      if (options.focusId !== undefined) {
        _this.$timeout(() => {
          (angular.element('#flowSubject_' + options.focusId) as any).focus();
        }, 500);
      }
    }
  }

  private getCurrentStepKey(flow): any {
    if (
      flow.Steps &&
      typeof flow.Steps.find((s) => s.IsSelectedStep && s.IsCompleted == 0) !==
        'undefined'
    ) {
      const nodeId = flow.Steps.find(
          (s) => s.IsSelectedStep && s.IsCompleted == 0
        ).ModelerNodeId,
        node = JSON.parse(flow.FlowSchema).nodeDataArray.find(
          (n) => n.id == nodeId
        );

      if (node) return node.key;
    }

    return null;
  }

  private updateModelWithFieldsAndData(
    tempModel,
    applyAvatar,
    userId,
    fullName,
    avatarUrl,
    flow
  ): any {
    const actors = this.avatarService.getAvatarList();
    const currentStepKey = this.getCurrentStepKey(flow);

    //ToDo: this code is duplicated in modeler - so we will move it to a shared service at some point
    this.lodashService.forEach(tempModel.nodeDataArray, (n) => {
      if (n.displayNotificationIcon === undefined) {
        n.displayNotificationIcon = false;
      }

      if (n.displayPublicFormIcon === undefined) {
        n.displayPublicFormIcon = false;
      }

      if (n.displayStepRuleIcon === undefined) {
        n.displayStepRuleIcon = false;
      }

      if (n.displayStepIntegrationIcon === undefined) {
        n.displayStepIntegrationIcon = false;
      }

      if (n.displayStepTaskIcon === undefined) {
        n.displayStepTaskIcon = false;
      }

      if (n.stepTaskEnabled != null) {
        n.displayStepTaskIcon = n.stepTaskEnabled;
      }

      if (n.rules === undefined || n.rules.length === 0) {
        n.displayStepRuleIcon = false;
      } else {
        this.lodashService.forEach(n.rules, (rule) => {
          if (typeof rule.isEnableRule !== 'undefined' && rule.isEnableRule) {
            // Only display the step rule icon if there is at least one rule enabled.
            n.displayStepRuleIcon = true;
          }
        });
      }

      // [FLOW-5387] If integrations are enabled then we need to display integration icon on step.
      if (n.webhooks === undefined || n.webhooks.length === 0) {
        n.displayStepIntegrationIcon = false;
      } else {
        this.lodashService.forEach(n.webhooks, (webhook) => {
          if (
            typeof webhook.isStepIntegrationEnabled !== 'undefined' &&
            webhook.isStepIntegrationEnabled
          ) {
            // Only display the integration icon if there is at least one rule enabled.
            n.displayStepIntegrationIcon = true;
          }
        });
      }

      // FLOW-4947 - Highlight the current step in the flow. Excluding taskType of 6 because it is custom email.
      if (n.category === 'activity') {
        n.color =
          !flow.IsFinalised &&
          currentStepKey &&
          n.key === currentStepKey &&
          n.taskType !== 6
            ? this.BPMN_CONSTANTS.Theme.FlowinglyHighlightedNode
            : this.BPMN_CONSTANTS.Theme.FlowinglyWhite;
      }

      if (
        n.stepType == this.flowinglyConstants.stepType.PARALLEL_APPROVAL ||
        n.stepType == this.flowinglyConstants.stepType.SEQUENTIAL_APPROVAL
      ) {
        n.avatarUrl = ASSETS_PATH + '/avatars/avatar_a_28.png';
        n.actorName = 'Approvers';
      } else {
        if (n.actorType && n.actorType === 'user' && actors !== null) {
          const match = this.lodashService.find(actors, (a) => {
            return a.id === n.actor;
          });

          if (match) {
            if (applyAvatar && match.id == userId) {
              n.avatarUrl = avatarUrl;
              n.actorName = fullName;
            } else {
              n.avatarUrl = this.avatarService.getModelerNodeAvatarUrl(
                n.actor,
                n.actorName
              );
            }
          }
        }
      }
    });

    const endNode = this.lodashService.find(
      tempModel.nodeDataArray,
      function (node) {
        return node.eventDimension === 8;
      }
    );
    if (endNode) {
      const linksToEndNode = this.lodashService.filter(
        tempModel.linkDataArray,
        (link) => {
          return link.to === endNode.key;
        }
      );
      const lastNodes = [];
      this.lodashService.forEach(linksToEndNode, (link) => {
        const node = this.lodashService.find(
          tempModel.nodeDataArray,
          (node) => {
            return (
              node.key === link.from &&
              (node.category === 'activity' ||
                node.category === 'exclusiveGateway')
            );
          }
        );
        lastNodes.push(node);
      });
      this.lodashService.forEach(lastNodes, function (node) {
        if (node && node.displayNotificationIcon === undefined) {
          node.displayNotificationIcon = true; // As per requirements
        }
      });
    }
    return tempModel;
  }
}

angular
  .module('flowingly.services')
  .factory('flowinglyDiagramService', [
    'lodashService',
    'goService',
    'BpmnDiagramService',
    'avatarService',
    'flowinglyConstants',
    'APP_CONFIG',
    'BPMN_CONSTANTS',
    (
      lodashService,
      goService,
      BpmnDiagramService,
      avatarService,
      flowinglyConstants,
      APP_CONFIG,
      BPMN_CONSTANTS
    ) =>
      new FlowinglyDiagramService(
        lodashService,
        goService,
        BpmnDiagramService,
        avatarService,
        flowinglyConstants,
        APP_CONFIG,
        BPMN_CONSTANTS
      )
  ]);

// type of FlowinglyDiagramService
export type FlowinglyDiagramServiceType = InstanceType<
  typeof FlowinglyDiagramService
>;
