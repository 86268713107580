/**
 * @ngdoc service
 * @name BpmnCommandService
 * @module flowingly.bpmn.modeler
 *
 * @description Facade service for creating GoJS command handlers specific to BPMN diagram.
 *
 * ## Notes
 *
 * ###API
 * * getDrawCommandHandler - creates and returns instance of a draw handler
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/9c9f57c35d8a2835898844ff97a37bb83bb4ae98/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.command.service.js?at=master
 */
'use strict';
import angular from 'angular';

declare const _: Lodash;

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnCommandService', bpmnCommandService);

bpmnCommandService.$inject = ['goService', 'guidService', 'flowinglyConstants'];

function bpmnCommandService(goService, guidService, flowinglyConstants) {
  const service = {
    getDrawCommandHandler: getDrawCommandHandler
  };
  return service;

  function getDrawCommandHandler() {
    goService.Diagram.inherit(drawCommandHandler, goService.CommandHandler);

    /**
     * This controls whether or not the user can invoke the {@link #alignLeft}, {@link #alignRight},
     * {@link #alignTop}, {@link #alignBottom}, {@link #alignCenterX}, {@link #alignCenterY} commands.
     * @this {drawCommandHandler}
     * @return {boolean}
     * This returns true:
     * if the diagram is not {@link Diagram#isReadOnly},
     * if the model is not {@link Model#isReadOnly}, and
     * if there are at least two selected {@link Part}s.
     */
    drawCommandHandler.prototype.canAlignSelection = function () {
      const diagram = this.diagram;
      if (
        diagram === undefined ||
        diagram === null ||
        diagram.isReadOnly ||
        diagram.isModelReadOnly
      )
        return false;
      if (diagram.selection.count < 2) return false;
      return true;
    };

    /**
     * Aligns selected parts along the left-most edge of the left-most part.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype.alignLeft = function () {
      const diagram = this.diagram;
      diagram.startTransaction('aligning left');
      let minPosition = Infinity;
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        minPosition = Math.min(current.position.x, minPosition);
      });

      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        current.move(new goService.Point(minPosition, current.position.y));
      });

      diagram.commitTransaction('aligning left');
    };

    /**
     * Aligns selected parts at the right-most edge of the right-most part.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype.alignRight = function () {
      const diagram = this.diagram;
      diagram.startTransaction('aligning right');
      let maxPosition = -Infinity;
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        const rightSideLoc =
          current.actualBounds.x + current.actualBounds.width;
        maxPosition = Math.max(rightSideLoc, maxPosition);
      });
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        current.move(
          new goService.Point(
            maxPosition - current.actualBounds.width,
            current.position.y
          )
        );
      });
      diagram.commitTransaction('aligning right');
    };

    /**
     * Aligns selected parts at the top-most edge of the top-most part.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype.alignTop = function () {
      const diagram = this.diagram;
      diagram.startTransaction('alignTop');
      let minPosition = Infinity;
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        minPosition = Math.min(current.position.y, minPosition);
      });
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        current.move(new goService.Point(current.position.x, minPosition));
      });
      diagram.commitTransaction('alignTop');
    };

    /**
     * Aligns selected parts at the bottom-most edge of the bottom-most part.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype.alignBottom = function () {
      const diagram = this.diagram;
      diagram.startTransaction('aligning bottom');
      let maxPosition = -Infinity;
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        const bottomSideLoc =
          current.actualBounds.y + current.actualBounds.height;
        maxPosition = Math.max(bottomSideLoc, maxPosition);
      });
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        current.move(
          new goService.Point(
            current.actualBounds.x,
            maxPosition - current.actualBounds.height
          )
        );
      });
      diagram.commitTransaction('aligning bottom');
    };

    /**
     * Aligns selected parts at the x-value of the center point of the first selected part.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype.alignCenterX = function () {
      const diagram = this.diagram;
      const firstSelection = diagram.selection.first();
      if (!firstSelection) return;
      diagram.startTransaction('aligning Center X');
      const centerX =
        firstSelection.actualBounds.x + firstSelection.actualBounds.width / 2;
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        current.move(
          new goService.Point(
            centerX - current.actualBounds.width / 2,
            current.actualBounds.y
          )
        );
      });
      diagram.commitTransaction('aligning Center X');
    };

    /**
     * Aligns selected parts at the y-value of the center point of the first selected part.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype.alignCenterY = function () {
      const diagram = this.diagram;
      const firstSelection = diagram.selection.first();
      if (!firstSelection) return;
      diagram.startTransaction('aligning Center Y');
      const centerY =
        firstSelection.actualBounds.y + firstSelection.actualBounds.height / 2;
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        current.move(
          new goService.Point(
            current.actualBounds.x,
            centerY - current.actualBounds.height / 2
          )
        );
      });
      diagram.commitTransaction('aligning Center Y');
    };

    /**
     * Aligns selected parts top-to-bottom in order of the order selected.
     * Distance between parts can be specified. Default distance is 0.
     * @this {drawCommandHandler}
     * @param {number} distance
     */
    drawCommandHandler.prototype.alignColumn = function (distance) {
      const diagram = this.diagram;
      diagram.startTransaction('align Column');
      if (distance === undefined) distance = 0; // for aligning edge to edge
      distance = parseFloat(distance);
      const selectedParts = [];
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        selectedParts.push(current);
      });
      for (let i = 0; i < selectedParts.length - 1; i++) {
        const current = selectedParts[i];
        // adds distance specified between parts
        const curBottomSideLoc =
          current.actualBounds.y + current.actualBounds.height + distance;
        const next = selectedParts[i + 1];
        next.move(
          new goService.Point(current.actualBounds.x, curBottomSideLoc)
        );
      }
      diagram.commitTransaction('align Column');
    };

    /**
     * Aligns selected parts left-to-right in order of the order selected.
     * Distance between parts can be specified. Default distance is 0.
     * @this {drawCommandHandler}
     * @param {number} distance
     */
    drawCommandHandler.prototype.alignRow = function (distance) {
      if (distance === undefined) distance = 0; // for aligning edge to edge
      distance = parseFloat(distance);
      const diagram = this.diagram;
      diagram.startTransaction('align Row');
      const selectedParts = [];
      diagram.selection.each(function (current) {
        if (current instanceof goService.Link) return; // skips over goService.Link
        selectedParts.push(current);
      });
      for (let i = 0; i < selectedParts.length - 1; i++) {
        const current = selectedParts[i];
        // adds distance specified between parts
        const curRightSideLoc =
          current.actualBounds.x + current.actualBounds.width + distance;
        const next = selectedParts[i + 1];
        next.move(new goService.Point(curRightSideLoc, current.actualBounds.y));
      }
      diagram.commitTransaction('align Row');
    };

    /**
     * This controls whether or not the user can invoke the {@link #rotate} command.
     * @this {drawCommandHandler}
     * @param {number=} angle the positive (clockwise) or negative (counter-clockwise) change in the rotation angle of each Part, in degrees.
     * @return {boolean}
     * This returns true:
     * if the diagram is not {@link Diagram#isReadOnly},
     * if the model is not {@link Model#isReadOnly}, and
     * if there is at least one selected {@link Part}.
     */
    drawCommandHandler.prototype.canRotate = function () {
      const diagram = this.diagram;
      if (
        diagram === undefined ||
        diagram === null ||
        diagram.isReadOnly ||
        diagram.isModelReadOnly
      )
        return false;
      if (diagram.selection.count < 1) return false;
      return true;
    };

    /**
     * Change the angle of the parts connected with the given part. This is in the command handler
     * so it can be easily accessed for the purpose of creating commands that change the rotation of a part.
     * @this {drawCommandHandler}
     * @param {number=} angle the positive (clockwise) or negative (counter-clockwise) change in the rotation angle of each Part, in degrees.
     */
    drawCommandHandler.prototype.rotate = function (angle) {
      if (angle === undefined || angle === null) angle = 90;
      const diagram = this.diagram;
      diagram.startTransaction('rotate ' + angle.toString());
      //var diagram = this.diagram;
      diagram.selection.each(function (current) {
        if (
          current instanceof goService.Link ||
          current instanceof goService.Group
        )
          return; // skips over Links and Groups
        current.angle += angle;
      });
      diagram.commitTransaction('rotate ' + angle.toString());
    };

    /**
     * This implements custom behaviors for arrow key keyboard events.
     * Set {@link #arrowKeyBehavior} to "select", "move" (the default), "scroll" (the standard behavior), or "none"
     * to affect the behavior when the user types an arrow key.
     * @this {drawCommandHandler}*/
    drawCommandHandler.prototype.doKeyDown = function () {
      const diagram = this.diagram;
      if (diagram === undefined || diagram === null) return;
      const e = diagram.lastInput;

      // determines the function of the arrow keys
      if (
        e.key === 'Up' ||
        e.key === 'Down' ||
        e.key === 'Left' ||
        e.key === 'Right'
      ) {
        const behavior = this.arrowKeyBehavior;
        if (behavior === 'none') {
          // no-op
          return;
        } else if (behavior === 'select') {
          this._arrowKeySelect();
          return;
        } else if (behavior === 'move') {
          this._arrowKeyMove();
          return;
        }
        // otherwise drop through to get the default scrolling behavior
      }

      // otherwise still does all standard commands
      goService.CommandHandler.prototype.doKeyDown.call(this);
    };

    /**
     * Collects in an Array all of the non-Link Parts currently in the Diagram.
     * @this {drawCommandHandler}
     * @return {Array}
     */
    drawCommandHandler.prototype._getAllParts = function () {
      const allParts = [];
      this.diagram.nodes.each(function (node) {
        allParts.push(node);
      });
      this.diagram.parts.each(function (part) {
        allParts.push(part);
      });
      // note that this ignores Links
      return allParts;
    };

    /**
     * To be called when arrow keys should move the Diagram.selection.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype._arrowKeyMove = function () {
      const diagram = this.diagram;
      const e = diagram.lastInput;
      // moves all selected parts in the specified direction
      let vdistance = 0;
      let hdistance = 0;
      // if control is being held down, move pixel by pixel. Else, moves by grid cell size
      if (e.control || e.meta) {
        vdistance = 1;
        hdistance = 1;
      } else if (diagram.grid !== null) {
        const cellsize = diagram.grid.gridCellSize;
        hdistance = cellsize.width;
        vdistance = cellsize.height;
      }
      diagram.startTransaction('arrowKeyMove');
      diagram.selection.each(function (part) {
        if (e.key === 'Up') {
          part.move(
            new goService.Point(
              part.actualBounds.x,
              part.actualBounds.y - vdistance
            )
          );
        } else if (e.key === 'Down') {
          part.move(
            new goService.Point(
              part.actualBounds.x,
              part.actualBounds.y + vdistance
            )
          );
        } else if (e.key === 'Left') {
          part.move(
            new goService.Point(
              part.actualBounds.x - hdistance,
              part.actualBounds.y
            )
          );
        } else if (e.key === 'Right') {
          part.move(
            new goService.Point(
              part.actualBounds.x + hdistance,
              part.actualBounds.y
            )
          );
        }
      });
      diagram.commitTransaction('arrowKeyMove');
    };

    /**
     * To be called when arrow keys should change selection.
     * @this {drawCommandHandler}
     */
    drawCommandHandler.prototype._arrowKeySelect = function () {
      const diagram = this.diagram;
      const e = diagram.lastInput;
      // with a part selected, arrow keys change the selection
      // arrow keys + shift selects the additional part in the specified direction
      // arrow keys + control toggles the selection of the additional part
      let nextPart = null;
      if (e.key === 'Up') {
        nextPart = this._findNearestPartTowards(270);
      } else if (e.key === 'Down') {
        nextPart = this._findNearestPartTowards(90);
      } else if (e.key === 'Left') {
        nextPart = this._findNearestPartTowards(180);
      } else if (e.key === 'Right') {
        nextPart = this._findNearestPartTowards(0);
      }
      if (nextPart !== undefined && nextPart !== null) {
        if (e.shift) {
          nextPart.isSelected = true;
        } else if (e.control || e.meta) {
          nextPart.isSelected = !nextPart.isSelected;
        } else {
          diagram.select(nextPart);
        }
      }
    };

    /**
     * Finds the nearest Part in the specified direction, based on their center points.
     * if it doesn't find anything, it just returns the current Part.
     * @this {drawCommandHandler}
     * @param {number} dir the direction, in degrees
     * @return {Part} the closest Part found in the given direction
     */
    drawCommandHandler.prototype._findNearestPartTowards = function (dir) {
      const originalPart = this.diagram.selection.first();
      if (originalPart === undefined || originalPart === null) return null;
      const originalPoint = originalPart.actualBounds.center;
      const allParts = this._getAllParts();
      let closestDistance = Infinity;
      let closest = originalPart; // if no parts meet the criteria, the same part remains selected

      for (let i = 0; i < allParts.length; i++) {
        const nextPart = allParts[i];
        if (nextPart === originalPart) continue; // skips over currently selected part
        const nextPoint = nextPart.actualBounds.center;
        const angle = originalPoint.directionPoint(nextPoint);
        const anglediff = this._angleCloseness(angle, dir);
        if (anglediff <= 45) {
          // if this part's center is within the desired direction's sector,
          let distance = originalPoint.distanceSquaredPoint(nextPoint);
          distance *= 1 + Math.sin((anglediff * Math.PI) / 180); // the more different from the intended angle, the further it is
          if (distance < closestDistance) {
            // and if it's closer than any other part,
            closestDistance = distance; // remember it as a better choice
            closest = nextPart;
          }
        }
      }
      return closest;
    };

    /**
     * @this {drawCommandHandler}
     * @param {number} a
     * @param {number} dir
     * @return {number}
     */
    drawCommandHandler.prototype._angleCloseness = function (a, dir) {
      return Math.min(
        Math.abs(dir - a),
        Math.min(Math.abs(dir + 360 - a), Math.abs(dir - 360 - a))
      );
    };

    /**
     * Reset the last offset for pasting.
     * @override
     * @this {drawCommandHandler}
     * @param {Iterable.<Part>} coll a collection of {@link Part}s.
     */
    drawCommandHandler.prototype.copyToClipboard = function (coll) {
      //remove invalid links
      const toRemove = [];
      coll.each(function (p) {
        if (p.category && p.category === 'exclusiveGateway') {
          toRemove.push(p);
        } else if (p instanceof go.Link) {
          if (
            p.fromNode.category === 'exclusiveGateway' ||
            p.toNode.category === 'exclusiveGateway'
          ) {
            toRemove.push(p);
          }
        }
      });
      for (let i = 0; i < toRemove.length; i++) {
        coll.remove(toRemove[i]);
      }

      this.diagram.model.nodeIdInClipboard =
        this.diagram.model.selectedNodeData.id; // save the node id copied to clipboard
      this.diagram.model.nodeKeyInClipboard =
        this.diagram.model.selectedNodeData.key;
      goService.CommandHandler.prototype.copyToClipboard.call(this, coll);
      this._lastPasteOffset.set(this.pasteOffset);
    };

    /**
     * Paste from the clipboard with an offset incremented on each paste, and reset when copied.
     * @override
     * @this {drawCommandHandler}
     * @return {Set.<Part>} a collection of newly pasted {@link Part}s
     */
    drawCommandHandler.prototype.pasteFromClipboard = function () {
      this.diagram.model.isPasteFromClipboard = true; // save the state to tell now is paste from clipboard, gojs will fire "ChangedSelection" event right after this.
      const pastedCollection =
        goService.CommandHandler.prototype.pasteFromClipboard.call(this);
      this.diagram.moveParts(pastedCollection, this._lastPasteOffset);
      this._lastPasteOffset.add(this.pasteOffset);
      //if what we are pasting has an id, give it a new one.
      _.forEach(pastedCollection.ad, (item) => {
        if (!item.key || !item.key.sh) {
          return;
        }
        if (item.key.sh.id) {
          item.key.sh.copiedFromNodeId = item.key.sh.id;
        } else {
          console.log('missed copied node id');
        }
        item.key.sh.id = guidService.new();
        if (
          item.key.sh.text &&
          item.key.sh.category !==
            flowinglyConstants.nodeCategory.CONVERGE_GATEWAY &&
          item.key.sh.category !== flowinglyConstants.nodeCategory.EVENT
        ) {
          item.key.sh.text = `Copy of ${item.key.sh.text}`;
        }
      });
      return pastedCollection;
    };

    drawCommandHandler.prototype.deleteSelection = function () {
      const diagram = this.diagram;
      diagram.selection.each(function (part) {
        if (part.sh.category === 'Lane') {
          if (part.memberParts.count > 0) {
            diagram.commandHandler.addTopLevelParts(part.memberParts, true);
          }
        }
      });
      goService.CommandHandler.prototype.deleteSelection.call(this);
    };

    /**
     * Gets or sets the arrow key behavior. Possible values are "move", "select", and "scroll".
     * The default value is "move".
     * @name drawCommandHandler#arrowKeyBehavior
     * @function.
     * @return {string}
     */
    Object.defineProperty(drawCommandHandler.prototype, 'arrowKeyBehavior', {
      get: function () {
        return this._arrowKeyBehavior;
      },
      set: function (val) {
        if (
          val !== 'move' &&
          val !== 'select' &&
          val !== 'scroll' &&
          val !== 'none'
        ) {
          throw new Error(
            'drawCommandHandler.arrowKeyBehavior must be either "move", "select", "scroll", or "none", not: ' +
              val
          );
        }
        this._arrowKeyBehavior = val;
      }
    });

    /**
     * Gets or sets the offset at which each repeated pasteSelection() puts the new copied parts from the clipboard.
     * @name drawCommandHandler#pasteOffset
     * @function.
     * @return {Point}
     */
    Object.defineProperty(drawCommandHandler.prototype, 'pasteOffset', {
      get: function () {
        return this._pasteOffset;
      },
      set: function (val) {
        if (!(val instanceof goService.Point))
          throw new Error(
            'drawCommandHandler.pasteOffset must be a Point, not: ' + val
          );
        this._pasteOffset.set(val);
      }
    });

    /* This CommandHandler class allows the user to position selected Parts in a diagram
     * relative to the first part selected, in addition to overriding the doKeyDown method
     * of the CommandHandler for handling the arrow keys in additional manners.
     */

    return new drawCommandHandler();
  }

  function drawCommandHandler() {
    goService.CommandHandler.call(this);
    this._arrowKeyBehavior = 'move';
    this._pasteOffset = new goService.Point(10, 10);
    this._lastPasteOffset = new goService.Point(0, 0);
  }
}

export type BpmnCommandServiceType = ReturnType<typeof bpmnCommandService>;
