/*
 * @ngdoc service
 * @name notificationService
 * @module flowingly.services
 *
 * @description A notification service for showing toast notifications.
 *
 * ## Notes
 * Wraps the toastr service, such that we can swap it out later if desired.
 * Also allows a nicer interface; hiding details required to style toastr.
 * 
 * ###API
 * * showErrorToast 
 * * showInfoToast 
 * * showSuccessToast 
 * * showWarningToast 
 * 
 * ###Usage
 *    
 * notificationService.showSuccessToast("Password changed successfully.", 1000);

 * notificationService.showErrorToast("Error reseting password. Reason:" + response.data.ErrorMessage, 2000)
 *
 *
 * Note:
 *  Converted to ts on 5/12/2019. If you need
 *  the original JS file, you can view it here on this link
 *
 *  https://bitbucket.org/flowingly-team/flowingly-source-code/src/bad03a1dc5283e0ed90fa7961aae1bcfa21ef25a/src/Flowingly.Shared.Angular/flowingly.services/notification.service.js
*/

import { ValidationServiceType } from './validation.service';

class NotificationService {
  public static ID = 'notificationService';
  public static $inject = [
    'toastrService',
    'lodashService',
    'validationService'
  ];
  constructor(
    private toastrService: Toastr,
    private lodashService: _.LoDashStatic,
    private validationService: ValidationServiceType
  ) {
    // Default settings
    toastrService.options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    };
  }

  public showErrorToast(message: string, duration?: number) {
    const messageToShow = this.setupMessage(message, duration, 'Error');
    this.toastrService.error(messageToShow);
  }

  public showSuccessToast(message: string, duration?: number) {
    const messageToShow = this.setupMessage(message, duration, 'Success');
    this.toastrService.success(messageToShow);
  }

  public showInfoToast(message: string, duration?: number) {
    const messageToShow = this.setupMessage(message, duration, 'Info');
    this.toastrService.info(messageToShow);
  }

  public showWarningToast(message: string, duration?: number) {
    const messageToShow = this.setupMessage(message, duration, 'Warning');
    this.toastrService.warning(messageToShow);
  }

  private setupTimeToShowOption(duration?: number) {
    const timeToShow = duration || 5000;
    this.toastrService.options.timeOut = timeToShow;
  }

  private setupMessage(
    message: string,
    duration: number,
    defaultMessage: string
  ) {
    this.setupTimeToShowOption(duration);

    if (this.validationService.isXssVulnerableString(message)) {
      message = this.lodashService.escape(message);
    }
    return this.validationService.sanitizeString(message) || defaultMessage;
  }
}

angular
  .module('flowingly.services')
  .service(NotificationService.ID, NotificationService);

export type NotificationServiceType = InstanceType<typeof NotificationService>;
