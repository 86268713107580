/**
 * @ngdoc service
 * @name momentService
 * @module flowingly.adapters
 *
 * @description Wraps the global moment Object.
 *
 * ## Notes
 * moment is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global moment object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes moment has already been loaded on the page.
 */
(function () {
  'use strict';

  angular.module('flowingly.adapters').factory('momentService', [
    '$window',
    function ($window) {
      if ($window.moment == undefined) {
        console.error('moment is not loaded');
      }
      return $window.moment;
    }
  ]);
})();
