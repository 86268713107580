/**
 * @ngdoc module
 * @name flowingly.filters
 * @module flowingly.filters
 * @description Filters shared across our websites
 */

'use strict';
import angular from 'angular';
angular.module('flowingly.filters', []);
