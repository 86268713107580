/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/user-profile/user.profile.change.avatar.component.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';
///
/// This DUMB component is used to display the user's avatar and respond
/// to their request to change it.
///
/// The work to save changes is completed by the user.profile.component.js
///
/// See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+User+Profile+Components

angular.module('flowingly.user.profile').component('userProfileChangeAvatar', {
  templateUrl: 'user.profile.change.avatar.tmpl.html',
  bindings: {
    user: '<',
    isMobile: '<',
    onRemoveAvatarClick: '&',
    onUploadAvatarClick: '&'
  },
  controller: [
    '$document',
    'avatarService',

    function (
      $document: angular.IDocumentService,
      avatarService: Services.AvatarService
    ) {
      const $ctrl = this;

      $ctrl.clearFileInputMethod = clearFileInput;
      $ctrl.removeAvatar = removeAvatar;
      $ctrl.onFileChange = onFileChange;
      $ctrl.user.avatarUrl = avatarService.getAvatarUrl($ctrl.user.id);

      function removeAvatar() {
        // Note this calls the method in runner.setup.users.dialog.controlller
        // which has all the necessary handling
        $ctrl.onRemoveAvatarClick();
      }

      function onFileChange() {
        // Note this calls the method in runner.setup.users.dialog.controlller
        // which has all the necessary handling
        $ctrl.onUploadAvatarClick({
          file: $ctrl.file,
          clearInputMethod: $ctrl.clearFileInputMethod
        });
      }

      function clearFileInput() {
        const input = $document[0].getElementById(
          'avatarUpload'
        ) as HTMLInputElement;
        input.value = '';
        $ctrl.file = null;
      }
    }
  ]
});
