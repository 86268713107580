/**
 * @ngdoc service
 * @name avatarService
 * @module flowingly.services
 *
 * @description A service repsonsible for defining content for avatar circles / flows icons
 *
 * ## Notes
 *
 * ###API
 * * getFlowInitials - flows are represented by intials, return for this flow
 * * getUserInitial - return first letter of this users name
 * * getColour - look up colour
 * * getAvatarUrl - get avatar url for a user
 * * getModelerNodeAvatarUrl - get avatar url for a node in the flow model
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/b8e3fc0a689eca68d3c5e1718e1c54635975cbe3/src/Flowingly.Shared.Angular/flowingly.services/avatar.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.services').factory('avatarService', avatarService);

avatarService.$inject = ['lodashService'];

function avatarService(lodashService: Lodash) {
  let _palette;
  let _actorsWithAvatars = null; // The actors and their avatar urls which have been retrieved from server side

  const service = {
    getFlowInitials: getFlowInitials,
    getUserInitial: getUserInitial,
    getColour: getColour,
    getBgColorForProcessMapRightPaneAvatar:
      getBgColorForProcessMapRightPaneAvatar,
    getAvatarUrl: getAvatarUrl,
    getModelerNodeAvatarUrl: getModelerNodeAvatarUrl,
    setActorsWithAvatars: setActorsWithAvatars,
    getAvatarList: getAvatarList,
    haveAvatarList: haveAvatarList
  };

  initialisePalette();

  return service;

  //////////// Public API Methods

  function haveAvatarList() {
    if (_actorsWithAvatars === null) return false;
    return true;
  }

  function getAvatarList() {
    return _actorsWithAvatars;
  }

  function setActorsWithAvatars(data) {
    _actorsWithAvatars = data;
    lodashService.forEach(_actorsWithAvatars, function (row) {
      if (row.id === undefined) {
        row.id = row.Id;
        row.isAvatar = row.IsAvatar;
        row.avatarUrl = row.AvatarUrl;
        row.avatarId = row.AvatarId;
        row.name = row.Name;
      }
      if (!row.isAvatar) {
        row.avatarUrl = null;
      }
    });
  }

  // At present we just have avatars for users, but in future will also have avatars for teams (groups)
  function getAvatarUrl(actorId) {
    //FLOW-3045 Removed avatar file functionality - for now at least
    // var match = actorHasAvatar(actorId);
    //  if (match) {
    //   if (match.isAvatar) {
    //     return match.avatarUrl;
    // } else {
    return null; //flowAvatarCircle will use a circle avatar (a style) if the avatar is null
    //  }
    // } else {
    //    return null;    //flowAvatarCircle will use a circle avatar (a style) if the avatar is null
    // }
  }

  function getModelerNodeAvatarUrl(actorId, actorName) {
    //FLOW-3045 Removed avatar file functionality - for now at least
    // var avatarUrl = getAvatarUrl(actorId);
    //if (avatarUrl !== undefined && avatarUrl !== null) {
    //  return avatarUrl;
    // }

    const firstLetter = actorName && actorName.charAt(0).toLowerCase();

    return firstLetter && firstLetter.match(/[a-z]/)
      ? ASSETS_PATH + `/avatars/avatar_${firstLetter}_28.png`
      : '';
  }

  function getFlowInitials(flowName, flowCode) {
    //use code from data (returned from DB)
    if (flowCode) {
      return flowCode;
    }
    //else generate one after first removing all alphanumerics
    //we dont remove spaces as we use this to split names into words
    //dahses are converted to spaces
    //the filter then removes any no length words ("") from the word array;
    const sanitised = flowName
      .replace(/-/, ' ')
      .replace(/[^\w\s]/gi, '')
      .toUpperCase();
    const words = sanitised.split(' ').filter(Boolean);
    //if there is only one word
    if (words.length === 1 && words[0].length > 1) {
      return words[0].charAt(0) + words[0].charAt(1);
    } else if (words.length > 1) {
      return words[0].charAt(0) + words[1].charAt(0);
    }
    if (words.length > 0) {
      return words[0].charAt(0);
    }
  }

  function getUserInitial(firstName) {
    return firstName.charAt(0);
  }

  function getColour(name) {
    return lookupColour(name);
  }

  function getBgColorForProcessMapRightPaneAvatar(initial) {
    const FlowinglyOrange = '#F57C00';

    initial = initial.toUpperCase();

    switch (initial) {
      case 'A':
        return '#E06055';
      case 'B':
        return '#F06292';
      case 'C':
        return '#BA68C8';
      case 'D':
        return '#9575CD';
      case 'E':
        return '#7986CB';
      case 'F':
        return '#5E97F6';
      case 'G':
        return '#4FC3F7';
      case 'H':
        return '#4DD0E1';
      case 'I':
        return '#4DB6AC';
      case 'J':
        return '#57BB8A';
      case 'K':
        return '#9CCC65';
      case 'L':
        return '#D4E157';
      case 'M':
        return '#FDD835';
      case 'N':
        return '#F6BF26';
      case 'O':
        return '#FFA726';
      case 'P':
        return '#FF8A65';
      case 'Q':
        return '#C2C2C2';
      case 'R':
        return '#90A4AE';
      case 'S':
        return '#A1887F';
      case 'T':
        return '#A3A3A3';
      case 'U':
        return '#AEB5DF';
      case 'V':
        return '#B39DDB';
      case 'W':
        return '#C2C2C2';
      case 'X':
        return '#80DEEA';
      case 'Y':
        return '#BCAAA4';
      case 'Z':
        return '#AED581';
      default:
        return FlowinglyOrange;
    }
  }

  //////////// Private Methods

  function actorHasAvatar(actorId) {
    const match = lodashService.find(_actorsWithAvatars, (a) => {
      return a.id === actorId;
    });
    return match;
  }

  function initialisePalette() {
    _palette = [
      '#FF8E8E', //1
      '#E994AB',
      '#67C7E2',
      '#A5D3CA',
      '#99C7FF', //5
      '#A8E4FF',
      '#75ECFD',
      '#92FEF9',
      '#7DFDD7',
      '#8BFEA8', //10
      '#FFACEC',
      '#EFA9FE',
      '#C4ABFE',
      '#EEEEA2' //14
    ];
  }

  function lookupColour(name) {
    const hex = hexEncode(name);
    const hexint = parseInt(hex, 16);
    const hue = getColorId(hexint);
    return getColourFromPalette(hue);
  }

  function getColorId(hexint) {
    const numColors = 14;
    let ranged = hexint;
    while (ranged > numColors) {
      ranged = ranged / numColors;
    }
    return parseInt(ranged);
  }

  function hexEncode(input) {
    let i = 0;
    let h = '';
    while (i < input.length) {
      h += input.charCodeAt(i++).toString(16);
    }
    return h;
  }

  function getColourFromPalette(index) {
    return _palette[index];
  }
}

export type AvatarServiceType = ReturnType<typeof avatarService>;
