angular.module('flowingly.services').factory('scriptService', scriptService);

/**
 * HTMLScriptElement does not include readyState & onreadystatechange for IE
 * Include them here so typescript does not throw errors
 */
type ScriptElemIncludeIE = HTMLScriptElement & {
  readyState?: string;
  onreadystatechange?: () => void | null;
};
function scriptService() {
  const loadScript = (
    url: string,
    type = 'text/javascript',
    charset = 'utf-8'
  ) => {
    if (!url) {
      const err = new Error(`loadScript called without URL`);
      console.warn(err);
      return Promise.reject(err);
    }

    return new Promise<void>((resolve, reject) => {
      let script = document.querySelector(
        "script[src*='" + url + "']"
      ) as ScriptElemIncludeIE;
      if (!script) {
        const heads = document.getElementsByTagName('head');
        if (heads && heads.length) {
          const head = heads[0];
          if (head) {
            script = document.createElement('script');
            script.setAttribute('src', url);
            script.setAttribute('type', type);
            if (charset) script.setAttribute('charset', charset);
            head.appendChild(script);

            if (script.readyState) {
              // only required for IE <9
              script.onreadystatechange = function () {
                if (
                  script.readyState === 'loaded' ||
                  script.readyState === 'complete'
                ) {
                  script.onreadystatechange = null;
                  resolve();
                }
              };
            } else {
              //Others
              script.onload = function () {
                resolve();
              };

              script.onerror = function () {
                reject();
              };
            }
          }
        }
      }
      return script;
    });
  };

  return {
    loadScript
  };
}

export type ScriptServiceType = ReturnType<typeof scriptService>;
