/**
 * @ngdoc service
 * @name exceptionService
 * @module flowingly.services
 *
 * @description A service for sending errors to Slack
 *
 * ## Notes
 * This service overrides Angular's built in exception handler, in order to forward them to Slack.
 * See: https://bizflo.atlassian.net/wiki/display/TECH/Client+Side+Error+Reporting
 *
 * ###API
 * * error - call to log the error
 *
 * ###Usage
 *  app.factory('$exceptionHandler', function (exceptionService, $injector) {
 *   return function (exception, cause) {
 *      exceptionService.error(exception, cause);
 *  };
 *
 * Converted to TS on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.services/exceptionHandler.service.js?at=master
 */
(function () {
  'use strict';
  angular
    .module('flowingly.services')
    .factory('exceptionService', exceptionService);

  exceptionService.$inject = ['$log'];

  function exceptionService($log) {
    //set these via web.config
    var logToConsole = true; //we always want errors in console

    var service = {
      //Log the provided error
      error: error
    };
    return service;

    //////////// Public Methods
    function error(exception, cause) {
      /* FLOW-5450: added constructor.name == "b" as after our code is uglified and deployed in live environments (Staging and NorthEU tried),
       * the custom exception names ValidationException and UserCancelledException were converted to that name */
      if (
        exception.constructor &&
        (exception.constructor.name == 'ValidationException' ||
          exception.constructor.name == 'UserCancelledException' ||
          exception.constructor.name == 'b')
      ) {
        return;
      }

      if (logToConsole) {
        // preserve the default behaviour which will log the error
        // to the console, and allow the application to continue running.
        $log.error.apply($log, arguments);
      }
    }
  }
})();
