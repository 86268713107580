/**
 * @ngdoc service
 * @name toastrService
 * @module flowingly.adapters
 *
 * @description Wraps the global toastr Object.
 *
 * ## Notes
 * toastr is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global toastr object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes toastr has already been loaded on the page.
 */
(function () {
  'use strict';

  angular.module('flowingly.adapters').factory('toastrService', [
    '$window',
    function ($window) {
      if ($window.toastr === undefined) {
        console.error('toastr is not loaded');
      }
      return $window.toastr;
    }
  ]);
})();
