/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/eb872f07031095ffd91e786eac819fd4f3aa7ed5/src/Flowingly.Shared.Angular/flowingly.directives/flowingly.contenteditable.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.directives').directive('flowinglyContenteditable', [
  '$sce',
  function ($sce) {
    return {
      restrict: 'A', // only activate on element attribute
      require: '?ngModel', // get a hold of NgModelController
      link: function (
        scope: angular.IScope,
        element: JQuery<HTMLElement>,
        attrs: angular.IAttributes,
        ngModel: angular.INgModelController
      ) {
        function read() {
          let html = element.html();
          // When we clear the content editable the browser leaves a <br> behind
          // If strip-br attribute is provided then we strip this out
          if (attrs.stripBr && html === '<br>') {
            html = '';
          }
          ngModel.$setViewValue(html);
        }

        if (!ngModel) return; // do nothing if no ng-model

        // Specify how UI should be updated
        ngModel.$render = function () {
          if (ngModel.$viewValue !== element.html()) {
            element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
          }
        };

        // Listen for change events to enable binding
        element.on('blur keyup change', function () {
          scope.$apply(read);
        });
        read(); // initialize
      }
    };
  }
]);
