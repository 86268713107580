/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/0e79da5eea4ea73285a3c42d2f3e41bfcb223521/src/Flowingly.Shared.Angular/flowingly.directives/delegation-banner/delegation-banner.module.js?at=master
 */

'use strict';
import angular from 'angular';

const delegationBannerModule = angular.module(
  'flowingly.delegation.banner',
  []
);

delegationBannerModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'delegation-banner.tmpl.html',
      require('./delegation-banner.tmpl.html').default
    );
  }
]);
