/**
 * @ngdoc directive
 * @name flowTableBuilderCell
 * @module flowingly.components
 * @description This comppnent represents a cell within the table builder.
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Dynamic+Table+Builder
 * ### Notes
 * This component displays the cell contents (after editing)
 * It is not possible to use expressions in ng-switch-when, so we cannot for example do:
 *   ng-switch-when="$ctrl.types.file"
 *   ng-switch-when="{{$ctrl.types.file}}"
  * ### Properties
 * * cell : the cell to be rendered
 * * onCellClicked : method to call each time the cell is clicked
 * * onCellDeleteClicked : method to call each time the cell delete button is clicked
 * @usage
 * ```
    <flow-table-builder-cell ng-repeat="cell in $ctrl.cells track by cell.id"
                             id="cell_{{cell.id}}"
                             cell="cell"
                             cell-count="$ctrl.cells.length"
                             on-cell-clicked="$ctrl.showHideEditor(cell)"
                             on-cell-delete-clicked="$ctrl.deleteCell(cell)" 
                             ng-class="{'active-cell': $ctrl.activeCell.id == cell.id}">
    </flow-table-builder-cell>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.components/tablebuilder/table.builder.cell.component.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('flowTableBuilderCell', {
    bindings: {
      cell: '<',
      cellCount: '<',
      onCellClicked: '&',
      onCellDeleteClicked: '&'
    },
    controller: function () {
      var $ctrl = this;
      $ctrl.dateSelectorOptions = {
        start: 'year',
        depth: 'day'
      };
    },
    templateUrl: 'table.builder.cell.component.tmpl.html'
  });
})();
