/**
 * @ngdoc service
 * @name BpmnCommonService
 * @module flowingly.bpmn.modeler
 *
 * @description A service for creating BPMN common (shared) nodes etc.
 *
 * ## Notes
 *
 * ###API
 * * getBpmnLinkingTool - creates and returns instance of a linking tool
 * * getLinkTemplate - return template for creating new links
 * * getGradientBrush - creates and returns a gradient brush
 * * makeMarkerPanel - ??
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/af9fa5eb34f8d6f7d707cf2f9997b53c28a58dcb/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.common.service.js?at=master
 */
'use strict';

import { Services } from '@Shared.Angular/@types/services';
import { BpmnModeler } from './@types/services';
import { SharedAngular } from '@Angular.Runner/@types/sharedAngular';

//declare const go: any;

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnCommonService', bpmnCommonService);

bpmnCommonService.$inject = [
  'goService',
  'BPMN_CONSTANTS',
  'pubsubService',
  'APP_CONFIG'
];

function bpmnCommonService(
  goService: GoJS,
  BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
  pubsubService: Services.PubSubService,
  APP_CONFIG: SharedAngular.APP_CONFIG
) {
  const service = {
    getBpmnLinkingTool: getBpmnLinkingTool,
    getLinkTemplate: getLinkTemplate,
    getGradientBrush: getGradientBrush,
    makeMarkerPanel: makeMarkerPanel,
    nodeClickHandler: nodeClickHandler,
    isInternetExplorer: isInternetExplorer,
    getTextEditingTool: getTextEditingTool,
    makePort: makePort,
    shouldShowConnectorPoints: shouldShowConnectorPoints,
    showPort: showPort,
    hidePort: hidePort
  };
  return service;

  function nodeClickHandler(e) {
    pubsubService.publish(
      'DIAGRAM_NODE_CLICKED',
      e.diagram.model.selectedNodeData
    );
  }

  function getBpmnLinkingTool() {
    goService.Diagram.inherit(bpmnLinkingTool, goService.LinkingTool);

    /*START bpmnLinkingTool*/
    /** @override */
    bpmnLinkingTool.prototype.insertLink = function (
      fromNode,
      fromPort,
      toNode,
      toPort
    ) {
      let lsave = null;
      // maybe temporarily change the link data that is copied to create the new link
      if (
        (bpmnLinkingTool as any).validateMessageLinkConnection(
          fromNode,
          fromPort,
          toNode,
          toPort
        )
      ) {
        lsave = this.archetypeLinkData;
        this.archetypeLinkData = { category: 'msg' };
      }

      const isBacklink =
        fromNode.data &&
        toNode.data &&
        fromNode.data.backlinkTo &&
        fromNode.data.backlinkTo === toNode.data.key;

      // create the link in the standard manner by calling the base method
      const newlink = goService.LinkingTool.prototype.insertLink.call(
        this,
        fromNode,
        fromPort,
        toNode,
        toPort
      );
      newlink.routing = setRoutingMode(BPMN_CONSTANTS.RoutingMode);
      newlink.curve = goService.Link.JumpGap;
      newlink.corner = BPMN_CONSTANTS.LinkCornerCurve;

      if (isBacklink) {
        newlink.data.isBacklink = true;
      }

      const nodeObjTo = angular.fromJson(angular.toJson(toNode.sh));
      const nodeObjFrom = angular.fromJson(angular.toJson(fromNode.sh));

      this.archetypeLinkData = {
        Trigger: { Type: 'Command' }
      };

      ///
      /// All nodes need to set to a trigger type of Command apart from the start node which needs to be Auto to
      /// transition to the next step when a flow is started
      ///
      const eventStart = 1;

      //PLEASE NOTE THAT IF LOGIC FOR SETTING TRIGGER TYPES CHANGES TO UPDATE:
      //
      //  flowinglyModelUtilityService.getTrigger();
      //
      if (
        nodeObjFrom.category === 'event' &&
        nodeObjFrom.eventDimension === eventStart
      ) {
        this.archetypeLinkData.Trigger.Type = 'Auto';
      }
      if (nodeObjFrom.category === 'gateway') {
        this.archetypeLinkData.Trigger.Type = 'Auto';
      }

      // maybe make the label visible
      if (
        fromNode.category === 'exclusiveGateway' &&
        nodeObjFrom.allowNamingLinks
      ) {
        const label = newlink.findObject('Label');
        if (label !== null) {
          label.text = 'Label';
          label.visible = true;
        }
      }

      // maybe restore the original archetype link data
      if (lsave !== null) this.archetypeLinkData = lsave;
      return newlink;
    };

    // static utility validation routines for linking & relinking as well as insert link logic

    // in BPMN, can't link sequence flows across subprocess or pool boundaries
    (bpmnLinkingTool as any).validateSequenceLinkConnection = function (
      fromnode,
      fromport,
      tonode,
      toport
    ) {
      if (fromnode.category === null || tonode.category === null) return true;

      // if either node is in a subprocess, both nodes must be in same subprocess (not even Message Flows)
      if (
        (fromnode.containingGroup !== null &&
          fromnode.containingGroup.category === 'subprocess') ||
        (tonode.containingGroup !== null &&
          tonode.containingGroup.category === 'subprocess')
      ) {
        if (fromnode.containingGroup !== tonode.containingGroup) return false;
      }

      if (fromnode.containingGroup === tonode.containingGroup) return true; // a valid Sequence Flow
      // also check for children in common pool
      const common = fromnode.findCommonContainingGroup(tonode);
      return common != null;
    };

    // in BPMN, Message Links must cross pool boundaries
    (bpmnLinkingTool as any).validateMessageLinkConnection = function (
      fromnode,
      fromport,
      tonode,
      toport
    ) {
      if (fromnode.category === null || tonode.category === null) return true;

      if (
        fromnode.category === 'privateProcess' ||
        tonode.category === 'privateProcess'
      )
        return true;

      // if either node is in a subprocess, both nodes must be in same subprocess (not even Message Flows)
      if (
        (fromnode.containingGroup !== null &&
          fromnode.containingGroup.category === 'subprocess') ||
        (tonode.containingGroup !== null &&
          tonode.containingGroup.category === 'subprocess')
      ) {
        if (fromnode.containingGroup !== tonode.containingGroup) return false;
      }

      if (fromnode.containingGroup === tonode.containingGroup) return false; // an invalid Message Flow
      // also check for children in common pool
      const common = fromnode.findCommonContainingGroup(tonode);
      return common === null;
    };
    /*END bpmnLinkingTool*/

    return new bpmnLinkingTool();
  }

  function getLinkTemplate() {
    const $GO = goService.GraphObject.make;

    const sequenceLinkTemplate = $GO(
      goService.Link,
      {
        routing: setRoutingMode(BPMN_CONSTANTS.RoutingMode),
        curve: goService.Link.JumpGap,
        corner: BPMN_CONSTANTS.LinkCornerCurve,
        reshapable: true,
        relinkableFrom: true,
        relinkableTo: true,
        toEndSegmentLength: 20,
        click: nodeClickHandler
      },
      $GO(
        goService.Shape,
        { strokeWidth: BPMN_CONSTANTS.LinkWidth },
        new go.Binding('stroke', '', function (link) {
          return getLinkColor(link, false);
        }).ofObject()
      ),
      $GO(
        goService.Shape,
        { toArrow: 'Triangle', scale: 1.25, stroke: null },
        new goService.Binding('fill', '', function (link) {
          return getLinkColor(link, true);
        }).ofObject()
      ),
      $GO(
        goService.Shape,
        {
          fromArrow: '',
          scale: 1.25,
          stroke: BPMN_CONSTANTS.LinkColour,
          fill: BPMN_CONSTANTS.LinkColour
        },
        new goService.Binding('fromArrow', 'isDefault', function (s) {
          if (s === null) return '';
          return s ? 'BackSlash' : 'StretchedDiamond';
        }),
        new goService.Binding('segmentOffset', 'isDefault', function (s) {
          return s ? new goService.Point(5, 0) : new goService.Point(0, 0);
        })
      ),

      $GO(
        goService.TextBlock,
        {
          // this is a Link label
          name: 'Label',
          //"font-style font-variant font-weight font-size font-family"
          font: 'normal normal 400 13px Open Sans, Serif',
          editable: false,
          isMultiline: false,
          text: 'LABEL',
          textAlign: 'center',
          segmentOffset: new goService.Point(0, 10),
          width: 100,
          margin: 20,
          visible: false,
          wrap: 'WrapFit',
          background: '#dcdfe5'
        },
        new goService.Binding('text', 'text').makeTwoWay(),
        new goService.Binding('visible', 'visible').makeTwoWay()
      ),

      new goService.Binding('fromSpot', 'fromSpot', goService.Spot.parse),
      new goService.Binding('points').makeTwoWay() // TwoWay Binding of Link.points
    );

    return sequenceLinkTemplate;
  }

  function getLinkColor(link, deleteBacklinkTo) {
    let color = BPMN_CONSTANTS.LinkColour;
    if (
      link &&
      link.fromNode &&
      link.fromNode.data &&
      link.fromNode.data.backlinkTo &&
      link.toNode &&
      link.toNode.data &&
      link.toNode.data.key === link.fromNode.data.backlinkTo
    ) {
      color = BPMN_CONSTANTS.BackLinkColour;
      if (deleteBacklinkTo) {
        delete link.fromNode.data.backlinkTo;
      }
      link.curve = goService.Link.JumpOver;
      link.data.fromSpot = 'Top';
      link.updateTargetBindings('fromSpot');
    }

    return color;
  }

  function bpmnLinkingTool() {
    goService.LinkingTool.call(this);
    // don't allow user to create link starting on the To node
    this.direction = goService.LinkingTool.ForwardsOnly;
    //this is how the link will look while dragged on screen
    this.temporaryLink.routing = setRoutingMode(BPMN_CONSTANTS.RoutingMode);
    this.linkValidation = function (fromnode, fromport, tonode, toport) {
      return (
        (bpmnLinkingTool as any).validateSequenceLinkConnection(
          fromnode,
          fromport,
          tonode,
          toport
        ) ||
        (bpmnLinkingTool as any).validateMessageLinkConnection(
          fromnode,
          fromport,
          tonode,
          toport
        )
      );
    };
  }

  function setRoutingMode(mode) {
    //Setting this property to AvoidsNodes requires the Diagram to do considerable computation when calculating Link routes.
    //Consider not using the AvoidsNodes with Diagrams that contain large numbers of Nodes and Links if you are targeting slow devices.
    switch (mode) {
      case 2:
        return goService.Link.Orthogonal;

      case 3:
        return goService.Link.AvoidsNodes;

      default:
        return goService.Link.Normal;
    }
  }

  function getGradientBrush(gradientType, gradientMeta) {
    const $GO = goService.GraphObject.make;
    return $GO(goService.Brush, gradientType, gradientMeta);
  }

  function makeMarkerPanel(sub, scale) {
    const $GO = goService.GraphObject.make;

    return $GO(
      goService.Panel,
      'Horizontal',
      {
        alignment: goService.Spot.MiddleBottom,
        alignmentFocus: goService.Spot.MiddleBottom
      },
      $GO(
        goService.Shape,
        'BpmnActivityLoop',
        {
          width: 12 / scale,
          height: 12 / scale,
          margin: 2,
          visible: false,
          strokeWidth: BPMN_CONSTANTS.ActivityMarkerStrokeWidth
        },
        new goService.Binding('visible', 'isLoop')
      ),
      $GO(
        goService.Shape,
        'BpmnActivityParallel',
        {
          width: 12 / scale,
          height: 12 / scale,
          margin: 2,
          visible: false,
          strokeWidth: BPMN_CONSTANTS.ActivityMarkerStrokeWidth
        },
        new goService.Binding('visible', 'isParallel')
      ),
      $GO(
        goService.Shape,
        'BpmnActivitySequential',
        {
          width: 12 / scale,
          height: 12 / scale,
          margin: 2,
          visible: false,
          strokeWidth: BPMN_CONSTANTS.ActivityMarkerStrokeWidth
        },
        new goService.Binding('visible', 'isSequential')
      ),
      $GO(
        goService.Shape,
        'BpmnActivityAdHoc',
        {
          width: 12 / scale,
          height: 12 / scale,
          margin: 2,
          visible: false,
          strokeWidth: BPMN_CONSTANTS.ActivityMarkerStrokeWidth
        },
        new goService.Binding('visible', 'isAdHoc')
      ),
      $GO(
        goService.Shape,
        'BpmnActivityCompensation',
        {
          width: 12 / scale,
          height: 12 / scale,
          margin: 2,
          visible: false,
          strokeWidth: BPMN_CONSTANTS.ActivityMarkerStrokeWidth,
          fill: null
        },
        new goService.Binding('visible', 'isCompensation')
      ),
      makeSubButton(sub)
    ); // end activity markers horizontal panel
  }

  function makeSubButton(sub) {
    const $GO = goService.GraphObject.make;
    if (sub)
      return [
        $GO('SubGraphExpanderButton'),
        { margin: 2, visible: false },
        new goService.Binding('visible', 'isSubProcess')
      ];
    return [];
  }

  function isInternetExplorer() {
    if (/Trident/.test(navigator.userAgent)) return true;

    return false;
  }

  function getTextEditingTool() {
    const textEditTool = new goService.TextEditingTool();
    const defaultTextEditor = textEditTool.defaultTextEditor;
    defaultTextEditor.style.backgroundColor =
      BPMN_CONSTANTS.Theme.FlowinglyWhite;

    return defaultTextEditor;
  }

  function makePort(
    name: string,
    spot: go.Spot,
    alignment: go.Spot
  ): go.GraphObject {
    const $GO = goService.GraphObject.make;

    return $GO(goService.Shape, 'Circle', {
      name: name,
      fill: '#42a5f5',
      opacity: 0.5,
      stroke: null,
      desiredSize: new goService.Size(20, 20),
      alignment: alignment,
      alignmentFocus: spot,
      portId: name,
      fromLinkable: true,
      toLinkable: true,
      cursor: 'pointer',
      visible: false
    });
  }

  function shouldShowConnectorPoints() {
    return (
      APP_CONFIG.enableConnectorPoints &&
      window.location.pathname === '/modeler/'
    );
  }

  function showPort(node, portName) {
    const port = node.findObject(portName);
    if (port) {
      port.visible = true;
    }
  }

  function hidePort(node, portName) {
    const port = node.findObject(portName);
    if (port) {
      port.visible = false;
    }
  }
}

export type BpmnCommonServiceType = ReturnType<typeof bpmnCommonService>;
