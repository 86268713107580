'use strict';
import angular from 'angular';
import { Services } from '../@types/services';
import {
  IFieldOptionQuery,
  IFieldOptionResult
} from '@Shared.Angular/@types/fieldOptions';

angular.module('flowingly.services').factory('fieldService', fieldService);

fieldService.$inject = ['APP_CONFIG', '$http'];

function fieldService(
  APP_CONFIG: Services.APP_CONFIG,
  $http: angular.IHttpService
) {
  const DATABASE_API_URI = APP_CONFIG.apiBaseUrl + 'customdatabase';
  const PUBLIC_FORM_API_URI = APP_CONFIG.apiBaseUrl + 'form';

  const service = {
    getFieldOptions
  };

  return service;

  function getFieldOptions(
    forPublicForm: boolean,
    request: IFieldOptionQuery[]
  ) {
    const URI =
      (forPublicForm ? PUBLIC_FORM_API_URI : DATABASE_API_URI) +
      '/fields/options';
    return $http.post<IFieldOptionResult[]>(URI, request).then((response) => {
      return response.data;
    });
  }
}

export type FieldServiceType = ReturnType<typeof fieldService>;
