/**
 * @ngdoc service
 * @name flowsUtilityService
 * @module flowingly.runner.services
 *
 * @description Service that provides utliities for working with forms and form data
 *
 */
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.components')
  .factory('flowsUtilityService', flowsUtilityService);

flowsUtilityService.$inject = [
  '$stateParams',
  '$location',
  'APP_CONFIG',
  'lodashService',
  'momentService'
];

function flowsUtilityService(
  $stateParams: angular.ui.IStateParamsService,
  $location: angular.ILocationService,
  APP_CONFIG: Services.APP_CONFIG,
  lodashService: Lodash,
  momentService: Moment
) {
  const service = {
    getStateParamValue: getStateParamValue,
    orderItemsAlphabetically: orderItemsAlphabetically,
    removeFlow: removeFlow,
    removeStep: removeStep,
    toCamel: toCamel,
    isInSupportHours: isInSupportHours
  };

  return service;

  //////////// Public API Methods

  function orderItemsAlphabetically(flows, property = 'name') {
    return flows.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) return -1;
      if (a[property].toLowerCase() > b[property].toLowerCase()) return 1;
      return 0;
    });
  }

  function toCamel(o) {
    let build, key, destKey, value;

    if (o instanceof Array) {
      build = [];
      for (key in o) {
        value = o[key];

        if (typeof value === 'object') {
          value = toCamel(value);
        }
        build.push(value);
      }
    } else {
      build = {};
      for (key in o) {
        if (Object.prototype.hasOwnProperty.call(o, key)) {
          destKey = (
            key.charAt(0).toLowerCase() + key.slice(1) || key
          ).toString();
          value = o[key];
          if (value !== null && typeof value === 'object') {
            value = toCamel(value);
          }

          build[destKey] = value;
        }
      }
    }
    return build;
  }

  function removeFlow(list, flowId) {
    const index = lodashService.findIndex(list, function (item) {
      return item.FlowId === flowId;
    });
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  function removeStep(list, flowId, stepId) {
    const index = lodashService.findIndex(list, function (item) {
      const intMatch = item.FlowId === flowId;
      const stepFound = lodashService.some(item.Steps, function (step) {
        return step.Id === stepId;
      });
      return intMatch && stepFound;
    });
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  function getStateParamValue(key) {
    let value;

    if ($stateParams.data && $stateParams.data[key]) {
      value = $stateParams.data[key];
    } else if ($location.search()[key]) {
      value = $location.search()[key];
    } else {
      value = undefined;
    }

    return value;
  }

  function isInSupportHours() {
    if (APP_CONFIG.supportHours && APP_CONFIG.outOfHoursSupportMessage) {
      try {
        const [utcSupportHourFrom, utcSupportHourTo] =
          APP_CONFIG.supportHours.split('|');
        return momentService().isBetween(
          momentService(utcSupportHourFrom),
          momentService(utcSupportHourTo)
        );
      } catch (e) {
        console.error(e);
        return true;
      }
    } else {
      return true;
    }
  }
}

export type FlowsUtilityServiceType = ReturnType<typeof flowsUtilityService>;
