/**
 * @ngdoc service
 * @name svgService
 * @module flowingly.runner.services
 *
 * @description utility service for svg
 *
 */

'use strict';
import angular from 'angular';

// Service responsible for persisting user information using angular-storage
class SvgService {
  private svcHttp: angular.IHttpService;
  private svcHe: He;

  constructor($http: angular.IHttpService, he: He) {
    this.svcHttp = $http;
    this.svcHe = he;
  }

  isSvgResource(href: string) {
    return /[.]svg\??/g.test(href);
  }

  /**
   * Given a URI svg as string get the resource as an html string
   *
   * @param {string} href
   */
  getSvgString(href: string) {
    return this.svcHttp.get(href).then((result) => result.data);
  }

  /**
   * Straight up em
   * bedding an html in an svg can cause problems
   * @param {any} svgString
   */
  getEmbeddableHtml(htmlString: string): string {
    const stripped = this.stripNbsps(htmlString);
    // this part is very confusing. basically what is happning here is that
    // sometimes (specially from runner), we get an already encoded
    // input so we need to be weary of those
    const decoded = this.flattenHTMLEntities(stripped);
    const encoded = this.replaceAmperSand(decoded);
    return encoded;
  }

  private flattenHTMLEntities(htmlString: string): string {
    return this.svcHe.decode(htmlString);
  }

  private replaceAmperSand(htmlString: string): string {
    return htmlString.replace(/&/g, '&amp;');
  }

  private stripNbsps(htmlString: string): string {
    const str = htmlString.replace(/&nbsp;/g, ' ');
    let newStr = '';
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) == 160) {
        // &Nbsp; char code ( as a ltieral)
        newStr += ' ';
      } else {
        newStr += str[i];
      }
    }
    return newStr;
  }
}

angular.module('flowingly.services').service('svgService', [
  '$http',
  'he',
  function ($http, he) {
    return new SvgService($http, he);
  }
]);

export type SvgServiceType = InstanceType<typeof SvgService>;
