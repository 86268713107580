/*
 * Converted to ts on 16/01/2020
 * See
 *  https://bitbucket.org/flowingly-team/flowingly-source-code/commits/b984ace3e94a00d25e8579e860baac87c6a727a7
 *  https://bitbucket.org/flowingly-team/flowingly-source-code/src/bad03a1dc5283e0ed90fa7961aae1bcfa21ef25a/src/Flowingly.Shared.Angular/flowingly.directives/input.validaton.directive.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.directives').directive('customInputValidation', [
  '$timeout',
  'momentService',
  'currencyService',
  function ($timeout, momentService, currencyService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (
        scope: angular.IScope,
        elem: JQuery,
        attr: angular.IAttributes,
        ctrl: angular.IController
      ) {
        function customValidator(ngModelValue) {
          if (attr.customInputValidation) {
            const isValid = comparervalues(ngModelValue, attr);
            ctrl.$setValidity('customInputValidate', isValid);
            $timeout(function () {
              updateErrorMessage(isValid, attr);
            });
          }
          return ngModelValue;
        }
        function comparervalues(ngModelVal, attr) {
          let valid = true;
          const custvaliobj = JSON.parse(attr.customInputValidation);
          const fieldType = attr.fieldType;
          if (custvaliobj.required && ngModelVal !== null) {
            if (fieldType === 'number' || fieldType === 'currency') {
              // this service returns a number if already number
              // @TODO move this method to a better place
              const tempVal = currencyService.parseNumber(ngModelVal);

              if (!isNaN(tempVal)) {
                const parsedNgVal = tempVal;
                let parsedcustVal = parseFloat(custvaliobj.value);
                if (
                  custvaliobj.valueOption &&
                  custvaliobj.valueOption === 'previousValue' &&
                  attr.customInputValidationPreviousData &&
                  custvaliobj.formFieldId
                ) {
                  const previousData = JSON.parse(
                    attr.customInputValidationPreviousData
                  );
                  parsedcustVal = parseFloat(
                    previousData[custvaliobj.formFieldId]
                  );
                }
                if (!isNaN(parsedcustVal)) {
                  switch (custvaliobj.rule) {
                    case 0:
                      valid = parsedcustVal === parsedNgVal;
                      break; // equals
                    case 1:
                      valid = parsedcustVal < parsedNgVal;
                      break; // greater than
                    case -1:
                      valid = parsedcustVal > parsedNgVal;
                      break; //less  than
                  }
                }
              }
            } else if (fieldType === 'date' || fieldType === 'datetime') {
              const dateFormat =
                fieldType === 'date' ? 'DD/MM/YYYY' : 'DD/MM/YYYY hh:mm:ss A'; // based on date formats in field-date-directve and field-dateTime-directive
              const parsedNgVal = momentService(
                ngModelVal,
                dateFormat
              ).toDate();
              let parsedcustVal = momentService(
                custvaliobj.value,
                dateFormat
              ).toDate();
              if (
                custvaliobj.valueOption &&
                custvaliobj.valueOption === 'previousValue' &&
                attr.customInputValidationPreviousData &&
                custvaliobj.formFieldId
              ) {
                const currentStepId = angular
                  .element('#stepForFormModeleNodeId')
                  .val();
                if (currentStepId !== custvaliobj.previousStepId) {
                  const previousDataTime = JSON.parse(
                    attr.customInputValidationPreviousData
                  );
                  parsedcustVal = previousDataTime[custvaliobj.formFieldId]
                    ? momentService(
                        previousDataTime[custvaliobj.formFieldId]
                      ).toDate()
                    : 'data not found';
                } else {
                  parsedcustVal = scope.ctrl.form.data[custvaliobj.formFieldId]
                    ? momentService(
                        scope.ctrl.form.data[custvaliobj.formFieldId],
                        dateFormat
                      ).toDate()
                    : 'data not found';
                }
              } else if (
                custvaliobj.valueOption &&
                custvaliobj.valueOption === 'createdDate' &&
                !!custvaliobj.stepCreatedDateOffset
              ) {
                const formattedDateValue = momentService()
                  .add(custvaliobj.stepCreatedDateOffset, 'days')
                  .format(dateFormat);
                parsedcustVal = momentService(
                  formattedDateValue,
                  dateFormat
                ).toDate();
              }

              if (
                parsedcustVal instanceof Date &&
                parsedNgVal instanceof Date &&
                !isNaN(parsedcustVal.valueOf()) &&
                !isNaN(parsedNgVal.valueOf())
              ) {
                switch (custvaliobj.rule) {
                  case 0:
                    valid = parsedcustVal.valueOf() === parsedNgVal.valueOf();
                    break; //equals
                  case 1:
                    valid = parsedcustVal.valueOf() < parsedNgVal.valueOf();
                    break; // after
                  case -1:
                    valid = parsedcustVal.valueOf() > parsedNgVal.valueOf();
                    break; //before
                }
              }
            }
          }
          return valid;
        }

        function updateErrorMessage(isValid, attr) {
          const errSpan = angular.element('#' + attr.id + '_error');
          if (errSpan.length === 0) return;
          const fieldType = attr.fieldType;
          const isDateField = fieldType === 'date' || fieldType === 'datetime';
          if (isValid) errSpan.html('');
          else {
            const custvaliobj = JSON.parse(attr.customInputValidation);
            if (
              custvaliobj.errorMessage &&
              custvaliobj.errorMessage.length > 0
            ) {
              errSpan.html(custvaliobj.errorMessage);
            } else {
              let error = '';
              error = 'A ' + fieldType + ' value should be  ';
              const oneRule =
                fieldType === 'number' || fieldType === 'currency'
                  ? ' greater than '
                  : ' after ';
              const minusOneRule =
                fieldType === 'number' || fieldType === 'currency'
                  ? ' less than '
                  : ' before ';
              const dateFormat = isDateField
                ? fieldType === 'date'
                  ? 'DD/MM/YYYY'
                  : 'DD/MM/YYYY hh:mm:ss A'
                : '';
              switch (custvaliobj.rule) {
                case 0:
                  error += ' equal to ';
                  break;
                case 1:
                  error += oneRule;
                  break;
                case -1:
                  error += minusOneRule;
                  break;
              }
              let value = '';
              if (
                custvaliobj.valueOption &&
                custvaliobj.valueOption === 'previousValue' &&
                attr.customInputValidationPreviousData &&
                custvaliobj.formFieldId
              ) {
                const currentStepId = angular
                  .element('#stepForFormModeleNodeId')
                  .val();
                if (currentStepId !== custvaliobj.previousStepId) {
                  const previousData = JSON.parse(
                    attr.customInputValidationPreviousData
                  );
                  value = previousData[custvaliobj.formFieldId];
                } else {
                  value = momentService(
                    scope.ctrl.form.data[custvaliobj.formFieldId],
                    dateFormat
                  ).toDate();
                }
              } else if (
                custvaliobj.valueOption &&
                custvaliobj.valueOption === 'createdDate' &&
                !!custvaliobj.stepCreatedDateOffset
              ) {
                const formattedDateValue = momentService()
                  .add(custvaliobj.stepCreatedDateOffset, 'days')
                  .format(dateFormat);
                value = momentService(formattedDateValue, dateFormat).toDate();
              } else {
                value = isDateField
                  ? momentService(custvaliobj.value, dateFormat).toDate()
                  : custvaliobj.value;
              }
              if (isDateField) {
                value = momentService(value).format(dateFormat);
              }
              errSpan.html(error + value);
            }
          }
        }

        ctrl.$parsers.unshift(customValidator);
        ctrl.$formatters.unshift(customValidator);

        attr.$observe('customInputValidationPreviousData', function (val: any) {
          if (val != '') {
            const data = JSON.parse(val);
            if (data && Object.keys(data).length > 0)
              customValidator(ctrl.$$lastCommittedViewValue);
          }
        });
        attr.$observe('customFormData', function (val: any) {
          if (val != '') {
            const data = JSON.parse(val);
            if (data && Object.keys(data).length > 0)
              customValidator(ctrl.$$lastCommittedViewValue);
          }
        });
      }
    };
  }
]);
