/**
 * @ngdoc directive
 * @name flowTableBuilder
 * @module flowingly.components
 * @description A component for generating tables for use in the Runner.
 * The format of the cells (input type), validation rules, and placeholder text can be configured.
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Dynamic+Table+Builder
 * ### Notes
 * This is the root component and enapsulates other components.
 * This directive is intended for use in the Modeler Form Builder
 * ### Properties
 * * tableSchema : JSON representation of the table schema
 * * onTableUpdated : method to call each time the table schema is updated
 * @usage
 * ```
 *    <flow-table-builder 
        class="hide-in-runner" 
        on-table-updated="ctrl.onTableUpdated(table)" 
        table-schema="ctrl.field.tableSchema"
        table-name="ctrl.field.displayName">
      </flow-table-builder>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/85bf14ea51f0682bcb98d8609f3c8f5a47bfd49c/src/Flowingly.Shared.Angular/flowingly.components/tablebuilder/table.builder.component.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('flowTableBuilder', {
    bindings: {
      tableSchema: '=',
      allFields: '<',
      tableFieldName: '<',
      onTableUpdated: '&'
    },
    templateUrl: 'table.builder.component.tmpl.html',
    controller: [
      '$element',
      'pubsubService',
      'flowinglyConstants',
      function ($element, pubsubService, flowinglyConstants) {
        var _lastCellOpenedId = undefined;
        var $ctrl = this;

        $ctrl.cells = [];
        $ctrl.types = {
          text: 1,
          currency: 2,
          number: 3,
          file: 4,
          date: 5,
          dropdown: 6,
          lookup: 7,
          formula: 8
        };
        $ctrl.addCell = addCell;
        $ctrl.showHideEditor = showHideEditor;
        $ctrl.deleteCell = deleteCell;
        $ctrl.updateTableSchema = updateTableSchema;
        $ctrl.errorCell = -1;
        $ctrl.errorMessage = '';

        this.$onInit = function () {};

        this.$onChanges = function () {
          //if ($ctrl.tableSchema == undefined || $ctrl.tableSchema === {}) {
          if ($ctrl.tableSchema == undefined) {
            $ctrl.cells.push(getDefaultCell(0));
            $ctrl.updateTableSchema();
          } else {
            generateTableFromSchema($ctrl.tableSchema);
          }
        };

        // PUBLIC METHODS ///////////////////////////////////////////////////////////

        function addCell($event) {
          //add an empty default cell (and open the editor)
          var newCell = getDefaultCell($ctrl.cells.length);
          //http://stackoverflow.com/questions/35604919/angular-1-5-component-onchanges-is-not-working
          var updatedModel = angular.copy($ctrl.cells);
          updatedModel.push(newCell);
          $ctrl.cells = updatedModel;

          $ctrl.updateTableSchema();
          $ctrl.showHideEditor(newCell);
        }

        function deleteCell(cell) {
          var idx = $ctrl.cells.findIndex((c) => c.id === cell.id);

          if (isCellInUse(idx)) {
            $ctrl.errorCell = idx;
            $ctrl.errorMessage =
              'Cannot delete this column as it is being used in a formula';
            $ctrl.showHideEditor(undefined);
            return;
          } else {
            $ctrl.errorCell = -1;
            $ctrl.errorMessage = '';
          }
          //We have to make sure that the cells array is updated first before updating the cell Id's
          $ctrl.cells.splice(idx, 1);

          $ctrl.updateTableSchema(cell);
          $ctrl.showHideEditor(undefined);

          updateCellFormulas(idx);
          updateCellIds();
        }

        function showHideEditor(cell, event) {
          //close the editor if:
          // - the cell has been deleted
          //   the cell will be undefined if we have just deleted one
          // - the current open editor cell has been clicked again
          // - we've changed cell and it's a dropdown, lookup, or formula
          //   we need to do this because the editor needs refreshing for these types
          // - the cell has an error
          if (
            cell == undefined ||
            ($ctrl.activeCell && $ctrl.activeCell.id === cell.id) ||
            ($ctrl.activeCell &&
              $ctrl.activeCell.id != cell.id &&
              (cell.type == $ctrl.types.lookup ||
                cell.type == $ctrl.types.dropdown ||
                cell.type == $ctrl.types.formula)) ||
            cell.id === $ctrl.errorCell
          ) {
            //close the editor by setting activeCell to undefined
            $ctrl.activeCell = undefined;
            _lastCellOpenedId = undefined;
            return;
          }
          $ctrl.errorCell = -1;
          $ctrl.errorMessage = '';
          //set the active cell to open the editor
          $ctrl.activeCell = cell;
          _lastCellOpenedId = cell.id;
        }

        function updateTableSchema(cell) {
          if (cell != undefined) {
            var updatedCellIndex = $ctrl.cells.findIndex(
              (c) => c.id == cell.id
            );

            //check to ensure that deleted cells aren't added back to the array with a -1 index
            if (updatedCellIndex > -1) {
              $ctrl.cells[updatedCellIndex] = cell;
            }

            if (cell.type === $ctrl.types.lookup) {
              cell.isRequired = false;
            } else {
              delete cell.lookupConfig;
            }

            if (cell.type !== $ctrl.types.dropdown) {
              delete cell.dbDataSource;
            }

            if (cell.type === $ctrl.types.formula) {
              cell.isRequired = false;
            } else {
              delete cell.formulaConfig;
            }
          }

          $ctrl.onTableUpdated({ table: getTableJson() });
        }

        /// PRIVATE METHODS //////////////////////////////////////////////////////////

        function getDefaultCell(id) {
          return {
            id: id,
            type: $ctrl.types.text,
            placeholder: 'click to edit',
            header: 'header',
            isRequired: true
          };
        }
        function getTableJson() {
          return JSON.stringify($ctrl.cells);
        }
        function generateTableFromSchema(schema) {
          if (!Array.isArray(schema)) {
            schema = JSON.parse(schema);
          }

          schema.forEach((cell) => $ctrl.cells.push(cell));
        }
        function updateCellIds() {
          var id = 0;
          //ensure that the cell ids start at zero and are contiguous
          $ctrl.cells.forEach((cell) => {
            cell.id = id;
            id++;
          });
        }
        function isCellInUse(deletedCellId) {
          return $ctrl.cells.some((cell) => {
            if (
              cell.formulaConfig &&
              cell.formulaConfig.formulaOperands.some(
                (operand) =>
                  operand ===
                  flowinglyConstants.formulaOperandPrefix.CELL + deletedCellId
              )
            ) {
              return true;
            }
            return false;
          });
        }
        function formulaCellReplacer(formula, deletedCellId) {
          const pattern = new RegExp(
            flowinglyConstants.formulaOperandPrefix.CELL + '(\\d+)',
            'g'
          );
          return formula.replace(pattern, (match, p1) => {
            const cellId = Number(p1);
            if (cellId > deletedCellId) {
              return (
                flowinglyConstants.formulaOperandPrefix.CELL + (cellId - 1)
              );
            }
            return match;
          });
        }
        function updateCellFormulas(deletedCellId) {
          for (const cell of $ctrl.cells) {
            if (cell.formulaConfig) {
              cell.formulaConfig.formula = formulaCellReplacer(
                cell.formulaConfig.formula,
                deletedCellId
              );
              cell.formulaConfig.formulaOperands =
                cell.formulaConfig.formulaOperands.map((operand) => {
                  return formulaCellReplacer(operand, deletedCellId);
                });
            }
          }
        }
      }
    ]
  });
})();
