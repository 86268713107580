import { Services } from '@Shared.Angular/@types/services';
import * as angular from 'angular';
export const EMPTY = { name: 'Please Choose...' };
export const EMPTY_PREVIOUS = { text: 'Please Choose...' };

export interface IOperationOption {
  value?: string;
  text?: string;
}

export const TEXT_OPERATION_OPTIONS: IOperationOption[] = [
  { value: null, text: EMPTY.name },
  { value: '=', text: 'Equals' },
  { value: '<>', text: 'Not Equals' }
];

export const NUMBER_OPERATION_OPTIONS: IOperationOption[] = [
  { value: null, text: EMPTY.name },
  { value: '=', text: 'Equals' },
  { value: '<>', text: 'Not Equals' },
  { value: '<', text: 'Less' },
  { value: '>', text: 'More' },
  { value: '<=', text: 'Less or Equal' },
  { value: '>=', text: 'More or Equal' }
];

// @TODO Remove at FLOW-4842
export interface ProxyInterface {
  name?: any;
  operation?: IOperationOption;
  value?: any;
}

export function getActualValue(str: string) {
  return str != EMPTY.name ? str : null;
}

export class CustomDatabaseConfigController implements angular.IController {
  public static $inject = [
    '$http',
    '$timeout',
    'APP_CONFIG',
    'customdbService',
    'lodashService',
    '$q',
    'flowinglyConstants',
    'brandingService'
  ];

  // from bindings
  private field: any;
  private allFields: any;
  private onFieldChange: any;
  private decisionHelpUri: string;

  public constructor(
    private $http,
    private $timeout,
    private APP_CONFIG: Services.APP_CONFIG,
    private customdbService: Services.CustomdbService,
    private lodash,
    private $q,
    private flowinglyConstants: Services.FlowinglyConstants,
    private brandingService
  ) {
    this.databasesApiBaseUrl = this.APP_CONFIG.apiBaseUrl + 'customdatabase';
    this.moveProxyToNgModel = this.moveProxyToNgModel.bind(this);
    this.moveNgModelToProxy = this.moveNgModelToProxy.bind(this);
    this.tableColumnsNameConstants = this.flowinglyConstants.tableColumnsName;
    this.decisionHelpUri = this.brandingService.getBrandedHelpUri(
      'https://help.flowingly.net/en/articles/2056865-decisions'
    );
  }

  private allDbs = [];
  private displayValueOptions = [];
  private valueOptions = [];
  private operationOptions = NUMBER_OPERATION_OPTIONS;
  private databasesApiBaseUrl: string;
  private copyOfField: any;
  private isReady: boolean;
  private shouldShowFilters = true; // used to "trick the UI" to perform a a rerender since theres an issue with the var swapping
  private tableColumnsNameConstants;

  private proxy: ProxyInterface = this.getEmptyProxy();

  private getEmptyProxy(): ProxyInterface {
    return { name: EMPTY, operation: {}, value: {} };
  }

  public moveProxyToNgModel() {
    if (this.proxy.name) {
      const col = this.proxy.name.name;

      this.field.dbDataSource.filters[0].column = getActualValue(col);
      this.onFilterColumnChange();
    }

    if (this.proxy.operation) {
      this.field.dbDataSource.filters[0].operation = getActualValue(
        this.proxy.operation.value
      );
    }

    if (this.proxy.value) {
      this.field.dbDataSource.filters[0].value = getActualValue(
        this.proxy.value.name
      );
    }

    this.submitFieldChange();
  }

  /**
   *  At the moment, our usage of kendo is match by exact reference, which is why we are fetching the
   *  actual object first, before assigning it to the proxy. Complicated yes, but theres a reason why
   *  it's being done this way.
   */
  public moveNgModelToProxy() {
    const colName = this.lodash.get(
      this.field,
      'dbDataSource.filters[0].column',
      EMPTY.name
    );
    const colData = (this.displayValueOptions as any).find(
      (v) => v.name === colName
    );
    const colType = this.lodash.get(colData, 'dataType');

    // move to proxy.name
    {
      this.proxy.name = colData == null ? EMPTY : colData;
    }

    // move to proxy.operation
    {
      const operation = this.lodash.get(
        this.field,
        'dbDataSource.filters[0].operation'
      );
      const operations =
        colType == 'text' ? TEXT_OPERATION_OPTIONS : NUMBER_OPERATION_OPTIONS;
      this.proxy.operation = (operations as any).find(
        (o) => o.value == operation
      );
    }

    //move to proxy.value
    {
      const valName = this.lodash.get(
        this.field,
        'dbDataSource.filters[0].value'
      );
      this.proxy.value = (this.valueOptions as any).find(
        (v) => v.name == valName
      );
    }
  }

  public onFilterColumnChange(operation?) {
    if (this.field.dbDataSource.filters[0].column) {
      const matchColumn = (this.displayValueOptions as any).find(
        (v) => v.name === this.field.dbDataSource.filters[0].column
      );
      if (
        matchColumn &&
        (matchColumn.dataType == 'currency' || matchColumn.dataType == 'number')
      ) {
        this.valueOptions = this.fetchValueOptions(matchColumn.dataType);
        this.operationOptions = NUMBER_OPERATION_OPTIONS;
      } else if (
        matchColumn &&
        (matchColumn.dataType == 'text' ||
          matchColumn.dataType == 'user' ||
          matchColumn.dataType == 'email')
      ) {
        this.valueOptions = this.fetchValueOptions();
        this.operationOptions = TEXT_OPERATION_OPTIONS;
      }

      if (operation) {
        this.$timeout(() => {
          this.field.dbDataSource.filters[0].operation = operation;
        });
      }

      this.submitFieldChange();
    }
  }

  public submitFieldChange() {
    if (this.field) {
      if (typeof this.field.dbDataSource.displayValue === 'undefined') {
        this.field.dbDataSource.displayValue = [EMPTY.name];
      }
      this.onFieldChange({ cell: this.field });
    }
  }

  public clearFilters() {
    if (typeof this.field.dbDataSource.filters !== 'undefined') {
      this.field.dbDataSource.filters[0].column = undefined;
      this.field.dbDataSource.filters[0].operation = undefined;
      this.field.dbDataSource.filters[0].value = undefined;
    }

    this.proxy = this.getEmptyProxy();
    this.moveNgModelToProxy();

    this.rerenderKendoComponentsHackFLOW4826();

    this.submitFieldChange();
  }

  public onDbNameChange() {
    // first time handler
    let isDuringInit = false;
    if (this.field.dbDataSource.dbName === undefined) {
      this.field.dbDataSource.dbName =
        this.copyOfField.dbDataSource && this.copyOfField.dbDataSource.dbName;
      isDuringInit = true;
    }

    // reset values
    this.displayValueOptions = [EMPTY];

    let promise;

    this.shouldShowFilters = false;
    if (
      this.field.dbDataSource.dbName &&
      this.field.dbDataSource.dbName !== ''
    ) {
      promise = this.$http
        .get(
          `${this.databasesApiBaseUrl}/columns/${this.field.dbDataSource.dbName}`,
          { noSpinner: true }
        )
        .then((response) => {
          const idColumnIndex = response.data.findIndex((d) => d.name === 'Id');
          const customIdColumnIndex = response.data.findIndex(
            (d) => d.name === 'customdatabaseid'
          );

          if (idColumnIndex >= 0 && customIdColumnIndex < 0) {
            response.data.splice(idColumnIndex, 1);
          } else if (customIdColumnIndex >= 0) {
            response.data.splice(customIdColumnIndex, 1);
          }

          this.displayValueOptions = response.data;
          this.displayValueOptions.unshift(EMPTY);
          this.field.dbDataSource.displayValueOptions = [];
          angular.copy(
            this.displayValueOptions,
            this.field.dbDataSource.displayValueOptions
          );

          if (isDuringInit) {
            this.field.dbDataSource.displayValue =
              this.copyOfField.dbDataSource.displayValue;
            this.field.dbDataSource.filters[0].column =
              this.copyOfField.dbDataSource.filters[0].column;
            this.onFilterColumnChange(
              this.field.dbDataSource.filters[0].operation
            );
          }
        });
    } else {
      promise = this.$q.resolve();
    }

    return promise.then(() => {
      // submit changes
      this.moveNgModelToProxy();
      this.submitFieldChange();

      this.shouldShowFilters = true;
    });
  }

  public $onInit() {
    this.isReady = false;
    this.copyOfField = angular.copy(this.field, {});

    if (this.field.dbDataSource === undefined) {
      this.field.dbDataSource = {
        dbName: '',
        displayValue: '',
        filters: [{}]
      };
      this.submitFieldChange();
    }

    this.$http
      .get(this.databasesApiBaseUrl + '?fromModeler=true', {
        noSpinner: true
      })
      .then((response) => {
        this.allDbs = [{ label: EMPTY.name, name: '' }];

        // remap for Kendo Usage
        for (let db of response.data) {
          db.label = db.name;
          this.allDbs.push(db);
        }

        return this.onDbNameChange();
      })
      .finally(() => {
        this.isReady = true;

        this.rerenderKendoComponentsHackFLOW4826();
      });

    // work out the valueOptions for filters which are short text/number/dropdown list/option list/currency/email fields before current field
    this.valueOptions = this.fetchValueOptions();
  }

  private rerenderKendoComponentsHackFLOW4826() {
    // below is a hack to show the "Please Choose..." onsome Kendo dropdowns
    // yes, we are triggering the $digest cycle twice... FML.
    // @TODO Remove at FLOW-4842
    //                                  - Cassey
    this.$timeout(() => {
      this.shouldShowFilters = false;
      this.$timeout(() => {
        this.shouldShowFilters = true;
      }, 1);
    }, 1);
  }

  private fetchValueOptions(dataType?: any) {
    const valueOptions = this.customdbService.populateValueOptions(
      { allFields: this.allFields },
      this.field,
      dataType
    );
    const placeholder =
      valueOptions.length == 0 ? EMPTY_PREVIOUS : { text: EMPTY.name };
    valueOptions.unshift(placeholder);
    return valueOptions;
  }
}

export class CustomDatabaseConfigComponent
  implements angular.IComponentOptions
{
  public templateUrl = 'customdatabase.config.component.tmpl.html';
  public bindings = {
    field: '=',
    allFields: '<',
    onFieldChange: '&'
  };
  public controller = CustomDatabaseConfigController;
}

angular
  .module('flowingly.components')
  .component('customdatabaseConfig', new CustomDatabaseConfigComponent());
