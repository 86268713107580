/**
 * @ngdoc service
 * @name goService
 * @module flowingly.adapters
 *
 * @description Wraps the global ExagoAPI Object.
 *
 * ## Notes
 * Go is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global go object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes go has already been loaded on the page.
 */

import angular, { IWindowService } from 'angular';

/**
 * ExagoApi comes from exago script https://stagingbi.flowingly.net/Exago/WrScriptResource.axd?s=ExagoApi
 * Tell typescript function ExagoApi exists
 * */
declare function ExagoApi(
  exagoBaseURL: string,
  apiKey: string,
  onLoadCallback: () => void,
  showErrorDetail?: boolean
): void;

function exagoJsApiResource($window: IWindowService) {
  function getActiveSession() {
    return $window.ExagoActiveSession;
  }

  function createInstance(appUrl: string, apiKey: string) {
    if (ExagoApi) {
      $window.ExagoActiveSession = new ExagoApi(appUrl, apiKey, onLoadCallback);

      // Note: this was previously defined in exago.report.component but
      // it doesnt make sense to define this every single time you instantiate
      // the component, so I've moved it here for now -Cassey
      $window.ExagoActiveSession.OnDisposeContainer = function resetContainer(
        container
      ) {
        $(container).empty();
      };
    } else console.error('Exago API resource is missing.');
  }

  function onLoadCallback() {
    console.log('Exago is ready');
    $window.isExagoReady = true;
  }

  const retValue = {
    CreateInstance: createInstance,
    GetActiveSession: getActiveSession,
    OnLoadCallback: onLoadCallback
  };
  return retValue;
}
angular
  .module('flowingly.adapters')
  .factory('exagoJsApiResource', ['$window', exagoJsApiResource]);

export type ExagoJsApiResourceType = ReturnType<typeof exagoJsApiResource>;
