/**
 * @ngdoc service
 * @name smartSelectService
 * @module flowingly.components
 *
 * @description A service responsible solely for smart select component
 */

angular
  .module('flowingly.components')
  .factory('smartSelectService', smartSelectService);

smartSelectService.$inject = ['$http', 'APP_CONFIG', 'flowinglyConstants'];

function smartSelectService($http, APP_CONFIG, flowinglyConstants) {
  let hasMoreResults = false;

  function getUsers(searchTerm) {
    return $http.get(
      APP_CONFIG.apiBaseUrl +
        `users/search?maxNumberOfUsersToShow=${
          flowinglyConstants.maxNumberOfUsersToShow
        }${searchTerm ? '&searchTerm=' + searchTerm : ''}`
    );
  }

  function getGroups() {
    return $http.get(APP_CONFIG.apiBaseUrl + 'teams?includeDetails=false');
  }

  function getAvailableApprovers(filter, searchTerm, noSpinner) {
    return $http.get(
      `${
        APP_CONFIG.apiBaseUrl
      }flows/${filter}/availableStepApprovers?maxNumberOfUsersToShow=${
        flowinglyConstants.maxNumberOfUsersToShow
      }${searchTerm ? '&searchTerm=' + searchTerm : ''}`,
      { noSpinner: noSpinner || false }
    );
  }

  function getHasMoreResults() {
    return hasMoreResults;
  }

  function setHasMoreResults(val) {
    hasMoreResults = val;
  }
  const service = {
    getUsers: getUsers,
    getGroups: getGroups,
    getAvailableApprovers: getAvailableApprovers,
    getHasMoreResults: getHasMoreResults,
    setHasMoreResults: setHasMoreResults
  };

  return service;
}
