import { ICurrencyRecord } from '../../interfaces/ICurrencyRecord';

/**
 * Fetched from https://datahub.io/core/currency-codes#data which is ISO 4217 compliant.
 * The JSON was last fetched and updated on 24/10/2019. If you need to update the JSON
 * download the JSON file from that website and run this code against it.
 *
 * Example:
 *      var dataset = {PASTE_JSON_HERE}
 *      var newJSON = JSON.stringify(
 *              dataset
 *                   // exclude defunc curencies
 *                  .filter({WithdrawalDate} => WithdrawalDate == null)
 *                   // symbol is not inhernityl supported by ISO 4217 so we fetch it later
 *                  .map(({ AlphabeticCode: code, Currency: name }) => ({ code, name, symbol:"" }))
 *          , null);
 *
 * Or if you want to do it VIA CLI you may also do so. Instructions are here.
 * https://datahub.io/core/currency-codes#curl.
 *
 * NOTE: That data set does not include the SYMBOLS which is not part of ISO 4217. The symbols
 * are then fetched from https://www.xe.com/symbols.php.
 *
 * As to how I did it, i opened the console of
 * and ran the following script against https://www.xe.com/symbols.php
 *
 * Example:
 *          var currencyMap = {};
 *          $(".currencySymblTable tr")
 *              .not(".currencySymblTableSubTitle")
 *              .each((i,tr) => {
 *                  const $tds = $(tr).children();
 *                  const $fontTd = $tds.eq(4);
 *                  const $codeTd = $tds.eq(1);
 *                  currencyMap[$codeTd.html()] = $fontTd.html();
 *              });
 *
 * And combined both
 */

export const CURRENCIES: ICurrencyRecord[] = [
  {
    code: 'AFN',
    name: 'Afghani',
    symbol: '؋'
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€'
  },
  {
    code: 'ALL',
    name: 'Lek',
    symbol: 'Lek'
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    symbol: ''
  },
  {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$'
  },
  {
    code: 'AOA',
    name: 'Kwanza',
    symbol: ''
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    symbol: '$'
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    symbol: '$'
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    symbol: ''
  },
  {
    code: 'AWG',
    name: 'Aruban Florin',
    symbol: 'ƒ'
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    symbol: '$'
  },
  {
    code: 'AZN',
    name: 'Azerbaijanian Manat',
    symbol: '₼'
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
    symbol: '$'
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    symbol: ''
  },
  {
    code: 'BDT',
    name: 'Taka',
    symbol: ''
  },
  {
    code: 'BBD',
    name: 'Barbados Dollar',
    symbol: '$'
  },
  {
    code: 'BYN',
    name: 'Belarusian Ruble',
    symbol: 'Br'
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    symbol: 'BZ$'
  },
  {
    code: 'XOF',
    name: 'CFA Franc BCEAO',
    symbol: ''
  },
  {
    code: 'BMD',
    name: 'Bermudian Dollar',
    symbol: '$'
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    symbol: ''
  },
  {
    code: 'BTN',
    name: 'Ngultrum',
    symbol: ''
  },
  {
    code: 'BOB',
    name: 'Boliviano',
    symbol: '$b'
  },
  {
    code: 'BOV',
    name: 'Mvdol',
    symbol: ''
  },
  {
    code: 'BAM',
    name: 'Convertible Mark',
    symbol: 'KM'
  },
  {
    code: 'BWP',
    name: 'Pula',
    symbol: 'P'
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    symbol: 'kr'
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    symbol: 'R$'
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    symbol: '$'
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    symbol: 'лв'
  },
  {
    code: 'BIF',
    name: 'Burundi Franc',
    symbol: ''
  },
  {
    code: 'CVE',
    name: 'Cabo Verde Escudo',
    symbol: ''
  },
  {
    code: 'KHR',
    name: 'Riel',
    symbol: '៛'
  },
  {
    code: 'XAF',
    name: 'CFA Franc BEAC',
    symbol: ''
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    symbol: '$'
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
    symbol: '$'
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    symbol: '$'
  },
  {
    code: 'CLF',
    name: 'Unidad de Fomento',
    symbol: ''
  },
  {
    code: 'CNY',
    name: 'Yuan Renminbi',
    symbol: '¥'
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    symbol: '$'
  },
  {
    code: 'COU',
    name: 'Unidad de Valor Real',
    symbol: ''
  },
  {
    code: 'KMF',
    name: 'Comoro Franc',
    symbol: ''
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
    symbol: ''
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    symbol: '$'
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colon',
    symbol: '₡'
  },
  {
    code: 'HRK',
    name: 'Kuna',
    symbol: 'kn'
  },
  {
    code: 'CUP',
    name: 'Cuban Peso',
    symbol: '₱'
  },
  {
    code: 'CUC',
    name: 'Peso Convertible',
    symbol: ''
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    symbol: 'ƒ'
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    symbol: 'Kč'
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    symbol: 'kr'
  },
  {
    code: 'DJF',
    name: 'Djibouti Franc',
    symbol: ''
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    symbol: 'RD$'
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    symbol: '£'
  },
  {
    code: 'SVC',
    name: 'El Salvador Colon',
    symbol: '$'
  },
  {
    code: 'ERN',
    name: 'Nakfa',
    symbol: ''
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
    symbol: ''
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
    symbol: '£'
  },
  {
    code: 'FJD',
    name: 'Fiji Dollar',
    symbol: '$'
  },
  {
    code: 'XPF',
    name: 'CFP Franc',
    symbol: ''
  },
  {
    code: 'GMD',
    name: 'Dalasi',
    symbol: ''
  },
  {
    code: 'GEL',
    name: 'Lari',
    symbol: ''
  },
  {
    code: 'GHS',
    name: 'Ghana Cedi',
    symbol: '¢'
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
    symbol: '£'
  },
  {
    code: 'GTQ',
    name: 'Quetzal',
    symbol: 'Q'
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    symbol: '£'
  },
  {
    code: 'GNF',
    name: 'Guinea Franc',
    symbol: ''
  },
  {
    code: 'GYD',
    name: 'Guyana Dollar',
    symbol: '$'
  },
  {
    code: 'HTG',
    name: 'Gourde',
    symbol: ''
  },
  {
    code: 'HNL',
    name: 'Lempira',
    symbol: 'L'
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    symbol: '$'
  },
  {
    code: 'HUF',
    name: 'Forint',
    symbol: 'Ft'
  },
  {
    code: 'ISK',
    name: 'Iceland Krona',
    symbol: 'kr'
  },
  {
    code: 'IDR',
    name: 'Rupiah',
    symbol: 'Rp'
  },
  {
    code: 'XDR',
    name: 'SDR (Special Drawing Right)',
    symbol: ''
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
    symbol: '﷼'
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    symbol: ''
  },
  {
    code: 'ILS',
    name: 'New Israeli Sheqel',
    symbol: '₪'
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    symbol: 'J$'
  },
  {
    code: 'JPY',
    name: 'Yen',
    symbol: '¥'
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    symbol: ''
  },
  {
    code: 'KZT',
    name: 'Tenge',
    symbol: 'лв'
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    symbol: ''
  },
  {
    code: 'KPW',
    name: 'North Korean Won',
    symbol: '₩'
  },
  {
    code: 'KRW',
    name: 'Won',
    symbol: '₩'
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    symbol: ''
  },
  {
    code: 'KGS',
    name: 'Som',
    symbol: 'лв'
  },
  {
    code: 'LAK',
    name: 'Kip',
    symbol: '₭'
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    symbol: '£'
  },
  {
    code: 'LSL',
    name: 'Loti',
    symbol: ''
  },
  {
    code: 'ZAR',
    name: 'Rand',
    symbol: 'R'
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
    symbol: '$'
  },
  {
    code: 'LYD',
    name: 'Libyan Dinar',
    symbol: ''
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    symbol: 'CHF'
  },
  {
    code: 'MOP',
    name: 'Pataca',
    symbol: ''
  },
  {
    code: 'MKD',
    name: 'Denar',
    symbol: 'ден'
  },
  {
    code: 'MGA',
    name: 'Malagasy Ariary',
    symbol: ''
  },
  {
    code: 'MWK',
    name: 'Malawi Kwacha',
    symbol: ''
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    symbol: 'RM'
  },
  {
    code: 'MVR',
    name: 'Rufiyaa',
    symbol: ''
  },
  {
    code: 'MRO',
    name: 'Ouguiya',
    symbol: ''
  },
  {
    code: 'MUR',
    name: 'Mauritius Rupee',
    symbol: '₨'
  },
  {
    code: 'XUA',
    name: 'ADB Unit of Account',
    symbol: ''
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    symbol: '$'
  },
  {
    code: 'MXV',
    name: 'Mexican Unidad de Inversion (UDI)',
    symbol: ''
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    symbol: ''
  },
  {
    code: 'MNT',
    name: 'Tugrik',
    symbol: '₮'
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    symbol: ''
  },
  {
    code: 'MZN',
    name: 'Mozambique Metical',
    symbol: 'MT'
  },
  {
    code: 'MMK',
    name: 'Kyat',
    symbol: ''
  },
  {
    code: 'NAD',
    name: 'Namibia Dollar',
    symbol: '$'
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
    symbol: '₨'
  },
  {
    code: 'NIO',
    name: 'Cordoba Oro',
    symbol: 'C$'
  },
  {
    code: 'NGN',
    name: 'Naira',
    symbol: '₦'
  },
  {
    code: 'OMR',
    name: 'Rial Omani',
    symbol: '﷼'
  },
  {
    code: 'PKR',
    name: 'Pakistan Rupee',
    symbol: '₨'
  },
  {
    code: 'PAB',
    name: 'Balboa',
    symbol: 'B/․'
  },
  {
    code: 'PGK',
    name: 'Kina',
    symbol: ''
  },
  {
    code: 'PYG',
    name: 'Guarani',
    symbol: 'Gs'
  },
  {
    code: 'PEN',
    name: 'Sol',
    symbol: 'S/․'
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    symbol: '₱'
  },
  {
    code: 'PLN',
    name: 'Zloty',
    symbol: 'zł'
  },
  {
    code: 'QAR',
    name: 'Qatari Rial',
    symbol: '﷼'
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    symbol: 'lei'
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    symbol: '₽'
  },
  {
    code: 'RWF',
    name: 'Rwanda Franc',
    symbol: ''
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
    symbol: '£'
  },
  {
    code: 'WST',
    name: 'Tala',
    symbol: ''
  },
  {
    code: 'STD',
    name: 'Dobra',
    symbol: ''
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    symbol: '﷼'
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    symbol: 'Дин․'
  },
  {
    code: 'SCR',
    name: 'Seychelles Rupee',
    symbol: '₨'
  },
  {
    code: 'SLL',
    name: 'Leone',
    symbol: ''
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    symbol: '$'
  },
  {
    code: 'XSU',
    name: 'Sucre',
    symbol: ''
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
    symbol: '$'
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
    symbol: 'S'
  },
  {
    code: 'SSP',
    name: 'South Sudanese Pound',
    symbol: ''
  },
  {
    code: 'LKR',
    name: 'Sri Lanka Rupee',
    symbol: '₨'
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
    symbol: ''
  },
  {
    code: 'SRD',
    name: 'Surinam Dollar',
    symbol: '$'
  },
  {
    code: 'SZL',
    name: 'Lilangeni',
    symbol: ''
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    symbol: 'kr'
  },
  {
    code: 'CHE',
    name: 'WIR Euro',
    symbol: ''
  },
  {
    code: 'CHW',
    name: 'WIR Franc',
    symbol: ''
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
    symbol: '£'
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    symbol: 'NT$'
  },
  {
    code: 'TJS',
    name: 'Somoni',
    symbol: ''
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    symbol: ''
  },
  {
    code: 'THB',
    name: 'Baht',
    symbol: '฿'
  },
  {
    code: 'TOP',
    name: 'Pa’anga',
    symbol: ''
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    symbol: 'TT$'
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    symbol: ''
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    symbol: ''
  },
  {
    code: 'TMT',
    name: 'Turkmenistan New Manat',
    symbol: ''
  },
  {
    code: 'UGX',
    name: 'Uganda Shilling',
    symbol: ''
  },
  {
    code: 'UAH',
    name: 'Hryvnia',
    symbol: '₴'
  },
  {
    code: 'AED',
    name: 'UAE Dirham',
    symbol: ''
  },
  {
    code: 'USN',
    name: 'US Dollar (Next day)',
    symbol: ''
  },
  {
    code: 'UYU',
    name: 'Peso Uruguayo',
    symbol: '$U'
  },
  {
    code: 'UYI',
    name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
    symbol: ''
  },
  {
    code: 'UZS',
    name: 'Uzbekistan Sum',
    symbol: 'лв'
  },
  {
    code: 'VUV',
    name: 'Vatu',
    symbol: ''
  },
  {
    code: 'VEF',
    name: 'Bolívar',
    symbol: 'Bs'
  },
  {
    code: 'VND',
    name: 'Dong',
    symbol: '₫'
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    symbol: '﷼'
  },
  {
    code: 'ZMW',
    name: 'Zambian Kwacha',
    symbol: ''
  },
  {
    code: 'ZWL',
    name: 'Zimbabwe Dollar',
    symbol: ''
  },
  {
    code: 'XBA',
    name: 'Bond Markets Unit European Composite Unit (EURCO)',
    symbol: ''
  },
  {
    code: 'XBB',
    name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
    symbol: ''
  },
  {
    code: 'XBC',
    name: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
    symbol: ''
  },
  {
    code: 'XBD',
    name: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
    symbol: ''
  },
  {
    code: 'XTS',
    name: 'Codes specifically reserved for testing purposes',
    symbol: ''
  },
  {
    code: 'XXX',
    name: 'The codes assigned for transactions where no currency is involved',
    symbol: ''
  },
  {
    code: 'XAU',
    name: 'Gold',
    symbol: ''
  },
  {
    code: 'XPD',
    name: 'Palladium',
    symbol: ''
  },
  {
    code: 'XPT',
    name: 'Platinum',
    symbol: ''
  },
  {
    code: 'XAG',
    name: 'Silver',
    symbol: ''
  }
];
