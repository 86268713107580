import angular from 'angular';
import { IRule } from './rule.interface';

export class RuleService {
  public DYNAMICACTOR_DYNAMICACTOR: IRule = {
    description: 'Dynamic actor with dynamic actor'
  };

  public DYNAMICACTORAR_DYNAMICACTORAR: IRule = {
    description: 'Dynamic actor approval rule with dynamic actor approval rule'
  };

  public DYNAMICACTOR_PARALLELAPPROVAL: IRule = {
    description: 'Dynamic actor with parallel approval'
  };

  public DYNAMICACTORAR_PARALLELAPPROVAL: IRule = {
    description: 'Dynamic actor approval rule with parallel approval'
  };

  public DYNAMICACTOR_SEQUENTIALAPPROVAL: IRule = {
    description: 'Dynamic actor with sequential approval'
  };

  public DYNAMICACTORAR_SEQUENTIALAPPROVAL: IRule = {
    description: 'Dynamic actor approval rule with sequential approval'
  };

  public SEQUENTIALAPPROVAL_SEQUENTIALAPPROVAL: IRule = {
    description: 'Sequential approval with sequential approval'
  };

  public PARALLELAPPROVAL_PARALLELAPPROVAL: IRule = {
    description: 'Parallel approval with parallel approval'
  };

  public PARALLELAPPROVAL_SEQUENTIALAPPROVAL: IRule = {
    description: 'Parallel approval with sequential approval'
  };

  public EXCLUSIVEGATEWAY_CONVERGEGATEWAY: IRule = {
    description: 'Exclusive gateway connect to converge gateway'
  };

  public DEFAULT: IRule = {
    description: 'Default rule for fallback'
  };
}

angular
  .module('flowingly.modeler.validation')
  .factory('ruleService', [() => new RuleService()]);

export type RuleServiceType = InstanceType<typeof RuleService>;
