import angular from 'angular';

import { IRule } from './rule.interface';
import { IRuleset } from './ruleset.interface';

export class RulesetValidationService {
  validateRuleData(ruleset: IRuleset[], ruleData: [IRule]) {
    let isValid = true;
    let errorMessage = '';

    ruleData.forEach((r) => {
      const matchRule = ruleset.filter((rs) => rs.rule === r);

      if (matchRule.length > 0 && !matchRule[0].allowed) {
        isValid = false;
        errorMessage = r.description;
        return;
      }
    });

    return {
      isValid: isValid,
      errorMessage: errorMessage
    };
  }
}

angular
  .module('flowingly.modeler.validation')
  .factory('rulesetValidationService', [() => new RulesetValidationService()]);

export type RuleSetValidationServiceType = InstanceType<
  typeof RulesetValidationService
>;
