import {
  Auth0Client,
  Auth0ClientOptions,
  createAuth0Client
} from '@auth0/auth0-spa-js';

class AngularAuth0SpaSdk implements angular.IServiceProvider {
  private config: Auth0ClientOptions = {
    clientId: null,
    domain: null,
    authorizationParams: {}
  };
  private client: Auth0Client = null;

  init(config: Auth0ClientOptions): void {
    this.config = { ...this.config, ...config };
  }

  async getClient(): Promise<Auth0Client> {
    if (!this.client) {
      this.client = await createAuth0Client(this.config);
    }
    return this.client;
  }

  $get = [
    '$q',
    ($q) => {
      const getClient = this.getClient.bind(this);
      return {
        getClient: () => $q.when(getClient())
      };
    }
  ];
}

angular
  .module('flowingly.services')
  .provider('angularAuth0SpaSdk', AngularAuth0SpaSdk);

export type AngularAuth0SpaSdkType = InstanceType<typeof AngularAuth0SpaSdk>;
