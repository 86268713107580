/**
 * @name flowSamePassword
 * @module flowingly.directives
 * @description Custom validator based on expressions.
 * ### Notes
 * see: https://docs.angularjs.org/guide/forms
 *       https://wijmo.com/blog/easy-form-validation-in-angularjs/
 *
 * @usage
 * ```
 *   <input type="password" ng-model="pwd1" >
 *   <input type="password" ng-model="pwd2" >
 *   <flow-custom-validation ng-model="pwd1" is-valid="pwd1 === pwd2" message="passwordsMustMatch"/>
 *   <div ng-messages="form.$error">
 *     <div ng-message="passwordsMustMatch">Passwords don't match.</div>
 *   </div>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.directives/custom.validation.directive.js?at=master
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.directives')
  .directive('flowCustomValidation', function () {
    return {
      require: 'ngModel',
      link: link,
      restrict: 'E'
    };

    function link(
      scope: angular.IScope,
      elm: JQuery,
      attrs: angular.IAttributes,
      ctl: angular.IController
    ) {
      scope.$watch(attrs.isValid, function (isValid) {
        ctl.$setValidity(attrs.message, isValid);
      });
    }
  });
