import { IHttpPromiseCallbackArg } from 'angular';
import { getInjector } from './serviceInjector';

/**
 * fetcher for useSWR hook
 * angularjs $http has type of IPromise which is not a real promise
 * Wrap $http inside a promise to make easier to work with typings in swr
 * @param url
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher = <T, E>(url: string, options?: any): Promise<T> => {
  const $http = getInjector().get('$http');
  return new Promise((resolve, reject) => {
    // backend always return data field so ok to do type assertion here to avoid typescript TS 2345 error
    const sucessCb = (res: IHttpPromiseCallbackArg<T>) => {
      const data = res.data as IResponseData<T>;
      resolve(data.dataModel);
    };

    const errorCb = (err: E) => reject(err);
    $http.get<T>(url, options).then(sucessCb, errorCb);
  });
};

/**
 * fetcher for useSWR hook. Do not show the giant orange spinner
 */
export const fetcherNoSpinner = <T>(url: string): Promise<T> =>
  fetcher(url, { noSpinner: true });
