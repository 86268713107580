/**
 * @ngdoc component
 * @name commentHistory
 * @module flowingly.components
 * @description This is a component for displaying the comment history
 *
 * Input attributes
 * comment - the comment object
 * isFirstComment - if it's first comment
 * isLastComment - if it's last comment
 * isMobile - is that mobile view?
 *
 * @usage
 * ```
 * <comment-history comment="comment" is-first-comment="$index === 0" is-last-comment="$index === $ctrl.commentList.length - 1" is-mobile="$ctrl.isMobile"></comment-history>
 * ```
 */

import * as angular from 'angular';

export class CommentHistoryComponent implements ng.IComponentOptions {
  public bindings: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      comment: '<',
      isFirstComment: '<',
      isLastComment: '<',
      isMobile: '<'
    };

    this.templateUrl = 'comment-history.tmpl.html';
  }
}

angular
  .module('flowingly.components')
  .component('commentHistory', new CommentHistoryComponent());
