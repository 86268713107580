/**
 * @name flowSamePassword
 * @module flowingly.directives
 * @description A directive with the purpose to validate a password input based on the value of another input.
 * ### Notes
 * When both input values are the same the inputs will be set to valid
 * @usage
 * ```
    <form name="signup">
        <input name="password" type="password" ng-model="user.password" same-password="signup.confirm" />
        <input name="confirm" type="password" ng-model="user.confirm" same-password="signup.confirm" />
    </form>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/same.password.directive.js?at=master
 */

'use strict';
import angular, { IAttributes, INgModelController, IScope } from 'angular';
angular
  .module('flowingly.directives')
  .directive('flowSamePassword', samePassword);

function samePassword() {
  const directive = {
    restrict: 'A',
    require: 'ngModel',
    link: link,
    scope: {
      flowSamePassword: '='
    }
  };
  return directive;

  function link(
    scope: IScope,
    element: JQuery<HTMLElement>,
    attrs: IAttributes,
    ngModel: INgModelController
  ) {
    ngModel.$viewChangeListeners.push(function () {
      const isSame =
        scope.flowSamePassword.$modelValue === ngModel.$modelValue ||
        ((scope.flowSamePassword.$modelValue === undefined ||
          scope.flowSamePassword.$modelValue === null ||
          scope.flowSamePassword.$modelValue === '') &&
          (ngModel.$modelValue === undefined ||
            ngModel.$modelValue === null ||
            ngModel.$modelValue === ''));

      ngModel.$setValidity('samePassword', isSame);
      scope.flowSamePassword.$setValidity('samePassword', isSame);
    });
  }
}
