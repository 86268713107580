/**
 * @ngdoc directive
 * @name goBpmnOverview
 * @module flowingly.modeler.directives
 * @description  A directive that represents a GoJS overview.
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/deaa3e49cef09006e53d6351564e8e4ba65c1f62/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.overview.directive.js?at=master
 */

'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';

angular.module('flowingly.bpmn.modeler').directive('goBpmnOverview', [
  'goService',
  'BpmnDiagramService',
  function (
    goService: GoJS,
    BpmnDiagramService: BpmnModeler.BpmnDiagramService
  ) {
    return {
      restrict: 'E',
      template: '<div></div>',
      replace: true,
      scope: { diagram: '&goDiagram' },
      link: function (scope, element, attrs) {
        const $GO = goService.GraphObject.make;

        //Get the GoJS diagram that is displaying the main diagram
        const diagram = BpmnDiagramService.getDiagram('main');

        //------------------------------------------the overview Diagram----------------------------------------------

        if (diagram != undefined) {
          $GO(goService.Overview, element[0], { observed: diagram });
        }

        //------------------------------------------end Diagram-------------------------------------------------------
      }
    };
  }
]);
