/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f8482de0f2555cd429164fa4e75defc2af1d0444/src/Flowingly.Shared.Angular/flowingly.directives/filesInput.directive.js?at=master
 */

'use strict';
import angular, { IScope, IAttributes, INgModelController } from 'angular';

angular
  .module('flowingly.directives')
  .directive('flowinglyFilesInput', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function postLink(
        scope: IScope,
        elem: JQuery,
        attr: IAttributes,
        ngModel: INgModelController
      ) {
        elem.on('change', function () {
          const files = (elem[0] as HTMLInputElement).files;
          ngModel.$setViewValue(files);
        });
      }
    };
  });
