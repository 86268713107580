import angular, { IPromise } from 'angular';
import { Services } from '@Shared.Angular/@types/services';
import { BusyLightType } from './busylight.service';

// Currently only intended for use by runner.flow.form.component
// If needed for use in other places should be extended to maintain a set of BusyLights
export default class BusyService {
  private busy: BusyLightType;

  constructor(private busyLight: Services.BusyLightFactory) {
    this.reset();
  }

  public addPromise(promise: IPromise<unknown>) {
    this.busy.addPromise(promise);
  }

  public get isBusy() {
    return this.busy.isBusy;
  }

  public reset() {
    this.busy = new this.busyLight();
  }
}

angular
  .module('flowingly.services')
  .factory('busyService', [
    'BusyLight',
    (BusyLight: Services.BusyLightFactory) => new BusyService(BusyLight)
  ]);

export type BusyServiceType = InstanceType<typeof BusyService>;
