/**
 * @ngdoc service
 * @name lodashService
 * @module flowingly.adapters
 *
 * @description Wraps the global Materialize Object.
 *
 * ## Notes
 * Materialize is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global Materialize object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes Materialize has already been loaded on the page.
 */
(function () {
  'use strict';

  angular.module('flowingly.adapters').factory('materializeService', [
    '$window',
    function ($window) {
      if ($window.Materialize == undefined) {
        console.error('Materialize is not loaded');
      }
      return $window.Materialize;
    }
  ]);
})();
