/**
 * @ngdoc service
 * @name dialogService
 * @module flowingly.services
 *
 * @description A service for displaying modal dialogs
 *
 * ## Notes
 * This service wraps ngDialog. The idea being to encapsulate the usage of ngDialog, so we can easily switch it out for another
 * dialog service in teh future if we decide to.
 * https://github.com/likeastore/ngDialog
 *
 * ###Usage
 *  dialogService.showMessageDialog({
 *    message: 'A message',
 *    headerText: 'Header text'
 *  });
 */

import { Services } from '@Shared.Angular/@types/services';
import angular, { IQService, ITimeoutService, dialog } from 'angular';
import { IDialogOpenOptions } from 'ng-dialog';

angular.module('flowingly.services').factory('dialogService', dialogService);

export interface IConfirmDialogOptions {
  title?: string;
  message?: string;
  className?: string;
  template?: string;
  confirmationWord?: string;
  actionButtonClass?: string;
  disabledSeconds?: number;
}

dialogService.$inject = [
  '$window',
  'ngDialog',
  'flowStore',
  'sessionService',
  '$q'
];

function dialogService(
  $window: angular.IWindowService,
  ngDialog: dialog.IDialogService,
  flowStore,
  sessionService: Services.SessionService,
  $q: IQService
) {
  //API
  const disableAnimation = /MSIE \d|Trident.*rv:/.test(
    $window.navigator.userAgent
  );
  const service = {
    isCloseModalWithCancelAction: isCloseModalWithCancelAction,
    showConfirmDialog: showConfirmDialog,
    showMessageDialog: showMessageDialog,
    showPublishDialog: showPublishDialog,
    showUnPublishDialog: showUnPublishDialog,
    showDialog: showDialog,
    showHelpModal: showHelpModal,
    showCreateCloneEditDialog: showCreateCloneEditDialog,
    showGateOrderChangeModal: showGateOrderChangeModal,
    getOpenDialogIds: getOpenDialogIds
  };

  return service;

  //////////// Public API Methods

  function isCloseModalWithCancelAction(data) {
    //user closed modal by clicking on overlay (or cancel or press Esc key)
    return data === '$document' || data === '$escape' || data === undefined;
  }

  function showConfirmDialog($scope, customOptions: IConfirmDialogOptions) {
    const options: IConfirmDialogOptions = {
      title: 'Please Confirm',
      message: 'Please confirm you wish to continue with this action',
      className: 'ngdialog-theme-plain',
      template: 'confirmDialog.tmpl.html',
      confirmationWord: '',
      actionButtonClass: 'btn-primary',
      disabledSeconds: 0
    };

    //overwrite default options with custom options
    angular.extend(options, customOptions);

    return ngDialog.openConfirm({
      disableAnimation: disableAnimation,
      showClose: false,
      className: options.className,
      template: options.template,
      scope: $scope,
      controller: [
        '$timeout',
        function ($timeout: ITimeoutService) {
          this.options = options;
          this.title = options.title;
          this.message = options.message;
          this.confirmationWord = options.confirmationWord;
          this.actionButtonClass = options.actionButtonClass;
          this.actionEnabled = options.disabledSeconds <= 0;

          this.actionCountdown = () => {
            $timeout(() => {
              this.disabledForSeconds--;
              if (this.disabledForSeconds <= 0) {
                this.actionEnabled = true;
              } else {
                this.actionCountdown();
              }
            }, 1000);
          };

          if (options.disabledSeconds > 0) {
            this.disabledForSeconds = options.disabledSeconds;
            this.actionCountdown();
          }
        }
      ],
      controllerAs: 'ctrl'
    });
  }

  function showDialog(customDialogOptions: IDialogOpenOptions) {
    const defaultDialogOptions = {
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain',
      appendClassName: '',
      showClose: false,
      controllerAs: 'ctrl'
    } as IDialogOpenOptions;

    angular.extend(defaultDialogOptions, customDialogOptions);

    const dialog = ngDialog.open(defaultDialogOptions);

    return dialog.closePromise.then(function (data) {
      return data.value;
    });
  }

  function showMessageDialog(customOptions) {
    const initialOptions = {
      disableAnimation: disableAnimation,
      headerText: 'Confirm dialog',
      message: undefined,
      big: undefined
    };

    //overwrite default options with custom options
    angular.extend(initialOptions, customOptions);

    const className =
      initialOptions.big != undefined
        ? 'ngdialog-theme-plain big-width'
        : 'ngdialog-theme-plain';

    ngDialog.open({
      className: className,
      showClose: false,
      template: 'services.message.dialog.tmpl.html',
      controller: [
        'ngDialog',
        function (ngDialog: dialog.IDialogService) {
          const ctrl = this;

          ctrl.model = initialOptions;
          ctrl.close = close;

          function close() {
            ngDialog.closeAll();
          }
        }
      ],
      controllerAs: 'ctrl'
    });
  }

  function showPublishDialog(
    scope,
    selectedFlow,
    controllerName,
    templatePath,
    isEdit,
    publishType,
    warningParagraphs
  ) {
    /* this dialog can be shown in two modes - validated / published
     *
     *  create - used to publish flows for the first time
     *  edit - used to modify published flows
     */
    scope.isEdit = isEdit;
    scope.publishType = publishType;
    scope.warningParagraphs = warningParagraphs;

    const path = templatePath || 'publishFlowDialog.tmpl.html';
    const publishDialog = ngDialog.open({
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain',
      appendClassName: 'ngdialog-publish ngdialog-normal pad-50',
      showClose: false,
      template: path,
      controller: controllerName, //"publishDialogController",
      controllerAs: 'ctrl',
      scope: scope,
      data: selectedFlow
    });

    return publishDialog.closePromise.then(function (data) {
      return data.value;
    });
  }

  function showUnPublishDialog(scope, flowCount) {
    const unPublishDialog = ngDialog.open({
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain ngdialog-normal pad-top-50',
      showClose: false,
      template: 'unPublishFlowDialog.tmpl.html',
      controller: 'UnPublishWorkflowController',
      controllerAs: 'ctrl',
      scope: scope,
      data: flowCount
    });

    return unPublishDialog.closePromise.then(function (data) {
      return data.value;
    });
  }

  function showHelpModal(scope, force) {
    return ngDialog.openConfirm({
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain  custom-width-50P',
      showClose: false,
      data: { hideDontShow: force },
      template:
        'Client/modeler.workflow/sidepanel/gateway/gateway.helpDialog.html',
      scope: scope
    });
  }

  // mode 0 = create, 1 = clone, 2 = edit
  function showCreateCloneEditDialog(scope, mode, workflow) {
    const dialog = ngDialog.open({
      disableAnimation: disableAnimation,
      className: 'ngdialog-theme-plain',
      showClose: false,
      template:
        'Client/modeler.workflow/dialogs/createCloneEdit.dialog.tmpl.html',
      scope: scope,
      controller: 'createCloneEditDialogController',
      controllerAs: 'ctrl',
      data: { mode: mode, workflow: workflow },
      closeByDocument: false
    });

    return dialog.closePromise.then(function (data) {
      return data.value;
    });
  }

  function showGateOrderChangeModal(scope) {
    let supressConfirmation = false;

    if (
      sessionService.getSessionState('defaultGateMessageShown') != undefined
    ) {
      supressConfirmation = true;
    }

    if (flowStore.get('dontShowAgain.gatewayDefault') != undefined) {
      supressConfirmation = true;
    }

    //supress the confirmation if the user clicked removeAll and they have already confirmed delete
    if (!supressConfirmation) {
      return ngDialog
        .openConfirm({
          disableAnimation: disableAnimation,
          className: 'ngdialog-theme-plain',
          showClose: false,
          template:
            'Client/modeler.workflow/sidepanel/gateway/gateway.confirmDefaultChange.html',
          scope: scope
        })
        .then(
          function (dontShowAgain) {
            if (dontShowAgain) {
              //flag will be namespaced in localStorage e.g. "flow.dontShowAgain.gatewayDropHelp"
              //but it is retrieved WITHOUT namespace E.g. "dontShowAgain.gatewayDropHelp"
              flowStore.set('dontShowAgain.gatewayDefault', true);
            }
            sessionService.setSessionState('defaultGateMessageShown', true);
            return $q.when();
          },
          function () {
            return $q.reject();
          }
        );
    }
    return $q.when();
  }

  function getOpenDialogIds() {
    return ngDialog.getOpenDialogs();
  }
}

export type DialogServiceType = ReturnType<typeof dialogService>;
