import ICategoryDetail from '@Shared.Angular/@types/core/contracts/queryModel/category/categoryDetail';
import ITeamsResultModel from '@Shared.Angular/@types/core/contracts/queryModel/team/teamsResultModel';
import ISearchUserResultModel from '@Shared.Angular/@types/core/contracts/queryModel/user/searchUserResultModel';
import ICustomTableName from '@Shared.Angular/@types/customDb/services/models/customTableName';
import { Services } from '@Shared.Angular/@types/services';
import { IHttpService } from 'angular';

/**
 * @ngdoc service
 * @name setupApiService
 * @module flowingly.services
 *
 * @description A service responsible solely for fetching data in setup page
 */

export default class SetupApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: Services.APP_CONFIG,
    private lodashService: Lodash
  ) {}

  public cachedCategories: ICategoryDetail[];
  service = {
    databases: [] as ICustomTableName[],
    teams: [],
    users: []
  };

  getCategories(noSpinner = false) {
    return this.$http
      .get<IResponseData<ICategoryDetail[]>>(
        this.APP_CONFIG.apiBaseUrl + 'setup/categories',
        {
          noSpinner: noSpinner || false
        }
      )
      .then((response) => {
        this.cachedCategories = angular.copy(response.data.dataModel);
        return response.data.dataModel;
      });
  }

  getDatabases(noSpinner) {
    return this.$http
      .get<ICustomTableName[]>(`${this.APP_CONFIG.apiBaseUrl}setup/databases`, {
        noSpinner: noSpinner || false
      })
      .then((response) => {
        angular.copy(response.data, this.service.databases);
        this.sortDatabasesByName();
        return response.data;
      });
  }

  sortDatabasesByName() {
    this.service.databases.sort((t1, t2) => {
      const t1NameToCompare = t1.name.trim().toLowerCase();
      const t2NameToCompare = t2.name.trim().toLowerCase();

      if (t1NameToCompare < t2NameToCompare) {
        return -1;
      }

      if (t1NameToCompare > t2NameToCompare) {
        return 1;
      }

      return 0;
    });
  }

  getTeamsWithOptions(options) {
    return this.$http
      .post<ITeamsResultModel>(
        `${this.APP_CONFIG.apiBaseUrl}setup/teams`,
        options,
        {
          noSpinner: true
        }
      )
      .then((response) => {
        angular.copy(response.data.teams, this.service.teams);
        return response.data;
      });
  }

  getUsersWithOptions(options) {
    const defaultOpts = {
      take: 100,
      skip: 0,
      page: 1,
      pageSize: 100,
      group: []
    };
    options = this.lodashService.defaults(options, defaultOpts);
    return this.$http
      .post<ISearchUserResultModel>(
        this.APP_CONFIG.apiBaseUrl + 'setup/users',
        options,
        {
          noSpinner: true
        }
      )
      .then((response) => {
        angular.copy(response.data.users, this.service.users);
        return response.data;
      });
  }
}
angular
  .module('flowingly.services')
  .factory('setupApiService', [
    '$http',
    'APP_CONFIG',
    'lodashService',
    (
      $http: IHttpService,
      APP_CONFIG: Services.APP_CONFIG,
      lodashService: Lodash
    ) => new SetupApiService($http, APP_CONFIG, lodashService)
  ]);
export type SetupApiServiceType = InstanceType<typeof SetupApiService>;
