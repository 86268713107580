/*
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f55534999e2330de1cc9cfab426e29804f613cf2/src/Flowingly.Shared.Angular/flowingly.services/flowingly.activity.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('flowinglyActivityService', flowinglyActivityService);

flowinglyActivityService.$inject = ['BPMN_CONSTANTS', 'flowinglyConstants'];

function flowinglyActivityService(BPMN_CONSTANTS, flowinglyConstants) {
  //API
  const service = {
    isActivity: isActivity,
    isComponent: isComponent,
    isTaskActivity: isTaskActivity,
    isApprovalActivity: isApprovalActivity,
    isMultipleApprovalActivity: isMultipleApprovalActivity,
    isTaskOrSingleApprovalActivity: isTaskOrSingleApprovalActivity,
    findStepTypeNumberWithName: findStepTypeNumberWithName
  };

  return service;

  //////////// Public API Methods
  function isActivity(modelNode) {
    return modelNode && modelNode.category === BPMN_CONSTANTS.ACTIVITY;
  }

  function isComponent(modelNode) {
    return (
      modelNode &&
      modelNode.category === flowinglyConstants.nodeCategory.COMPONENT
    );
  }

  function isTaskActivity(modelNode) {
    return modelNode && modelNode.taskType === flowinglyConstants.taskType.TASK;
  }

  function isTaskOrSingleApprovalActivity(modelNode) {
    return (
      modelNode &&
      (modelNode.taskType === flowinglyConstants.taskType.TASK ||
        modelNode.taskType === flowinglyConstants.taskType.APPROVAL)
    );
  }

  function isApprovalActivity(modelNode) {
    return (
      modelNode &&
      (modelNode.taskType === flowinglyConstants.taskType.APPROVAL ||
        modelNode.taskType === flowinglyConstants.taskType.PARALLEL_APPROVAL ||
        modelNode.taskType === flowinglyConstants.taskType.SEQUENTIAL_APPROVAL)
    );
  }

  function isMultipleApprovalActivity(modelNode) {
    return (
      modelNode &&
      (modelNode.taskType === flowinglyConstants.taskType.PARALLEL_APPROVAL ||
        modelNode.taskType === flowinglyConstants.taskType.SEQUENTIAL_APPROVAL)
    );
  }

  function findStepTypeNumberWithKey(value: string) {
    for (const key in flowinglyConstants.stepType) {
      if (key === value) {
        return flowinglyConstants.stepType[key];
      }
    }
    return undefined;
  }

  function findStepTypeNumberWithName(value: string) {
    for (const key in flowinglyConstants.stepTypeName) {
      if (flowinglyConstants.stepTypeName[key] === value) {
        return findStepTypeNumberWithKey(key);
      }
    }
    return undefined;
  }
}

export type FlowinglyActivityServiceType = ReturnType<
  typeof flowinglyActivityService
>;
