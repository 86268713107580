/**
 * @ngdoc component
 * @name flowAvatarCircle
 * @module flowingly.components
 * @description This is a component for displaying the users avatar. If they have no avatar saved, the first letter of their name is used instead.
 * @usage
 * ```
 * <flow-avatar-circle name="'$ctrl.user.name" avatar-url="$ctrl.user.avatarUrl"></flow-avatar-circle>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/c1bef6b1678a4d74661919252b68fd866d11676a/src/Flowingly.Shared.Angular/flowingly.components/flow.avatar.circle.component.js?at=master
 */
(function () {
  ///
  /// Styling for this directive will be handled by the component that is including this directive
  ///

  angular.module('flowingly.components').component('flowAvatarCircle', {
    bindings: {
      //url to the users avartar
      avatarUrl: '<',
      actorId: '<',
      //the users name
      name: '<'
    },
    controller: [
      '$element',
      'avatarService',
      function ($element, avatarService) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
          showAvatar();
        };
        $ctrl.$onChanges = function () {
          showAvatar();
        };

        function showAvatar() {
          if ($ctrl.actorId) {
            var url = avatarService.getAvatarUrl($ctrl.actorId);
            if (url) {
              $ctrl.avatarUrl = url;
            }
          }

          $ctrl.showImage = false;
          if ($ctrl.name) {
            $ctrl.firstLetter = avatarService.getUserInitial($ctrl.name);
            //get random colour using the name as an input
            var color = avatarService.getColour($ctrl.name);
            //update the colour in the dom
            var element = angular.element(
              $element[0].getElementsByClassName('circle')
            );
            element.attr('style', 'background-color:' + color);
          } else {
            $ctrl.firstLetter = '?';
          }
        }
      }
    ],
    template: [
      '<div>',
      '<img ng-if="$ctrl.showImage" ng-src="{{$ctrl.avatarUrl}}" title="{{$ctrl.name}}">',
      '<p ng-show="!$ctrl.showImage" class="circle" title="{{$ctrl.name}}">{{$ctrl.firstLetter}}</p>',
      '</div>',
      '</div>'
    ].join('')
  });
})();
