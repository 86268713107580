import angular from 'angular';
import { Services } from '../@types/services';
import { FormFieldType, LookupSpecialFieldLabels } from './flowingly.constants';
import { LookupSpecialFields } from '@Shared.Angular/@types/core/contracts/queryModel/workflow/lookupSpecialFields';
import IFormField from '@Shared.Angular/@types/core/contracts/queryModel/card/formField';

angular
  .module('flowingly.services')
  .factory('customdbService', customdbService);

customdbService.$inject = ['APP_CONFIG', '$http'];

function customdbService(
  APP_CONFIG: Services.APP_CONFIG,
  $http: angular.IHttpService
) {
  const databasesApiBaseUrl = APP_CONFIG.apiBaseUrl + 'customdatabase';
  //API
  const service = {
    populateValueOptions: populateValueOptions,
    getColumnsUnfiltered,
    getColumns,
    getAllDatabases
  };

  return service;

  //////////// Public API Methods
  function getAllDatabases() {
    return $http
      .get(databasesApiBaseUrl + '?fromModeler=true', { noSpinner: true })
      .then((response) => response.data);
  }

  function getColumns(...args) {
    return (getColumnsUnfiltered as any)(...args).then((columns) => {
      const idColumnIndex = columns.findIndex((d) => d.name === 'Id');
      const customIdColumnIndex = columns.findIndex(
        (d) => d.name === 'customdatabaseid'
      );

      if (idColumnIndex >= 0 && customIdColumnIndex < 0) {
        columns.splice(idColumnIndex, 1);
      } else if (customIdColumnIndex >= 0) {
        columns.splice(customIdColumnIndex, 1);
      }
      return columns;
    });
  }

  function getColumnsUnfiltered(dbName, _config_ = {}) {
    const config = {
      noSpinner: true,
      ..._config_
    };
    return $http
      .get(`${databasesApiBaseUrl}/columns/${dbName}`, config)
      .then((response) => response.data);
  }

  function populateValueOptions(
    data: { allFields: IFormField[]; cells?: unknown[] },
    field: IFormField,
    dataType,
    addCurrentActorOptions = false
  ) {
    let valueOptions = [];
    const isTable = data.cells !== undefined;

    valueOptions.push({
      name: LookupSpecialFields.InitiatorFullName,
      text: LookupSpecialFieldLabels.InitiatorFullName,
      type: 'text'
    });

    valueOptions.push({
      name: LookupSpecialFields.InitiatorEmail,
      text: LookupSpecialFieldLabels.InitiatorEmail,
      type: 'text'
    });

    if (addCurrentActorOptions) {
      valueOptions.push({
        name: LookupSpecialFields.CurrentActorFullName,
        text: LookupSpecialFieldLabels.CurrentActorFullNameText,
        type: 'text'
      });
      valueOptions.push({
        name: LookupSpecialFields.CurrentActorEmail,
        text: LookupSpecialFieldLabels.CurrentActorEmailText,
        type: 'text'
      });
    }

    if (data.allFields || data.cells) {
      if (isTable) {
        for (const c of data.cells) {
          if (c.id === field.id) {
            break;
          } else if (
            c.type === 1 || //text
            c.type === 2 || //currency
            c.type === 3 || //number
            c.type === 6
          ) {
            //dropdown
            valueOptions.push({
              name: c.id + '', //kendo don't like int by some reason
              text: c.header,
              type: c.type
            });
          }
        }
      } else {
        for (const f of data.allFields) {
          if (f.name === field.name) {
            break;
          } else if (
            f.type === FormFieldType.TEXT ||
            f.type === FormFieldType.NUMBER ||
            f.type === FormFieldType.SELECT_LIST ||
            f.type === FormFieldType.RADIO_BUTTON_LIST ||
            f.type === FormFieldType.CURRENCY ||
            f.type === FormFieldType.EMAIL ||
            (f.type === FormFieldType.LOOKUP && f.lookupConfig)
          ) {
            valueOptions.push({
              name: f.name,
              text: f.displayName,
              type: f.type
            });
          }
        }
      }

      // ensure field type matches data type, eg. validate and prevent short text field with number data column
      if (dataType && dataType !== 'text') {
        valueOptions = valueOptions.filter((o) => o.type === dataType);
      }
    }

    return valueOptions;
  }
}

export type CustomdbServiceType = ReturnType<typeof customdbService>;
