import angular from 'angular';

export class FlowinglyDragDrop {
  public link: (
    scope: ng.IScope,
    element: ng.IAugmentedJQuery,
    attrs: ng.IAttributes
  ) => void;
  public restrict: string;
  public scope = {
    isDraggable: '<',
    isDroppable: '<',
    dragStart: '&',
    dragEnd: '&',
    dragOver: '&',
    dragLeave: '&',
    drop: '&'
  };

  constructor() {
    this.link = (scope: ng.IScope, element: JQuery) => {
      if (scope.isDraggable) {
        element.attr('draggable', 'true');
      }

      element.on('dragstart', function (ev) {
        ev.originalEvent.dataTransfer.effectAllowed = 'copy';
        scope.dragStart();
      });

      element.on('dragend', function () {
        scope.dragEnd();
      });

      if (scope.isDroppable) {
        element.on('dragover', function (ev) {
          ev.preventDefault();
          ev.originalEvent.dataTransfer.dropEffect = 'copy';
          scope.dragOver();
        });

        element.on('dragleave', function (ev) {
          ev.preventDefault();
          scope.dragLeave();
        });

        element.on('drop', function (ev) {
          ev.preventDefault();
          scope.drop();
        });
      }
    };

    this.restrict = 'A';
  }

  static Factory() {
    const directive = () => {
      return new FlowinglyDragDrop();
    };

    directive['$inject'] = [];

    return directive;
  }
}

angular
  .module('flowingly.directives')
  .directive('flowinglyDragDrop', FlowinglyDragDrop.Factory());
