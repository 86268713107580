/**
 * @name delegationBanner
 * @module flowingly.directives
 * @description A component is used to display delegation banner.
 * @usage
 * ```
    <delegation-banner user="ctrl.user"></delegation-banner>
 * ```
 *
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/ba08a9e223ae3b3307b6a1d00d82d22ace18b02d/src/Flowingly.Shared.Angular/flowingly.directives/delegation-banner/delegation-banner.component.js?at=master
 */

'use strict';

import angular from 'angular';
import { Services } from '@Shared.Angular/@types/services';
angular.module('flowingly.delegation.banner').component('delegationBanner', {
  templateUrl: 'delegation-banner.tmpl.html',
  bindings: {
    user: '<'
  },
  controller: [
    'sessionService',
    '$timeout',
    'accountApiService',
    'notificationService',
    '$rootScope',
    function (
      sessionService: Services.SessionService,
      $timeout: angular.ITimeoutService,
      accountApiService: Services.AccountApiService,
      notificationService: Services.NotificationService,
      $rootScope: angular.IRootScopeService
    ) {
      const $ctrl = this;
      $ctrl.showBannerPopup = false;
      $ctrl.showBanner = showBanner;
      $ctrl.turnOff = turnOff;
      $rootScope.globalInDelegationMode = this.user.inDelegationMode; //initialization

      $ctrl.$onInit = function () {
        //FLOW-4290: Might not be an actual issue occuring in prod as this works well for a real SSO user (used Microsoft SSO), but is here to fix anyway */
        sessionService
          .getUserDetails($ctrl.user.email)
          .then(function (response) {
            const res = response.data;
            if (res.success === true) {
              const userDetails = sessionService.formatUserForLocalStorage(
                res.dataModel
              );
              $rootScope.user = userDetails;
              $ctrl.user.delegateApprovalUserFullName =
                $rootScope.user.delegateApprovalUserFullName;
              $ctrl.user.delegateStepUserFullName =
                $rootScope.user.delegateStepUserFullName;
            }
          });
      };

      $(window).click(function (event) {
        const target = event.target;
        if (
          target.id === 'delegationBanner' ||
          target.id === 'delegationBannerPopup' ||
          target.id === 'tab-inprogress-link-large-screen' ||
          angular.element(target).hasClass('yay-toggle hamburger') ||
          angular.element(target).hasClass('yay-toggle side-toggle')
        )
          return;

        $timeout(function () {
          $ctrl.showBannerPopup = false;
        });
      });
      $(window).on('orientationchange', function (event) {
        $timeout(function () {
          $ctrl.showBannerPopup = false;
        });
      });
      function turnOff() {
        accountApiService.turnOffDelegation().then(function () {
          const user = sessionService.getUser();
          user.inDelegationMode = false;
          user.delegateApprovalUserId = undefined;
          user.delegateStepUserId = undefined;
          user.delegateStepUserFullName = '';
          user.delegateApprovalUserFullName = '';
          sessionService.clearUser();
          sessionService.setUser(user);
          $rootScope.globalInDelegationMode = false;
          notificationService.showSuccessToast(
            'Delegation has been turned off'
          );
        });
      }
      function showBanner() {
        $ctrl.showBannerPopup = !$ctrl.showBannerPopup;
        $timeout(function () {
          setPopupPosition();
        });
      }

      function setPopupPosition() {
        if ($ctrl.showBannerPopup) {
          const banner = document
            .getElementById('delegationBanner')
            .getBoundingClientRect();
          const width = $(window).width();
          const popupleft =
            width < 601 ? banner.right - 295 : banner.left - 150;

          angular.element('#delegationBannerPopup').css({ left: popupleft });
        }
      }
    }
  ]
});
