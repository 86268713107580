/**
 * @name profileAvatarCircle
 * @module flowingly.directives
 * @description Draws a profile avatar based on the size.
 * ### Notes
 * The colour for the background is chosen from a small defined palette, the fullname used as the look up key.
 * Example below draws a 100 x 100 px circle in place of the user's avatar using their full name
 * @usage
 * ```
   <profile-avatar-circle name='' size='100'></profile-avatar-circle>
 * ```
 *
 * Converted to ts on 15/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.directives/profile.avatar.circle.js?at=master
 */
import angular, { IScope } from 'angular';
angular.module('flowingly.directives').component('profileAvatarCircle', {
  bindings: {
    //the users name
    fullname: '=',
    size: '<'
  },
  controller: [
    '$scope',
    '$element',
    function ($scope: IScope, $element: JQuery) {
      const $ctrl = this;

      this.$onInit = function () {
        initName();
        $scope.$watch('fullname', function (newValue, oldValue) {
          if (newValue !== oldValue && newValue) {
            initName();
          }
        });
      };

      function initName() {
        if ($ctrl.fullname) {
          $ctrl.firstLetter = $ctrl.fullname.charAt(0);
          //get random colour using the name as an input
          const color = lookupColour($ctrl.fullname);
          //update the colour in the dom
          const element = angular.element(
            $element[0].getElementsByClassName('circle')
          );
          element.attr('style', 'background-color:' + color);
        } else {
          $ctrl.firstLetter = '?';
        }
        if (!$ctrl.size) {
          $ctrl.size = 100;
        }
      }

      function lookupColour(name) {
        const hex = hexEncode(name);
        const hexint = parseInt(hex, 16);
        const hue = getColorId(hexint);
        return getColourFromPalette(hue);
      }

      function getColorId(hexint) {
        const numColors = 14;
        let ranged = hexint;
        while (ranged > numColors) {
          ranged = ranged / numColors;
        }
        return parseInt(ranged);
      }

      function hexEncode(input) {
        let i = 0;
        let h = '';
        while (i < input.length) {
          h += input.charCodeAt(i++).toString(16);
        }
        return h;
      }

      function getColourFromPalette(index) {
        return palette[index];
      }

      const palette = [
        '#FF8E8E', //1
        '#E994AB',
        '#67C7E2',
        '#A5D3CA',
        '#99C7FF', //5
        '#A8E4FF',
        '#75ECFD',
        '#92FEF9',
        '#7DFDD7',
        '#8BFEA8', //10
        '#FFACEC',
        '#EFA9FE',
        '#C4ABFE',
        '#EEEEA2' //14
      ];
    }
  ],
  template: [
    '<div class="circle w-{{$ctrl.size}} h-{{$ctrl.size}} white-text" title="{{$ctrl.name}}">' +
      '<h1 class="center-align mt-25 fs-50" ng-if="$ctrl.size>=80">' +
      '{{$ctrl.firstLetter}}' +
      '</h1>' +
      '<h3 class="center-align m-0 fs-20 lh-40" ng-if="$ctrl.size<80 && $ctrl.size>=40">' +
      '{{$ctrl.firstLetter}}' +
      '</h3>' +
      '<h4 class="center-align mt-5 lh-{{$ctrl.size}}" ng-if="$ctrl.size<40">' +
      '{{$ctrl.firstLetter}}' +
      '</h4>' +
      '</div>'
  ].join('')
});
