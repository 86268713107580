/**
 * @ngdoc service
 * @name BpmnMenuService
 * @module flowingly.bpmn.modeler
 *
 * @description  A service for creating BPMN context menus
 *
 * ## Notes
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/04c5ea8eba7bf8961358cbf6f93bb688a37854a4/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.menu.service.js?at=master
 */

'use strict';
import angular from 'angular';
angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnMenuService', BpmnMenuService);

BpmnMenuService.$inject = ['$rootScope', '$timeout', 'goService'];

function BpmnMenuService(
  $rootScope: angular.IRootScopeService,
  $timeout: angular.ITimeoutService,
  goService: GoJS
) {
  const service = {
    getActivityNodeMenu: getActivityNodeMenu,
    getBoundaryEventMenu: getBoundaryEventMenu
  };
  return service;

  function getActivityNodeMenu() {
    const $GO = goService.GraphObject.make;

    const activityNodeMenu = $GO(
      goService.Adornment,
      'Vertical',
      //$GO("ContextMenuButton",
      //    $GO(goService.TextBlock, "Add Email Event", { margin: 3 }),
      //    { click: function(e, obj) { addActivityNodeBoundaryEvent(2, 5); } }),
      //$GO("ContextMenuButton",
      //    $GO(goService.TextBlock, "Add Timer Event", { margin: 3 }),
      //    { click: function(e, obj) { addActivityNodeBoundaryEvent(3, 5); } }),
      //$GO("ContextMenuButton",
      //    $GO(goService.TextBlock, "Add Escalation Event", { margin: 3 }),
      //    { click: function(e, obj) { addActivityNodeBoundaryEvent(4, 5); } }),
      //$GO("ContextMenuButton",
      //    $GO(goService.TextBlock, "Add Error Event", { margin: 3 }),
      //    { click: function(e, obj) { addActivityNodeBoundaryEvent(7, 5); } }),
      //$GO("ContextMenuButton",
      //    $GO(goService.TextBlock, "Add Signal Event", { margin: 3 }),
      //    { click: function(e, obj) { addActivityNodeBoundaryEvent(10, 5); } }),
      //$GO("ContextMenuButton",
      //    $GO(goService.TextBlock, "Add N-I Escalation Event", { margin: 3 }),
      //    { click: function(e, obj) { addActivityNodeBoundaryEvent(4, 6); } }),
      $GO(
        'ContextMenuButton',
        $GO(goService.TextBlock, 'Rename', { margin: 3 }),
        {
          click: function (e, obj) {
            issueCommand('rename', obj.part.data);
          }
        }
      )
    );

    return activityNodeMenu;
  }

  function getBoundaryEventMenu() {
    const $GO = goService.GraphObject.make;
    const boundaryEventMenu = // context menu for each boundaryEvent on Activity node
      $GO(
        goService.Adornment,
        'Vertical',
        $GO(
          'ContextMenuButton',
          $GO(goService.TextBlock, 'Remove event'),
          // in the click event handler, the obj.part is the Adornment; its adornedObject is the port
          {
            click: function (e, obj) {
              issueCommand('emoveActivityNodeBoundary', obj.part.adornedObject);
            }
          }
        )
      );
    return boundaryEventMenu;
  }

  /// PRIVATE ///////////////////////////////////////////////////////////////////////
  function issueCommand(type, data) {
    //timeout is required to avoid $digest already in progress issues
    $timeout(function () {
      // TODO: review use of $broadcast
      $rootScope.$broadcast('go.event', { type: 'rename', data: data });
    }, 0);
  }
}

export type BpmnMenuServiceType = ReturnType<typeof BpmnMenuService>;
