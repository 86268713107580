'use strict';
import angular from 'angular';

angular.module('flowingly.adapters').factory('jspdfService', [
  '$window',
  function ($window: angular.IWindowService) {
    if ($window.jsPDF === undefined) {
      console.error('jsPDF is not loaded');
    }
    return $window.jsPDF;
  }
]);
