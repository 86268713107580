/**
 * @ngdoc module
 * @name flowingly.adapters
 * @module flowingly.adapters
 * @description Wrappers for global objects to make testing easier and depdencies more explicit
 */

//angular.module('flowingly.adapters', []);

(function () {
  'use strict';

  angular.module('flowingly.adapters', []);
})();
