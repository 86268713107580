'use strict';
import angular from 'angular';
import ModelerValidationErrorsService from '../flowingly.modeler.validation/modeler.validation.errors.service';
import { BpmnModeler } from './@types/services';
import { Services } from '@Shared.Angular/@types/services';
import { SharedAngular } from '@Angular.Runner/@types/sharedAngular';

/**
 * @ngdoc service
 * @name BpmnActivityNodeService
 * @module flowingly.bpmn.modeler
 *
 * @description A service for creating BPMN Activity nodes (Tasks).
 *
 * ## Notes
 *
 * ### Model Usage
 * { key: 131, category: "activity", text: "Task", item: "generic task", taskType: 0}
 *
 *  There are two types of nodes (node/palette node). The first is what gets drawn on the canvas;
 *  the second what gets drawni the palette and dragged on to the canvas.
 * ###API
 * * getDefaultActivityCardModel - Get default state of activity node (model)
 * * getNode -  Get an actvity node defined by the (optional) options
 * * getPaletteNode - Get an actvity palette node defined by the (optional) options
 */

///
/// A service for creating BPMN Activity nodes
///
/// Model Usage
///
/// { key: 131, category: "activity", text: "Task", item: "generic task", taskType: 0},
///

class BpmnActivityNodeService {
  private defaults: any;

  static $inject = [
    'goService',
    'BpmnPartsFactory',
    'BPMN_CONSTANTS',
    'BpmnCommonService',
    'flowinglyConstants',
    'modelerValidationErrorsService',
    '$window',
    'pubsubService',
    'APP_CONFIG',
    'avatarService'
  ];
  constructor(
    private goService: GoJS,
    private BpmnPartsFactory,
    private BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
    private BpmnCommonService: BpmnModeler.BpmnCommonService,
    private flowinglyConstants: Services.FlowinglyConstants,
    private modelerValidationErrorsService: ModelerValidationErrorsService,
    private $window: angular.IWindowService,
    private pubsubService: Services.PubSubService,
    private APP_CONFIG: Services.APP_CONFIG,
    private avatarService: SharedAngular.AvatarService
  ) {
    this.defaults = {
      ActivityNodeEditable: this.BPMN_CONSTANTS.MakeNodesEditable,
      ActivityNodeWidth: 145,
      ActivityNodeMaskWidth: 150,
      ActivityNodeHeight: 80,
      ActivityNodeStroke: this.BPMN_CONSTANTS.ActivityNodeBorder,
      ActivityNodeFill: this.BPMN_CONSTANTS.ActivityNodeFill,
      ActivityNodeStrokeWidth: this.BPMN_CONSTANTS.ActivityNodeStrokeWidth,
      ActivityNodePaletteStrokeWidth:
        this.BPMN_CONSTANTS.ActivityNodePaletteStrokeWidth,
      ActivityHeaderColor: this.BPMN_CONSTANTS.ActivityHeaderFillColor,
      ActivitySquareColour: this.BPMN_CONSTANTS.ActivitySquareFillColor,
      ActivitySquareHighlightColour:
        this.BPMN_CONSTANTS.ActivitySquareHighlightColor
    };
    this.determineBackgroundColor = this.determineBackgroundColor.bind(this);
  }
  ///
  /// Get default state of activity node (model)
  ///
  public getDefaultActivityCardModel() {
    return {
      description: '',
      formElements: []
    };
  }

  public getNode(options) {
    angular.extend(this.defaults, options); //override defaults, if options supplied
    return this.getActivityNode();
  }

  ///
  /// Get a Gateway node defined by the (optional) options
  /// If options is not supplied, default values will be used
  ///
  public getPaletteNode(options) {
    angular.extend(this.defaults, options); //override defaults, if options supplied

    return this.getActivityPaletteNode();
  }

  private nodeActivityTaskVisibilityConverter(s) {
    //if it's an approval task then show the tickmark
    if (
      s === this.flowinglyConstants.taskType.APPROVAL ||
      s === this.flowinglyConstants.taskType.PARALLEL_APPROVAL ||
      s === this.flowinglyConstants.taskType.SEQUENTIAL_APPROVAL
    ) {
      return true;
    } else {
      return false;
    }
  }

  private nodeShowEmailIconVisibilityConverter(node) {
    //if it's notify email checked show envelope
    const show =
      node.displayNotificationIcon === 'true' ||
      node.displayNotificationIcon === true;

    return !this.isCustomMailActivity(node.taskType) && show;
  }

  private nodeShowRefSequenceVisibilityConverter(s) {
    //if it has ref sequence show it
    const show = !!s;

    return false;
  }

  private nodeShowIntegrationIconVisibilityConverter(display) {
    //If integration is enabled on the step and business settings then show the integration icon
    const show =
      (display === 'true' || display === true) &&
      this.APP_CONFIG.enableWebhooks;
    return show;
  }

  private nodeShowStepTaskIconVisibilityConverter(display) {
    const isStepTaskEnabled =
      this.APP_CONFIG.enableStepTasks != null &&
      this.APP_CONFIG.enableStepTasks;

    const show = (display === 'true' || display === true) && isStepTaskEnabled;
    return show;
  }

  private nodeShowPublicFormIconVisibilityConverter(s) {
    //if it's public form checked show icon
    const show = s === 'true' || s === true;
    return show;
  }

  private nodeShowStepRuleIconVisibilityConverter(s) {
    //if the step has a step rule then show icon
    return s && this.APP_CONFIG.allowStepRules;
  }

  private isCustomMailActivity(taskType) {
    return taskType === 6;
  }

  private getBorderColor(goNodeModel) {
    const hasError = this.modelerValidationErrorsService.hasErrors(
      goNodeModel.key
    );
    return hasError
      ? this.BPMN_CONSTANTS.Theme.Error.Border.Color
      : this.BPMN_CONSTANTS.ActivityNodeBorder;
  }

  private getBorderWidth(goNodeModel) {
    const hasError = this.modelerValidationErrorsService.hasErrors(
      goNodeModel.key
    );
    const { taskType } = goNodeModel;

    if (this.isCustomMailActivity(taskType)) {
      // custom email is made with the same function :(
      return hasError
        ? this.BPMN_CONSTANTS.Theme.Error.Border.StrokeWidth / 2
        : 0; // divided by 2 to match the activity width
    } else {
      return hasError
        ? this.BPMN_CONSTANTS.Theme.Error.Border.StrokeWidth
        : this.BPMN_CONSTANTS.ActivityNodeStrokeWidth;
    }
  }

  private isNodeCurrentStep(s) {
    if (s.color) return s.color;
    else return this.BPMN_CONSTANTS.Theme.FlowinglyWhite;
  }

  private isInModeler() {
    const _isInModeler =
      this.$window.location.pathname === '/modeler/' ? true : false;

    return _isInModeler;
  }

  public determineBackgroundColor(actorName) {
    const firstLetter = actorName.charAt(0).toUpperCase();
    return this.avatarService.getBgColorForProcessMapRightPaneAvatar(
      firstLetter
    );
  }

  //---------------------------------------------TEMPLATE------------------------------------------
  private getActivityNode() {
    const $GO = this.goService.GraphObject.make;

    const activityNodeTemplate = $GO(
      this.goService.Node,
      'Auto',
      {
        isShadowed: true,
        shadowColor: this.BPMN_CONSTANTS.diagram.shadow.color,
        shadowBlur: this.BPMN_CONSTANTS.diagram.shadow.blur,
        shadowOffset: new this.goService.Point(
          this.BPMN_CONSTANTS.diagram.shadow.offsetX,
          this.BPMN_CONSTANTS.diagram.shadow.offsetY
        ),
        locationSpot: this.goService.Spot.Center,
        resizable: false,
        selectionAdorned: true,
        defaultStretch: this.goService.GraphObject.Vertical,
        cursor: 'move',
        mouseEnter: function (e, node) {
          if (!this.BpmnCommonService.shouldShowConnectorPoints()) return;

          if (this.isCustomMailActivity(node.data.taskType)) {
            this.BpmnCommonService.showPort(node, 'EmailConnectionSpot');
          } else {
            this.BpmnCommonService.showPort(node, 'ActivityConnectionSpot');
          }
        }.bind(this),
        mouseLeave: function (e, node) {
          this.BpmnCommonService.hidePort(node, 'ActivityConnectionSpot');
          this.BpmnCommonService.hidePort(node, 'EmailConnectionSpot');
        }.bind(this)
      },
      new this.goService.Binding('itemArray', 'boundaryEventArray'),
      new this.goService.Binding('locationObjectName', 'taskType', (s) => {
        return this.isCustomMailActivity(s) ? 'Icon' : 'Square';
      }),
      new this.goService.Binding(
        'location',
        'loc',
        this.goService.Point.parse
      ).makeTwoWay(this.goService.Point.stringify),
      $GO(
        this.goService.Panel,
        'Auto',
        $GO(
          this.goService.Shape,
          {
            fill: 'white',
            stroke: this.defaults.ActivityNodeStroke,
            strokeWidth: this.defaults.ActivityNodeStrokeWidth,
            fromLinkable: true,
            toLinkable: true,
            portId: 'activity',
            fromLinkableDuplicates: true,
            toLinkableDuplicates: true,
            //toMaxLinks: 1,
            cursor: 'pointer',
            fromSpot: this.goService.Spot.RightSide,
            toSpot: this.goService.Spot.LeftSide,
            name: 'outline'
          },

          // Business Rule for FLOW-4654, when valdiation the borders of
          // the nodes should turn red if they contain an error.
          new this.goService.Binding(
            'stroke',
            '',
            this.getBorderColor.bind(this)
          ),
          new this.goService.Binding(
            'strokeWidth',
            '',
            this.getBorderWidth.bind(this)
          ),

          new this.goService.Binding('fill', 'taskType', (s) => {
            return this.isCustomMailActivity(s) ? 'transparent' : 'white';
          }),
          new this.goService.Binding('portId', 'taskType', (s) => {
            return this.isCustomMailActivity(s) ? 'customMail' : '';
          })
        ),
        $GO(this.goService.Shape, {
          fill: 'transparent',
          stroke: null,
          cursor: 'move',
          width: this.defaults.ActivityNodeMaskWidth,
          height: this.defaults.ActivityNodeHeight,
          alignment: this.goService.Spot.Left,
          name: 'Mask'
        })
      ),

      $GO(
        this.goService.Panel,
        'Table',
        {
          defaultAlignment: this.goService.Spot.Left,
          margin: new this.goService.Margin(1, 1.5, 1, 1)
        },
        $GO(this.goService.RowColumnDefinition, {
          row: 0,
          background: this.defaults.ActivityHeaderColor
        }),

        new this.goService.Binding(
          !this.isInModeler() ? 'background' : '',
          '',
          (s) => {
            return this.isNodeCurrentStep(s);
          }
        ),
        $GO(this.goService.RowColumnDefinition, { row: 1 }),
        $GO(this.goService.RowColumnDefinition, { row: 2, height: 24 }),

        //header icon
        $GO(
          this.goService.Panel,
          'Horizontal',
          {
            row: 0,
            column: 0,
            stretch: this.goService.GraphObject.Fill,
            minSize: new this.goService.Size(34, 34)
          },
          $GO(
            this.goService.TextBlock,
            {
              width: 34,
              textAlign: 'center',
              font: '600 16pt "Open Sans"',
              stroke: '#ffffff'
            },
            new this.goService.Binding('text', 'actorName', function (
              actorName
            ) {
              const firstLetter = actorName.charAt(0).toUpperCase();
              return firstLetter;
            })
          ),
          new this.goService.Binding(
            'background',
            'actorName',
            this.determineBackgroundColor
          )
        ),
        //header title
        $GO(
          this.goService.TextBlock,
          {
            row: 0,
            column: 1,
            alignment: this.goService.Spot.Left,
            margin: 5,
            font: '600 10pt "Open Sans"',
            textAlign: 'left',
            stroke: '#408EE0',
            width: 120,
            wrap: this.goService.TextBlock.WrapDesiredSize
          },
          new this.goService.Binding('text', 'actorName')
        ),
        //task name
        $GO(
          this.goService.TextBlock,
          {
            row: 1,
            column: 0,
            columnSpan: 2,
            textAlign: 'center',
            margin: 15,
            font: '600 11pt "Open Sans"',
            stroke: '#35384D',
            editable: true,
            textValidation: (textBlock, oldValue, newValue) => {
              return !(
                newValue.indexOf('{') > -1 || newValue.indexOf('}') > -1
              );
            },
            textEditor: this.BpmnCommonService.getTextEditingTool(),
            cursor: 'move',
            width: this.defaults.ActivityNodeWidth,
            wrap: this.goService.TextBlock.WrapFit,
            isMultiline: true,
            stretch: this.goService.GraphObject.Horizontal
          },
          new this.goService.Binding('text').makeTwoWay((stepName, node) => {
            // FLOW-6030: This is a binding conversion function that is executed before setting the binding.
            // This gets executed at the time if initialization as well at this time the node and stepName would be equal.
            // Once the user double clicks and updates the value then this function gets executed before updating the binding property to node.
            if (node.text !== stepName) {
              // FLOW-6030 : If the stepname is updated then we set the latest step name to the node and then fire the
              // diagram updated event. This is to avoid issue with timing issue where before the binding updates the stepname and
              // firing the DIAGRAM_MODIFIED event. We want to fire the event after the node.text property is updated with the latest value.
              node.text = stepName;
              this.pubsubService.publish('DIAGRAM_MODIFIED');
            }
            return stepName;
          })
        ),
        new this.goService.Binding('visible', 'taskType', (s) => {
          return !this.isCustomMailActivity(s);
        })
      ),
      //Task ref sequence text
      $GO(
        this.goService.Panel,
        'Auto',
        {
          alignment: new this.goService.Spot(0, 1, 5, -5)
        },
        $GO(this.goService.Shape, 'Ellipse', {
          fill: this.defaults.ActivityHeaderColor,
          stroke: this.defaults.ActivityNodeStroke
        }),
        $GO(
          this.goService.TextBlock,
          {
            font: '600 10pt "Open Sans"',
            textAlign: 'center',
            stroke: '#35384D',
            margin: new this.goService.Margin(0, 5)
          },
          new this.goService.Binding('text', 'refSequence')
        ),
        new this.goService.Binding('visible', '', (node) => {
          return !!node.refSequence && node.taskType !== 6;
        })
      ),

      $GO(
        this.goService.Panel,
        'Horizontal',
        {
          height: 100,
          alignment: this.goService.Spot.BottomRight
        },
        // Integration Icon
        $GO(
          this.goService.Picture,
          {
            name: 'Icon',
            width: !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.diagram.webhooksSvgIcon.width
              : this.BPMN_CONSTANTS.diagram.webhooksSvgIconIE.width,
            height: !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.diagram.webhooksSvgIcon.height
              : this.BPMN_CONSTANTS.diagram.webhooksSvgIconIE.height,
            scale: !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.diagram.webhooksSvgIcon.scale
              : this.BPMN_CONSTANTS.diagram.webhooksSvgIconIE.scale,
            source: ASSETS_PATH + '/webhooks-icon.svg',
            margin: new this.goService.Margin(0, 5, 3, 0),
            alignment: this.goService.Spot.BottomRight
          },
          new this.goService.Binding(
            'visible',
            'displayStepIntegrationIcon',
            this.nodeShowIntegrationIconVisibilityConverter.bind(this)
          )
        ),
        // End Integration Icon
        // Public Forms Icon
        $GO(
          this.goService.Shape,
          {
            fill: this.BPMN_CONSTANTS.PublicFormIconColour,
            stroke: this.BPMN_CONSTANTS.PublicFormIconColour,
            strokeWidth: 1,
            width: 22,
            height: 22,
            geometryString:
              'M1408 928v320q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-320q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19-45-19l-176-176-652 652q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45 45-19h512q26 0 45 19t19 45z',
            margin: new this.goService.Margin(0, 5, 6, 0),
            alignment: this.goService.Spot.BottomRight
          },
          new this.goService.Binding(
            'visible',
            'displayPublicFormIcon',
            this.nodeShowPublicFormIconVisibilityConverter.bind(this)
          )
        ),
        // End Public Forms Icon
        //Step Rules Icon, arrow-alt-circle-up-solid.svg LICENSE: https://fontawesome.com/license
        $GO(
          this.goService.Shape,
          {
            stroke: this.BPMN_CONSTANTS.StepRuleIconColour,
            strokeWidth: 2.5,
            width: 22,
            height: 22,
            geometryString:
              'M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm292 116V256h70.9c10.7 0 16.1-13 8.5-20.5L264.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3c-7.6 7.6-2.2 20.5 8.5 20.5H212v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z',
            margin: new this.goService.Margin(0, 5, 5, 0),
            alignment: this.goService.Spot.BottomRight
          },
          new this.goService.Binding(
            'visible',
            'displayStepRuleIcon',
            this.nodeShowStepRuleIconVisibilityConverter.bind(this)
          )
        ),
        //END Step Rules Icon

        //Approval Task Icon, Material check icon
        $GO(
          this.goService.Shape,
          'BpmnTaskScript',
          {
            fill: this.BPMN_CONSTANTS.ApprovalIconColour,
            stroke: this.BPMN_CONSTANTS.ApprovalIconColour,
            strokeWidth: 2,
            geometryString: 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z',
            margin: new this.goService.Margin(0, 5, 8, 5),
            alignment: this.goService.Spot.BottomRight
          },
          new this.goService.Binding(
            'visible',
            'taskType',
            this.nodeActivityTaskVisibilityConverter.bind(this)
          )
        )
        //END Approval Task Icon
      ),
      $GO(
        this.goService.Panel,
        'Vertical',
        {
          background: 'transparent',
          cursor: 'move'
        },
        $GO(
          this.goService.Panel,
          'Spot',
          $GO(
            this.goService.Panel,
            'Auto',
            $GO(
              this.goService.Picture,
              {
                fromLinkable: true,
                toLinkable: true,
                portId: 'customMail',
                name: 'Icon',
                fromLinkableDuplicates: true,
                toLinkableDuplicates: true,
                fromSpot: this.goService.Spot.RightSide,
                toSpot: this.goService.Spot.LeftSide,
                width: this.BPMN_CONSTANTS.diagram.emailIcon.outerWidth,
                height: this.BPMN_CONSTANTS.diagram.emailIcon.outerHeight,
                cursor: 'pointer',
                source: ASSETS_PATH + '/email.svg'
              },
              new this.goService.Binding('portId', 'taskType', (s) => {
                return this.isCustomMailActivity(s) ? '' : 'customMail';
              })
            ),
            $GO(this.goService.Shape, 'Circle', {
              fill: 'transparent',
              stroke: null,
              width: this.BPMN_CONSTANTS.diagram.emailIcon.outerWidth - 10,
              height: this.BPMN_CONSTANTS.diagram.emailIcon.outerHeight - 10
            }),
            this.BpmnCommonService.makePort(
              'EmailConnectionSpot',
              this.goService.Spot.Right,
              new this.goService.Spot(1, 0.5, 0, 0)
            )
          )
        ),
        this.BpmnPartsFactory.getTextBlock(
          'text',
          true,
          undefined,
          'move',
          undefined,
          true,
          this.BPMN_CONSTANTS.ActivityLabelFill
        ),
        new this.goService.Binding(
          'visible',
          'taskType',
          this.isCustomMailActivity.bind(this)
        )
      ),
      this.BpmnCommonService.makePort(
        'ActivityConnectionSpot',
        this.goService.Spot.Right,
        new this.goService.Spot(1, 0.5, 0, 0)
      ),
      { click: this.BpmnCommonService.nodeClickHandler }
    );

    return activityNodeTemplate;
  }

  private getActivityPaletteNode() {
    const $GO = this.goService.GraphObject.make;

    return this.BpmnPartsFactory.paletteIcon(
      $GO(
        this.goService.Picture,
        {},

        new this.goService.Binding('source', 'taskType', (taskType) => {
          if (this.isCustomMailActivity(taskType)) {
            return ASSETS_PATH + '/email.svg';
          } else {
            return ASSETS_PATH + '/activity.svg';
          }
        }),

        new this.goService.Binding('width', 'taskType', (taskType) => {
          if (this.isCustomMailActivity(taskType)) {
            return !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.palette.icon.innerWidth
              : this.BPMN_CONSTANTS.palette.iconIE.email.innerWidth;
          } else {
            return !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.palette.icon.innerWidth
              : this.BPMN_CONSTANTS.palette.iconIE.activity.innerWidth;
          }
        }),

        new this.goService.Binding('height', 'taskType', (taskType) => {
          if (this.isCustomMailActivity(taskType)) {
            return !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.palette.icon.innerHeight
              : this.BPMN_CONSTANTS.palette.iconIE.email.innerHeight;
          } else {
            return !this.BpmnCommonService.isInternetExplorer
              ? this.BPMN_CONSTANTS.palette.icon.innerHeight
              : this.BPMN_CONSTANTS.palette.iconIE.activity.innerHeight;
          }
        }),

        new this.goService.Binding('scale', 'taskType', (taskType) => {
          if (this.isCustomMailActivity(taskType)) {
            return !this.BpmnCommonService.isInternetExplorer
              ? 1
              : this.BPMN_CONSTANTS.palette.iconIE.email.scale;
          } else {
            return !this.BpmnCommonService.isInternetExplorer
              ? 1
              : this.BPMN_CONSTANTS.palette.iconIE.activity.scale;
          }
        })
      )
    );
  }
}

angular
  .module('flowingly.bpmn.modeler')
  .service('BpmnActivityNodeService', BpmnActivityNodeService);

export type BpmnActivityNodeServiceType = InstanceType<
  typeof BpmnActivityNodeService
>;
