/**
 * @ngdoc service
 * @name goService
 * @module flowingly.adapters
 *
 * @description Wraps the global Go Object.
 *
 * ## Notes
 * Go is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global go object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes go has already been loaded on the page.
 */

import * as angular from 'angular';

angular.module('flowingly.adapters').factory('goService', [
  '$window',
  function ($window) {
    if ($window.go == undefined) {
      console.error('Go is not loaded');
    }
    return $window.go;
  }
]);
