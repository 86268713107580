/*
 * Converted to ts on 16/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/db114d5bb5acf3216e345a732beb9bb60cfd6da7/src/Flowingly.Shared.Angular/flowingly.directives/comment.file.upload.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular.module('flowingly.directives').directive('commentFileUpload', [
  function () {
    const directive = {
      restrict: 'A',
      scope: {
        onFileUploaded: '&',
        targetId: '<',
        targetType: '<'
      },
      controllerAs: 'ctrl',
      controller: [
        '$scope',
        'pubsubService',
        'lodashService',
        'notificationService',
        'fileService',
        function controller(
          $scope: angular.IScope,
          pubsubService: Services.PubSubService,
          lodashService: Lodash,
          notificationService: Services.NotificationService,
          fileService: Services.FileService
        ) {
          const ctrl = this;
          ctrl.onFileChange = onFileChange;

          function onFileChange(event) {
            if (event.target.files.length === 0) {
              return;
            }
            pubsubService.publish('FILEUPLOAD_UPLOAD_STARTED', undefined);
            event.stopPropagation();
            const filesToUpload = event.target.files;
            uploadFiles(filesToUpload);
          }

          function uploadFiles(filesToUpload) {
            lodashService.each(filesToUpload, function (file) {
              fileService.uploadCommentFile(file).then(
                function (response) {
                  $scope.onFileUploaded({
                    file: {
                      filename: file.name,
                      id: response.data,
                      downloadLink: createDownloadLink(response.data),
                      size: Math.floor(file.size / 1000)
                    }
                  });
                  pubsubService.publish(
                    'FILEUPLOAD_UPLOAD_COMPLETED',
                    undefined
                  );
                  notificationService.showSuccessToast(file.name + ' uploaded');
                },
                function (error) {
                  pubsubService.publish('FILEUPLOAD_UPLOAD_FAILED', undefined);
                }
              );
            });
          }

          /// PRIVATE METHODS ///////////////////////////////////////////////////////////////////////
          function createDownloadLink(fileId) {
            return fileService.getDownloadLink(fileId);
          }

          function validateFile(filename) {
            //we now allow upload of any file type
            return true;
          }
        }
      ]
    };

    return directive;
  }
]);
