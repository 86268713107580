/**
 * @ngdoc directive
 * @name flowTableDate
 * @module flowingly.components
 * @description This component is responsbile for render a date control for a table cell.
 * ### Notes
 * The use case here is slightly different so we cannot resuse the shared version of this directive.
 * ### Properties
 * #### Inputs
 * * cell : the cell control work with
 * #### Events
 * * onValueChange : method to call when date value been changed
 * @usage
 * ```
   <flow-table-date cell="cell" on-value-change="$ctrl.updateForm(cell)"></flow-table-date>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/a3394a73235013c719c58d174dc4a4440f730679/src/Flowingly.Shared.Angular/flowingly.components/tablerunner/table.runner.date.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('flowTableDate', {
    bindings: {
      cell: '<',
      onValueChange: '&'
    },
    templateUrl: 'table.runner.date.tmpl.html',
    controller: [
      '$timeout',
      'guidService',
      function ($timeout, guidService) {
        var $ctrl = this;

        $ctrl.handleOnChange = handleOnChange;
        $ctrl.showDatePicker = function () {
          var datePicker = getDatePicker();
          datePicker.open();
        };

        this.$onInit = function () {
          $ctrl.id = guidService.new();
          $ctrl.options = {
            placeholder: 'DD/MM/YYYY',
            format: 'dd/MM/yyyy',
            parseFormats: ['dd/MM/yyyy']
          };

          $timeout(function () {
            getDatePickerElement()
              .kendoDatePicker($ctrl.options)
              .attr('readonly', true)
              .attr('placeholder', $ctrl.options.placeholder);
          });
        };

        function handleOnChange() {
          $ctrl.onValueChange({ cell: $ctrl.cell });
        }

        /// PRIVATE METHODS //////////////////////////////////////////////////////////
        function getDatePickerElement(): any {
          return angular.element('#' + $ctrl.id);
        }

        function getDatePicker() {
          return getDatePickerElement().data('kendoDatePicker');
        }
      }
    ]
  });
})();
