/**
 * @ngdoc directive
 * @name flowinglyMentionTag
 * @module flowingly.directives
 * @description This is a attribute directive for displaying the mention name as blue text
 * @usage
 * ```
 * <div class="flow-comment-history__comment-content flex-column" data-ng-bind-html="$ctrl.commentListLocal.comment" flowingly-mention-tag></div>
 * ```
 */

import { Services } from '@Shared.Angular/@types/services';
import angular, { IScope, IAttributes, ITimeoutService } from 'angular';

export class FlowinglyMentionTag {
  public link: (
    scope: IScope,
    element: JQuery<HTMLElement>,
    attrs: IAttributes
  ) => void;
  public restrict: string;

  constructor(
    private $timeout: ITimeoutService,
    private flowinglyMentionService
  ) {
    this.link = (scope: IScope, element: JQuery<HTMLElement>) => {
      element.css('display', 'none');
      this.$timeout(() => {
        const transformedText =
          this.flowinglyMentionService.transformMentionTag(
            element[0].innerHTML
          );
        element[0].innerHTML = transformedText;
        element.css('display', 'block');
      });
    };

    this.restrict = 'A';
  }

  static Factory() {
    const directive = (
      $timeout: ITimeoutService,
      flowinglyMentionService: Services.FlowinglyMentionService
    ) => {
      return new FlowinglyMentionTag($timeout, flowinglyMentionService);
    };

    directive['$inject'] = ['$timeout', 'flowinglyMentionService'];

    return directive;
  }
}

angular
  .module('flowingly.directives')
  .directive('flowinglyMentionTag', FlowinglyMentionTag.Factory());
