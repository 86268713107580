/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/430e5d24428db28582955b079d7c74af3a8619e2/src/Flowingly.Shared.Angular/flowingly.components/smartselect/smart.select.search.component.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.components')
    ///
    /// A component for retrieving list of entities that the user can publish to
    /// User as input to a smart-select-list
    ///
    /// See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Smart+Select+Component
    ///
    .component('smartSelectSearch', {
      bindings: {
        searchGroup: '=',
        searchResults: '=?',
        filterBy: '<?',
        onResultsListUpdated: '&',
        availableUsers: '<?',
        availableGroups: '<?',
        isEmailRecipients: '<?',
        availableEmailFields: '<?',
        uniqueId: '@',
        showTitle: '<?'
      },
      controller: [
        'smartSelectService',
        'flowinglyConstants',
        function (smartSelectService, flowinglyConstants) {
          //Available search groups
          const userText = flowinglyConstants.searchEntityType.USER;
          const groupText = flowinglyConstants.searchEntityType.GROUP;
          const approverText = flowinglyConstants.searchEntityType.APPROVER;
          const stepFormFieldText =
            flowinglyConstants.searchEntityType.STEP_FORM_FIELD;

          var $ctrl = this;

          $ctrl.search = search;

          this.$onInit = function () {
            search();
          };

          ///// PRIVATE METHODS

          function search() {
            if ($ctrl.searchGroup === groupText) {
              // if availableGroups is defined, use it rather than call API to get all groups
              if ($ctrl.availableGroups) {
                handleSearchResults($ctrl.availableGroups, groupText);
              } else {
                smartSelectService.getGroups().then(function (response) {
                  handleSearchResults(response.data.dataModel, groupText);
                });
              }
            } else if ($ctrl.searchGroup === userText) {
              // if availableUsers is defined, use it rather than call API to get all users
              if ($ctrl.availableUsers) {
                handleSearchResults($ctrl.availableUsers, userText);
              } else {
                smartSelectService.getUsers().then(function (response) {
                  smartSelectService.setHasMoreResults(
                    response.data.hasMoreResults
                  );
                  handleSearchResults(
                    response.data.userDetailForModals,
                    userText
                  );
                });
              }
            } else if ($ctrl.searchGroup === approverText) {
              // if availableUsers is defined, use it rather than call API to get all users
              if ($ctrl.availableUsers) {
                handleSearchResults($ctrl.availableUsers, approverText);
              } else {
                smartSelectService
                  .getAvailableApprovers($ctrl.filterBy)
                  .then(function (response) {
                    smartSelectService.setHasMoreResults(
                      response.data.dataModel.hasMoreResults
                    );
                    handleSearchResults(
                      response.data.dataModel.availableStepApprovers,
                      userText
                    );
                  });
              }
            } else if ($ctrl.searchGroup === stepFormFieldText) {
              handleSearchResults(
                $ctrl.availableEmailFields,
                stepFormFieldText
              );
            }
          }

          function handleSearchResults(results, searchGroup) {
            //update the list and notify parent
            $ctrl.searchResults = results;
            $ctrl.onResultsListUpdated({
              results: $ctrl.searchResults,
              searchGroup: searchGroup
            });
          }
        }
      ],
      template: `
                <div class="smart-select-search">
                    <h4 ng-if="$ctrl.showTitle" class="row-title">Search</h4>
                    <ul class="inline-list">
                        <li><input type="radio" name="radiouser{{$ctrl.uniqueId}}" id="radiouser{{$ctrl.uniqueId}}" ng-model="$ctrl.searchGroup" value="User" ng-change="$ctrl.search()" /><label for="radiouser{{$ctrl.uniqueId}}">User</label></li>
                        <li><input type="radio" name="radiogroup{{$ctrl.uniqueId}}" id="radiogroup{{$ctrl.uniqueId}}" ng-model="$ctrl.searchGroup" value="Group" ng-change="$ctrl.search()"/><label for="radiogroup{{$ctrl.uniqueId}}">Team</label></li>
                        <li ng-if="$ctrl.isEmailRecipients"><input type="radio" name="radiogroup{{$ctrl.uniqueId}}" id="radiostepformfield{{$ctrl.uniqueId}}" ng-model="$ctrl.searchGroup" value="StepFormField" ng-change="$ctrl.search()"/><label for="radiostepformfield{{$ctrl.uniqueId}}">Step/Form Field</label></li>
                    </ul>
                </div>
            `
    });
})();
