/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/11e594fa4c9196ad6d315186280fd1f29ec2c68d/src/Flowingly.Shared.Angular/flowingly.components/formula/formula.config.component.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('formulaConfig', {
    bindings: {
      field: '=',
      allFields: '<',
      cells: '<',
      onFieldChange: '&'
    },
    templateUrl: 'formula.config.component.tmpl.html',
    controller: [
      '$timeout',
      'pubsubService',
      'validationService',
      'flowinglyModelUtilityService',
      'flowinglyConstants',
      function (
        $timeout,
        pubsubService,
        validationService,
        flowinglyModelUtilityService,
        flowinglyConstants
      ) {
        let $ctrl = this;
        let element;
        let currentFocus = -1;
        const cursor = '&zwnj;';
        $ctrl.error = false;
        $ctrl.mathOperators = ['+', '-', '*', '/'];
        $ctrl.brackets = ['(', ')'];
        $ctrl.$onInit = () => {
          $timeout(init, 500);
        };
        var OPRTYPE = {
          MATH: 'math',
          BRACKET: 'bracket',
          OPERAND: 'operand',
          TEXT: 'text'
        };

        function init() {
          $ctrl.isTable = () => $ctrl.cells !== undefined;
          pubsubService.subscribe(
            'WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED',
            onFormFieldsChanged
          );
          let fieldname = $ctrl.field.name ? $ctrl.field.name : '';
          $ctrl.field.placeholder = '';
          element = document.getElementById(`${fieldname}_formulaBuilder`);
          if ($ctrl.field.formulaConfig === undefined) {
            $ctrl.field.formulaConfig = {
              formula: '',
              formulaOperands: [],
              displayFormula: ''
            };
            element.innerHTML = '=';
            placeCaretAtEnd(element);
          } else {
            updateFormula();
          }
          element.addEventListener('click', setAutoCompleteSettings);
          element.addEventListener('input', setAutoCompleteSettings);
          element.addEventListener('keyup', updateFormulaConfig);
          element.addEventListener('mouseup', updateFormulaConfig);
          element.addEventListener('keydown', onKeyDown);
        }

        function onKeyDown(e) {
          var autoListElement: any = document.getElementById(
            this.id + '_autocomplete-list'
          );
          var target = window.getSelection().focusNode;
          if (autoListElement)
            autoListElement = autoListElement.getElementsByTagName('div');
          if (e.keyCode === 40) {
            //down
            currentFocus++;
            addActive(autoListElement);
          } else if (e.keyCode === 38) {
            //up
            currentFocus--;
            addActive(autoListElement);
          } else if (e.keyCode === 13) {
            //enter
            e.preventDefault();
            if (currentFocus > -1) {
              if (autoListElement) autoListElement[currentFocus].click();
            }
          } else if (e.keyCode === 8) {
            //backspace
            if (target && target.nodeType === Node.TEXT_NODE) {
              if (target.nodeValue === '\u200C') {
                target.parentNode.removeChild(target.previousSibling);
                return false;
              } else if (
                target.nodeValue === '=\u200C' ||
                target.nodeValue === '='
              ) {
                e.preventDefault();
              }
            }
          } else if (e.keyCode === 46) {
            //delete
            if (target && target.nodeType === Node.TEXT_NODE) {
              if (target.nodeValue === '\u200C') {
                target.parentNode.removeChild(target.nextSibling);
                return false;
              } else if (
                target.nodeValue === '=\u200C' ||
                target.nodeValue === '='
              ) {
                if (getCaretPosition(element) === 2) {
                  target.parentNode.removeChild(target.nextSibling);
                }
                e.preventDefault();
              }
            }
          } else {
            placeCaretAtEnd(element);
          }
        }

        function setAutoCompleteSettings() {
          var currentSelection = true;
          $ctrl.error = false;
          $ctrl.errorMessage = '';
          var cursorPositionElement: any = window
            .getSelection()
            .getRangeAt(0).commonAncestorContainer;
          var previousElemet = cursorPositionElement.previousSibling;
          var cursorPosChild =
            cursorPositionElement !== element
              ? previousElemet
              : element.lastChild;
          currentFocus = -1;
          var filterText: any = '';
          if (cursorPosChild === null || !cursorPosChild) {
            if (isSpan(cursorPositionElement.parentNode)) {
              currentSelection = getPreviousElementOperation(
                cursorPositionElement
              );
            } else {
              currentSelection = getPreviousElementOperation(
                cursorPositionElement.parentNode
              );
              filterText = cursorPositionElement.parentNode.innerText;
            }
          } else {
            currentSelection = getPreviousElementOperation(cursorPosChild);
            filterText = cursorPositionElement.nodeValue;
          }

          filterText = replaceText(filterText) || '';
          if (filterText.indexOf('=') === 0) {
            filterText = filterText.replace('=', '');
          }
          if (!isNaN(filterText) && filterText.length > 0) {
            filterText = '';
            currentSelection = false;
            updateFormulaConfig();
          }
          buildAutoPopulate(
            currentSelection,
            filterText,
            cursorPositionElement,
            cursorPosChild === null || !cursorPosChild,
            filterText.length > 0 && filterText[0] === '@'
          );
        }

        function getItemType(item) {
          if (
            item.type === flowinglyConstants.formFieldType.LOOKUP ||
            item.type === flowinglyConstants.tableCellType.LOOKUP
          ) {
            return item.lookupConfig
              ? item.lookupConfig.displayValueType
              : item.type;
          } else if (
            item.type === flowinglyConstants.formFieldType.DROPDOWN_LIST ||
            item.type === flowinglyConstants.tableCellType.DROPDOWN
          ) {
            if (
              item.dbDataSource &&
              Array.isArray(item.dbDataSource.displayValueOptions)
            ) {
              const matchingOption = item.dbDataSource.displayValueOptions.find(
                (option) => option.name === item.dbDataSource.displayValue
              );
              return matchingOption?.dataType;
            }
          }
          return item.type;
        }

        function isNumericOperand(item) {
          var type = getItemType(item);
          return (
            type === flowinglyConstants.formFieldType.NUMBER ||
            type === flowinglyConstants.formFieldType.CURRENCY ||
            type === flowinglyConstants.tableCellType.NUMBER ||
            type === flowinglyConstants.tableCellType.CURRENCY
          );
        }

        function isTextOperand(item) {
          var type = getItemType(item);
          return (
            type === flowinglyConstants.formFieldType.TEXT ||
            type === flowinglyConstants.tableCellType.TEXT
          );
        }

        function isOperand(item) {
          return isNumericOperand(item) || isTextOperand(item);
        }

        function getOperands(filter = (item) => isOperand(item)) {
          var operands = [];
          if ($ctrl.isTable()) {
            operands = operands.concat(
              $ctrl.cells
                .filter((item) => filter(item))
                .map((item) => ({
                  id: item.id,
                  name: flowinglyConstants.formulaOperandPrefix.CELL + item.id,
                  displayName: item.header,
                  type: getItemType(item)
                }))
            );
          } else {
            const updatedFields = getFieldUpdate();
            operands = operands.concat(
              updatedFields
                .filter((item) => filter(item))
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  displayName: item.displayName,
                  type: getItemType(item)
                }))
            );
          }
          return operands;
        }

        function getFieldUpdate() {
          let fields = [];
          $ctrl.allFields.forEach((element) => {
            switch (element.type) {
              case flowinglyConstants.formFieldType.TABLE:
                fields = fields.concat(
                  flowinglyModelUtilityService.transformTableCellToField(
                    flowinglyModelUtilityService.getEligibleCellsInFields([
                      element
                    ])
                  )
                );
                break;
              default:
                fields = fields.concat(element);
                break;
            }
          });
          return fields;
        }

        function buildAutoPopulate(
          currentSelection,
          filterText?,
          cursorPositionElement?,
          previousChildExists?,
          brackets?
        ) {
          closeAllLists();
          if (filterText === undefined) filterText = '';
          $ctrl.Operands = getOperands();
          $ctrl.Operators = $ctrl.mathOperators;
          if ($ctrl.field.formulaConfig.formulaOperands.length > 0) {
            var fieldFound = $ctrl.Operands.find(
              (item) =>
                item.name === $ctrl.field.formulaConfig.formulaOperands[0]
            );
            if (isNumericOperand(fieldFound)) {
              $ctrl.Operands = getOperands((item) => isNumericOperand(item));
              $ctrl.Operators = $ctrl.mathOperators;
            } else {
              $ctrl.Operands = getOperands((item) => isTextOperand(item));
              $ctrl.Operators = ['+'];
            }
          }
          var autoList = document.createElement('DIV');
          autoList.setAttribute('id', element.id + '_autocomplete-list');
          autoList.setAttribute('class', 'autocomplete-items');
          element.parentNode.appendChild(autoList);
          autoList.addEventListener('mouseleave', closeAllLists);
          var autoListData = [];
          autoListData = angular.copy(
            currentSelection ? $ctrl.Operands : $ctrl.Operators,
            []
          );
          if (currentSelection) {
            autoListData.push({ displayName: '(', name: '(' });
            autoListData.push({ displayName: ')', name: ')' });
          } else {
            autoListData.push('(');
            autoListData.push(')');
          }
          if (filterText.length > 0) {
            autoListData = autoListData.filter((e) => {
              var propName = currentSelection ? e.displayName : e;
              return (
                propName.toUpperCase().indexOf(filterText.toUpperCase()) !== -1
              );
            });
          }
          for (var i = 0; i < autoListData.length; i++) {
            var autoListElement = document.createElement('DIV');
            var text = currentSelection
              ? autoListData[i].displayName
              : autoListData[i];
            var value = currentSelection
              ? autoListData[i].name
              : autoListData[i];
            autoListElement.innerHTML = '<strong>' + text + '</strong>';
            autoListElement.innerHTML +=
              "<input type='hidden' value='" + text + "'>";
            autoListElement.innerHTML +=
              "<input type='hidden' value='" + value + "'>";
            autoListElement.addEventListener('click', function (e) {
              var valueofSelected = this.getElementsByTagName('input')[1].value;
              var isOperator =
                $ctrl.mathOperators.indexOf(valueofSelected) !== -1;
              var isBracket = $ctrl.brackets.indexOf(valueofSelected) !== -1;
              var insertTmpl =
                "<span class='auto-completed-selected' contenteditable='false' readonly='readonly' unselectable='on'  auto-selected-value=" +
                valueofSelected +
                '>' +
                this.getElementsByTagName('input')[0].value +
                '</span>';
              if (cursorPositionElement && cursorPositionElement !== element) {
                var div: any = document.createElement('div');
                div.innerHTML = insertTmpl;
                var filter = (Object as any).assign(
                  '',
                  cursorPositionElement.data
                );
                if (
                  previousChildExists &&
                  isSpan(cursorPositionElement.parentNode)
                ) {
                  cursorPositionElement.parentNode.setAttribute(
                    'auto-selected-value',
                    div.firstChild.getAttribute('auto-selected-value')
                  );
                  cursorPositionElement.parentNode.innerHTML =
                    div.firstChild.innerHTML;
                } else {
                  if (element.childNodes.length === 1) {
                    if (
                      isNaN(
                        replaceText(
                          cursorPositionElement.nodeValue.replace('=', '')
                        )
                      )
                    )
                      cursorPositionElement.nodeValue = '=';
                  } else {
                    var clear = true;
                    if (isOperator || isBracket) {
                      clear = isNaN(
                        replaceText(cursorPositionElement.nodeValue)
                      );
                    }
                    if (clear) cursorPositionElement.nodeValue = '';
                  }

                  if (cursorPositionElement.nextSibling)
                    element.insertBefore(
                      div.firstChild,
                      cursorPositionElement.nextSibling
                    );
                  else element.appendChild(div.firstChild);
                }
              } else {
                var lstNode = element.childNodes[element.childNodes.length - 1];
                if (lstNode.nodeType === Node.TEXT_NODE) {
                  if (element.childNodes.length === 1) lstNode.nodeValue = '=';
                  else lstNode.remove();
                }
                element.innerHTML += insertTmpl;
              }
              insertChar();
              updateFormulaConfig();
              var currentSelection = true;
              if ($ctrl.mathOperators.indexOf(valueofSelected) !== -1) {
                currentSelection = true;
              } else {
                currentSelection = false;
              }
              if (!previousChildExists) buildAutoPopulate(currentSelection);
            });
            autoList.appendChild(autoListElement);
          }
        }

        function closeAllLists(elmnt?) {
          var x = document.getElementsByClassName('autocomplete-items');
          for (var i = 0; i < x.length; i++) {
            if (elmnt !== x[i] && elmnt !== element) {
              x[i].parentNode.removeChild(x[i]);
            }
          }
        }

        function addActive(x) {
          if (!x) return false;
          removeActive(x);
          if (currentFocus >= x.length) currentFocus = 0;
          if (currentFocus < 0) currentFocus = x.length - 1;
          x[currentFocus].classList.add('autocomplete-active');
        }

        function removeActive(x) {
          for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
          }
        }

        function updateFormulaConfig() {
          let b = document.createElement('DIV');
          b.innerHTML = element.innerHTML;
          b.innerHTML = replaceText(b.innerHTML, true);
          $ctrl.field.placeholder = b.innerText;
          $ctrl.field.formulaConfig.displayFormula = b.innerHTML;
          $ctrl.field.formulaConfig.formulaOperands = [];
          for (var i = 0; i < element.children.length; i++) {
            var child: any = b.children[0];
            var type = typeOfOperand(child);
            if (type !== OPRTYPE.TEXT) {
              var selectedval = child.attributes.getNamedItem(
                'auto-selected-value'
              ).value;
              if (type === OPRTYPE.OPERAND) {
                var fieldFound = getOperands().find(
                  (e) => e.name === selectedval
                );
                if (fieldFound) {
                  $ctrl.field.formulaConfig.formulaOperands.push(selectedval);
                  child.replaceWith(selectedval);
                }
              } else {
                child.replaceWith(selectedval);
              }
            }
          }
          $ctrl.field.formulaConfig.formula = b.innerHTML.trim();
          $ctrl.onFieldChange({ cell: $ctrl.field });
          validateFormula();
        }

        function updateFormula() {
          var oldDisplayFormula = $ctrl.field.formulaConfig.displayFormula;
          let b = document.createElement('DIV');
          b.innerHTML = angular.copy(oldDisplayFormula, '');
          b.innerHTML = replaceText(b.innerHTML, true);
          if (b.innerHTML.length === 0) b.innerHTML = '=';
          for (var i = 0; i < b.children.length; i++) {
            var child: any = b.children[i];
            var type = typeOfOperand(child);
            if (type === OPRTYPE.OPERAND) {
              var selectedval = child.attributes.getNamedItem(
                'auto-selected-value'
              ).value;
              var fieldFound = getOperands().find(
                (e) => e.name === selectedval
              );
              if (fieldFound) {
                child.innerText = fieldFound.displayName;
              }
            }
          }
          element.innerHTML = b.innerHTML;
          $ctrl.field.placeholder = b.innerText;
          $ctrl.onFieldChange({ cell: $ctrl.field });
          insertChar();
        }

        function validateFormula() {
          if ($ctrl.field.formulaConfig.displayFormula) {
            let b: any = document.createElement('DIV');
            b.innerHTML = angular.copy(
              $ctrl.field.formulaConfig.displayFormula,
              ''
            );
            b.innerHTML = replaceText(b.innerHTML);
            var textNodes = [],
              operatorNodes = [];
            b.childNodes.forEach(function (n) {
              if (n.nodeType === Node.TEXT_NODE) {
                if (n.nodeValue && n.nodeValue !== '=') textNodes.push(n);
              } else {
                operatorNodes.push(n);
              }
            });
            if (textNodes.length > 0) {
              for (var k = 0; k < textNodes.length; k++) {
                var txt = angular.copy(textNodes[k].nodeValue, '');
                if (b.firstChild === textNodes[k]) {
                  txt = txt.replace('=', '');
                }
                txt = replaceText(txt);
                isValid = !isNaN(txt);
                if (isValid) {
                  if (textNodes[k].previousSibling) {
                    var previousType = typeOfOperand(
                      textNodes[k].previousSibling
                    );
                    isValid = previousType !== OPRTYPE.OPERAND;
                  }
                  if (textNodes[k].nextSibling && isValid) {
                    var nextType = typeOfOperand(textNodes[k].nextSibling);
                    isValid = nextType !== OPRTYPE.OPERAND;
                  }
                }
                if (!isValid) break;
              }
              if (!isValid) {
                $ctrl.error = true;
                $ctrl.errorMessage = 'Text is not allowed';
                return;
              }
            }
            var previousOperand = null;
            var isValid = true;
            operatorNodes.forEach(function (opNode) {
              var type = typeOfOperand(opNode);
              if (type === OPRTYPE.MATH || type === OPRTYPE.OPERAND) {
                if (
                  previousOperand !== null &&
                  previousOperand === type &&
                  isValid
                ) {
                  $ctrl.error = true;
                  $ctrl.errorMessage = 'Invalid expression';
                  isValid = false;
                }
                previousOperand = type;
              }
            });
            if (!isValid) return;
            var formula = angular.copy($ctrl.field.formulaConfig.formula, '');
            if (formula[0] !== '=') {
              $ctrl.error = true;
              $ctrl.errorMessage = 'Invalid expression';
              return;
            }
            formula = formula.replace('=', '');
            var stringDataTypeFound = false;
            for (
              var i = 0;
              i < $ctrl.field.formulaConfig.formulaOperands.length;
              i++
            ) {
              var operand = $ctrl.field.formulaConfig.formulaOperands[i];
              var oprandField = getOperands().find(
                (item) => item.name === operand
              );
              if (oprandField) {
                var replaceVal = isNumericOperand(oprandField)
                  ? new Number(1)
                  : new String('t');
                formula = formula.replace(operand, replaceVal);
                if (isTextOperand(oprandField)) {
                  stringDataTypeFound = true;
                }
              } else {
                $ctrl.error = true;
                $ctrl.errorMessage = 'Invalid expression';
              }
            }
            try {
              formula = formula.trim();
              if (stringDataTypeFound) {
                $ctrl.mathOperators.forEach((e) => {
                  if (e !== '+' && formula.indexOf(e) !== -1) {
                    $ctrl.error = true;
                    $ctrl.errorMessage = 'Invalid expression';
                  }
                });
              } else {
                eval(formula);
              }
              $ctrl.error = false;
              $ctrl.errorMessage = '';
            } catch (e) {
              $ctrl.error = true;
              $ctrl.errorMessage = 'Invalid expression';
            }
          }
        }

        function onFormFieldsChanged() {
          updateFormula();
        }

        function insertChar() {
          element.innerHTML = replaceText(element.innerHTML);
          let div = document.createElement('div');
          for (var i = 0; i < element.childNodes.length; i++) {
            var node = element.childNodes[i];
            if (isSpan(node)) {
              node.setAttribute('contenteditable', 'false');
              node.innerHTML = '&nbsp;' + node.innerHTML + '&nbsp;';
            }
            div.innerHTML +=
              node.nodeType === Node.TEXT_NODE
                ? node.nodeValue
                : node.outerHTML;
            div.innerHTML += cursor;
          }
          element.innerHTML = div.innerHTML;
        }

        function isSpan(node) {
          return node && node.nodeName.toUpperCase() === 'SPAN';
        }

        function getCaretPosition(ele) {
          var caretPos = 0,
            sel,
            range;
          if (window.getSelection) {
            sel = window.getSelection();
            if (sel.rangeCount) {
              range = sel.getRangeAt(0);
              if (range.commonAncestorContainer.parentNode === ele) {
                caretPos = range.endOffset;
              }
            }
          } else if (
            (document as any).selection &&
            (document as any).selection.createRange
          ) {
            range = (document as any).selection.createRange();
            if (range.parentElement() === ele) {
              var tempEl = document.createElement('span');
              ele.insertBefore(tempEl, ele.firstChild);
              var tempRange = range.duplicate();
              tempRange.moveToElementText(tempEl);
              tempRange.setEndPoint('EndToEnd', range);
              caretPos = tempRange.text.length;
            }
          }
          return caretPos;
        }

        function placeCaretAtEnd(el) {
          el.focus();
          if (
            typeof window.getSelection != 'undefined' &&
            typeof document.createRange != 'undefined'
          ) {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          } else if (
            typeof (document.body as any).createTextRange != 'undefined'
          ) {
            var textRange = (document.body as any).createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
          }
        }

        function getPreviousElementOperation(currentElement) {
          var selElment = currentElement;
          var previousElementFound = false;
          var currentSelection = true;
          while (!previousElementFound) {
            if (selElment === element) {
              currentSelection = true;
              previousElementFound = true;
            } else {
              if (selElment.previousSibling) {
                var type = typeOfOperand(selElment);
                if (type === OPRTYPE.TEXT) {
                  var txt = angular.copy(selElment.nodeValue);
                  txt = replaceText(txt);
                  if (txt === '=') {
                    currentSelection = true;
                    previousElementFound = true;
                  } else if (txt.length > 0 && !isNaN(txt)) {
                    currentSelection = false;
                    previousElementFound = true;
                  } else {
                    selElment = selElment.previousSibling;
                  }
                } else if (type !== OPRTYPE.BRACKET) {
                  previousElementFound = true;
                  currentSelection = type === OPRTYPE.MATH;
                } else {
                  selElment = selElment.previousSibling;
                }
              } else {
                if (isSpan(selElment.parentNode)) {
                  var stype = typeOfOperand(selElment.parentNode);
                  previousElementFound = true;
                  if (stype !== OPRTYPE.BRACKET)
                    currentSelection = stype !== OPRTYPE.MATH;
                } else {
                  previousElementFound = true;
                }
              }
            }
          }
          return currentSelection;
        }

        function typeOfOperand(ele) {
          if (
            isSpan(ele) &&
            ele.attributes.getNamedItem('auto-selected-value')
          ) {
            var spanVal = ele.attributes.getNamedItem(
              'auto-selected-value'
            ).value;
            if ($ctrl.brackets.indexOf(spanVal) !== -1) return OPRTYPE.BRACKET;
            else if ($ctrl.mathOperators.indexOf(spanVal) !== -1)
              return OPRTYPE.MATH;
            else return OPRTYPE.OPERAND;
          } else {
            return OPRTYPE.TEXT;
          }
        }

        function replaceText(txt, noSpace?) {
          if (txt && txt !== null) {
            txt = txt.replace(/\u200C/g, '');
            if (!noSpace) txt = txt.replace(/&nbsp;/g, '');
          }
          return txt;
        }
      }
    ]
  });
})();
